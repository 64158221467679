import { createSelector, OutputSelector } from 'reselect';
import { State } from '../combinedReducers';
import { ExerciseLibraryEnum } from '../../enums/ExerciseLibraryEnum';
import { ExerciseDTO } from '../../strapi/ExerciseDTO';
import { FilterDTO } from '../../strapi/FilterDTO';
import { Filter } from '../../interfaces/filter';
import { FiltersDTO } from '../../strapi/FiltersDTO';
import { TrainingDTO } from '../../strapi/TrainingDTO';
import { stringNoDiacritics } from '../../code/helpers/general';

export const exercisesReducer = (state: State): ExerciseDTO[] =>
  state.exercises.exercises;
export const searchQueryReducer = (state: State): string =>
  state.exercises.searchQuery;
export const activeFiltersReducer = (state: State): Filter =>
  state.exercises.activeFilters;
export const filtersReducer = (state: State): FiltersDTO | null =>
  state.exercises.filters;
export const visibleCountReducer = (state: State): number =>
  state.exercises.visibleCount;
export const activeTrainingReducer = (state: State): TrainingDTO | undefined =>
  state.trainings.selectedTraining;
export const activeFiltersCount = (state: State): number => {
  let count = 0;
  Object.values(state.exercises.activeFilters).forEach(filter => {
    count += filter.length;
  });

  return count;
};

export const getFilteredExercises = createSelector(
  exercisesReducer,
  activeFiltersReducer,
  searchQueryReducer,
  visibleCountReducer,
  (exercises, activeFilters, searchQuery) => {
    let exercisesFiltered: ExerciseDTO[] = exercises.filter(
      item =>
        stringNoDiacritics(item.title.text.toLowerCase()).search(
          stringNoDiacritics(searchQuery.toLowerCase()),
        ) !== -1,
    );

    // Filter by Equipment
    if (activeFilters.equipment_items.length > 0) {
      exercisesFiltered = exercisesFiltered.filter(item =>
        item.equipment_items.some((r: FilterDTO) =>
          activeFilters.equipment_items.includes(r.id),
        ),
      );
    }

    // Filter by Body Parts
    if (activeFilters['body-part'].length > 0) {
      exercisesFiltered = exercisesFiltered.filter(item =>
        item.parts.some((r: FilterDTO) =>
          activeFilters['body-part'].includes(r.id),
        ),
      );
    }

    // Filter by Exercise Type
    if (activeFilters['exercise-type'].length > 0) {
      exercisesFiltered = exercisesFiltered.filter(item =>
        activeFilters['exercise-type'].includes(item.type.id),
      );
    }

    // Filter by Library
    if (
      !(
        activeFilters.library.includes(ExerciseLibraryEnum.Motivibe) &&
        activeFilters.library.includes(ExerciseLibraryEnum.My)
      )
    ) {
      if (activeFilters.library.includes(ExerciseLibraryEnum.Motivibe)) {
        exercisesFiltered = exercisesFiltered.filter(
          item => item.isMotivibeLibrary === true,
        );
      }

      if (activeFilters.library.includes(ExerciseLibraryEnum.My)) {
        exercisesFiltered = exercisesFiltered.filter(
          item =>
            item.isMotivibeLibrary === false || item.isMotivibeLibrary === null,
        );
      }
    }

    return exercisesFiltered;
  },
);
// Sort exercises according to set filters in training plan and filter them according to chosen filters and search
export const getFilteredExercisesWithSorting = createSelector(
  exercisesReducer,
  activeFiltersReducer,
  searchQueryReducer,
  activeTrainingReducer,
  (exercises, activeFilters, searchQuery, selectedTraining) => {
    let exercisesFiltered: ExerciseDTO[] = exercises.filter(
      item =>
        stringNoDiacritics(item.title.text.toLowerCase()).search(
          stringNoDiacritics(searchQuery.toLowerCase()),
        ) !== -1,
    );
    // sort it according to body parts in training
    if (selectedTraining) {
      selectedTraining.body_part_names.map(bodyPart => {
        exercisesFiltered.sort((a: ExerciseDTO, b: ExerciseDTO) => {
          // if a is in body part then sort it that a is before b
          if (
            a.parts.find(part => part.id === bodyPart.id) &&
            !b.parts.find(part => part.id === bodyPart.id)
          ) {
            return -1;
          }
          return 0;
        });
        return bodyPart;
      });
    }
    // Filter by Equipment
    if (activeFilters.equipment_items.length > 0) {
      exercisesFiltered = exercisesFiltered.filter(item =>
        item.equipment_items.some((r: FilterDTO) =>
          activeFilters.equipment_items.includes(r.id),
        ),
      );
    }

    // Filter by Body Parts
    if (activeFilters['body-part'].length > 0) {
      exercisesFiltered = exercisesFiltered.filter(item =>
        item.parts.some((r: FilterDTO) =>
          activeFilters['body-part'].includes(r.id),
        ),
      );
    }

    // Filter by Exercise Type
    if (activeFilters['exercise-type'].length > 0) {
      exercisesFiltered = exercisesFiltered.filter(item =>
        activeFilters['exercise-type'].includes(item.type.id),
      );
    }

    // Filter by Library
    if (
      !(
        activeFilters.library.includes(ExerciseLibraryEnum.Motivibe) &&
        activeFilters.library.includes(ExerciseLibraryEnum.My)
      )
    ) {
      if (activeFilters.library.includes(ExerciseLibraryEnum.Motivibe)) {
        exercisesFiltered = exercisesFiltered.filter(
          item => item.isMotivibeLibrary === true,
        );
      }

      if (activeFilters.library.includes(ExerciseLibraryEnum.My)) {
        exercisesFiltered = exercisesFiltered.filter(
          item =>
            item.isMotivibeLibrary === false || item.isMotivibeLibrary === null,
        );
      }
    }
    return exercisesFiltered;
  },
);
export const getAllExercisesCount = createSelector(
  exercisesReducer,
  exercise => exercise.length,
);

export const getFiltersForSelect = createSelector(filtersReducer, filters => {
  const modifiedFilter: FiltersDTO = {
    'body-part': [],
    'exercise-type': [],
    equipment_items: [],
  };

  if (filters) {
    Object.keys(filters).forEach((itemKey: string) => {
      // @ts-ignore
      modifiedFilter[itemKey] = filters[itemKey].map(item => ({
        value: item.id,
        text: item.name || item.type,
      }));
    });

    return modifiedFilter;
  }

  return null;
});

export const getExerciseById = (
  id: string,
): OutputSelector<
  State,
  ExerciseDTO | undefined,
  (res: ExerciseDTO[]) => ExerciseDTO | undefined
> =>
  createSelector(exercisesReducer, exerciseReducer =>
    exerciseReducer.find(exercise => exercise.id === id),
  );

export const getPaginatedExercises = createSelector(
  getFilteredExercises,
  visibleCountReducer,
  (exercises, visibleCount) => ({
    exercises: exercises.slice(0, visibleCount),
    countOfFiltered: exercises.length,
  }),
);
