import { OptionsObject } from 'notistack';
import prettyBytes from 'pretty-bytes';
import { ReactNode } from 'react';

/**
 * check the file size is within the limit
 * if fileSize is in limit callback will be called
 * else warning message will be shown
 * @param fileSize
 * @param callback
 * @param enqueueSnackbar in component use const { enqueueSnackbar } = useSnackbar(); from notistack library
 */
export const checkUploadedFileSize = (
  fileSize: number,
  callback: () => void,
  enqueueSnackbar: (
    message: ReactNode,
    options?: OptionsObject | undefined,
  ) => string | number | null | undefined,
): void => {
  const maxFileSize = 50000000;
  if (fileSize < maxFileSize) {
    callback();
  } else {
    enqueueSnackbar(
      `Velikost vašeho souboru je ${prettyBytes(
        fileSize,
      )}. Maximální velikost je ${prettyBytes(maxFileSize)}`,
      {
        variant: 'warning',
      },
    );
  }
};
