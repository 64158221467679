/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Trans } from '@lingui/macro';
import { Grid } from '@material-ui/core';
import { COLORS, MPX } from '../../../../../styles/themes';

interface SeriesRepeatWeightPauseRowProps {
  notSeries?: boolean;
  notRepeats?: boolean;
  notWeights?: boolean;
  notPause?: boolean;
  isCircuit?: boolean;
}

const SerieRepeatWeightPauseRow: React.FC<SeriesRepeatWeightPauseRowProps> = ({
  notSeries,
  notRepeats,
  notWeights,
  notPause,
  isCircuit,
}) => (
  <div>
    <Grid container direction="row" alignItems="center" css={gridCss}>
      <Grid item container justify="center" xs={3}>
        {!notSeries && !isCircuit && <Trans>Série</Trans>}
        {!notSeries && isCircuit && <Trans>Kruhy</Trans>}
      </Grid>
      <Grid item container justify="center" xs={3}>
        {!notRepeats && <Trans>Opakování</Trans>}
      </Grid>
      <Grid item container justify="center" xs={3}>
        {!notWeights && <Trans>Váha</Trans>}
      </Grid>
      <Grid item container justify="center" xs={3}>
        {!notPause && <Trans>Pauza</Trans>}
      </Grid>
    </Grid>
  </div>
);

export default SerieRepeatWeightPauseRow;

const gridCss = css`
  color: ${COLORS.gray4};
  font-size: large;
  padding-top: ${MPX * 0.5}vh;
`;
