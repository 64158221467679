import { v4 as uuid } from 'uuid';
import {
  TrainingExerciseWithOperation,
  TrainingSectionPartWithOperation,
  TrainingSectionWithOperation,
} from '../../interfaces/traning';
import {
  TrainingExerciseDTO,
  TrainingSectionPartsDTO,
  TrainingSectionDTO,
} from '../../strapi/TrainingDTO';
import { OperationEnum } from '../../enums/OperationEnum';
import { TrainingSectionTypeEnum } from '../../enums/TrainingTypesEnum';
import { ExerciseDTO } from '../../strapi/ExerciseDTO';
import {
  PauseModifierEnum,
  RepeatsModifierEnum,
} from '../../enums/RepeatsModifierEnum';

const operationCheck = (
  ex: TrainingExerciseWithOperation | TrainingSectionPartWithOperation,
): OperationEnum => {
  if (ex.operation === OperationEnum.DELETED) {
    return OperationEnum.DELETED;
  }
  if (ex.operation === OperationEnum.CREATED) {
    return OperationEnum.CREATED;
  }

  return OperationEnum.UPDATED;
};

/**
 * TRAINING SECTION
 */
export const mapTrainingSectionToHaveOperation = (
  section: TrainingSectionDTO,
): TrainingSectionWithOperation => ({
  ...section,
  training_section_parts: section.training_section_parts.map(tsp =>
    mapTrainingSectionPartExerciseDTOWithOperation(tsp),
  ),
});

/**
 * TRAINING SECTION PART (exercise wrapper)
 */
export const mapTrainingSectionPartExerciseDTOWithOperation = (
  tsp: TrainingSectionPartsDTO,
): TrainingSectionPartWithOperation => ({
  ...tsp,
  operation: OperationEnum.DONOTHING,
  training_exercises: tsp.training_exercises.map(te =>
    mapTrainingExerciseDTOWithOperation(te),
  ),
});

export const createTrainingSectionPart = (
  order: number,
  sectionId: string,
  type?: TrainingSectionTypeEnum,
  exercise?: TrainingExerciseWithOperation,
): TrainingSectionPartWithOperation => {
  const newSectionPart: TrainingSectionPartWithOperation = {
    order,
    id: uuid(),
    type: type || TrainingSectionTypeEnum.SingleExercise,
    operation: OperationEnum.CREATED,
    createdAt: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    training_exercises: exercise ? [{ ...exercise, id: uuid() }] : [],
    training_section: sectionId,
    // hlavni pauza u kruhove treninku je defaultne na 30 sekund
    pause: type && type === TrainingSectionTypeEnum.CircuitTraining ? 30 : 10,
    series: 4,
  };

  return newSectionPart;
};

export const updateSectionPartAttribute = (
  ex: TrainingSectionPartWithOperation,
  value: any,
  attribute: string,
): TrainingSectionPartWithOperation => {
  const updatedSectionPart: TrainingSectionPartWithOperation = {
    ...ex,
    [attribute]: value,
    // if section part is newly created, then do not update it, keep it always as CREATED
    operation: operationCheck(ex),
  };

  return updatedSectionPart;
};

/**
 * TRAINING EXERCISE
 */

export const mapTrainingExerciseDTOWithOperation = (
  trEx: TrainingExerciseDTO,
): TrainingExerciseWithOperation => ({
  ...trEx,
  operation: OperationEnum.DONOTHING,
});

export const updateExerciseAttribute = (
  ex: TrainingExerciseWithOperation,
  value: any,
  attribute: string,
): TrainingExerciseWithOperation => {
  const updatedExercise: TrainingExerciseWithOperation = {
    ...ex,
    [attribute]: value,
    operation: operationCheck(ex),
  };

  return updatedExercise;
};

/**
 * Generated main input for repeats or weights
 * @param exercise Exercie that will have updated MAIN REPEATS or WEIGHTS input
 * @param value Number of series
 * @returns updated exercise with new main input for repeats and weights
 */
export const updateExerciseWeightsAndRepeatsAccordingToNumberOfSeries = (
  exercise: TrainingExerciseWithOperation,
  value: number | string,
): TrainingExerciseWithOperation => {
  let repeats = exercise.repeats.split(';');
  let weights = exercise.weights.split(';');

  // if series were decreased then remove values from main input
  if (repeats.length >= value || weights.length >= value) {
    repeats = repeats.slice(0, Number(value));
    weights = weights.slice(0, Number(value));
    // else add the same values as the last serie value one into to the input
  } else {
    repeats.push(
      ...Array(Number(value) - repeats.length).fill(
        repeats[repeats.length - 1],
      ),
    );

    weights.push(
      ...Array(Number(value) - weights.length).fill(
        weights[weights.length - 1],
      ),
    );
  }

  const isSameRepeat = repeats.every(i => repeats[0] === i);
  const isSameWeight = weights.every(i => weights[0] === i);

  return {
    ...exercise,
    repeats: isSameRepeat ? repeats[0] : repeats.join(';'),
    weights: isSameWeight ? weights[0] : weights.join(';'),
  };
};

// when pulled item from exercise library to section setup
export const mapLibraryDTOToTrainingExercise = (
  ex: ExerciseDTO,
): TrainingExerciseWithOperation => {
  const updatedExercise: TrainingExerciseWithOperation = {
    id: uuid(),
    exercise: ex,
    order: 1,
    weights: '0',
    pause: 60,
    repeats: '15',
    series: 4,
    comment: '',
    pauseAfterExercise: 0,
    repeats_modifier: RepeatsModifierEnum.Quantity,
    pause_modifier: PauseModifierEnum.Seconds,
    training_section_part: '',
    operation: OperationEnum.CREATED,
    createdAt: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };

  return updatedExercise;
};

export const DELIMITER = ';';
export const includesDelimiter = (text: string): boolean =>
  text.includes(DELIMITER);
