import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import { AxiosResponse } from 'axios';
import { baseAsyncRequest } from '../actionHelpers';
import { makeServerCallAsync } from '../../code/helpers/api/api';
import { State } from '../combinedReducers';
import { AT as ActionTypes } from '../actionTypes';
import {
  PhotoProgressDTO,
  CreatePhotoProgressDTO,
} from '../../strapi/PhotoProgressDTO';
import {
  MeasureProgressDTO,
  CreateMeasureProgressDTO,
} from '../../strapi/MeasuresProgressDTO';
import {
  WeightProgressDTO,
  CreateWeightProgressDTO,
} from '../../strapi/WeightProgressDTO';
import { ProgressesDTO } from '../../strapi/ProgressesDTO';

// TODO: STATE TYPINGS
export const setProgresses = action(
  ActionTypes.SET_PROGRESSES,
  payload<{ progresses: ProgressesDTO }>(),
);

export const performFetchProgress: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (userId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/fitness-progresses/${userId}`);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_FETCH_PROGRESS,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;

      dispatch(setProgresses({ progresses: data }));
    }
  };

export const performAddWeightProgress: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (weightProgress: CreateWeightProgressDTO, onSuccesCb?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/weight-progresses`, weightProgress);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_ADD_WEIGHT,
      request,
      dispatch,
    );

    if (results) {
      if (onSuccesCb) {
        onSuccesCb();
      }
    }
  };

export const performPatchWeightProgress: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (weightProgresses: WeightProgressDTO[], onSuccesCb?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const progresses = weightProgresses.map((item: any) => ({
      id: item.id,
      operation: item.operation,
      weight: item.weight,
    }));

    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('patch', `/weight-progresses`, progresses);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_PATCH_WEIGHT_PROGRESSES,
      request,
      dispatch,
    );

    if (results) {
      if (onSuccesCb) {
        onSuccesCb();
      }
    }
  };

export const performAddImageProgress: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (imageProgress: CreatePhotoProgressDTO, onSuccesCb?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/photo-progresses`, imageProgress);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_ADD_WEIGHT,
      request,
      dispatch,
    );

    if (results) {
      if (onSuccesCb) {
        onSuccesCb();
      }
    }
  };

export const performPatchPhotoProgress: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (photoProgresses: PhotoProgressDTO[], onSuccesCb?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const progresses = photoProgresses.map(item => ({
      id: item.id,
      operation: item.operation,
    }));

    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('patch', `/photo-progresses`, progresses);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_PATCH_PHOTO_PROGRESSES,
      request,
      dispatch,
    );

    if (results) {
      if (onSuccesCb) {
        onSuccesCb();
      }
    }
  };

export const performAddMeasuresProgress: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (measureProgress: CreateMeasureProgressDTO, onSuccesCb?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/dimensions-progresses`, measureProgress);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_ADD_MEASURES,
      request,
      dispatch,
    );

    if (results) {
      if (onSuccesCb) {
        onSuccesCb();
      }
    }
  };

export const performPatchMeasuresProgress: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (measureProgress: MeasureProgressDTO[], onSuccesCb?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const progresses = measureProgress.map(item => ({
      id: item.id,
      operation: item.operation,
      hips: item.hips,
      waist: item.waist,
      chest: item.chest,
    }));

    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('patch', `/dimensions-progresses`, progresses);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_PATCH_MEASURES_PROGRESSES,
      request,
      dispatch,
    );

    if (results) {
      if (onSuccesCb) {
        onSuccesCb();
      }
    }
  };
