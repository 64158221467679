/** @jsx jsx */
import { css } from '@emotion/react';
import { COLORS } from '../../../styles/themes';

export const titleCss = css`
  color: ${COLORS.white};
  width: 60vw;
  padding: 2vh;
  padding-bottom: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-size: x-large;
`;
