import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import { setProgresses } from './actions';
import { WeightProgressDTO } from '../../strapi/WeightProgressDTO';
import { PhotoProgressDTO } from '../../strapi/PhotoProgressDTO';
import { MeasureProgressDTO } from '../../strapi/MeasuresProgressDTO';

export interface State {
  dimensionsProgresses: MeasureProgressDTO[];
  photoProgresses: PhotoProgressDTO[];
  weightProgresses: WeightProgressDTO[];
}

export const initialState: State = {
  dimensionsProgresses: [],
  photoProgresses: [],
  weightProgresses: [],
};

export const progressesReducer = reducer<State>(
  initialState,
  on(setProgresses, (state: State, { payload }) => {
    state.dimensionsProgresses = payload.progresses.dimensionsProgresses;
    state.photoProgresses = payload.progresses.photoProgresses;
    state.weightProgresses = payload.progresses.weightProgresses;
  }),
);
