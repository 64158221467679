import { createSelector, OutputSelector } from 'reselect';
import { State } from '../combinedReducers';
import {
  LoadingReducerState,
  SuccessReducerState,
  ErrorReducerState,
} from './reducers';

export const loadingReducer = (state: State): LoadingReducerState =>
  state.loading;
export const successReducer = (state: State): SuccessReducerState =>
  state.success;
export const errorReducer = (state: State): ErrorReducerState => state.error;

export const isLoading = (
  actions: string[],
): OutputSelector<State, boolean, (res: LoadingReducerState) => boolean> =>
  createSelector(loadingReducer, loading =>
    // returns true only when all actions is not loading
    actions.some(actionName => loading[actionName]),
  );

export const isSuccessful = (
  actions: string[],
): OutputSelector<State, boolean, (res: SuccessReducerState) => boolean> =>
  createSelector(successReducer, success =>
    // returns true only when all actions are success
    actions.every(actionName => success[actionName]),
  );

export const getError = (
  actions: string[],
): OutputSelector<
  State,
  string | undefined,
  (res: ErrorReducerState) => string | undefined
> =>
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  createSelector(
    errorReducer,
    error => actions.map(actionName => error[actionName])[0],
  );
