import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';

import {
  addTrainerEvent,
  setCalendarClickDate,
  setPlanningMode,
  setTrainerEvents,
  updateTrainerEvent,
} from './actions';
import { EventDTO } from '../../interfaces/event';

export interface State {
  activeDate: Date | undefined;
  // used for planning events
  planningMode: boolean;
  trainerEvents: EventDTO[];
}

export const initialState: State = {
  activeDate: undefined,
  planningMode: false,
  trainerEvents: [],
};

export const calendarReducer = reducer<State>(
  initialState,
  on(setCalendarClickDate, (state: State, { payload }) => {
    const { date } = payload;
    state.activeDate = date;
  }),
  on(setPlanningMode, (state: State, { payload }) => {
    state.planningMode = payload;
  }),
  on(setTrainerEvents, (state: State, { payload }) => {
    state.trainerEvents = payload.trainerEvents;
  }),
  on(addTrainerEvent, (state: State, { payload }) => {
    state.trainerEvents.push(payload.trainerEvent);
  }),
  on(updateTrainerEvent, (state: State, { payload }) => {
    state.trainerEvents = state.trainerEvents.map(event => {
      if (event.id === payload.trainerEvent.id) {
        return payload.trainerEvent;
      }
      return event;
    });
  }),
);
