import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import {
  addClient,
  setClients,
  deleteClient,
  setActiveClient,
  updateClient,
  setSearchQuery,
  clearSearchQuery,
} from './actions';
import { UserDTO } from '../../strapi/UserDTO';

export interface State {
  clients: UserDTO[];
  activeClient: UserDTO | undefined;
  searchQuery: string;
}

export const initialState: State = {
  clients: [],
  activeClient: undefined,
  searchQuery: '',
};

export const clientReducer = reducer<State>(
  initialState,
  on(setClients, (state: State, { payload }) => {
    const { clients } = payload;
    state.clients = clients;
  }),
  on(addClient, (state: State, { payload }) => {
    const { client } = payload;
    state.clients.push(client);
  }),
  on(updateClient, (state: State, { payload }) => {
    const { client } = payload;
    const clientIndex = state.clients.findIndex(item => item.id === client.id);
    state.clients[clientIndex] = client;
  }),
  on(deleteClient, (state: State, { payload }) => {
    const { clientId } = payload;
    state.clients = state.clients.filter(client => client.id !== clientId);
  }),
  on(setActiveClient, (state: State, { payload }) => {
    state.activeClient = payload;
  }),
  on(setSearchQuery, (state: State, { payload }) => {
    state.searchQuery = payload;
  }),
  on(clearSearchQuery, (state: State) => {
    state.searchQuery = '';
  }),
);
