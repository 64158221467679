export enum TrainingPause {
  training = 'training',
  pause = 'pause',
  elapsedTimeOutOfTraining = 'elapsedTimeOutOfTraining',
}

export enum ETrainingBottomMenu {
  StartTraining = 'StartTraining',
  RunningTraining = 'RunningTraining',
  BasicTraining = 'BasicTraining',
  SkipCircleTraining = 'SkipCircleTraining',
  BeforeCircleTraining = 'BeforeCircleTraining',
  FinishedTraining = 'FinishedTraining',
}

export enum TrainingSectionIndex {
  WarmUp = 0,
  Training = 1,
  Stretch = 2,
}
