// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordDto
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordDto
   */
  password: string;
}

export function ResetPasswordDtoFromJSON(json: any): ResetPasswordDto {
  return ResetPasswordDtoFromJSONTyped(json, false);
}

export function ResetPasswordDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResetPasswordDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    token: json['token'],
    password: json['password'],
  };
}

export function ResetPasswordDtoToJSON(value?: ResetPasswordDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token: value.token,
    password: value.password,
  };
}
