/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';
import { Grid } from '@material-ui/core';
import { TrainingExerciseDTO } from '../../../../../strapi/TrainingDTO';
import { COLORS, MPX } from '../../../../../styles/themes';
import { KG } from '../../../shared/constants';

export interface CommentTrainingDescriptionDetailProps {
  trainingExercise: TrainingExerciseDTO;
}

const populateWeightsRepeats = (weights: string[], repeats: string[]) => {
  let populatedRepeats = repeats;
  let populatedWeights = weights;
  if (weights.length > repeats.length) {
    const repeatsValue = repeats[0];
    populatedRepeats = [...Array(weights.length)].map(() => repeatsValue);
  }

  if (weights.length < repeats.length) {
    const weightsValue = weights[0];
    populatedWeights = [...Array(repeats.length)].map(() => weightsValue);
  }

  return { populatedWeights, populatedRepeats };
};

const generateRows = (
  populatedWeights: string[],
  wModifier: string,
  populatedRepeats: string[],
  rModifier: string,
) =>
  populatedWeights.map((_, index) => (
    <Grid item container key={uuidv4()} css={rowCss}>
      <Grid item container justify="center" xs={3}>
        {index + 1}.
      </Grid>
      <Grid item container justify="center" xs={3}>
        {populatedRepeats[index]}
        {rModifier}
      </Grid>
      <Grid item container justify="center" xs={3}>
        {populatedWeights[index]}
        {wModifier}
      </Grid>
      <Grid item xs={3} />
    </Grid>
  ));

const DataTrainingDescriptionDetail: React.FC<CommentTrainingDescriptionDetailProps> =
  ({ trainingExercise }) => {
    const weights = trainingExercise.weights.split(';');
    const repeats = trainingExercise.repeats.split(';');
    const { populatedWeights, populatedRepeats } = populateWeightsRepeats(
      weights,
      repeats,
    );

    return (
      <Grid container direction="row" alignItems="center" css={gridCss}>
        {generateRows(
          populatedWeights,
          KG,
          populatedRepeats,
          trainingExercise.repeats_modifier,
        )}
      </Grid>
    );
  };

export default DataTrainingDescriptionDetail;

const gridCss = css`
  color: ${COLORS.white};
  font-size: x-large;
  padding-top: 2vh;
`;

const rowCss = css`
  padding-top: ${MPX * 0.5}%;
`;
