import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import { AxiosResponse } from 'axios';
import { State } from '../combinedReducers';
import { baseAsyncRequest } from '../actionHelpers';
import { AT as ActionTypes } from '../actionTypes';
import { makeServerCallAsync } from '../../code/helpers/api/api';
import { MessageDTO, MessagePostDTO } from '../../strapi/MessageDTO';

export const setConversation = action(
  ActionTypes.SET_CONVERSATION,
  payload<MessageDTO[]>(),
);

export const updateConversation = action(
  ActionTypes.UPDATE_CONVERSATION,
  payload<MessageDTO>(),
);

export const markAllAsRead = action(
  ActionTypes.MARK_AS_READ,
  payload<string>(),
);

export const fetchConversations: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/messages`);

    const results = await baseAsyncRequest(
      ActionTypes.FETCH_CONVERSATION,
      request,
      dispatch,
      {
        defaultMessage: 'Nezdařilo se stáhnout konverzace.',
      },
    );

    if (results) {
      const { data } = results;
      dispatch(setConversation(data));
    }
  };

export const postMessage: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (postMessageDTO: MessagePostDTO) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/messages`, postMessageDTO);

    const results = await baseAsyncRequest(
      ActionTypes.POST_MESSAGE,
      request,
      dispatch,
      {
        defaultMessage: 'Nezdařilo se poslat správu.',
      },
    );

    if (results) {
      const { data } = results;
      dispatch(updateConversation(data));
    }
  };

export const performAllAsRead: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (recipientId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/messages/readAll/${recipientId}`);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_ALL_AS_READ,
      request,
      dispatch,
      {
        defaultMessage: 'Zprávy nebyly označené jako přečetené.',
      },
    );

    if (results) {
      dispatch(markAllAsRead(recipientId));
    }
  };
