/** @jsx jsx */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { css, jsx } from '@emotion/react';
import { makeStyles } from '@material-ui/core/styles';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { Grid, IconButton } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { COLORS, MPX } from '../../../../styles/themes';
import ProgressBar from './singleExerciseDescriptionComponents/ProgressBar';
import {
  TrainingSectionDTO,
  TrainingSectionPartsDTO,
} from '../../../../strapi/TrainingDTO';

import {
  TrainingSectionTypeEnum,
  TrainingSetWrapperText,
} from '../../../../enums/TrainingTypesEnum';
import PauseExerciseRow from './pauseAfterExerciseComponents/PauseExerciseRow';
import { ClientPath, routePathBuilder, RouterPath } from '../../../../routes';
import { CenteredSpinner } from '../../../shared/Loader';
import {
  buildStringWithSemicolons,
  orderByOrder,
} from '../../helper/functions';
import {
  getCurrentURL,
  isInPauseAfterExercise,
  shouldTakePauseTimeInSeconds,
  trainingIsRunning,
} from '../../../../store/trainingParts/selectors';
import { getSelectedTrainingWithSortedParts } from '../../../../store/training/selectors';
import { performIsInPauseAfterExercise } from '../../../../store/trainingParts/actions';

export interface PauseAfterExerciseProps {
  section: TrainingSectionDTO | undefined;
  sectionPart: TrainingSectionPartsDTO | undefined;
  trainingId: string;
}

let elapsedTrigger: NodeJS.Timeout | null = null;
let exerciseDoneTrigger: NodeJS.Timeout | null = null;

const PauseAfterExercise: React.FC<PauseAfterExerciseProps> = ({
  section,
  sectionPart,
  trainingId,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const training = useSelector(getSelectedTrainingWithSortedParts);
  const isInPauseExercise = useSelector(isInPauseAfterExercise);
  const pauseTotalTime = useSelector(shouldTakePauseTimeInSeconds);
  const currentURL = useSelector(getCurrentURL);
  const isRunning = useSelector(trainingIsRunning);
  const [elapsedTime, setTime] = useState(0);

  useEffect(() => {
    if (isRunning && pauseTotalTime) {
      elapsedTrigger = setTimeout(() => {
        history.push(currentURL);
      }, 1000 * (pauseTotalTime + 1));
    } else if (elapsedTrigger) {
      clearTimeout(elapsedTrigger);
    }

    return () => {
      if (elapsedTrigger) {
        dispatch(performIsInPauseAfterExercise(!isInPauseExercise));
        clearTimeout(elapsedTrigger);
        elapsedTrigger = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pauseTotalTime, isRunning]);

  useEffect(() => {
    if (isRunning && pauseTotalTime) {
      exerciseDoneTrigger = setTimeout(
        () => setTime(prevTime => prevTime + 1),
        1000,
      );
    } else if (exerciseDoneTrigger) {
      clearTimeout(exerciseDoneTrigger);
      setTime(0);
    }

    return () => {
      if (exerciseDoneTrigger) {
        clearTimeout(exerciseDoneTrigger);
        exerciseDoneTrigger = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elapsedTime, isRunning, pauseTotalTime]);

  const progressBar = useMemo(
    () => (
      <ProgressBar
        durationElapsed={elapsedTime || 0}
        durationTotal={pauseTotalTime || 0}
        isPause
        noGo
      />
    ),
    [pauseTotalTime, elapsedTime],
  );

  if (!section || !sectionPart || !training) {
    return <CenteredSpinner message="Připravuji pauzu" />;
  }

  if (!isInPauseExercise) {
    history.push(
      routePathBuilder([RouterPath.Client, ClientPath.Training, trainingId]),
    );
  }

  const orderedTrainingSectionParts: TrainingSectionPartsDTO[] = orderByOrder(
    section.training_section_parts,
  );

  const nextSection = orderedTrainingSectionParts
    .filter(
      tsp => tsp.training_exercises.length > 0 && tsp.order > sectionPart.order,
    )
    .find(tsp => tsp.order > sectionPart.order);

  if (!nextSection) {
    history.push(
      routePathBuilder([RouterPath.Client, ClientPath.Training, trainingId]),
    );
  }
  const nextNumber = nextSection ? nextSection.order + 1 : <Trans>Konec</Trans>;
  const totalNumber = section.training_section_parts.length + 2;

  let nextExercise = '';
  let weightsNextExercise = '';
  if (nextSection) {
    if (nextSection.type === TrainingSectionTypeEnum.SingleExercise) {
      nextExercise = nextSection.training_exercises[0].exercise.title.text;
      weightsNextExercise = nextSection.training_exercises[0].weights;
    } else {
      nextExercise = TrainingSetWrapperText[nextSection.type];
    }
  }

  return (
    <div css={pauseCss}>
      <Grid container justify="center" alignItems="center">
        <IconButton className={classes.timerIcon}>
          <WatchLaterIcon />
        </IconButton>
      </Grid>
      <div css={titleCss}>
        <Trans>Pauza</Trans>
      </div>
      <div css={singleBarCss}>{progressBar}</div>
      <div css={titleCss}>
        <Trans>Následuje</Trans> {nextNumber}/{totalNumber}
      </div>
      <Grid container direction="row" justify="space-between" css={exerciseCss}>
        <Grid item>{nextExercise}</Grid>
        <Grid item>
          {weightsNextExercise &&
            buildStringWithSemicolons(weightsNextExercise)}
        </Grid>
      </Grid>
      {nextSection &&
        nextSection.type !== TrainingSectionTypeEnum.SingleExercise &&
        nextSection?.training_exercises.map(te => (
          <PauseExerciseRow
            title={te.exercise.title.text}
            key={te.id}
            weights={te.weights}
          />
        ))}
    </div>
  );
};

export default PauseAfterExercise;

const useStyles = makeStyles(() => ({
  timerIcon: {
    '& svg': {
      fontSize: 125,
      color: 'white',
    },
  },
}));

const pauseCss = css`
  padding: ${0.5 * MPX}vh;
  color: ${COLORS.white};
`;
const titleCss = css`
  width: 60vw;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-size: x-large;
  padding-top: ${0.5 * MPX}vh;
`;
const singleBarCss = css`
  padding-top: ${0.5 * MPX}vh;
`;
const exerciseCss = css`
  font-size: large;
  color: ${COLORS.white};
  padding-top: ${0.5 * MPX}vh;
`;
