import { MediaFileDTO } from './MediaFileDTO';
import { LocalizedItemDTO, CreateLocalizedItemDTO } from './LocalizedItemDTO';
import { FilterDTO } from './FilterDTO';
import { RepeatsModifierEnum } from '../enums/RepeatsModifierEnum';
import { TrainingExerciseDTO } from './TrainingDTO';

export enum ExerciseTrainingTypeEnum {
  REPEAT = 'repeat',
  TIME = 'time',
}
export interface ExerciseDTO {
  id: string;
  createdAt: string;
  updated_at: string;
  isMotivibeLibrary: boolean;
  hasWeights: boolean;
  hasPause: boolean;
  hasRepeats: boolean;
  isDone: boolean;
  hasSeries: boolean;
  title: LocalizedItemDTO;
  description: LocalizedItemDTO[];
  Illustration: MediaFileDTO[];
  parts: FilterDTO[];
  equipment_items: FilterDTO[];
  type: FilterDTO;
  repeats_modifier: RepeatsModifierEnum;
  exerciseTrainingType: ExerciseTrainingTypeEnum;
  training_exercises: TrainingExerciseDTO[];
}

export interface CreateExerciseDTO {
  hasWeights?: boolean;
  hasPause?: boolean;
  hasRepeats?: boolean;
  hasSeries?: boolean;
  title: CreateLocalizedItemDTO;
  description: CreateLocalizedItemDTO[];
  Illustration?: string[];
  parts: string[];
  equipment_items: string[];
  type: string;
  isMotivibeLibrary?: boolean;
  owner?: string;
  repeats_modifier?: RepeatsModifierEnum;
  exerciseTrainingType: ExerciseTrainingTypeEnum;
}
