import { ActionCreator, Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { State } from '../auth/reducers';
import { makeServerCallAsync } from '../../code/helpers/api/api';
import { baseAsyncRequest } from '../actionHelpers';
import { MediaUploadActionTypes } from '../actionTypes';
import { MediaFileDTO } from '../../strapi/MediaFileDTO';

export const performUploadMedia: ActionCreator<
  ThunkAction<Promise<{ files: MediaFileDTO[] } | null>, State, number, any>
> =
  (files: FormData, onSuccessfulUploadCallback) =>
  async (
    dispatch: Dispatch<Action>,
  ): Promise<{ files: MediaFileDTO[] } | null> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/upload`, files);
    const results = await baseAsyncRequest(
      MediaUploadActionTypes.UPLOAD_FILE,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;
      if (onSuccessfulUploadCallback) {
        onSuccessfulUploadCallback(data);
      }
      return { files: data };
    }

    return null;
  };
