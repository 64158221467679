import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DispatchProps, ClientProgress } from './ClientProgress';
import { State } from '../../store/combinedReducers';
import {
  performFetchProgress,
  performAddWeightProgress,
  performPatchWeightProgress,
} from '../../store/progress/actions';
import { AT } from '../../store/actionTypes';
import { isLoading } from '../../store/request/selectors';
import { clearRS } from '../../store/request/actions';

const requestActions = [AT.UPLOAD_FILE];

const mapStateToProps = (state: State) => ({
  weightProgress: state.progresses.weightProgresses,
  photoProgress: state.progresses.photoProgresses,
  measureProgress: state.progresses.dimensionsProgresses,
  isLoadingAddPhoto: isLoading(requestActions)(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(
    {
      // TODO
      performFetchProgress,
      performAddWeightProgress,
      performPatchWeightProgress,
      clearRequestState: () => dispatch(clearRS(requestActions)),
    },
    dispatch,
  ),
});

const ClientProgressContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientProgress);

export { ClientProgressContainer as default };
