/** @jsx jsx */
import { FC, useCallback } from 'react';
import { jsx, css, SerializedStyles } from '@emotion/react';
import { Trans } from '@lingui/macro';
import { CardContent, Card } from '@material-ui/core';
import { Link, useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Create, Queue, Delete } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { TrainingPlanTableProps } from './TrainingPlanTable';
import {
  ApplicationPath,
  TrainerPath,
  routePathBuilder,
} from '../../../../../routes';
import { TrainingPlanState } from '../../../../../enums/TrainingPlanStateEnum';
import { COLORS, MPX } from '../../../../../styles/themes';
import { LocalTrainingPlanDTO } from '../../../../../strapi/TrainingPlanDTO';
import { PopupMenu } from '../../../../shared/PopupMenu';

export const TrainingPlanCards: FC<TrainingPlanTableProps> = ({
  trainingPlans,
  removeTrainingPlan,
}) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const duplicateTraining = (trainingId: string): void => {
    history.push(`/t/clients/${id}/training-plans/${trainingId}/duplicate`);
  };

  const redirectEdit = (trainingId: string): void => {
    history.push(`/t/clients/${id}/training-plans/${trainingId}/informations`);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = useCallback(
    (id: string): void => {
      removeTrainingPlan(id, () => {
        enqueueSnackbar(<Trans>Plán byl odstraněn.</Trans>, {
          variant: 'success',
        });
      });
    },
    [removeTrainingPlan, enqueueSnackbar],
  );

  return (
    <div>
      {trainingPlans.map((tp: LocalTrainingPlanDTO) => (
        <Card css={cardCss} key={tp.id}>
          <CardContent css={paddingNullCss}>
            <div css={cardHeaderCss}>
              <Link
                css={linkCss}
                to={`${routePathBuilder([
                  ApplicationPath.Trainer,
                  TrainerPath.Clients,
                ])}/${id}/training-plans/${tp.id}`}
              >
                {tp.title}
              </Link>

              <div css={popupMenuCss}>
                <PopupMenu
                  menuItems={[
                    {
                      label: 'Editovat',
                      icon: Create,
                      onClick: (): void => redirectEdit(tp.id),
                      disabled: false,
                    },
                    {
                      label: 'Duplikovat',
                      icon: Queue,
                      onClick: (): void => duplicateTraining(tp.id),
                      disabled: false,
                    },
                    {
                      label: 'Smazat',
                      icon: Delete,
                      onClick: (): any => handleDelete(tp.id),
                      disabled: tp.state !== TrainingPlanState.Saved,
                    },
                  ]}
                />
              </div>
            </div>
            <div css={cardContentCss}>
              <div css={cardContentRowCss}>
                <span>
                  <Trans>Počet tréninků:</Trans>
                </span>
                <span>{tp.trainingsCount}</span>
              </div>
              <div css={cardContentRowCss}>
                <span>
                  <Trans>Stav plánu:</Trans>
                </span>
                <span css={colorTextHandlerCss(tp.state)}>{tp.state}</span>
              </div>
              <div css={cardContentRowCss}>
                <span>
                  <Trans>První trénink:</Trans>
                </span>
                <span>
                  {tp.firstTraining && format(new Date(tp.firstTraining), 'P')}
                </span>
              </div>
              <div css={cardContentRowCss}>
                <span>
                  <Trans>Poslední trénink:</Trans>
                </span>
                <span>
                  {tp.lastTraining && format(new Date(tp.lastTraining), 'P')}
                </span>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

const cardCss = css`
  margin-bottom: ${4 * MPX}px;
  padding: 0;
`;

const cardHeaderCss = css`
  background-color: ${COLORS.primary};
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${MPX * 5}px;
  position: relative;
`;

const cardContentCss = css`
  min-height: 120px;
  padding: ${2 * MPX}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const cardContentRowCss = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${2 * MPX}px;
`;

const colorTextHandlerCss = (state: string): SerializedStyles => css`
  color: ${state === TrainingPlanState.Running
    ? `${COLORS.secondary} !important`
    : `${COLORS.gray6} !important`};
`;

const linkCss = css`
  text-decoration: none;
  color: ${COLORS.contrast};
  font-size: 18px;
`;

const paddingNullCss = css`
  padding: 0 !important;
`;

const popupMenuCss = css`
  position: absolute;
  right: 0;

  .MuiSvgIcon-root {
    color: rgba(255, 255, 255, 0.74) !important;
    transform: rotate(90deg);
  }

  .MuiButtonBase-root {
    padding: ${1.25 * MPX}px !important;
  }
`;
