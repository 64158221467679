/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Chip, Grid } from '@material-ui/core';
import { FilterDTO } from '../../../../strapi/FilterDTO';
import { COLORS, MPX } from '../../../../styles/themes';

export interface OverviewBodyPartsProps {
  parts: FilterDTO[];
}

const renderBodyParts = (parts: FilterDTO[]) => (
  <React.Fragment>
    {parts.map(part => (
      <Grid item key={part.id}>
        <Chip label={part.name.toLowerCase()} />
      </Grid>
    ))}
  </React.Fragment>
);

const OverviewBodyParts: React.FC<OverviewBodyPartsProps> = ({ parts }) => (
  <Grid container direction="row" css={exercisePartsCss}>
    {renderBodyParts(parts)}
  </Grid>
);

export default OverviewBodyParts;

const exercisePartsCss = css`
  margin: 0 -${MPX * 2}px ${MPX * 10}px;
  padding-left: 2vw;

  .MuiChip-root {
    margin: ${MPX}px ${MPX * 2}px !important;

    .MuiChip-label {
      color: ${COLORS.gray7};
      font-size: 14px !important;
    }
  }
`;
