/** @jsx jsx */
import { FC, useMemo, useCallback, useEffect, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { RouteComponentProps } from 'react-router-dom';
import { isSameDay } from 'date-fns';
import { Trans } from '@lingui/macro';
import {
  useMediaQuery,
  useTheme,
  Drawer,
  Hidden,
  Dialog,
  IconButton,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { TrainingDTO } from '../../strapi/TrainingDTO';
import {
  performSetTrainingDate,
  setTraining,
} from '../../store/training/actions';
import {
  setCalendarClickDate,
  setPlanningMode,
  performFetchTrainerEvents,
  performAddNewTrainerEvent,
  performUpdateTrainerEvent,
} from '../../store/calendar/actions';

import { EventDTO } from '../../interfaces/event';
import { TrainingPlanDTO } from '../../strapi/TrainingPlanDTO';

import { COLORS, MPX } from '../../styles/themes';
import { drawerFullWidthCss } from '../../styles/drawer';
import { Calendar } from '../trainingSchedule/comps/Calendar';
import { ScheduleModal } from '../trainingSchedule/comps/ScheduleModal';
import { DesktopCalendar } from '../trainingSchedule/comps/DesktopCalendar';
import { EventItem } from '../trainingSchedule/comps/EventItem';
import { LeftHeadingWithButtons } from '../shared/LeftHeadingWithButtons';
import { LinkButton } from '../shared/Buttons';
import { UserDTO } from '../../strapi/UserDTO';
import { TrainerEventModal } from './TrainerEventModal';
import { performFetchClients } from '../../store/client/actions';
import { iconMobileCss, mobileBodyMargin } from '../../styles/general';
import { addBtnCss, iconBtnCss } from '../../styles/buttons';
import { HeadingWithCloseButton } from '../shared/HeadingWithCloseButton';

export interface StateProps {
  training: TrainingDTO | undefined;
  events: EventDTO[];
  activeTrainingPlan: TrainingPlanDTO | undefined;
  eventsMobile: EventDTO[];
  selectedDate: Date | undefined;
  trainingPlans: TrainingPlanDTO[];
  planningState: boolean;
  loggedUser: UserDTO | undefined;
  clients: UserDTO[];
}

export interface DispatchProps {
  performSetTrainingDate: typeof performSetTrainingDate;
  setTraining: typeof setTraining;
  setCalendarClickDate: typeof setCalendarClickDate;
  setPlanningMode: typeof setPlanningMode;
  performFetchTrainerEvents: typeof performFetchTrainerEvents;
  performFetchClients: typeof performFetchClients;
  performAddNewTrainerEvent: typeof performAddNewTrainerEvent;
  performUpdateTrainerEvent: typeof performUpdateTrainerEvent;
}

export interface RouteParams {
  id: string;
  trainingPlanId: string;
}

type TrainerScheduleProps = StateProps &
  DispatchProps &
  RouteComponentProps<RouteParams>;

export const TrainerSchedule: FC<TrainerScheduleProps> = ({
  training,
  performSetTrainingDate,
  setTraining,
  events,
  activeTrainingPlan,
  selectedDate,
  setCalendarClickDate,
  trainingPlans,
  planningState,
  setPlanningMode,
  loggedUser,
  performFetchTrainerEvents,
  performFetchClients,
  performAddNewTrainerEvent,
  performUpdateTrainerEvent,
  clients,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const [openScheduleModal, setOpenModal] = useState<boolean>(false);
  const [activeEvent, setActiveEvent] = useState<EventDTO | undefined>(
    undefined,
  );
  useEffect(() => {
    if (loggedUser) {
      performFetchTrainerEvents(loggedUser.id);
      performFetchClients(loggedUser.id);
    }
  }, [performFetchTrainerEvents, performFetchClients, loggedUser]);

  // filter events according to selected date (Click to calendar to some day)
  const memoEvents = useMemo(
    () =>
      events.filter(
        item => selectedDate && isSameDay(item.start, selectedDate),
      ),
    [selectedDate, events],
  );

  const handleClickToCalendar = useCallback(
    (date: Date) => {
      setCalendarClickDate({ date });
    },
    [setCalendarClickDate],
  );
  const handleCloseDrawer = () => {
    setTraining({ training: undefined });
    setPlanningMode(false);
  };
  const handleSaveEvent = (event: EventDTO) => {
    if (loggedUser) {
      performAddNewTrainerEvent(event, loggedUser, () => {
        enqueueSnackbar(<Trans>Událost byla vytvořena</Trans>, {
          variant: 'success',
        });
      });
    }
  };

  const handleUpdatedTrainerEvent = (event: EventDTO) => {
    if (loggedUser) {
      performUpdateTrainerEvent(event, loggedUser, () => {
        enqueueSnackbar(<Trans>Událost byla aktualizována</Trans>, {
          variant: 'success',
        });
      });
    }
  };

  return (
    <div css={backgroundCss(isMobile)}>
      <LeftHeadingWithButtons title="Rozvrh">
        {!isMobile ? (
          <LinkButton
            text="Nová událost"
            colorIcon={COLORS.baseOrange}
            onClick={() => setOpenModal(true)}
          />
        ) : (
          <IconButton css={iconBtnCss} onClick={() => setOpenModal(true)}>
            <AddCircle css={[addBtnCss, iconMobileCss]} />
          </IconButton>
        )}
      </LeftHeadingWithButtons>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          open={!!(training && training.dateFrom)}
          onClose={handleCloseDrawer}
          css={drawerFullWidthCss}
        >
          <ScheduleModal
            training={training}
            performSetTrainingDate={performSetTrainingDate}
            handleClose={handleCloseDrawer}
            disabled={false}
            clientName=""
            setPlanningMode={setPlanningMode}
            planningState={planningState}
          />
        </Drawer>
        {/* for mobile */}
        <Drawer
          anchor="left"
          open={!!activeEvent}
          onClose={handleCloseDrawer}
          css={drawerFullWidthCss}
        >
          <HeadingWithCloseButton
            title="Událost"
            closeFunction={() => setActiveEvent(undefined)}
          />
          <TrainerEventModal
            closeIcon={Boolean(false)}
            event={activeEvent}
            handleClose={() => setActiveEvent(undefined)}
            handleSave={event => {
              if (activeEvent) {
                handleUpdatedTrainerEvent(event);
              } else {
                handleSaveEvent(event);
              }
            }}
            clients={clients}
          />
        </Drawer>
      </Hidden>
      <Dialog
        open={openScheduleModal}
        onClose={(): void => setOpenModal(false)}
        css={dialogCss(isMobile)}
        fullScreen={isMobile}
        fullWidth={isMobile}
      >
        {/* new event */}
        {isMobile && (
          <HeadingWithCloseButton
            title="Nová událost"
            closeFunction={() => setOpenModal(false)}
          />
        )}
        <TrainerEventModal
          event={undefined}
          handleClose={() => setOpenModal(false)}
          handleSave={event => {
            handleSaveEvent(event);
          }}
          clients={clients}
        />
      </Dialog>
      <div css={[calendarWrapperCss(isMobile), mobileBodyMargin(isMobile)]}>
        {isMobile ? (
          <Calendar
            setSelectedDate={handleClickToCalendar}
            planningState={planningState}
            training={training}
            setTraining={setTraining}
            selectedDate={selectedDate}
          />
        ) : (
          <DesktopCalendar
            events={events}
            training={training}
            performSetTrainingDate={performSetTrainingDate}
            setTraining={setTraining}
            activeTrainingPlan={activeTrainingPlan}
            trainingPlans={trainingPlans}
            planningState={planningState}
            setPlanningMode={setPlanningMode}
            showToday
            clients={clients}
            loggedUser={loggedUser}
            performUpdateTrainerEvent={performUpdateTrainerEvent}
          />
        )}
      </div>
      {isMobile && memoEvents && (
        <div css={divCss}>
          {memoEvents.map(e => (
            <EventItem
              key={e.id}
              event={e}
              handleClick={(event: EventDTO) => {
                const found = trainingPlans.find(
                  tp => tp.id === event.trainingPlanId,
                );

                if (found) {
                  const training = found.trainings.find(t => t.id === e.id);
                  setTraining({ training });
                } else {
                  setActiveEvent(event);
                }
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const divCss = css`
  border-top: 1px solid ${COLORS.gray7};
  margin: 0px ${MPX}px;
`;
const backgroundCss = (isMobile: boolean) => css`
  background-color: ${isMobile ? COLORS.white : COLORS.bg};
  flex-grow: 1;
  padding: ${!isMobile && ' 30px 2%'};
`;

const calendarWrapperCss = (isMobile: boolean) => css`
  margin-top: ${!isMobile && 8 * MPX}px;
  .fc .fc-scroller {
    z-index: 0 !important;
  }
`;

const dialogCss = (isMobile: boolean) => css`
  ${!isMobile && `left: 20% !important`};
`;
