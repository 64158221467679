export interface MediaFileBasicDTO {
  id: string;
  name: string;
  hash: string;
  sha256: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  provider: string;
  provider_metadata: unknown;
  createdAt: string;
  updated_at: string;
}

export interface MediaFileDTO extends MediaFileBasicDTO {
  formats: {
    thumbnail: MediaFileDTO;
    large: MediaFileDTO;
    small: MediaFileDTO;
    medium: MediaFileDTO;
  };
}

export enum FormatEnum {
  THUMBNAIL = 'thumbnail',
  LARGE = 'large',
  SMALL = 'small',
  MEDIUM = 'medium',
}
