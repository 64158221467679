// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ChatResponseDto,
  ChatResponseDtoFromJSON,
  ChatResponseDtoFromJSONTyped,
  ChatResponseDtoToJSON,
} from './';

/**
 *
 * @export
 * @interface ConversationsResponseDto
 */
export interface ConversationsResponseDto {
  /**
   *
   * @type {number}
   * @memberof ConversationsResponseDto
   */
  usersChatsId: number;
  /**
   *
   * @type {number}
   * @memberof ConversationsResponseDto
   */
  chatId: number;
  /**
   *
   * @type {number}
   * @memberof ConversationsResponseDto
   */
  userId: number;
  /**
   *
   * @type {ChatResponseDto}
   * @memberof ConversationsResponseDto
   */
  chat: ChatResponseDto;
}

export function ConversationsResponseDtoFromJSON(
  json: any,
): ConversationsResponseDto {
  return ConversationsResponseDtoFromJSONTyped(json, false);
}

export function ConversationsResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConversationsResponseDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    usersChatsId: json['usersChatsId'],
    chatId: json['chatId'],
    userId: json['userId'],
    chat: ChatResponseDtoFromJSON(json['chat']),
  };
}

export function ConversationsResponseDtoToJSON(
  value?: ConversationsResponseDto | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    usersChatsId: value.usersChatsId,
    chatId: value.chatId,
    userId: value.userId,
    chat: ChatResponseDtoToJSON(value.chat),
  };
}
