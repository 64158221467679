import { css, SerializedStyles } from '@emotion/react';
import { isToday } from 'date-fns';
import { COLORS } from './themes';

export const checkIfToday = (someDate: Date | undefined): boolean => {
  if (!someDate || JSON.stringify(someDate) === '{}') {
    return false;
  }

  return isToday(someDate);
};

export const addDays = (result: Date, days: number) => {
  result.setDate(result.getDate() + days);
  return result;
};

export const isDateLessThanTomorrow = (
  dateString: string | undefined,
): boolean => {
  if (!dateString) {
    return false;
  }

  const date = new Date(dateString);
  const tomorrow = new Date(addDays(new Date(), 1).setHours(0, 0, 0, 0));
  return date < tomorrow;
};

export const dot = css`
  width: 5px;
  height: 5px;
  border-radius: 50%;
`;
export const wrapperCss = (
  selectedDate: Date | undefined,
): SerializedStyles => css`
  .fc-daygrid-day-top {
    display: flex;
    flex-direction: row;
  }
  /* head without border */
  td.fc-head-container.fc-widget-header {
    border: none;
  }
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  /* number in cell */
  .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
    margin: 5px;
  }

  .fc th {
    text-align: center;
    text-transform: capitalize;
    padding: 4px;
    vertical-align: top;
    background-color: transparent;
    color: ${COLORS.grayBlue};
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    background-color: ${COLORS.bg};
    border: none !important;
  }

  .fc td {
    padding: 0;
    vertical-align: top;
    background-color: white;
    color: ${COLORS.darkerBlue};
  }

  .fc-event {
    border: none;
    border-left: 3px solid ${COLORS.lightDarkBlue};
    color: ${COLORS.blue};
    background-color: ${COLORS.white};
    border-radius: 0px;
  }
  .fc-event-title {
    font-weight: normal;
  }
  .fc-event-time {
    font-weight: bold;
  }

  .fc-event,
  .fc-event-dot {
    color: ${COLORS.blue};
    /* default BACKGROUND color */
  }

  .fc-event,
  .fc-event:hover {
    color: ${COLORS.blue};
    /* default TEXT color */
    text-decoration: none;
    /* if <a> has an href */
  }

  .fc-day-grid-event {
    margin: 1px 2px 0;
    /* spacing between events and edges */
    padding: 0px 3px;
  }

  a.fc-more {
    margin: 1px 3px;
    font-size: 0.85em;
    cursor: pointer;
    text-decoration: none;
    color: ${COLORS.baseOrange};
  }

  .fc-day-today {
    color: ${COLORS.lightDarkBlue};
    border-top: 4px solid ${COLORS.lightDarkBlue};
    font-weight: bold;
    background-color: ${COLORS.white};
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: ${COLORS.white};
  }

  .fc-button {
    color: ${COLORS.almostBlackBlue};
  }

  .fc-button:hover {
    text-decoration: none;
    color: ${COLORS.blueDark};
    background-color: ${COLORS.bg};
    border-color: ${COLORS.darkestBlue};
  }

  /* "primary" coloring */
  .fc-button-primary {
    color: ${COLORS.blueDark};
    border-color: ${COLORS.darkestBlue};
    background-color: ${COLORS.bg};
  }

  .fc-button-primary:disabled {
    color: ${COLORS.white};
    background-color: ${COLORS.blueDark};
    border-color: ${COLORS.blueDark};
  }

  .fc-button-primary:not(:disabled):active,
  .fc-button-primary:not(:disabled).fc-button-active {
    color: ${COLORS.white} !important;
    background-color: ${COLORS.darkerBlue};
    border-color: ${COLORS.darkerBlue};
  }

  .fc-toolbar {
    color: ${COLORS.darkerBlue};
  }
  .fc-toolbar h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  .fc td,
  .fc hr,
  .fc tbody,
  .fc-row {
    border-color: '#ccc' !important;
    background: '#fff' !important;
  }

  .fc-ltr .fc-axis {
    text-align: center;
    padding: 4px 10px;
  }

  .fc-time-grid .fc-slats td {
    padding-top: 10px;
    background: transparent;
  }
  .fc-row .fc-widget-header {
    margin-right: 0px;
  }
  .fc-daygrid-event-dot {
    display: none;
  }
  .fc-event-main {
    background-color: transparent;
    color: ${COLORS.darkerBlue};
  }

  /*  mobile */
  /* 600px is breakpoint sm in material UI */
  @media (max-width: 600px) {
    /* number in cell */
    .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
      margin: 0px;
    }
    .fc td {
      border-style: solid;
      border-width: 1px;
      padding: 0;
      vertical-align: top;
      background-color: white;
      color: ${COLORS.darkerBlue};
      width: 30px;
      height: 30px;
      display: table-cell;
    }
    .fc {
      text-align: center !important;
    }

    .fc td {
      padding: 0;
      vertical-align: middle !important;
    }
    td {
      text-align: center !important;
      vertical-align: middle !important;
    }
    .fc-row.fc-rigid .fc-content-skeleton {
    }
    .fc-day-number {
      float: none !important;
      position: unset;
    }
    .fc-daygrid-day-number {
      position: unset;
      padding: 3px;
    }
    .fc-day-today {
      color: ${COLORS.blueDark};

      border: none;
    }

    .fc-day .fc-widget-content .fc-wed .fc-today {
      color: ${COLORS.blueDark};
    }

    /* how to style today cell */
    .fc-day-today {
      background-color: ${COLORS.white};
      border: 2px solid
        ${checkIfToday(selectedDate) ? COLORS.lightDarkBlue : COLORS.gray4};
      border-radius: 50%;
      line-height: 25px;
      font-weight: normal;
      display: inline-block;
      width: 25px;
      height: 25px;
    }
    .fc td {
      border: none;
    }

    .fc th {
      background-color: ${COLORS.white};
    }
    /* "primary" coloring */
    .fc-button-primary {
      color: ${COLORS.blueDark};
      border: none;
      background-color: ${COLORS.white};
    }

    .fc-event {
      border: none;
      background-color: transparent;
      height: 10px;
      margin-top: -15px !important;
    }
    .fc-daygrid-event {
      // toto je celej event
    }
    .fc-event-title {
      display: none;
    }
    .fc-event-time {
      display: none;
    }

    .fc-content {
      display: none;
    }

    td.fc-event-container {
      vertical-align: top;
    }
    .fc-daygrid-day-top {
      display: inline-table;
      margin-top: 5px;
    }
    .fc-daygrid-day-events {
      min-height: 1em;
    }
    .dot {
      position: absolute;
      left: 45%;
      margin-top: 25px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }

    & .fc-daygrid-day {
      &.fc-day-today {
        .fc-daygrid-day-top {
          opacity: 1;
          background-color: ${COLORS.white};

          content: '';

          width: 35px;
          height: 35px;
          border-radius: 50% !important;
          border-collapse: separate;
          margin: 0 auto;

          border: ${checkIfToday(selectedDate)
            ? `2px solid ${COLORS.lightDarkBlue}`
            : `2px solid ${COLORS.gray4}`};
        }
      }
    }
    .fc-highlight {
      border: 2px solid ${COLORS.lightDarkBlue};
      border-radius: 50%;
      width: 35px;
      height: 35px;
      left: 15%;
      background-color: transparent;
    }
  }

  .training-box {
    border-left: 3px solid ${COLORS.gray4} !important;
  }

  .event-box {
    border-left: 3px solid ${COLORS.lightDarkBlue} !important;
  }
`;
