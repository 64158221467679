/** @jsx jsx */
import { FC } from 'react';
import { jsx, css } from '@emotion/react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  AccordionDetails,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Trans } from '@lingui/macro';
import { setFilter } from '../../../../store/exercisesLibrary/actions';
import { FiltersDTO } from '../../../../strapi/FiltersDTO';
import { MPX, COLORS } from '../../../../styles/themes';
import { activeFiltersReducer } from '../../../../store/exercisesLibrary/selector';

interface Props {
  setFilter: typeof setFilter;
  filters: FiltersDTO | null;
}
// TODO: STRING OF LIBRARY_ID
export const LIBRARY_TYPE = [
  {
    name: 'Moje',
    id: 'my',
  },
  {
    name: 'MotiVibe',
    id: 'motivibe',
  },
];

export enum FilterTypes {
  BodyPart = 'body-part',
  Equipment = 'equipment_items',
  ExerciseType = 'exercise-type',
  Library = 'library',
}

export const LeftMenu: FC<Props> = ({ setFilter, filters }) => {
  const activeFilters = useSelector(activeFiltersReducer);

  const handleFilter =
    (id: string, type: FilterTypes): any =>
    (event: any): void => {
      setFilter({ type, filter: id, isActive: event.target.checked });
    };

  return (
    <div>
      <Accordion
        defaultExpanded={activeFilters[FilterTypes.BodyPart].length > 0}
        css={panelCss}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography variant="overline">
            <Trans>Svalová partie</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {filters &&
              filters['body-part'].map(item => (
                <FormControlLabel
                  css={optionsCss}
                  key={item.id}
                  control={
                    <Checkbox
                      css={checkboxCss}
                      value={item.id}
                      checked={activeFilters[FilterTypes.BodyPart].includes(
                        item.id,
                      )}
                      onChange={handleFilter(item.id, FilterTypes.BodyPart)}
                    />
                  }
                  label={item.name}
                />
              ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded={activeFilters[FilterTypes.ExerciseType].length > 0}
        css={panelCss}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography variant="overline">
            <Trans>Druh aktivity</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {filters &&
              filters['exercise-type'].map(item => (
                <FormControlLabel
                  css={optionsCss}
                  key={item.id}
                  control={
                    <Checkbox
                      css={checkboxCss}
                      value={item.id}
                      checked={activeFilters[FilterTypes.ExerciseType].includes(
                        item.id,
                      )}
                      onChange={handleFilter(item.id, FilterTypes.ExerciseType)}
                    />
                  }
                  label={item.name}
                />
              ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded={activeFilters[FilterTypes.Equipment].length > 0}
        css={panelCss}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography variant="overline">
            <Trans>Vybavení</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {filters &&
              filters.equipment_items.map(item => (
                <FormControlLabel
                  css={optionsCss}
                  key={item.id}
                  control={
                    <Checkbox
                      css={checkboxCss}
                      value={item.id}
                      checked={activeFilters[FilterTypes.Equipment].includes(
                        item.id,
                      )}
                      onChange={handleFilter(item.id, FilterTypes.Equipment)}
                    />
                  }
                  label={item.name}
                />
              ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded css={panelCss}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography variant="overline">
            <Trans>Knihovna</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {LIBRARY_TYPE.map(item => (
              <FormControlLabel
                key={item.id}
                css={optionsCss}
                control={
                  <Checkbox
                    css={checkboxCss}
                    value={item.id}
                    checked={activeFilters[FilterTypes.Library].includes(
                      item.id,
                    )}
                    onChange={handleFilter(item.id, FilterTypes.Library)}
                  />
                }
                label={item.name}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const panelCss = css`
  background: transparent !important;
  box-shadow: none !important;
  margin: 0 !important;

  .MuiExpansionPanelSummary-content {
    margin: 0 !important;

    .MuiTypography-root {
      font-weight: 500 !important;
      color: ${COLORS.gray} !important;
      font-size: 14px;
    }
  }

  .MuiExpansionPanelSummary-root {
    border-bottom: 1px solid #000;
  }

  .MuiButtonBase-root {
    padding: 0 !important;
  }

  .MuiExpansionPanelDetails-root {
    padding-left: 0;
    padding-right: ${MPX * 4}px;
    padding-bottom: ${MPX * 3}px;
  }

  .MuiIconButton-edgeEnd {
    margin-right: ${MPX * 2}px;
  }

  .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 48px;
  }

  .MuiFormControlLabel-root {
    padding: ${MPX * 2.5}px 0;
    margin-left: 0;
  }

  .MuiFormGroup-root {
    flex: 1;
  }

  .MuiCheckbox-root {
    margin-right: ${MPX * 2.5}px;
  }
`;

export const optionsCss = css`
  color: ${COLORS.gray} !important;
`;

export const checkboxCss = css`
  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;
