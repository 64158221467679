import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { State } from '../../../store/combinedReducers';
import {
  PasswordSettings,
  StateProps,
  DispatchProps,
} from './PasswordSettings';
import { performUpdatePassword } from '../../../store/auth/actions';
import { AT } from '../../../store/actionTypes';
import { isLoading, getError } from '../../../store/request/selectors';
import { clearRS as clearRequestState } from '../../../store/request/actions';

const requestActions = [AT.PERFORM_UPDATE_PASSWORD];

const mapStateToProps = (state: State): StateProps => ({
  loggedUser: state.auth.loggedUser,
  isLoading: isLoading(requestActions)(state),
  requestError: getError(requestActions)(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(
    {
      performUpdatePassword,
      clearRequestState: () => dispatch(clearRequestState(requestActions)),
    },
    dispatch,
  ),
});

const PasswordSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordSettings);

export default PasswordSettingsContainer;
