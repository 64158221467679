/** @jsx jsx */
import React, { Fragment, useMemo, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { css, jsx } from '@emotion/react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { COLORS, MPX } from '../../../../styles/themes';
import { useDeCapitilizeButtonStyles } from '../../../../styles/buttons';
import {
  performFinishTraining,
  performUpdateExerciseIs,
} from '../../../../store/training/actions';
import {
  getSkipped,
  trainingsReducer,
} from '../../../../store/training/selectors';
import { SectionType, UpdateType } from '../../../../interfaces/enums';
import {
  getFirstSkippedURL,
  getStretchURL,
} from '../../../../store/trainingParts/selectors';
import {
  getDataAboutTrainingProgress,
  orderByOrder,
} from '../../helper/functions';
import {
  ApplicationPath,
  ClientPath,
  routePathBuilder,
} from '../../../../routes';
import { TrainingSectionIndex } from '../../helper/enums';
import { loadingReducer } from '../../../../store/request/selectors';
import { TrainingActionTypes } from '../../../../store/actionTypes';

const FinalExercise: React.FC = () => {
  const classes = useDeCapitilizeButtonStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [finishTraining, setFinishTraining] = useState(false);
  const skippedURL = useSelector(getFirstSkippedURL);
  const stretchURL = useSelector(getStretchURL);
  const training = useSelector(trainingsReducer);
  const loading = useSelector(loadingReducer);
  const trainingFetchingInProgress =
    loading[TrainingActionTypes.PERFORM_FETCH_TRAINING];
  const { sections: skippedSections, exercises: skippedExercises } =
    useSelector(getSkipped);
  const { doneExercises, totalNumberOfExercises } =
    getDataAboutTrainingProgress(training);

  const renderFinishedExercises = useMemo(
    () => (
      <Typography variant="h6">
        <Trans>Odcvičeno</Trans> {doneExercises} / {totalNumberOfExercises}{' '}
        <Trans>cviků</Trans>
      </Typography>
    ),
    [doneExercises, totalNumberOfExercises],
  );
  // renderContinueTrainingBtn
  // TODO calling BE multiple times instead of just once
  // wrong implementation but budget and time...
  const handleContinueTraining = useCallback(() => {
    const promises = [];
    promises.push(
      skippedSections.map(section => {
        const { id } = section;
        const updateWhat = UpdateType.SKIPPED;
        const type = SectionType.SECTION;

        return dispatch(performUpdateExerciseIs(id, type, updateWhat));
      }),
    );

    promises.push(
      skippedExercises.map(exercise => {
        const { id } = exercise;
        const updateWhat = UpdateType.SKIPPED;
        const type = SectionType.SECTION_PART;

        return dispatch(performUpdateExerciseIs(id, type, updateWhat));
      }),
    );

    Promise.all(promises).then(() => {
      history.push(skippedURL);
    });
  }, [skippedSections, skippedExercises, dispatch, history, skippedURL]);

  const renderContinueTrainingBtn = useMemo(
    () => (
      <div css={buttonCss}>
        <Button
          onClick={handleContinueTraining}
          classes={{ root: classes.root }}
          variant="contained"
          color="secondary"
        >
          <Trans>Pokračovat v tréninku</Trans>
        </Button>
        <div css={subtitleCss}>
          <Typography variant="caption">
            <Trans>Spustí všechny nedocvičené cviky.</Trans>
          </Typography>
        </div>
      </div>
    ),
    [classes.root, handleContinueTraining],
  );

  // renderFinishOrContinue
  const handleFinishTraining = useCallback(() => {
    if (training && !training.isFinished) {
      dispatch(performFinishTraining(training.id));
      setFinishTraining(true);
    }
  }, [dispatch, training]);

  const renderFinishOrContinue = useMemo(
    () => (
      <Fragment>
        {renderFinishedExercises}

        {renderContinueTrainingBtn}
        <div css={buttonCss}>
          <Button
            onClick={handleFinishTraining}
            classes={{ root: classes.root, outlined: classes.outlined }}
            variant="outlined"
            color="secondary"
          >
            <Trans>Dokončit trénink</Trans>
          </Button>
        </div>
        <div css={subtitleCss}>
          <Typography variant="caption">
            <Trans>
              Trénink bude dokončen tak, ja je a už se k němu nedete muset
              vracet.
            </Trans>
          </Typography>
        </div>
      </Fragment>
    ),
    [
      classes.outlined,
      classes.root,
      handleFinishTraining,
      renderContinueTrainingBtn,
      renderFinishedExercises,
    ],
  );

  // renderFinishOrContinue
  const handleCloseTraining = useCallback(() => {
    history.push(
      routePathBuilder([ApplicationPath.Client, ClientPath.Schedule]),
    );
  }, [history]);
  const renderAllFinished = useMemo(
    () => (
      <Fragment>
        <Typography variant="h6">
          <Trans>Všechny cviky odcvičeny, trénink je dokončen.</Trans>
        </Typography>
        <div css={buttonCss}>
          <Button
            onClick={handleCloseTraining}
            classes={{ root: classes.root }}
            variant="contained"
            color="secondary"
          >
            <Trans>Zavřít trénink</Trans>
          </Button>
        </div>
      </Fragment>
    ),
    [classes.root, handleCloseTraining],
  );

  // renderFinishOrLastUnfinished
  const handleGoToLastUnfinished = useCallback(() => {
    history.push(stretchURL);
  }, [history, stretchURL]);

  const renderFinishOrLastUnfinished = useMemo(
    () => (
      <Fragment>
        {renderFinishedExercises}
        {renderContinueTrainingBtn}
        <div css={buttonCss}>
          <Button
            onClick={handleGoToLastUnfinished}
            classes={{ root: classes.root, outlined: classes.outlined }}
            variant="outlined"
            color="secondary"
          >
            <Trans>Jít na protažení</Trans>
          </Button>
        </div>
        <div css={subtitleCss}>
          <Typography variant="caption">
            <Trans>
              Vynecháte neodcvičené cviky a přejdete na závěrečné protažení.
            </Trans>
          </Typography>
        </div>
      </Fragment>
    ),
    [
      classes.outlined,
      classes.root,
      handleGoToLastUnfinished,
      renderContinueTrainingBtn,
      renderFinishedExercises,
    ],
  );

  const renderContent = useMemo(() => {
    if (trainingFetchingInProgress) {
      return <CircularProgress size={24} css={submitBtnProgress} />;
    }
    const stretchSection = orderByOrder(training?.training_sections)[
      TrainingSectionIndex.Stretch
    ];
    if (stretchSection?.isDone || finishTraining) {
      return renderAllFinished;
    }

    if (stretchSection?.isSkipped) {
      return renderFinishOrContinue;
    }

    return renderFinishOrLastUnfinished;
  }, [
    finishTraining,
    renderAllFinished,
    renderFinishOrContinue,
    renderFinishOrLastUnfinished,
    training?.training_sections,
    trainingFetchingInProgress,
  ]);

  return <div css={wrapperCss}> {renderContent} </div>;
};

export default FinalExercise;

const wrapperCss = css`
  padding: ${0.5 * MPX}vh;
  height: 100%;
  color: ${COLORS.white};
  padding-top: 26%;
  text-align: center;
`;

const buttonCss = css`
  margin-top: ${4 * MPX}px;
`;

const subtitleCss = css`
  margin-top: ${2 * MPX}px;
`;

const submitBtnProgress = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;
