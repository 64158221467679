/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { MPX } from '../../../../../styles/themes';
import { KG } from '../../../shared/constants';
import { isStringOnlyNumber } from '../../../helper/functions';
import TextWithInformationIcon from '../../../shared/TextWithInformationIcon';
import PauseRowCircuit from '../circuitExerciseComponents/PauseRowCircuit';

export interface ExerciseRowProps {
  title: string;
  repeats: string;
  weight: string;
  repeatsModifier: string;
  weightModifier?: string;
  detailLink: string;
  pause?: string;
}

const ExerciseRow: React.FC<ExerciseRowProps> = ({
  title,
  repeats,
  repeatsModifier,
  weight,
  weightModifier = KG,
  detailLink,
  pause,
}) => (
  <React.Fragment>
    <Grid container direction="column" css={gridCss}>
      <TextWithInformationIcon title={title} link={detailLink} />
      <Grid
        container
        item
        direction="row"
        justify="space-around"
        alignItems="center"
        css={repeatsWeightCss}
      >
        <Grid item xs={2} />
        <Grid item xs={6}>
          {isStringOnlyNumber(repeats)
            ? `${repeats}${repeatsModifier}`
            : `${repeats}`}
        </Grid>
        <Grid item xs={4}>
          {isStringOnlyNumber(weight)
            ? `${weight}${weightModifier}`
            : `${weight}`}
        </Grid>
        {pause && <PauseRowCircuit pause={pause} />}
      </Grid>
    </Grid>
  </React.Fragment>
);

export default ExerciseRow;

const gridCss = css`
  padding-left: ${0.5 * MPX}vh;
  padding-right: ${0.5 * MPX}vh;
  padding-bottom: ${4 * MPX}px;
`;

const repeatsWeightCss = css`
  font-size: ${MPX * 5}px;
  font-weight: 500;
  padding-top: ${3.5 * MPX}px;
  text-align: left;
`;
