import { ActionCreator, Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import { AxiosResponse } from 'axios';
import { State } from '../combinedReducers';
import { makeServerCallAsync } from '../../code/helpers/api/api';
import { baseAsyncRequest } from '../actionHelpers';
import { ExercisesLibraryActionTypes } from '../actionTypes';
import { ExerciseDTO } from '../../strapi/ExerciseDTO';
import { FiltersDTO } from '../../strapi/FiltersDTO';

export const setExercises = action(
  ExercisesLibraryActionTypes.ADD_EXERCISES,
  payload<{ exercises: ExerciseDTO[] }>(),
);

export const addExercise = action(
  ExercisesLibraryActionTypes.ADD_EXERCISE,
  payload<{ exercise: ExerciseDTO }>(),
);

export const updateExercise = action(
  ExercisesLibraryActionTypes.UPDATE_EXERCISE,
  payload<{ exercise: ExerciseDTO }>(),
);

export const deleteExercise = action(
  ExercisesLibraryActionTypes.DELETE_EXERCISE,
  payload<{ exerciseId: string }>(),
);

export const setSearchQuery = action(
  ExercisesLibraryActionTypes.SET_SEARCH_QUERY,
  payload<{ searchQuery: string }>(),
);

export const setFilters = action(
  ExercisesLibraryActionTypes.SET_FILTERS,
  payload<{ filters: FiltersDTO }>(),
);
export const resetFilters = action(
  ExercisesLibraryActionTypes.RESET_FILTERS,
  payload<unknown>(),
);

export const setFilter = action(
  ExercisesLibraryActionTypes.SET_FILTER,
  payload<{ type: string; filter: string; isActive: boolean }>(),
);

export const setVisibleCount = action(
  ExercisesLibraryActionTypes.SET_VISIBLE_COUNT,
  payload<{ visibleCount: number }>(),
);

export const performFetchExercises: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/exercises?isMotivibeLibrary=true`);

    const results = await baseAsyncRequest(
      ExercisesLibraryActionTypes.PERFORM_FETCH_EXERCISES,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;
      dispatch(setExercises({ exercises: data }));
    }
  };

export const performFetchExerciseById: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (id: number) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/exercises/${id}`);

    const results = await baseAsyncRequest(
      ExercisesLibraryActionTypes.PERFORM_FETCH_EXERCISE_BY_ID,
      request,
      dispatch,
    );

    if (results && results.status === 200) {
      const { data } = results;
      dispatch(addExercise({ exercise: data }));
    }
  };

export const performFetchFilters: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  () =>
  async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    const user = getState().auth.loggedUser;
    if (user) {
      const request = async (): Promise<AxiosResponse<any>> =>
        makeServerCallAsync('get', `/filters/${user.id}`);

      const results = await baseAsyncRequest(
        ExercisesLibraryActionTypes.PERFORM_FETCH_FILTERS,
        request,
        dispatch,
      );

      if (results) {
        const { data } = results;
        dispatch(setFilters({ filters: data }));
      }
    }
  };

export const performAddExercise: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (exercise: ExerciseDTO, onAddCallback) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/exercises`, exercise);
    const results = await baseAsyncRequest(
      ExercisesLibraryActionTypes.PERFORM_ADD_EXERCISE,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;
      dispatch(addExercise({ exercise: data }));
      onAddCallback(data);
    }
  };

export const performUpdateExercise: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (id: number, exercise: ExerciseDTO, onUpdateCallback) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('put', `/exercises/${id}`, exercise);
    const results = await baseAsyncRequest(
      ExercisesLibraryActionTypes.PERFORM_ADD_EXERCISE,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;
      dispatch(updateExercise({ exercise: data }));
      onUpdateCallback();
    }
  };

export const performDeleteExercise: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (id: string, onDeleteCallback) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('delete', `/exercises/${id}`);
    const results = await baseAsyncRequest(
      ExercisesLibraryActionTypes.PERFORM_DELETE_EXERCISE,
      request,
      dispatch,
    );
    if (results) {
      dispatch(deleteExercise({ exerciseId: id }));
      onDeleteCallback();
    }
  };

export const performAddEquipment: ActionCreator<
  ThunkAction<Promise<string>, State, number, any>
> =
  (equipmentName: string, onAddCallback) =>
  async (dispatch: Dispatch<Action>, getState): Promise<string> => {
    const user = getState().auth.loggedUser;
    const newEquipment = {
      name: equipmentName,
      exercises: [],
      author: { ...user },
    };

    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/equipment-items`, newEquipment);

    const results = await baseAsyncRequest(
      ExercisesLibraryActionTypes.PERFORM_ADD_EQUIPMENT,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;

      onAddCallback();
      // return id for update item in autocomplete
      return data.id;
    }

    return '';
  };
