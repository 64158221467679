enum AuthActionTypes {
  SET_LOGGED_USER = '[auth] SET_LOGGED_USER',
  LOG_OUT = '[auth] LOG_OUT',
  PERFORM_USER_LOGIN = '[auth] PERFORM_USER_LOGIN',
  REFRESH_USER_FROM_COOKIE = '[auth] REFRESH_USER_FROM_COOKIE',
  PERFORM_TRAINEE_ACCOUNT_ACTIVATION = '[auth] PERFORM_TRAINEE_ACCOUNT_ACTIVATION',
  PERFORM_TRAINER_REGISTER = '[auth] PERFORM_TRAINER_REGISTER',
  PERFORM_FORGOT_PASSWORD = '[auth] PERFORM_FORGOT_PASSWORD',
  PERFORM_RESET_PASSWORD = '[auth] PERFORM_RESET_PASSWORD',
  PERFORM_UPDATE_PASSWORD = '[auth] PERFORM_UPDATE_PASSWORD',
  DELETE_MY_ACCOUNT = '[auth] DELETE_MY_ACCOUNT',
}

export enum ClientActionTypes {
  ADD_CLIENT = '[client] ADD_CLIENT',
  UPDATE_CLIENT = '[client] UPDATE_CLIENT',
  SET_CLIENTS = '[client] SET CLIENTS',
  SET_SEARCH_QUERY = '[client] SET SEARCH QUERY',
  CLEAR_SEARCH_QUERY = '[client] CLEAR SEARCH QUERY',
  GET_CLIENT = '[client] GET CLIENT',
  FETCH_CLIENT = '[client] FETCH CLIENT',
  DELETE_CLIENT = '[client] DELETE CLIENT',
  PERFORM_ADD_CLIENT = '[client] PERFORM_ADD_CLIENT',
  PERFORM_UPDATE_CLIENT = '[client] PERFORM_UPDATE_CLIENT',
  PERFORM_DELETE_CLIENT = '[client] PERFORM_DELETE_CLIENT',
  PERFORM_FETCH_CLIENTS = '[client] PERFORM_FETCH_CLIENTS',
  SET_ACTIVE_CLIENT = '[chat] SET_ACTIVE_CLIENT',
}

export enum TrainingPlansActionTypes {
  PERFORM_TRAINING_PLANS_BY_CLIENT_ID = '[training plan] PERFORM_TRAINING_PLANS_BY_CLIENT_ID',
  PERFORM_TRAINING_PLANS_BY_ID = '[training plan] PERFORM_TRAINING_PLANS_BY_ID',
  PROCESS_TRAINING_PLANS = '[training plan] PROCESS_TRAINING_PLANS',
  GET_TRAINING_PLAN = '[training plan] GET_TRAINING_PLAN',
  PERFORM_ADD_TRAINING_PLAN = '[training plan] PERFORM_ADD_TRAINING_PLAN',
  PERFORM_DUPLICATE_TRAINING_PLAN = '[training plan] PERFORM_DUPLICATE_TRAINING_PLAN',
  PERFORM_UPDATE_TRAINING_PLAN = '[training plan] PERFORM_UPDATE_TRAINING_PLAN',
  ADD_TRAINING_PLAN = '[training plan] ADD_TRAINING_PLAN',
  UPDATE_TRAINING_PLAN = '[training plan] UPDATE_TRAINING_PLAN',
  UPDATE_TRAINING = '[training plan] UPDATE_TRAINING',
  PERFORM_REMOVE_TRAINING = '[training plan] PERFORM_REMOVE_TRAINING',
  REMOVE_TRAINING_PLAN = '[training plan] REMOVE_TRAINING_PLAN',
  REPLACE_TRAINING = '[training plan] REPLACE_TRAINING',
  FETCH_TRAINING_PLANS = '[training plan] FETCH_TRAINING_PLANS',
  SET_ALL_TRAINING_PLANS = '[training plan] SET_ALL_TRAINING_PLANS',
  UPDATE_TRAINING_IN_TRAINING_PLAN = '[training plan] UPDATE_TRAINING_IN_TRAINING_PLAN',
}

export enum TrainingActionTypes {
  SET_TRAINING = '[training] SET_TRAINING',
  PERFORM_FETCH_TRAINING = '[training] PERFORM_FETCH_TRAINING',
  PERFORM_FETCH_CLIENT_TRAININGS = '[training] PERFORM_FETCH_CLIENT_TRAININGS',
  UPDATE_TRAINING_SECTION = '[training] UPDATE_TRAINING_SECTION',
  PERFORM_PATCH_SECTION = '[training] PERFORM_PATCH_SECTION',
  PERFORM_CLEAR_EXERCISES = '[training] PERFORM_CLEAR_EXERCISES',
  PERFORM_SET_TRAINING_DATE = '[training] PERFORM_SET_TRAINING_DATE',
  UPDATE_IS_DONE_EXERCISE = '[training] UPDATE_IS_DONE_EXERCISE',
  START_TRAINING = '[training] START_TRAINING',
  PAUSE_TRAINING = '[training] PAUSE_TRAINING',
  SET_DONES_AT_TRAINING = '[training] SET_DONES_AT_TRAINING',
}

export enum TrainingPartActionTypes {
  START_TRAINING_PART = '[training_part] START_TRAINING_PART',
  END_TRAINING_PART = '[training_part] END_TRAINING_PART',
  SET_TRAINING_RUNNING_FOR = '[training_part] SET_TRAINING_RUNNING_FOR',
  SET_TIME_TO_DISPLAY_WHEN_CALLING_BE = '[training_part] SET_TIME_TO_DISPLAY_WHEN_CALLING_BE',
  IS_IN_PAUSE_AFTER_EXERCISE = '[training_part] IS_IN_PAUSE_AFTER_EXERCISE',
  SET_TEMPORARY_TIME = '[training_part] SET_TEMPORARY_TIME',
  REMOVE_TEMPORARY_TIME = '[training_part] REMOVE_TEMPORARY_TIME',
}

export enum ProgressActionTypes {
  PERFORM_FETCH_PROGRESS = '[progress] PERFORM_FETCH_PROGRESS',
  SET_PROGRESSES = '[progress] SET_PROGRESSES',
  PERFORM_ADD_WEIGHT = '[progress] PERFORM_ADD_WEIGHT',
  PERFORM_ADD_MEASURES = '[progress] PERFORM_ADD_MEASURES',
  PERFORM_PATCH_WEIGHT_PROGRESSES = '[progress] PERFORM_PATCH_WEIGHT_PROGRESSES',
  PERFORM_ADD_IMAGE = '[progress] PERFORM_ADD_IMAGE',
  PERFORM_PATCH_PHOTO_PROGRESSES = '[progress] PERFORM_PATCH_PHOTO_PROGRESSES',
  PERFORM_PATCH_MEASURES_PROGRESSES = '[progress] PERFORM_PATCH_MEASURES_PROGRESSES',
}

export enum DocsActionTypes {
  SET_DOCUMENTS = '[docs] SET_DOCUMENTS',
  PERFORM_FETCH_DOCUMENTS = '[docs] PERFORM_FETCH_DOCUMENTS',
  PERFORM_EDIT_DOCUMENTS = '[docs] PERFORM_EDIT_DOCUMENTS',
  UPDATE_DOCUMENT = '[docs] UPDATE_DOCUMENT',
  DELETE_DOCUMENT = '[docs] DELETE_DOCUMENT',
  PERFORM_DELETE_DOCUMENT = '[docs] PERFORM_DELETE_DOCUMENT',
}

enum GeneralActionTypes {
  CHANGE_UI_LANGUAGE = '[general] CHANGE_UI_LANGUAGE',
  CHANGE_CIRCULAR_RUNNING = '[general] CHANGE_CIRCULAR_RUNNING',
}

enum RequestActionTypes {
  SET_RS = '[request] SET_RS',
  CLEAR_RS = '[request] CLEAR_RS',
}

enum ChatActionTypes {
  FETCH_CONVERSATION = '[chat] FETCH_CONVERSATION',
  SET_CONVERSATION = '[chat] SET_CONVERSATION',
  PERFORM_OPEN_CHAT = '[chat] PERFORM_OPEN_CHAT',
  POST_MESSAGE = '[chat] POST_MESSAGE',
  UPDATE_CONVERSATION = '[chat] UPDATE_CONVERSATION',
  PERFORM_ALL_AS_READ = '[chat] PERFORM_ALL_AS_READ',
  MARK_AS_READ = '[chat] MARK_AS_READ',
}

export enum ExercisesLibraryActionTypes {
  ADD_EXERCISES = '[exercises library] ADD_EXERCISES',
  ADD_EXERCISE = '[exercises library] ADD_EXERCISE',
  UPDATE_EXERCISE = '[exercises library] UPDATE_EXERCISE',
  DELETE_EXERCISE = '[exercises library] DELETE_EXERCISE',
  SET_SEARCH_QUERY = '[exercises library] SET_SEARCH_QUERY',
  SET_FILTERS = '[exercises library] SET_FILTERS',
  SET_FILTER = '[exercises library] SET_FILTER',
  SET_VISIBLE_COUNT = '[exercises library] SET_VISIBLE_COUNT',
  PERFORM_FETCH_EXERCISES = '[exercises library] PERFORM_FETCH_EXERCISES',
  PERFORM_FETCH_EXERCISE_BY_ID = '[exercises library] PERFORM_FETCH_EXERCISE_BY_ID',
  PERFORM_FETCH_FILTERS = '[exercises library] PERFORM_FETCH_FILTERS',
  PERFORM_ADD_EXERCISE = '[exercises library] PERFORM_ADD_EXERCISE',
  PERFORM_UPDATE_EXERCISE = '[exercises library] PERFORM_UPDATE_EXERCISE',
  PERFORM_DELETE_EXERCISE = '[exercises library] PERFORM_DELETE_EXERCISE',
  PERFORM_ADD_EQUIPMENT = '[exercises library] PERFORM_ADD_EQUIPMENT',
  RESET_FILTERS = '[exercise library] RESET_FILTERS',
}

export enum MediaUploadActionTypes {
  UPLOAD_FILE = '[media upload] UPLOAD_FILE',
}

export enum CalendarActionTypes {
  SET_CALENDAR_CLICK_DATE = '[calendar] SET_CALENDAR_CLICK_DATE',
  SET_PLANNING_MODE = '[calendar] SET_PLANNING_MODE',
  PERFORM_FETCH_TRAINER_EVENTS = '[training] PERFORM_FETCH_TRAINER_EVENTS',
  SET_TRAINER_EVENTS = '[training] SET_TRAINER_EVENTS',
  PERFORM_ADD_NEW_TRAINER_EVENT = '[training] PERFORM_ADD_NEW_TRAINER_EVENT',
  PERFORM_UPDATE_TRAINER_EVENT = '[training] PERFORM_UPDATE_TRAINER_EVENT',
  ADD_TRAINER_EVENT = '[training] ADD_TRAINER_EVENT',
  UPDATE_TRAINER_EVENT = '[training] UDPATE_TRAINER_EVENT',
}

export enum NotesActionTypes {
  FETCH_NOTES = '[notes] FETCH_NOTES',
  SET_NOTES = '[notes] SET_NOTES',
  ADD_NOTE = '[notes] ADD_NOTE',
  PERFORM_ADD_NOTE = '[notes] PERFORM_ADD_NOTE',
  PERFORM_UPDATE_NOTE = '[notes] PERFORM_UPDATE_NOTE',
  PERFORM_DELETE_NOTE = '[notes] PERFORM_DELETE_NOTE',
  UPDATE_NOTE = '[notes] UPDATE_NOTE',
  DELETE_NOTE = '[notes] DELETE_NOTE',
}

export const AT = {
  ...AuthActionTypes,
  ...GeneralActionTypes,
  ...RequestActionTypes,
  ...ClientActionTypes,
  ...ChatActionTypes,
  ...ExercisesLibraryActionTypes,
  ...MediaUploadActionTypes,
  ...TrainingPlansActionTypes,
  ...TrainingActionTypes,
  ...CalendarActionTypes,
  ...ProgressActionTypes,
  ...NotesActionTypes,
  ...DocsActionTypes,
  ...TrainingPartActionTypes,
};

export type AT =
  | AuthActionTypes
  | GeneralActionTypes
  | RequestActionTypes
  | ClientActionTypes
  | ChatActionTypes
  | ExercisesLibraryActionTypes
  | MediaUploadActionTypes
  | TrainingPlansActionTypes
  | TrainingActionTypes
  | CalendarActionTypes
  | ProgressActionTypes
  | NotesActionTypes
  | DocsActionTypes
  | TrainingPartActionTypes;
