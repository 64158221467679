/** @jsx jsx */
import { FC } from 'react';
import { css, jsx } from '@emotion/react';
import { MenuItem, Select } from '@material-ui/core';
import { PauseItemsAll } from '../../../../enums/TrainingTypesEnum';
import { inputCss } from '../TraningExercise';

interface PausePickerProps {
  hasPause: boolean;
  value: number | string | undefined;

  handlePauseChange: (pause: number) => void;
  itemsForSelect?: number[];
}
/* je pou6ito predevsim pro repeats a pause */
export const TimeDropdownPicker: FC<PausePickerProps> = ({
  value,
  handlePauseChange,
  itemsForSelect,
}) => {
  const decideNumber = (item: number) =>
    item > 90 ? `${item / 60}m` : `${item}s`;
  const getItem = (item: number | string) => (
    // pokud je poloza se strednikama tak ji nechci ukazovat v selectu proto hideCss
    // ale nechci aby mi rval warning
    <MenuItem key={item} value={item} css={hideCss(typeof item !== 'number')}>
      {typeof item !== 'string' ? decideNumber(item) : item}
    </MenuItem>
  );
  const getAllItems = () => {
    let itemArray: Array<string | number> = [];
    if (itemsForSelect) {
      itemArray = itemsForSelect.map(item => item);
    } else {
      itemArray = PauseItemsAll.map(pauseItem => pauseItem);
    }
    // repeats jsou string a nekdy je to number takye pro jistotu hodim vse do stringu
    if (
      value &&
      itemArray.find(i => i.toString() === value.toString()) === undefined
    ) {
      itemArray.push(value);
    }
    return itemArray.map(item => getItem(item));
  };
  return (
    <Select
      css={inputCss}
      fullWidth
      variant="outlined"
      onChange={(e): void =>
        handlePauseChange(parseInt(e.target.value as string, 10))
      }
      margin="dense"
      value={value}
    >
      {getAllItems()}
    </Select>
  );
};

const hideCss = (hide: boolean) => css`
  display: ${hide && 'none'} !important;
`;
