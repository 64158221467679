import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import { Capacitor } from '@capacitor/core';
import { setLoggedUser, logOut } from './actions';
import { removeUserFromCookie } from '../../code/helpers/api';

// NEW
import { UserDTO } from '../../strapi/UserDTO';
import { updateFcmToken } from '../../code/helpers/notifications';
import { removeAuthHeader } from '../../code/helpers/api/http';
// END NEW

export interface State {
  loggedUser: UserDTO | undefined;
}

export const initialState: State = {
  loggedUser: undefined,
};

export const authReducer = reducer<State>(
  initialState,
  on(setLoggedUser, (state: State, { payload }) => {
    state.loggedUser = payload.user;
  }),
  on(logOut, (state: State) => {
    if (Capacitor.isNativePlatform() && state.loggedUser) {
      updateFcmToken(state.loggedUser, null);
    }
    removeUserFromCookie();
    removeAuthHeader();
    state.loggedUser = undefined;
  }),
);
