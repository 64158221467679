/** @jsx jsx */
import React from 'react';
import { Grid } from '@material-ui/core';
import { css, jsx } from '@emotion/react';
import { Trans } from '@lingui/macro';
import { COLORS, MPX } from '../../../../../styles/themes';

interface PauseRowCircuitProps {
  pause: string | undefined;
}

const PauseRowCircuit: React.FC<PauseRowCircuitProps> = ({ pause }) => (
  <Grid container css={gridTitleRow}>
    <Grid item xs={6} css={titleCss}>
      <Trans>Pauza</Trans>
    </Grid>
    <Grid item xs={4} />
    <Grid container item justify="center" alignItems="center" xs={6}>
      {pause}
    </Grid>
  </Grid>
);

export default PauseRowCircuit;

const gridTitleRow = css`
  padding-top: ${5 * MPX}px;
`;

const titleCss = css`
  color: ${COLORS.white};
  width: 60vw;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-size: x-large;
  padding-bottom: ${MPX}px;
`;
