import { Action, Dispatch } from 'redux';
import * as Sentry from '@sentry/browser';

import { setRS } from './request/actions';
import { RS } from '../interfaces/enums';
import { AT } from './actionTypes';

interface ErrorRequestOption {
  sentryCapture?: boolean;
  defaultMessage?: string;
  errorMap?: Record<number, string>;
  errorIdMap?: Record<string, string>;
}

const errorDefaultOptions: ErrorRequestOption = {
  sentryCapture: true,
};

export const baseAsyncRequest = async <T>(
  actionType: AT,
  request: () => Promise<T>,
  dispatch: Dispatch<Action>,
  errorOptions?: ErrorRequestOption,
): Promise<T | undefined> => {
  dispatch({ type: actionType });

  try {
    dispatch(
      setRS({
        rs: RS.Loading,
        n: actionType,
      }),
    );

    const results = await request();

    dispatch(setRS({ rs: RS.Success, n: actionType }));
    return results;
  } catch ({ response: err }) {
    if (err) {
      const options = { ...errorDefaultOptions, ...errorOptions };
      const { errorMap, defaultMessage, sentryCapture, errorIdMap } = options;

      if (sentryCapture) {
        Sentry.captureException(new Error(JSON.stringify(err)));
      }

      let errorMessage;

      const { message, statusCode } = err.data;

      errorMessage = defaultMessage || message;
      if (errorMap && statusCode in errorMap) {
        errorMessage = errorMap[statusCode];
      }

      try {
        const { statusCode } = err.data;

        if (errorMap && statusCode in errorMap) {
          errorMessage = errorMap[statusCode];
        } else {
          const errorId = Array.isArray(err.data.message)
            ? err.data.message[0].messages[0].id
            : undefined;

          // eslint-disable-next-line max-depth
          if (errorIdMap && errorId && errorId in errorIdMap) {
            errorMessage = errorIdMap[errorId];
          } else {
            throw new Error(
              'No mapping found for this error. Fallback to default.',
            );
          }
        }
      } catch (jsonError) {
        if (defaultMessage) {
          errorMessage = defaultMessage;
        } else {
          errorMessage =
            'Nastala neočekávaná chyba. Prosím, zkuste to znovu za chvíli.';
        }
      }

      dispatch(setRS({ rs: RS.Failure, n: actionType, message: errorMessage }));

      return undefined;
    }
    return undefined;
  }
};
