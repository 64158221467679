/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { Trans } from '@lingui/macro';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { MoreHoriz } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { usePopupMenuItemStyles } from '../../styles/popupMenuItemStyles';
import { usePopupMenuStyles } from '../../styles/popupMenuStyles';
import { COLORS, MPX } from '../../styles/themes';

export interface MenuItemProps {
  icon: (props: SvgIconProps) => JSX.Element;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface ClientMenuProps {
  menuItems: MenuItemProps[];
  anchorElRoot?: Element;
  handleClose?: () => void;
  border?: boolean;
}

export const PopupMenu: React.FC<ClientMenuProps> = ({
  menuItems,
  anchorElRoot,
  handleClose,
  border,
}) => {
  const menuClasses = usePopupMenuStyles();
  const menuItemClasses = usePopupMenuItemStyles();

  const [anchorEl, setAnchorEl] = React.useState(anchorElRoot || null);

  const handleClickClientMenu = (event: any): void => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleCloseClientMenu = (event: any): void => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  return (
    <React.Fragment>
      {anchorElRoot === undefined && (
        <IconButton aria-label="more" onClick={handleClickClientMenu}>
          <MoreHoriz css={border ? bordered : ''} />
        </IconButton>
      )}
      <Menu
        classes={{ list: menuClasses.list }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        id="user-menu"
        anchorEl={anchorElRoot || anchorEl}
        keepMounted
        open={Boolean(anchorElRoot) || Boolean(anchorEl)}
        onClose={
          anchorElRoot != null && handleClose
            ? handleClose
            : handleCloseClientMenu
        }
      >
        {menuItems.map(menuItem => (
          <MenuItem
            onClick={(event): void => {
              menuItem.onClick();
              handleCloseClientMenu(event);
            }}
            classes={{ root: menuItemClasses.root }}
            key={menuItem.label}
            disabled={menuItem.disabled}
            css={menuItemCss}
          >
            <menuItem.icon htmlColor="white" fontSize="small" />
            <div css={menuLabelCss}>
              <Trans id={menuItem.label} />
            </div>
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

const menuLabelCss = css`
  margin-left: ${2.5 * MPX}px;
`;

const menuItemCss = css`
  &.Mui-disabled {
    opacity: 1 !important;
    color: rgba(255, 255, 255, 0.5);
    .MuiSvgIcon-root {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &.Mui-focusVisible {
    opacity: 1 !important;
    background-color: ${COLORS.primaryDark} !important;
  }
`;

const bordered = css`
  border: 1px solid;
  padding: 8px 8px;
  color: ${COLORS.gray4};
  border-radius: 4px;
`;
