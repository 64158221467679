import { createSelector, OutputSelector } from 'reselect';
import { setHours, isSameDay } from 'date-fns';
import { t } from '@lingui/macro';
import { State } from '../combinedReducers';
import { setTrainingPlanState } from '../../code/helpers/trainingPlans';
import {
  LocalTrainingPlanDTO,
  TrainingPlanDTO,
} from '../../strapi/TrainingPlanDTO';
import { TrainingDTO } from '../../strapi/TrainingDTO';
import { EventDTO, EventType } from '../../interfaces/event';
import { COLORS } from '../../styles/themes';
import { UserDTO } from '../../strapi/UserDTO';
import { getNameForTraining } from '../../code/helpers/training';
import { remapTrainingToEvent } from '../../code/helpers/calendarHelper';

export const trainingPlansReducer = (state: State): TrainingPlanDTO[] =>
  state.trainingPlans.trainingPlans;
export const allTrainingPlansReducer = (state: State): TrainingPlanDTO[] =>
  state.trainingPlans.trainerTrainingPlans;
export const loggedUserReducer = (state: State): UserDTO | undefined =>
  state.auth.loggedUser;
export const trainerEventsReducer = (state: State): EventDTO[] =>
  state.calendar.trainerEvents;

export const activeClient = (state: State): UserDTO | undefined =>
  state.client.activeClient;

export const getTrainingPlansByClientId = (
  id: string,
): OutputSelector<
  State,
  LocalTrainingPlanDTO[],
  (res: LocalTrainingPlanDTO[]) => void
> =>
  createSelector(trainingPlansReducer, trainingPlans =>
    trainingPlans
      .filter(tp => tp.client && tp.client.id === id)
      .map(tp => ({
        ...tp,
        ...setTrainingPlanState(tp.trainings),
      })),
  );

// remaps the array of whole training plans for trainer according to active client
// and add trainer events
export const getEventsForTrainer = createSelector(
  allTrainingPlansReducer,
  loggedUserReducer,
  trainerEventsReducer,
  (trainingPlans, loggedUser, trainerEvents) => {
    let events: EventDTO[] = [];

    trainingPlans.forEach(tp => {
      // @ts-ignore
      if (loggedUser && tp.owner.id === loggedUser.id) {
        // restOfEvents are events With Trainer
        events = [...events, ...mapRestOfEvents(tp, tp.client)];
      }
    });
    return [...events, ...trainerEvents];
  },
);

// remaps the array of whole training plans for trainer according to active client
export const getEventsForActiveClient = (
  idClient: string,
  idTrainingPlan: string,
): OutputSelector<
  State,
  EventDTO[],
  (res: TrainingPlanDTO[], trainerEvents: EventDTO[]) => EventDTO[]
> =>
  createSelector(
    allTrainingPlansReducer,
    trainerEventsReducer,
    (trainingPlans, trainerEvents) => {
      let events: EventDTO[] = [];

      trainingPlans.map(tp => {
        if (tp.client !== null && tp.client.id === idClient) {
          events = [
            ...events,
            ...mapClientEvents(
              tp,
              idTrainingPlan,
              `${tp.client.name} ${tp.client.surname}`,
            ),
          ];
        } else {
          events = [...events, ...mapRestOfEvents(tp, tp.client)];
        }
        return tp;
      });

      return [
        ...events,
        ...trainerEvents.filter(
          trEvent => trEvent.client && trEvent.client.id === idClient,
        ),
      ];
    },
  );

// events of active client
// id training plan means if it it active training plan for which is harmonogram viewed
export const mapClientEvents = (
  trainingPlan: TrainingPlanDTO,
  idTrainingPlan: string,
  clientName: string,
): EventDTO[] => {
  const events: EventDTO[] = [];

  trainingPlan.trainings.map(training => {
    if (training.dateFrom) {
      const event: EventDTO = {
        id: training.id,
        title:
          idTrainingPlan !== trainingPlan.id
            ? clientName
            : getNameForTraining(training, t`Trénink`),
        // if training is without trainer then training will be shown as first trainings in day
        start: training.withTrainer
          ? new Date(training.dateFrom)
          : setHours(new Date(training.dateFrom), 0),
        end: training.dateTo ? new Date(training.dateTo) : undefined,
        location: training.location,
        borderColor:
          idTrainingPlan === trainingPlan.id
            ? COLORS.lightDarkBlue
            : COLORS.gray8,
        trainingPlanId: trainingPlan.id,
        type: EventType.training,
        isFinished: training.isFinished,
      };
      events.push(event);
      return event;
    }
    return training;
  });
  return events;
};

// rest of events for rest of clients
export const mapRestOfEvents = (
  trainingPlan: TrainingPlanDTO,
  client: UserDTO,
): EventDTO[] => {
  const events: EventDTO[] = [];
  trainingPlan.trainings.map(training => {
    if (training.withTrainer && training.dateFrom) {
      const event: EventDTO = {
        id: training.id,
        title: client ? `${client.name} ${client.surname}` : '-',
        start: new Date(training.dateFrom),
        end: training.dateTo ? new Date(training.dateTo) : undefined,
        location: training.location,
        borderColor: COLORS.gray8,
        trainingPlanId: trainingPlan.id,
        type: EventType.training,
        isFinished: training.isFinished,
      };

      events.push(event);
      return event;
    }
    return training;
  });
  return events;
};

// remaps the array of whole training plans for trainer according to active client
export const getEventsForMobileCalendar = (
  idTrainingPlan: string | undefined,
): OutputSelector<State, EventDTO[], (res: TrainingPlanDTO[]) => EventDTO[]> =>
  createSelector(allTrainingPlansReducer, trainingPlans => {
    let events: EventDTO[] = [];

    trainingPlans.forEach(tp => {
      tp.trainings.forEach(training => {
        if (training.dateFrom != null) {
          const found = events.find(
            e =>
              training.dateFrom &&
              isSameDay(new Date(training.dateFrom), e.start),
          );
          if (!found) {
            const event: EventDTO = {
              id: training.id,
              title: ``,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              start: training.dateFrom
                ? new Date(training.dateFrom)
                : new Date(),
              end: undefined,
              moreInOneDay: false,
              location: training.location,
              textColor:
                tp.id === idTrainingPlan ? COLORS.lightDarkBlue : COLORS.gray8,
              trainingPlanId: idTrainingPlan,
              borderColor:
                tp.id === idTrainingPlan ? COLORS.lightDarkBlue : COLORS.gray8,
              type: EventType.training,
              isFinished: training.isFinished,
            };

            events.push(event);
          } else if (
            (tp.id === idTrainingPlan &&
              found.textColor &&
              found.textColor === COLORS.gray8) ||
            (tp.id !== idTrainingPlan &&
              found.textColor === COLORS.lightDarkBlue)
          ) {
            // the event already exist, has to check if is different event from existing one
            // means check color and training plan

            // just tell event that there is more types of events in this day
            // in this day are training of other clients or other training plans
            // and also training of active training plan

            events = events.map(e =>
              found.id === e.id ? { ...e, moreInOneDay: true } : e,
            );
          }
        }
      });
    });
    return events;
  });

// remaps the array of whole training plans for trainer according to active client
export const getEventsForMobileTrainer = createSelector(
  allTrainingPlansReducer,
  loggedUserReducer,
  trainerEventsReducer,
  (trainingPlans, loggedUser, trainerEvents) => {
    // EVENTY trenera dame modre
    let events: EventDTO[] = trainerEvents.map(te => ({
      ...te,
      textColor: COLORS.lightDarkBlue,
    }));

    trainingPlans.forEach(tp => {
      tp.trainings.forEach(training => {
        // musi mit logged user prirayenej ten trenink pak nas tady zajima
        if (
          training.dateFrom != null &&
          loggedUser &&
          training.owner &&
          loggedUser.id === training.owner.toString() &&
          training.withTrainer
        ) {
          const found = events.find(
            e =>
              training.dateFrom &&
              isSameDay(new Date(training.dateFrom), e.start),
          );
          // pokud tam ta udalost neni v eventu tak ji musim pridat
          if (!found) {
            const event: EventDTO = remapTrainingToEvent(training);

            events.push(event);
          } else {
            // the event already exist, has to check if is different event from existing one
            // means check color and training plan

            // just tell event that there is more types of events in this day
            // in this day are training of other clients or other training plans
            // and also training of active training plan

            events = events.map(e =>
              training.dateFrom &&
              isSameDay(new Date(training.dateFrom), e.start) &&
              e.textColor === COLORS.lightDarkBlue
                ? { ...e, moreInOneDay: true }
                : e,
            );
          }
        }
      });
    });
    return events;
  },
);

export const getTrainingPlansById = (
  id: string,
): OutputSelector<
  State,
  LocalTrainingPlanDTO[],
  (res: LocalTrainingPlanDTO[]) => void
> =>
  createSelector(trainingPlansReducer, trainingPlans =>
    trainingPlans
      .filter(tp => tp.id === id)
      .map(tp => ({ ...tp, ...setTrainingPlanState(tp.trainings) })),
  );

export const getTrainingPlanById = (
  id: string,
): OutputSelector<
  State,
  LocalTrainingPlanDTO | undefined,
  (res: TrainingPlanDTO[]) => LocalTrainingPlanDTO | undefined
> =>
  createSelector(trainingPlansReducer, trainingPlans => {
    const selectedTp = trainingPlans.find(tp => tp.id === id);

    if (selectedTp) {
      const sortedTrainings = [...selectedTp.trainings].sort(
        (prev: TrainingDTO, curr: TrainingDTO) => prev.order - curr.order,
      );
      const trainingPlan: LocalTrainingPlanDTO = {
        ...selectedTp,
        ...setTrainingPlanState(selectedTp.trainings),
        trainings: sortedTrainings,
      };
      return trainingPlan;
    }

    return undefined;
  });

// selector for getting whole training plan by id - object type TrainingPlanDTO
export const getWholeTrainingPlanById = (
  id: string,
): OutputSelector<
  State,
  TrainingPlanDTO | undefined,
  (res: TrainingPlanDTO[]) => TrainingPlanDTO | undefined
> =>
  createSelector(trainingPlansReducer, trainingPlans =>
    trainingPlans.find(tp => tp.id === id),
  );

export const getTrainingPlanByTrainingId =
  (user: UserDTO | undefined, trainingId: string) =>
  (): TrainingPlanDTO | undefined => {
    if (!user) {
      return undefined;
    }

    const trainingPlans: TrainingPlanDTO[] = user.training_plans;
    const foundPlan = trainingPlans.find(tp =>
      tp.trainings.find(t => t.id === trainingId),
    );

    return foundPlan;
  };
