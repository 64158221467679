import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import {
  pauseRunningTraining,
  setTrainingRunningFor,
  startTrainingFrom,
  setTimeToDisplayWhenCallingBE,
  pauseChange,
  setTemporaryTime,
  removeTemporaryTime,
} from './actions';

export interface State {
  trainingRunningFrom: Date | undefined;
  trainingRunningFor: number;
  trainingIsRunnig: boolean;
  timeToDisplayWhenCallingBE: number | undefined;
  inPauseAfterExercise: boolean;
}

export const initialState: State = {
  trainingRunningFrom: undefined,
  trainingRunningFor: 0,
  trainingIsRunnig: false,
  timeToDisplayWhenCallingBE: undefined,
  inPauseAfterExercise: false,
};

export const trainingPartsReducer = reducer<State>(
  initialState,
  // eslint-disable-next-line
  on(pauseRunningTraining, (state: State, {}) => {
    state.trainingIsRunnig = false;
    state.trainingRunningFrom = undefined;
    state.trainingRunningFor = 0;
  }),
  on(startTrainingFrom, (state: State, { payload }) => {
    state.trainingRunningFrom = payload
      ? payload.trainingRunningFrom
      : undefined;
    state.trainingIsRunnig = true;
  }),
  on(setTimeToDisplayWhenCallingBE, (state: State, { payload }) => {
    state.timeToDisplayWhenCallingBE = payload.time;
    state.trainingIsRunnig = false;
    state.trainingRunningFrom = undefined;
    state.trainingRunningFor = 0;
  }),
  on(setTrainingRunningFor, (state: State, { payload }) => {
    if (state.trainingIsRunnig) {
      state.trainingRunningFor = payload.trainingRunningFor;
    }
  }),
  on(pauseChange, (state: State, { payload }) => {
    state.inPauseAfterExercise = payload.isInPause;
  }),
  on(setTemporaryTime, (state: State, { payload }) => {
    state.timeToDisplayWhenCallingBE = payload.time;
  }),
  // eslint-disable-next-line no-empty-pattern
  on(removeTemporaryTime, (state: State, {}) => {
    state.timeToDisplayWhenCallingBE = undefined;
  }),
);
