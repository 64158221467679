export enum TrainingTypeEnum {
  Personal = 'personal',
  Combined = 'combined',
  Remote = 'remote',
}

export enum TrainingSectionTypeEnum {
  SingleExercise = 'singleExercise',
  CircuitTraining = 'circuitTraining',
  SuperSet = 'superSerie',
}

export enum TrainingExerciseKeys {
  repeats = 'repeats',
  weights = 'weights',
  circuit = 'circuit',
}

export const TrainingSetWrapperText: Record<TrainingSectionTypeEnum, string> = {
  [TrainingSectionTypeEnum.SingleExercise]: 'Cvik',
  [TrainingSectionTypeEnum.CircuitTraining]: 'Kruhový trénink',
  [TrainingSectionTypeEnum.SuperSet]: 'Supersérie',
};

export const PauseItemsTimeNotCircle = [
  15, 20, 30, 45, 60, 90, 120, 180, 300, 600, 720, 900, 1200, 1500, 1800, 2100,
  2400, 2700, 3000, 3300, 3600,
];

export const PauseItemsTimeCircle = [
  15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 90, 120,
];
export const PauseItemsAll = [5, 10, 15, 20, 30, 45, 60, 90, 120, 180, 300];
