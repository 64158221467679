/** @jsx jsx */
import { FC, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Grid } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { UpdatePasswordDTO, UserDTO } from '../../../strapi/UserDTO';
import { ONCHANGE_FORM_MODE } from '../../shared/helpers/FormSetting';
import {
  AppFormRequestError,
  AppFormSubmit,
  AppFormVertical,
  AppTextInput,
  AppTextInputProps,
} from '../../shared/Forms';
import {
  passwordRules,
  PASSWORD_HINT,
  requiredRule,
  sameAsRule,
} from '../../shared/helpers/FormRules';
import { performUpdatePassword } from '../../../store/auth/actions';
import { MPX } from '../../../styles/themes';

export interface StateProps {
  loggedUser: UserDTO | undefined;
  isLoading: boolean;
  requestError: string | undefined;
}

export interface DispatchProps {
  performUpdatePassword: typeof performUpdatePassword;
  clearRequestState: () => void;
}

type PasswordSettingsProps = DispatchProps & StateProps;

export const PasswordSettings: FC<PasswordSettingsProps> = ({
  loggedUser,
  performUpdatePassword,
  clearRequestState,
  isLoading,
  requestError,
}) => {
  const history = useHistory();
  const methods = useForm({
    mode: ONCHANGE_FORM_MODE,
  });

  const { handleSubmit, watch } = methods;
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (requestError) {
      enqueueSnackbar(requestError, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, requestError]);
  useEffect(() => (): void => clearRequestState(), [clearRequestState]);

  const onSubmit = handleSubmit(({ oldPassword, password, passwordAgain }) => {
    if (loggedUser) {
      const updatedUser: UpdatePasswordDTO = {
        id: loggedUser.id,
        password: oldPassword,
        newPassword: password,
        confirmPassword: passwordAgain,
      };
      performUpdatePassword(updatedUser, () => {
        enqueueSnackbar(<Trans>Heslo bylo upraveno.</Trans>, {
          variant: 'success',
        });
      });
    }
  });

  const inputs: AppTextInputProps[] = [
    {
      name: 'oldPassword',
      label: 'Aktuální heslo',
      rules: { ...requiredRule },
      type: 'password',
    },
    {
      name: 'password',
      label: 'Nové heslo',
      rules: { ...requiredRule, ...passwordRules },
      helperText: PASSWORD_HINT,
      type: 'password',
    },
    {
      name: 'passwordAgain',
      label: 'Nové heslo znovu',
      rules: { ...requiredRule, ...sameAsRule('password', watch) },
      type: 'password',
    },
  ];

  return (
    <Grid container justify="center" alignItems="center" css={containerStyles}>
      <Grid item xs={12} md={9} xl={5} lg={6}>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <AppFormVertical>
              {inputs.map(input => (
                <AppTextInput key={input.name} {...input} />
              ))}
              <AppFormRequestError />

              <Grid
                container
                spacing={2}
                alignItems="baseline"
                justify="center"
              >
                <Grid item>
                  <AppFormSubmit
                    text="Uložit změny"
                    loading={isLoading}
                    disabledOnError
                  />
                </Grid>
                <Grid item>
                  <Button onClick={() => history.goBack()}>
                    <Trans>Zrušit</Trans>
                  </Button>
                </Grid>
              </Grid>
            </AppFormVertical>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

const containerStyles = css`
  padding: ${`0px ${2.25 * MPX}px`};
  padding-top: 40px;
`;
