/** @jsx jsx */
import {
  FC,
  useState,
  useEffect,
  Fragment,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { jsx, css, SerializedStyles } from '@emotion/react';
import {
  Grid,
  OutlinedInput,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Pause from '@material-ui/icons/Pause';
import RemoveBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { Delete, InsertLink, Timer } from '@material-ui/icons';
import { Trans } from '@lingui/macro';
import { ReactSortable, Sortable, Store } from 'react-sortablejs';
import { v4 as uuid } from 'uuid';
import { MPX, COLORS } from '../../../styles/themes';
import { TrainingExercise, inputCss, addBoxIconCss } from './TraningExercise';
import { dropzoneCss, dropzoneTextCss } from '../../../styles/dropzone';
import {
  inputNameState,
  ExerciseInputsEnum,
} from '../../../code/helpers/trainingExerciseInputs';
import {
  updateSectionPartAttribute,
  updateExerciseAttribute,
  updateExerciseWeightsAndRepeatsAccordingToNumberOfSeries,
} from '../../../code/helpers/trainingExercise';
import {
  TrainingSectionPartWithOperation,
  TrainingExerciseWithOperation,
} from '../../../interfaces/traning';
import { selectedItemCss } from './SetWrapper';
import { copyTrainingExerciseInSuperSet } from '../../../code/helpers/trainingSetupHelpers';
import { OperationEnum } from '../../../enums/OperationEnum';
import { PopupMenu } from '../../shared/PopupMenu';
import { previousPauseInSet } from '../../../code/helpers/trainingExerciseSeries';
import { PauseModifierEnum } from '../../../enums/RepeatsModifierEnum';
import { TrainingSectionTypeEnum } from '../../../enums/TrainingTypesEnum';
import { TimeDropdownPicker } from './comps/TimeDropdownPicker';
import { marginMobileCss } from '../comps/SetupSection';
import { PauseRow } from './comps/PauseRow';

interface SuperSetProps {
  superSet: TrainingSectionPartWithOperation;
  typeOfSet: ReactNode;
  isLast: boolean;
  isSingleOne: boolean;
  previousPause: number | undefined;
  updateSuperSet: (superSetToSave: TrainingSectionPartWithOperation) => void;
  handleAdd: (from: TrainingSectionPartWithOperation) => void;
}

export const SuperSet: FC<SuperSetProps> = ({
  superSet,
  typeOfSet,
  isSingleOne,
  isLast,
  previousPause,
  updateSuperSet,
  handleAdd,
}) => {
  const isEmptySet = superSet.training_exercises.length === 0;
  const [superSetToSave, setSuperSetToSave] = useState(() => superSet);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const hideX = useMediaQuery('(max-width: 350px)');
  const [isSuperSetOpened, toggleSuperSet] = useState(true);
  const [seriesInput, setSeriesInput] = useState(superSet.series || 4);

  useEffect(() => {
    setSuperSetToSave({
      ...superSet,
      training_exercises: superSet.training_exercises,
    });
  }, [superSet, superSet.training_exercises]);

  const handleChange = (value: string | number, name: string) => {
    const stateToChange = inputNameState.find(
      i => i.name === (name as ExerciseInputsEnum),
    );

    if (stateToChange) {
      // kdyz je to pauza prepocitej pause input
      let pause = 0;
      if (name === ExerciseInputsEnum.PAUSE_MODIFIER) {
        if (superSetToSave.pause) {
          pause =
            value === PauseModifierEnum.Seconds
              ? superSetToSave.pause * 60
              : superSetToSave.pause * (1 / 60);
        }
      }

      if (name === ExerciseInputsEnum.SERIES) {
        const updatedSuperSet: TrainingSectionPartWithOperation = {
          ...superSetToSave,
          series: Number(value),
          training_exercises: superSetToSave.training_exercises.map(te =>
            updateExerciseWeightsAndRepeatsAccordingToNumberOfSeries(te, value),
          ),
        };
        updateSuperSet(updatedSuperSet);
      } else {
        const updatedSectionPart = updateSectionPartAttribute(
          name === ExerciseInputsEnum.PAUSE_MODIFIER
            ? updateSectionPartAttribute(superSetToSave, pause, 'pause')
            : superSetToSave,
          value,
          stateToChange.stateAttr,
        );
        updateSuperSet(updatedSectionPart);
      }
    }
  };

  // SuperSet deletion
  const handleDelete = () => {
    const updatedSectionPart: TrainingSectionPartWithOperation = {
      ...superSetToSave,
      operation: OperationEnum.DELETED,
    };
    updateSuperSet(updatedSectionPart);
  };

  const lastExercise = useMemo(
    () =>
      superSetToSave.training_exercises[
        superSetToSave.training_exercises.length - 1
      ],
    [superSetToSave.training_exercises],
  );

  // UPDATES TExercise ORDER ON ITEMS SWAP
  const updateExerciseOrder = useCallback(
    (
      newlyOrdered: TrainingExerciseWithOperation[],
      sortable?: Sortable | null,
      store?: Store,
      updatedSuperSet?: TrainingSectionPartWithOperation,
    ) => {
      if (store && !store.dragging) {
        return;
      }
      // separate elements to arrays
      // DELETED which indexes will be ignored
      const deletedElements = newlyOrdered.filter(
        fd => fd.operation === OperationEnum.DELETED,
      );
      // ACTIVE that will be reordered
      const activeElements = newlyOrdered.filter(
        fd => fd.operation !== OperationEnum.DELETED,
      );

      const updatedExercises = activeElements.map((te, index) => {
        let copiedExercise: TrainingExerciseWithOperation = { ...te };

        // if is new item in sortable, then generate unique ID and flagged it so it wont get generated again
        if (te.operation === OperationEnum.CREATED && !te.flagged) {
          copiedExercise = {
            ...te,
            id: uuid(),
            flagged: true,
          };

          const prevTE = index > 0 ? activeElements[index - 1] : undefined;
          copiedExercise = copyTrainingExerciseInSuperSet(
            copiedExercise,
            prevTE,
          );
        }

        const nextTE: TrainingExerciseWithOperation = activeElements[index + 1];

        const isNextNew =
          nextTE &&
          nextTE.operation === OperationEnum.CREATED &&
          !nextTE.flagged;
        const isNextLast = nextTE && !activeElements[index + 2];
        const isCurrentNew =
          te.operation === OperationEnum.CREATED && !te.flagged;
        const isCurrentLast = !nextTE;
        const hasEmptyAfterPause = !te.pauseAfterExercise;
        if (
          (superSetToSave.type === TrainingSectionTypeEnum.CircuitTraining &&
            ((isNextNew && isNextLast) || (isCurrentNew && !isCurrentLast))) ||
          (hasEmptyAfterPause && !isCurrentLast)
        ) {
          const prevPause = previousPauseInSet(superSetToSave, index);
          copiedExercise = updateExerciseAttribute(
            copiedExercise,
            prevPause,
            'pauseAfterExercise',
          );
        }

        return updateExerciseAttribute(copiedExercise, index + 1, 'order');
      });

      const superSet = updatedSuperSet || superSetToSave;

      const newSuperSet: TrainingSectionPartWithOperation = {
        ...superSet,
        training_exercises: [...updatedExercises, ...deletedElements],
      };

      updateSuperSet(newSuperSet);
    },
    [superSetToSave, updateSuperSet],
  );

  const updateExerciseInSuperSet = useCallback(
    (
      exercise: TrainingExerciseWithOperation,
      // is repeat modified? If yes we have change all rounds in circuitTraining
      repeat?: number | string,
    ) => {
      const updatedExercises = superSetToSave.training_exercises.map(te =>
        te.id === exercise.id
          ? exercise
          : { ...te, repeats: repeat ? repeat.toString() : te.repeats },
      );
      let deletedExerciseOperation;

      // pokud v superserii je jeden cvik a ten smazu tak cele superserii nastavuji deleted
      if (
        updatedExercises.length === 1 &&
        updatedExercises[0].operation === OperationEnum.DELETED
      ) {
        deletedExerciseOperation = OperationEnum.DELETED;
        // super set uz byl vytvoren takze chci updatovat existujici
      } else if (superSetToSave.operation === OperationEnum.DONOTHING) {
        deletedExerciseOperation = OperationEnum.UPDATED;
      }

      const updated: TrainingSectionPartWithOperation = {
        ...superSetToSave,
        training_exercises: updatedExercises,
        // pokud se nezmenilo operation podle veci ktere se menili v exercise tak tam vrazim to co tam bylo nebo to zmenene
        operation: deletedExerciseOperation || superSetToSave.operation,
      };

      updateExerciseOrder(updated.training_exercises, null, undefined, updated);
    },
    [superSetToSave, updateExerciseOrder],
  );

  const handleExercisePauseChange = useCallback(
    (pause: number) => {
      const updatedExercise = updateExerciseAttribute(
        lastExercise,
        pause,
        'pauseAfterExercise',
      );
      updateExerciseInSuperSet(updatedExercise);
    },
    [lastExercise, updateExerciseInSuperSet],
  );

  const renderExercises = (isSuperSet: boolean) => (
    <Grid item css={fullWidthCss}>
      {isSuperSet ? (
        <ReactSortable
          list={superSetToSave.training_exercises}
          setList={updateExerciseOrder}
          animation={150}
          group={{ name: 'training-setup', pull: false, put: true }}
          fallbackOnBody
          fallbackTolerance={3}
          swapThreshold={0.65}
        >
          {superSetToSave.training_exercises.map((te, index) => {
            const isLast =
              index === superSetToSave.training_exercises.length - 1;

            return (
              <TrainingExercise
                // index needed to render the component
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                isSuperSet={isSuperSet}
                superSetType={superSetToSave.type}
                wrapperSeries={superSetToSave.series}
                exercise={te}
                isLast={isLast}
                updateTrainingExercise={updateExerciseInSuperSet}
              />
            );
          })}
        </ReactSortable>
      ) : (
        superSetToSave.training_exercises.map(te => (
          <TrainingExercise
            key={te.id}
            isSuperSet={isSuperSet}
            superSetType={superSetToSave.type}
            exercise={te}
            isLast={isLast}
            previousAfterPause={previousPause}
            updateTrainingExercise={updateExerciseInSuperSet}
          />
        ))
      )}
    </Grid>
  );

  const renderPopUpMenu = () => {
    const deleteText = <Trans>Smazat</Trans>;
    const addText = <Trans>Přidat cvik</Trans>;
    const addPauseText =
      superSet.type === TrainingSectionTypeEnum.SuperSet ? (
        <Trans>Přidat pauzu za Supersérii</Trans>
      ) : (
        <Trans>Přidat pauzu za Kruhový trénink</Trans>
      );

    let button;

    if (superSet.type === TrainingSectionTypeEnum.CircuitTraining) {
      button = {
        label: addText.props.id,
        icon: Timer,
        onClick: () => {
          handleAdd(superSet);
        },
      };
    }

    if (superSet.type === TrainingSectionTypeEnum.SuperSet) {
      button = {
        label: addText.props.id,
        icon: InsertLink,
        onClick: () => {
          handleAdd(superSet);
        },
      };
    }

    const menuItems = [
      {
        label: addPauseText.props.id,
        icon: Pause,
        disabled: superSet.training_exercises.length === 0,
        onClick: () => handleExercisePauseChange(10),
      },
      {
        label: deleteText.props.id,
        icon: Delete,
        onClick: () => handleDelete(),
      },
    ];

    if (isMobile && button) {
      menuItems.unshift(button);
    }

    return <PopupMenu css={isMobile && noPaddingCss} menuItems={menuItems} />;
  };
  const decideText = () => {
    const numberOfVisibleExercises = superSetToSave.training_exercises.filter(
      te => te.operation !== OperationEnum.DELETED,
    ).length;
    if (numberOfVisibleExercises === 1) {
      return <Trans>cvik</Trans>;
    }
    if (numberOfVisibleExercises >= 5 || numberOfVisibleExercises === 0) {
      return <Trans>cviků</Trans>;
    }
    return <Trans>cviky</Trans>;
  };
  const renderSetHeader = () => (
    <Grid
      container
      css={setHeaderCss(isMobile)}
      direction={isMobile ? 'column' : 'row'}
      alignItems="center"
      spacing={1}
      justify="space-evenly"
    >
      {/* header */}
      <Grid container alignItems="center" item xs={12} sm={4}>
        {isMobile && (
          <Grid item xs={1}>
            {!isSuperSetOpened ? (
              <AddBoxIcon
                css={addBoxIconCss}
                fontSize="small"
                onClick={() => toggleSuperSet(!isSuperSetOpened)}
              />
            ) : (
              <RemoveBoxIcon
                css={addBoxIconCss}
                fontSize="small"
                onClick={() => toggleSuperSet(!isSuperSetOpened)}
              />
            )}
          </Grid>
        )}
        <Grid item xs={5} sm={4} css={isMobile ? titleCss : wrapNameCss}>
          {typeOfSet}
        </Grid>
        {isMobile && (
          <Fragment>
            <Grid item xs={5} css={titleExerciseCss}>
              (
              {
                superSetToSave.training_exercises.filter(
                  te => te.operation !== OperationEnum.DELETED,
                ).length
              }
              {'\xa0'}
              {decideText()})
            </Grid>

            <Grid
              container
              item
              alignItems={isMobile ? 'flex-start' : undefined}
              justify={isMobile ? 'center' : undefined}
              xs={1}
            >
              {renderPopUpMenu()}
            </Grid>
          </Fragment>
        )}
      </Grid>
      {isMobile && (
        <Grid container item xs={12} css={headerDescCss}>
          <Grid item xs={2}>
            {superSet.type === TrainingSectionTypeEnum.CircuitTraining ? (
              <Trans>Kolo</Trans>
            ) : (
              <Trans>Série</Trans>
            )}
          </Grid>
          <Grid item xs={4}>
            <Trans>Opakování</Trans>
          </Grid>
          <Grid item xs={3}>
            <Trans>Váha</Trans>
          </Grid>
          <Grid item xs={3}>
            <Trans>Pauza</Trans>
          </Grid>
        </Grid>
      )}
      <Grid container alignItems="center" item sm={8}>
        <Grid
          container
          item
          xs={12}
          sm={11}
          justify="space-between"
          alignItems={isMobile ? 'center' : undefined}
        >
          <Grid container item xs={2} sm={2}>
            <OutlinedInput
              css={inputCss()}
              type="number"
              inputMode="numeric"
              name={ExerciseInputsEnum.SERIES}
              inputProps={{ min: 1 }}
              fullWidth
              value={seriesInput}
              // onBlur was added because user can write into input,
              // f.e. deletion or typing into input should not generate new series right away when typing.
              // should update onBlur only for user not to loose filled series
              onBlur={(e: any): void => {
                // in Safari, user can write strings into input, but as it is error onBlur event it will default into 4 series
                // if input is cleared by user, we defaultly add 4 series
                setSeriesInput(e.target.value || 4);
                handleChange(
                  (e.target.value as number | string) || 4,
                  e.target.name,
                );
              }}
              onChange={(e: any) => {
                setSeriesInput(e.target.value);
              }}
              margin="dense"
              endAdornment={isMobile && !hideX ? 'x' : undefined}
            />
          </Grid>
          <Grid container item xs={3} sm={3}>
            <TimeDropdownPicker
              value={superSetToSave.pause}
              handlePauseChange={(e: number) =>
                handleChange(e, ExerciseInputsEnum.PAUSE)
              }
              hasPause={false}
            />
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item xs={1}>
            {renderPopUpMenu()}
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const renderSuperSet = () =>
    superSetToSave.operation !== OperationEnum.DELETED ? (
      <Grid container>
        <Grid item css={[setWrapCss(isMobile, isLast), selectedItemCss]}>
          {renderSetHeader()}
          {!isMobile
            ? renderExercises(true)
            : isSuperSetOpened && renderExercises(true)}
          {isEmptySet && !isMobile && (
            <div css={[dropzoneCss(isMobile), isMobile && marginMobileCss]}>
              <span css={dropzoneTextCss}>
                <Trans>Přidejte cvik přetáhnutím z pravého seznamu,</Trans>
              </span>
            </div>
          )}
        </Grid>
        {lastExercise && lastExercise.pauseAfterExercise ? (
          <PauseRow
            pause={lastExercise.pauseAfterExercise}
            isSuperSet={false}
            handlePauseChange={handleExercisePauseChange}
          />
        ) : (
          <Fragment />
        )}
      </Grid>
    ) : (
      <Fragment />
    );

  return isSingleOne ? renderExercises(false) : renderSuperSet();
};

const setWrapCss = (isMobile: boolean, isLast: boolean) => css`
  border-left: ${!isMobile && `4px solid ${COLORS.gray6}`};
  padding-left: ${isMobile ? 0 : 3 * MPX}px !important;

  margin: ${!isMobile && `${2 * MPX}px 0 ${3 * MPX}px ${2 * MPX}px !important`};
  width: 100% !important;
  border-bottom: ${isMobile && !isLast && `1px solid ${COLORS.gray8}`};
  background-color: ${isMobile && COLORS.grayBlueBackground};
  border-radius: ${isMobile && isLast && `0 0 10px 10px`};

  .MuiInputBase-root {
    background: white;
  }
`;

export const titleCss = css`
  color: ${COLORS.darkBlue};
  font-size: 18px;
  font-weight: 300;
  word-wrap: break-word;
`;
export const titleExerciseCss = css`
  color: ${COLORS.gray8};
  font-size: 18px;
  font-weight: 200;
  word-wrap: break-word;
`;
const wrapNameCss = css`
  color: ${COLORS.darkBlue};
  font-size: 20px;
  font-weight: 400;
`;

export const setHeaderCss = (isMobile: boolean): SerializedStyles => css`
  margin-bottom: ${MPX}px !important;
  margin-top: ${MPX}px;
  padding: ${isMobile && `0px ${MPX * 2}px !important`};
`;

const fullWidthCss = css`
  width: 100% !important;
`;

const noPaddingCss = css`
  padding: 0 !important;
`;

export const headerDescCss = css`
  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.gray6};
  text-align: center;
`;
