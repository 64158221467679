/** @jsx jsx */
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { css, jsx } from '@emotion/react';
import { performFetchTrainingById } from '../../../store/training/actions';
import {
  getSelectedTraining,
  getSelectedTrainingId,
  getSelectedTrainingSections,
} from '../../../store/training/selectors';
import TrainingDetailSectionWrapper from './components/TrainingDetailSectionWrapper';
import {
  COLORS,
  EXTENDED_MENU_HEIGHT,
  MPX,
  TOP_MENU_HEIGHT,
} from '../../../styles/themes';
import { HeadingWithBackButton } from '../shared/HeadingWithBackButton';
import { TrainingSectionDTO } from '../../../strapi/TrainingDTO';
import { TypeOfSection } from '../../../interfaces/enums';
import { orderByOrder } from '../helper/functions';
import { performIsInPauseAfterExercise } from '../../../store/trainingParts/actions';
import { isInPauseAfterExercise } from '../../../store/trainingParts/selectors';
import { getLoggedUser } from '../../../store/auth/selectors';

const getOrder = (
  sections: TrainingSectionDTO[],
  current: TrainingSectionDTO,
  index: number,
): number => {
  let newIndex = index;
  if (current.title === TypeOfSection.STRETCH) {
    const trainingSection = sections.find(
      section => section.title === TypeOfSection.TRAINING,
    );

    if (trainingSection) {
      newIndex = trainingSection.training_section_parts.length - 1 + index;
    }
  }

  return newIndex;
};

const TrainingDetail: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  const { id: trainingId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const trainingSections = useSelector(getSelectedTrainingSections);
  const storeTrainingId = useSelector(getSelectedTrainingId);
  const training = useSelector(getSelectedTraining);
  const isInPause = useSelector(isInPauseAfterExercise);
  const user = useSelector(getLoggedUser);

  useEffect(() => {
    if (!storeTrainingId || storeTrainingId !== trainingId) {
      dispatch(performFetchTrainingById(trainingId));
    }
  }, [trainingId, dispatch, storeTrainingId]);

  useEffect(() => {
    if (isInPause) {
      dispatch(performIsInPauseAfterExercise(false));
    }
  }, [dispatch, isInPause]);

  if (!storeTrainingId || !isMobile || !training) {
    return null;
  }

  const orderedSections: TrainingSectionDTO[] = orderByOrder(trainingSections);

  return (
    <div>
      <div css={headerCss}>
        <HeadingWithBackButton
          title="Detail tréninku"
          link="/c/schedule"
          showConfirmationModalOnClose={!training.isFinished}
        />
      </div>
      <Grid container direction="column" css={trainingDetailWrapperCss}>
        {orderedSections.map((trainingSection, index) => (
          <TrainingDetailSectionWrapper
            section={trainingSection}
            orderOfSection={getOrder(
              orderedSections,
              trainingSection,
              index + 1,
            )}
            key={trainingSection.id}
          />
        ))}
      </Grid>
    </div>
  );
};

export default TrainingDetail;

const headerCss = css`
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  left: 0;
  right: 0;
`;

const trainingDetailWrapperCss = css`
  padding-left: 3px !important;
  padding-top: ${TOP_MENU_HEIGHT}px;
  padding-bottom: ${EXTENDED_MENU_HEIGHT + 10 * MPX}px;
  overflow: scroll;
  background-color: ${COLORS.bcgTrainingClient};
  min-height: 96vh;
`;
