/** @jsx jsx */
import { FC, useState, useEffect, useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import {
  useMediaQuery,
  Grid,
  Typography,
  Button,
  useTheme,
  IconButton,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { COLORS, MPX } from '../../../styles/themes';
import { Note } from './comps/Note';
import { ModalWindow } from '../../shared/ModalWindow';
import { AppTextInput } from '../../shared/Forms';
import { requiredRule } from '../../shared/helpers/FormRules';
import {
  performFetchNotesByUserId,
  performAddNote,
  performUpdateNote,
  performDeleteNote,
} from '../../../store/notes/actions';
import { NoteDTO, CreateNoteDTO, UpdateNoteDTO } from '../../../strapi/NoteDTO';
import { sortUserNotes } from '../../../code/helpers/user';
import { headerCss } from '../tabs/trainingPlan/TrainingPlan';
import { addBtnCss, iconBtnCss } from '../../../styles/buttons';
import { iconMobileCss, mobileBodyMargin } from '../../../styles/general';

export interface StateProps {
  notes: NoteDTO[];
  isLoading: boolean;
  requestError: string | undefined;
}

export interface DispatchProps {
  performFetchNotesByUserId: typeof performFetchNotesByUserId;
  performAddNote: typeof performAddNote;
  performUpdateNote: typeof performUpdateNote;
  performDeleteNote: typeof performDeleteNote;
}

export interface RouteParams {
  id: string;
}

type NotesProps = StateProps & RouteComponentProps<RouteParams> & DispatchProps;

export const ClientNotes: FC<NotesProps> = ({
  notes,
  performFetchNotesByUserId,
  performAddNote,
  performUpdateNote,
  performDeleteNote,
  requestError,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // sort notes according to date
  const memoNotes = useMemo(() => sortUserNotes(notes), [notes]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id: userId } = useParams<{ id: string }>();

  useEffect(() => {
    performFetchNotesByUserId(userId);
  }, [performFetchNotesByUserId, userId]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (requestError) {
      enqueueSnackbar(requestError, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, requestError]);

  const onSubmit = (e: any) => {
    const dataDTO: CreateNoteDTO = {
      user: userId!,
      text: e.note,
    };

    performAddNote(dataDTO);
    setIsModalOpen(false);
  };

  const onEdit = (note: NoteDTO, newData: UpdateNoteDTO) => {
    performUpdateNote(note.id, newData);
  };

  const onDelete = (noteId: string) => {
    performDeleteNote(noteId);
  };

  const renderHeader = (): JSX.Element => (
    <Grid css={headerCss}>
      <Typography variant="h5">
        <Trans>Poznámky</Trans>
      </Typography>

      {!isMobile ? (
        <div>
          <Button
            onClick={() => {
              setIsModalOpen(true);
            }}
            size="medium"
            variant="contained"
            color="secondary"
          >
            <Trans>Vytvořit poznámku</Trans>
          </Button>
        </div>
      ) : (
        <IconButton
          css={iconBtnCss}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <AddCircle css={[addBtnCss, iconMobileCss]} />
        </IconButton>
      )}
    </Grid>
  );
  return (
    <div css={[backgroundCss(isMobile), mobileBodyMargin(isMobile)]}>
      {renderHeader()}

      <ModalWindow
        headerText={<Trans>Poznámka</Trans>}
        isOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        agreeButtonText={<Trans>Uložit</Trans>}
        cancelButtonText={<Trans>Zavřít</Trans>}
        onSubmit={onSubmit}
        closeIcon
        mobileFooter={false}
      >
        <AppTextInput
          label="Poznámka"
          variant="outlined"
          name="note"
          rules={{ ...requiredRule }}
          multiline
          rows="8"
          rowsMax="8"
        />
      </ModalWindow>

      <Grid
        container
        css={[noteGridCss]}
        alignItems="flex-start"
        justify="flex-start"
        spacing={3}
      >
        {memoNotes.map((item: NoteDTO) => (
          <Grid key={item.id} item xs={12} sm={3}>
            <Note note={item} editNote={onEdit} deleteNote={onDelete} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const backgroundCss = (isMobile: boolean) => css`
  background-color: ${isMobile ? COLORS.white : COLORS.bg};
  padding: ${!isMobile && 5 * MPX}px;
`;

const noteGridCss = css`
  padding-top: ${2 * MPX}px;
`;
