/** @jsx jsx */
import React from 'react';
import { IconButton } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { css, jsx } from '@emotion/react';
import { COLORS } from '../../../../styles/themes';

const TrainingDetailIconNext: React.FC = () => (
  <div>
    <IconButton css={navigateCss}>
      <NavigateNext css={iconCss} />
    </IconButton>
  </div>
);

export default TrainingDetailIconNext;

const navigateCss = css`
  width: 1.5em !important;
  height: 1.5em !important;
  color: white !important;
`;

const iconCss = css`
  color: ${COLORS.white} !important;
`;
