/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import {
  Button,
  Link,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { mdiFacebook, mdiGoogle } from '@mdi/js';
import Icon from '@mdi/react';

import { Link as RouterLink } from 'react-router-dom';
import { ButtonProps } from '@material-ui/core/Button';
import { SocialSite } from '../../interfaces/enums';
import { AppTextDivider } from './Typo';

interface SocialButtonsProps {
  buttons?: SocialSite[];
  text?: string;
}

const sbMapper = {
  [SocialSite.FB]: {
    icon: mdiFacebook,
    color: '#0071bb',
  },
  [SocialSite.Google]: {
    icon: mdiGoogle,
    color: '#dd4b39',
  },
};

export const SocialButtons: React.FC<SocialButtonsProps> = ({
  buttons,
  text,
}) => {
  if (!buttons || !buttons.length) {
    return null;
  }

  return (
    <div css={socialButtons}>
      {buttons.map(socialButton => {
        const { color, icon } = sbMapper[socialButton];
        return (
          <Button
            variant="outlined"
            key={socialButton}
            size="large"
            fullWidth
            style={{
              textTransform: 'none',
              marginBottom: `12px`,
              color: `#FFF`,
            }}
            css={{
              backgroundColor: `${color} !important`,
              borderColor: `${color} !important`,
            }}
            startIcon={<Icon path={icon} size={1} color="#fff" />}
          >
            <Trans id={`${text} ${socialButton}`} />
          </Button>
        );
      })}

      <AppTextDivider text="nebo" />
    </div>
  );
};

const socialButtons = css`
  margin-bottom: 25px !important;
`;

type LinkButtonProps = {
  text: string;
  path?: string;
  withMobileIcon?: boolean;
  colorIcon?: string;
} & ButtonProps;

export const LinkButton: React.FC<LinkButtonProps> = ({
  text,
  path,
  variant,
  color,
  fullWidth,
  size,
  withMobileIcon,
  children,
  colorIcon,
  ...restProps
}) => {
  const allProps: ButtonProps = {
    variant: variant || 'contained',
    color: color || 'secondary',
    size: size || 'large',
    fullWidth: fullWidth != null ? fullWidth : false,
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const decideAvatar = () => {
    if (!withMobileIcon) {
      return (
        <Button type="submit" {...restProps} {...allProps}>
          <Trans id={text} />
        </Button>
      );
    }
    if (isMobile) {
      return (
        <Avatar css={avatarColor(colorIcon || '')} variant="circle">
          {children}
        </Avatar>
      );
    }
    return (
      <Button type="submit" {...restProps} {...allProps}>
        <Trans id={text} />
      </Button>
    );
  };
  return path ? (
    <Link component={RouterLink} to={path} css={link}>
      {decideAvatar()}
    </Link>
  ) : (
    decideAvatar()
  );
};

const link = css`
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }
`;

const avatarColor = (color: string) => css`
  background-color: ${color} !important;
`;
