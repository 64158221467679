import { getHours, getMinutes, isSameDay } from 'date-fns';
import {
  TrainingDTO,
  TrainingSectionDTO,
  TrainingSectionPartsDTO,
  TrainingExerciseDTO,
} from '../../strapi/TrainingDTO';
import { COLORS } from '../../styles/themes';
import { TrainerEventDTO } from '../../strapi/TrainerEventDTO';
import { EventDTO } from '../../interfaces/event';

// returns name for Training. If training has body parts then return body part names.
// Else return 'Training 1'
export const getNameForTraining = (
  training: TrainingDTO,
  trainingText: string,
): string =>
  training.body_part_names && training.body_part_names.length > 0
    ? training.body_part_names.map(item => item.name).join(', ')
    : `${trainingText} ${training.order}`;

export const getAllExecrisesFromTrainingPlan = (
  training: TrainingDTO,
): string[] => {
  const allExercises: string[] = [];

  training.training_sections
    .filter((ts: TrainingSectionDTO) => ts.isText === false)
    .forEach((ts: TrainingSectionDTO) => {
      ts.training_section_parts.forEach((tsp: TrainingSectionPartsDTO) => {
        tsp.training_exercises.forEach((te: TrainingExerciseDTO) => {
          allExercises.push(te.exercise.title.text);
        });
      });
    });

  return allExercises;
};

export const getUpdatedHoursForDateTo = (
  dateFrom: Date,
  dateTo: Date,
): Date => {
  // every training is within the same day
  const timeToWithStartDate = new Date(dateFrom);
  timeToWithStartDate.setHours(getHours(new Date(dateTo)));
  timeToWithStartDate.setMinutes(getMinutes(new Date(dateTo)));
  return timeToWithStartDate;
};

export const decideDateTo = (
  training: TrainingDTO | TrainerEventDTO | undefined,
): Date => {
  if (training) {
    if (training.dateTo) {
      return new Date(training.dateTo);
    }
    return training.dateFrom ? new Date(training.dateFrom) : new Date();
  }
  return new Date();
};

export const decideLabel = (isDisabled: boolean, isMobile: boolean): string => {
  if (isDisabled) {
    return isMobile ? COLORS.gray6 : COLORS.scheduleInputBg;
  }
  return isMobile ? COLORS.gray6 : COLORS.contrast;
};

export const determineIfEventOcursInTheSameDayAsAnyTraining = (
  trainings: TrainingDTO[],
  event: TrainerEventDTO,
): boolean =>
  Boolean(
    trainings.find(training => {
      if (!training.dateFrom) {
        return false;
      }
      return isSameDay(new Date(training.dateFrom), new Date(event.dateFrom));
    }),
  );
