/** @jsx jsx */
import { FC, Fragment, ReactNode } from 'react';
import { jsx, css, SerializedStyles } from '@emotion/react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { useForm, FormProvider } from 'react-hook-form';
import { COLORS, MPX } from '../../styles/themes';
import { BASE_FORM_MODE } from './helpers/FormSetting';
import {
  headerBodyMargin,
  iconMobileCss,
  mobileBodyMargin,
} from '../../styles/general';
import { marginMobileCss } from '../trainingSetup/comps/SetupSection';

interface ComponentProps {
  isOpen: boolean;
  handleAgree?: () => void;
  handleClose: (inModal?: boolean) => void;
  agreeButtonText: ReactNode;
  cancelButtonText?: ReactNode;
  headerText: ReactNode;
  fullWidth?: boolean;
  closeIcon?: boolean;
  onSubmit?: (data: unknown) => void;
  disableSubmitBtnOnError?: boolean;
  fullScreen?: boolean;
  mobileFooter: boolean;
}

export const ModalWindow: FC<ComponentProps> = ({
  isOpen,
  handleClose,
  agreeButtonText,
  cancelButtonText,
  headerText,
  fullWidth,
  fullScreen,
  children,
  onSubmit,
  disableSubmitBtnOnError,
  handleAgree,
  mobileFooter,
}) => {
  const methods = useForm({
    mode: BASE_FORM_MODE,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const onSubmitBasic = methods.handleSubmit(data => {
    if (onSubmit) {
      onSubmit(data);
    }
  });
  const renderButtons = (submitBtnDisabled?: boolean) => (
    <Fragment>
      <Grid item>
        <Button
          size="large"
          color="secondary"
          variant="contained"
          type={onSubmit ? 'submit' : undefined}
          onClick={onSubmit ? undefined : handleAgree}
          disabled={disableSubmitBtnOnError && submitBtnDisabled}
        >
          {agreeButtonText}
        </Button>
      </Grid>
      {cancelButtonText && (
        <Grid item>
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            onClick={() => {
              handleClose(true);
            }}
          >
            {cancelButtonText}
          </Button>
        </Grid>
      )}
    </Fragment>
  );
  const renderDialogContent = (submitBtnDisabled?: boolean) => (
    <Fragment>
      <DialogTitle css={[headerCss, headerBodyMargin(isMobile)]}>
        <Grid container alignItems="flex-start" justify="flex-start">
          <span>
            {closeIconBtn && (
              <IconButton
                onClick={() => {
                  handleClose();
                }}
                css={[closeIconBtn(isMobile)]}
              >
                <CloseOutlined css={isMobile && iconMobileCss} />
              </IconButton>
            )}
            {headerText}
          </span>
        </Grid>
      </DialogTitle>
      <DialogContent
        css={[
          fullScreen ? mobileBodyMargin(isMobile) : isMobile && marginMobileCss,
          contentCss(isMobile),
        ]}
      >
        {onSubmit ? (
          <FormProvider {...methods}>
            <form onSubmit={onSubmitBasic}>{children}</form>
          </FormProvider>
        ) : (
          children
        )}
      </DialogContent>

      <DialogActions>
        <Grid
          container
          spacing={1}
          justify="center"
          direction="row"
          css={[actionsCss, isMobile && mobileFooter && mobileActionsCss]}
        >
          {onSubmit ? (
            <FormProvider {...methods}>
              <form onSubmit={onSubmitBasic} css={formCss}>
                <Grid
                  container
                  spacing={1}
                  justify="center"
                  direction="row"
                  css={[
                    actionsCss,
                    isMobile && mobileFooter && mobileActionsCss,
                  ]}
                >
                  {renderButtons(submitBtnDisabled)}
                </Grid>
              </form>{' '}
            </FormProvider>
          ) : (
            renderButtons(submitBtnDisabled)
          )}
        </Grid>
      </DialogActions>
    </Fragment>
  );

  return (
    <Dialog
      open={isOpen}
      fullScreen={fullScreen}
      onClose={() => {
        handleClose();
      }}
      css={dialogCss(isMobile)}
      fullWidth={fullWidth}
      scroll="paper"
    >
      {onSubmit
        ? renderDialogContent(!methods.formState.isValid)
        : renderDialogContent()}
    </Dialog>
  );
};

const dialogCss = (isMobile: boolean) => css`
  .MuiPaper-root {
    min-width: ${!isMobile && `${80 * MPX}px !important`};
    max-height: ${!isMobile && '80%'};
  }
`;

const formCss = css`
  width: inherit;
`;

const headerCss = css`
  .MuiTypography-root {
    position: relative;
    font-size: ${7 * MPX}px;

    justify-items: flex-start;
    color: ${COLORS.gray};
  }
`;

const actionsCss = css`
  padding: ${1 * MPX}px 0px;
  justify-content: center !important;
`;

const mobileActionsCss = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: white;
  z-index: 99999;
`;

const contentCss = (isMobile: boolean) => css`
  text-align: center;
  width: ${isMobile && 'initial !important'};
`;

const closeIconBtn = (isMobile: boolean): SerializedStyles => css`
  padding: ${!isMobile ? 1.25 * MPX : 0}px !important;
  color: ${COLORS.gray6};
`;
