// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateMessageResponseDto
 */
export interface UpdateMessageResponseDto {
  /**
   *
   * @type {number}
   * @memberof UpdateMessageResponseDto
   */
  chatMessageId: number;
  /**
   *
   * @type {number}
   * @memberof UpdateMessageResponseDto
   */
  chatId: number;
  /**
   *
   * @type {number}
   * @memberof UpdateMessageResponseDto
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof UpdateMessageResponseDto
   */
  body: string;
}

export function UpdateMessageResponseDtoFromJSON(
  json: any,
): UpdateMessageResponseDto {
  return UpdateMessageResponseDtoFromJSONTyped(json, false);
}

export function UpdateMessageResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateMessageResponseDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    chatMessageId: json['chatMessageId'],
    chatId: json['chatId'],
    userId: json['userId'],
    body: json['body'],
  };
}

export function UpdateMessageResponseDtoToJSON(
  value?: UpdateMessageResponseDto | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    chatMessageId: value.chatMessageId,
    chatId: value.chatId,
    userId: value.userId,
    body: value.body,
  };
}
