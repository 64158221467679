import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import { changeCircularRunning, changeUiLanguage } from './actions';
import { AppLanguages, DEFAULT_LANGUAGE } from '../../code/constants/languages';

export interface State {
  language: AppLanguages;
  isCircularRunning: boolean;
}

export const initialState: State = {
  language: DEFAULT_LANGUAGE,
  isCircularRunning: false,
};

export const generalReducer = reducer<State>(
  initialState,
  on(changeUiLanguage, (state: State, { payload }) => {
    state.language = payload.nextLanguage;
  }),
  on(changeCircularRunning, (state: State, { payload }) => {
    state.isCircularRunning = payload.running;
  }),
);
