import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import { AxiosResponse } from 'axios';
import { State } from '../combinedReducers';
import { baseAsyncRequest } from '../actionHelpers';
import { TrainingPlansActionTypes as ActionTypes } from '../actionTypes';
import { makeServerCallAsync } from '../../code/helpers/api/api';
import {
  TrainingPlanDTO,
  CreateTrainingPlanDTO,
  UpdateTrainingPlanDTO,
} from '../../strapi/TrainingPlanDTO';
import { TrainingDTO } from '../../strapi/TrainingDTO';
import { updateClient } from '../client/actions';

export const processTrainingPlans = action(
  ActionTypes.PROCESS_TRAINING_PLANS,
  payload<{ trainingPlans: TrainingPlanDTO[] }>(),
);

export const setAllTrainingPlans = action(
  ActionTypes.SET_ALL_TRAINING_PLANS,
  payload<{ trainerTrainingPlans: TrainingPlanDTO[] }>(),
);
export const addTrainingPlan = action(
  ActionTypes.ADD_TRAINING_PLAN,
  payload<{ trainingPlan: TrainingPlanDTO }>(),
);

export const updateTrainingPlan = action(
  ActionTypes.UPDATE_TRAINING_PLAN,
  payload<{ trainingPlan: TrainingPlanDTO }>(),
);

export const removeTrainingPlan = action(
  ActionTypes.REMOVE_TRAINING_PLAN,
  payload<{ planId: string }>(),
);

export const updateTrainingInTrainingPlan = action(
  ActionTypes.UPDATE_TRAINING_IN_TRAINING_PLAN,
  payload<{ trainingPlanId: string; training: TrainingDTO }>(),
);

export const perfomTrainingPlansByClientId: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (id: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/training-plans?client=${id}`);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_TRAINING_PLANS_BY_CLIENT_ID,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      dispatch(processTrainingPlans({ trainingPlans: result.data }));
    }
  };

export const fetchTrainingPlans: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/training-plans`);

    const result = await baseAsyncRequest(
      ActionTypes.FETCH_TRAINING_PLANS,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      dispatch(setAllTrainingPlans({ trainerTrainingPlans: result.data }));
    }
  };
export const perfomTrainingPlansById: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (id: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/training-plans/${id}`);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_TRAINING_PLANS_BY_ID,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      dispatch(addTrainingPlan({ trainingPlan: result.data }));
    }
  };

export const performAddTrainingPlan: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (createDTO: CreateTrainingPlanDTO, onSuccessCallback: (id: number) => void) =>
  async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/training-plans`, createDTO);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_ADD_TRAINING_PLAN,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      const client = getState().client.clients.find(
        c => c.id === createDTO.client,
      );

      if (client) {
        dispatch(
          updateClient({
            client: {
              ...client,
              training_plans: [...client?.training_plans, result.data],
            },
          }),
        );
      }
      onSuccessCallback(result.data.id);
    }
  };

export const performDuplicateTrainingPlan: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (
    createDTO: CreateTrainingPlanDTO,
    trainingPlanId: string,
    onSuccessCallback: (id: string) => void,
  ) =>
  async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync(
        'PATCH',
        `/training-plans/${trainingPlanId}/duplicate`,
        createDTO,
      );

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_DUPLICATE_TRAINING_PLAN,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      const client = getState().client.clients.find(
        c => c.id === createDTO.client,
      );
      if (client) {
        dispatch(
          updateClient({
            client: {
              ...client,
              training_plans: [...client?.training_plans, result.data],
            },
          }),
        );
      }
      dispatch(addTrainingPlan({ trainingPlan: result.data }));
      onSuccessCallback(result.data.id);
    }
  };

export const performUpdateTrainingPlan: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (updateDTO: UpdateTrainingPlanDTO, onSuccessCallback: () => void) =>
  async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('put', `/training-plans/${updateDTO.id}`, updateDTO);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_UPDATE_TRAINING_PLAN,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      // Update client training plans
      const client = getState().client.clients.find(
        c => c.id === updateDTO.client,
      );

      if (client) {
        dispatch(
          updateClient({
            client: {
              ...client,
              training_plans: [...client?.training_plans, result.data],
            },
          }),
        );
      }
      onSuccessCallback();
    }
  };

export const performUpdateTrainingName: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (id: number, selectedBodyParts, onUpdateCallback, isFinished?: boolean) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const updateObject = {
      body_part_names: selectedBodyParts,
      isFinished,
    };

    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('put', `/trainings/${id}`, updateObject);

    const results = await baseAsyncRequest(
      ActionTypes.UPDATE_TRAINING,
      request,
      dispatch,
    );
    if (results) {
      onUpdateCallback();
    }
  };

export const performReplaceTrainingsByTraining: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (replaceBy: number, toReplace: number[], onReplaceCallback: any) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const trainingsToReplace = {
      trainings: toReplace,
    };

    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync(
        'patch',
        `/trainings/${replaceBy}/replace`,
        trainingsToReplace,
      );

    const results = await baseAsyncRequest(
      ActionTypes.REPLACE_TRAINING,
      request,
      dispatch,
    );
    if (results) {
      onReplaceCallback();
    }
  };

export const performRemoveTrainingPlan: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (id: string, onDeleteCallback: () => void) =>
  async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('delete', `/training-plans/${id}`);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_REMOVE_TRAINING,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      dispatch(removeTrainingPlan({ planId: result.data.id }));
      if (onDeleteCallback) {
        onDeleteCallback();
      }

      // Update client training plans
      const client = getState().client.clients.find(
        c => c.id === getState().client.activeClient?.id,
      );
      if (client) {
        dispatch(
          updateClient({
            client: {
              ...client,
              training_plans: [
                ...client?.training_plans.filter(tp => tp.id !== id),
              ],
            },
          }),
        );
      }
    }
  };
