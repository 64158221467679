/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { COLORS } from '../../../styles/themes';
import { getCircularRunning } from '../../../store/general/selectors';

export interface IImageCarouselImage {
  url: string;
  link: string;
  alt: string;
}

interface ImageCarouselProps {
  images: IImageCarouselImage[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const history = useHistory();
  const isCircuitRunning = useSelector(getCircularRunning);

  const routeToDetail = (link: string) => {
    if (!isCircuitRunning) {
      history.push(link);
    }
  };
  return (
    <div css={imageCarouselWrapperCss}>
      <Carousel
        showArrows={false}
        showThumbs={false}
        autoPlay={false}
        autoFocus={false}
        showStatus={false}
        infiniteLoop
        interval={9999999} // bug in the library https://github.com/leandrowd/react-responsive-carousel/issues/621
        showIndicators={images.length > 1}
      >
        {images.map(image => (
          // eslint-disable-next-line
          <div key={image.url} onClick={() => routeToDetail(image.link)}>
            <img css={imageCss} src={image.url} alt={image.alt} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;

const imageCarouselWrapperCss = css`
  .control-dots .dot {
    box-shadow: none !important;
    width: 12px !important;
    height: 12px !important;
    background: white !important;
    opacity: 0.7;
  }

  .dot.selected {
    background: ${COLORS.secondary} !important;
    opacity: 1 !important;
  }
`;

const imageCss = css`
  max-height: 30vh;
  object-fit: contain;
`;
