/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useEffect } from 'react';
import { Sound, useAnySound } from '../../../../code/helpers/sounds';
import { includesDelimiter } from '../../../../code/helpers/trainingExercise';
import { ExerciseDTO } from '../../../../strapi/ExerciseDTO';
import { TrainingExerciseDTO } from '../../../../strapi/TrainingDTO';
import { getImagesFromExerciseForCarousel } from '../../helper/functions';
import CommentTrainingDescriptionDetail from './singleExerciseDescriptionComponents/CommentTrainingDescriptionDetail';
import DataTrainingDescriptionDetail from './singleExerciseDescriptionComponents/DataTrainingDescriptionDetail';
import HeaderTrainingDescriptionDetail from './singleExerciseDescriptionComponents/HeaderTrainingDescriptionDetail';

export interface SingleExerciseDescriptionDetailProps {
  exercise: ExerciseDTO;
  trainingExercise: TrainingExerciseDTO;
  detailLink: string;
  orderOfExercise?: number;
}

const determineIfDetailNeeded = (
  trainingExercise: TrainingExerciseDTO,
): boolean =>
  includesDelimiter(trainingExercise.repeats) ||
  includesDelimiter(trainingExercise.weights);

const SingleExerciseDescriptionDetail: React.FC<SingleExerciseDescriptionDetailProps> =
  ({ exercise, trainingExercise, detailLink, orderOfExercise }) => {
    const { play } = useAnySound();

    useEffect(() => {
      if (exercise) {
        play(Sound.go);
      }
    }, [exercise, play]);

    if (!exercise) {
      return null;
    }

    return (
      <div>
        <HeaderTrainingDescriptionDetail
          images={getImagesFromExerciseForCarousel(
            [trainingExercise],
            [detailLink],
          )}
          title={exercise.title.text}
          trainingExercise={trainingExercise}
          detailLink={detailLink}
          orderOfExercise={orderOfExercise}
        />
        {trainingExercise && determineIfDetailNeeded(trainingExercise) && (
          <DataTrainingDescriptionDetail trainingExercise={trainingExercise} />
        )}
        {trainingExercise?.comment && (
          <CommentTrainingDescriptionDetail
            comment={trainingExercise.comment}
          />
        )}
      </div>
    );
  };

export default SingleExerciseDescriptionDetail;
