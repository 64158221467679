/** @jsx jsx */
import { useState, FC, useEffect, ChangeEvent } from 'react';
import { jsx, css, SerializedStyles } from '@emotion/react';
import { Trans } from '@lingui/macro';
import { cs } from 'date-fns/locale';
import {
  IconButton,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  TextField,
  MenuItem,
  Select,
  Grid,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useSnackbar } from 'notistack';
import { MPX, COLORS } from '../../styles/themes';
import { decideLabel } from '../../code/helpers/training';

import { EventDTO, EventType } from '../../interfaces/event';
import { UserDTO } from '../../strapi/UserDTO';

interface ComponentProps {
  event: EventDTO | undefined;
  handleClose: () => void;
  handleSave: (event: EventDTO) => void;
  requestError?: string | null;
  clients: UserDTO[];
}

const eventNameTrans = <Trans>Název události</Trans>;

export const TrainerEventModal: FC<ComponentProps> = ({
  event,
  handleClose,
  handleSave,
  requestError,
  clients,
}) => {
  const [selectedTimeTo, handleTimeToChange] = useState(
    event && event.end ? new Date(event.end) : new Date(),
  );

  const [selectedDate, handleDateChange] = useState(
    event && event.start ? new Date(event.start) : new Date(),
  );
  const [trainingPlace, setTrainingPlace] = useState(
    event && event.location ? event.location : '',
  );
  const [title, setTitle] = useState(event && event.title);

  const [client, setClient] = useState(
    event && event.client ? event.client.id : '',
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (requestError) {
      enqueueSnackbar(requestError, {
        variant: 'error',
      });
    }
  }, [requestError, enqueueSnackbar]);

  const handleFormSave = async () => {
    let decideClient;
    if (event && event.client) {
      if (event.client.id === client) {
        decideClient = event.client;
      }
    }
    const newEvent: EventDTO = {
      id: event ? event.id : '',
      start: selectedDate,
      end: selectedTimeTo,
      // should never fallback to that string as save is disabled without it
      // just TS to be OK with it
      title: title || 'Bez názvu',
      client: decideClient || clients.find(cl => client === cl.id),
      location: trainingPlace,
      type: EventType.event,
    };
    await handleSave(newEvent);
    handleClose();
  };
  const handleChangeSelect = (id: string) => {
    const found = clients.find(client => client.id === id);
    if (found) {
      setClient(found.id);
    }
  };

  return (
    <div css={isMobile ? scheduleCardCssMobile : scheduleCardCss(isMobile)}>
      <Grid container css={marginBottomCss}>
        {!isMobile && (
          <Grid
            container
            justifyContent="space-between"
            css={trainingNameCss(isMobile)}
          >
            <Typography variant="h6">
              <Trans>Nová událost</Trans>
            </Typography>

            <IconButton size="small" onClick={(): void => handleClose()}>
              <Close css={IconColorCss(isMobile)} />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={12}>
          <div css={inputWithLabelCss(false, isMobile)}>
            <label htmlFor="date-picker-inline">
              <Trans>Název události</Trans>
            </label>
            <TextField
              css={[inputCss(isMobile), selectIconCss(true)]}
              autoComplete="gym"
              name="gym"
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder={eventNameTrans.props.id}
              variant="outlined"
              fullWidth
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div css={inputWithLabelCss(false, isMobile)}>
            <label htmlFor="date-picker-inline">
              <Trans>Datum</Trans>
            </label>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
              <DatePicker
                css={inputCss(isMobile)}
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="dd.MM.yyyy"
                id="date-picker-inline"
                value={selectedDate}
                onChange={(date: MaterialUiPickersDate): void => {
                  handleDateChange(date || new Date());
                  handleTimeToChange(date || new Date());
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div css={inputWithLabelCss(false, isMobile)}>
            <label htmlFor="time-picker-inline">
              <Trans>Začátek</Trans>
            </label>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
              <TextField
                css={inputCss(isMobile)}
                variant="outlined"
                id="time-picker-inline"
                fullWidth
                size="small"
                defaultValue={selectedDate.toTimeString().slice(0, 5)}
                type="time"
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ): void => {
                  const timeFromDate = new Date(selectedDate);
                  timeFromDate.setHours(
                    parseInt(event.target.value.slice(0, 2), 10) || 0,
                  );
                  timeFromDate.setMinutes(
                    parseInt(event.target.value.slice(3, 5), 10) || 0,
                  );
                  handleDateChange(timeFromDate);
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div css={inputWithLabelCss(false, isMobile)}>
            <label htmlFor="time-to">
              <Trans>Konec</Trans>
            </label>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
              <TextField
                css={inputCss(isMobile, false)}
                disabled={false}
                variant="outlined"
                fullWidth
                id="time-to"
                size="small"
                defaultValue={selectedTimeTo.toTimeString().slice(0, 5)}
                type="time"
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ): void => {
                  const timeToDate = new Date(selectedDate);
                  timeToDate.setHours(
                    parseInt(event.target.value.slice(0, 2), 10) || 0,
                  );
                  timeToDate.setMinutes(
                    parseInt(event.target.value.slice(3, 5), 10) || 0,
                  );
                  handleTimeToChange(timeToDate);
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          css={[
            inputWithLabelCss(false, isMobile),
            fullWidthCss,
            marginBottomCss,
          ]}
        >
          <label htmlFor="outlined-gym-native-simple">
            <Trans>Klient</Trans>
          </label>
          <Select
            variant="outlined"
            fullWidth
            css={inputCss(isMobile)}
            value={client}
            renderValue={value => {
              const found = clients.find(one => one.id === value);
              if (found) {
                return `${found.name} ${found.surname}`;
              }
              return event && event.client
                ? `${event.client.name} ${event.client.surname}`
                : '';
            }}
            displayEmpty
            onChange={(e): void => {
              handleChangeSelect(e.target.value as string);
            }}
          >
            {clients.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {`${item.name} ${item.surname}`}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid
          item
          xs={12}
          css={[inputWithLabelCss(false, isMobile), fullWidthCss]}
        >
          <label htmlFor="outlined-gym-native-simple">
            <Trans>Místo</Trans>
          </label>
          <TextField
            css={[inputCss(isMobile), selectIconCss(true)]}
            autoComplete="gym"
            name="gym"
            value={trainingPlace}
            onChange={e => setTrainingPlace(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              css={buttonCss(isMobile)}
              onClick={() => handleFormSave()}
              disabled={!title}
            >
              <Trans>Uložit</Trans>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const fullWidthCss = css`
  width: 100%;
`;

const buttonCss = (isMobile: boolean) => css`
  margin-top: ${2 * MPX}px !important;
  width: ${isMobile && '100%'} !important;
`;

const inputWithLabelCss = (isDisabled = false, isMobile: boolean) => css`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: ${2 * MPX}px;
    color: ${decideLabel(isDisabled, isMobile)} !important;
  }
`;
const scheduleCardCss = (isMobile: boolean) => css`
  ${!isMobile && `position: relative;`};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: ${isMobile ? '300px' : '490px'};
  height: ${isMobile ? '560px' : '490px'};
  border-radius: 4px;
  padding: ${4 * MPX}px ${8 * MPX}px;
  background-color: ${isMobile ? COLORS.white : COLORS.primary};
  margin: ${isMobile ? `${2 * MPX}px` : 'auto'};

  ::after {
    content: '';
    width: 20px;
    height: 20px;
    background-color: ${COLORS.primary};
    position: absolute;
    top: 209px;
    left: -10px;
    transform: rotate(45deg);
  }
`;
const scheduleCardCssMobile = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 4px;
  padding: ${2 * MPX}px ${4 * MPX}px;
  background-color: ${COLORS.white};
  margin: ${2 * MPX}px;
`;

const IconColorCss = (isMobile: boolean) => css`
  color: ${isMobile ? COLORS.baseOrange : COLORS.contrast};
`;

const trainingNameCss = (isMobile: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  color: ${isMobile ? COLORS.darkDarkBlue : COLORS.contrast};
`;

const decideBorder = (isMobile: boolean, isRed?: boolean): string => {
  if (isRed) {
    return COLORS.danger;
  }
  if (isMobile) {
    return COLORS.darkDarkBlue;
  }
  return COLORS.contrast;
};
const inputCss = (isMobile: boolean, isRed?: boolean): SerializedStyles => css`
  .MuiOutlinedInput-root {
    margin-bottom: ${isMobile ? `${3 * MPX}px` : '0px'} !important;
    border: ${isRed && `1 px solid ${COLORS.danger}`};
    border: ${isMobile && `1 px solid ${COLORS.gray}`};
    :focus {
      border: ${isRed && `1 px solid ${COLORS.danger}`} !important;
    }
    :hover {
      border: ${isRed && `1 px solid ${COLORS.danger} `} !important;
    }
  }
  input {
    padding: ${4 * MPX}px ${3 * MPX}px !important;
  }
  select {
    padding: ${4 * MPX}px ${3 * MPX}px !important;
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding: ${4 * MPX}px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${decideBorder(isMobile, isRed)};
  }
  .MuiInputBase-input {
    color: ${isMobile ? COLORS.darkDarkBlue : COLORS.contrast};
    ${!isMobile && `background-color: ${COLORS.scheduleInputBg}`};
  }
  .MuiInputBase-input.Mui-disabled {
    color: ${COLORS.gray7};
  }
  .MuiSelect-icon {
    color: ${isMobile ? COLORS.darkDarkBlue : COLORS.contrast};
  }
  .MuiInputBase-root.Mui-disabled {
    color: ${isMobile ? COLORS.white : COLORS.scheduleInputBg};
  }
`;

const marginBottomCss = css`
  margin-bottom: ${2 * MPX}px;
`;

const selectIconCss = (disabled: boolean): SerializedStyles => css`
  .MuiSelect-icon {
    color: ${disabled ? COLORS.scheduleInputBg : COLORS.contrast};
  }
`;
