// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import { GenderEnum } from '../../../enums/GeneralEnums';
/**
 *
 * @export
 * @interface CreateUserDetailsDto
 */
export interface CreateUserDetailsDto {
  /**
   *
   * @type {string}
   * @memberof CreateUserDetailsDto
   */
  phone: string;
  /**
   * Training type
   * @type {string}
   * @memberof CreateUserDetailsDto
   */
  trainingType: CreateUserDetailsDtoTrainingTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreateUserDetailsDto
   */
  goals?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserDetailsDto
   */
  healthStatus?: string;
  /**
   *
   * @type {number}
   * @memberof CreateUserDetailsDto
   */
  height?: number;
  /**
   *
   * @type {number}
   * @memberof CreateUserDetailsDto
   */
  weight?: number;
  /**
   *
   * @type {number}
   * @memberof CreateUserDetailsDto
   */
  chest?: number;
  /**
   *
   * @type {number}
   * @memberof CreateUserDetailsDto
   */
  hips?: number;
  /**
   *
   * @type {number}
   * @memberof CreateUserDetailsDto
   */
  waist?: number;
  /**
   *
   * @type {string}
   * @memberof CreateUserDetailsDto
   */
  sex?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof CreateUserDetailsDto
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserDetailsDto
   */
  note?: string;
}

export function CreateUserDetailsDtoFromJSON(json: any): CreateUserDetailsDto {
  return CreateUserDetailsDtoFromJSONTyped(json, false);
}

export function CreateUserDetailsDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateUserDetailsDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    phone: json['phone'],
    trainingType: json['trainingType'],
    goals: !exists(json, 'goals') ? undefined : json['goals'],
    healthStatus: !exists(json, 'healthStatus')
      ? undefined
      : json['healthStatus'],
    height: !exists(json, 'height') ? undefined : json['height'],
    weight: !exists(json, 'weight') ? undefined : json['weight'],
    chest: !exists(json, 'chest') ? undefined : json['chest'],
    hips: !exists(json, 'hips') ? undefined : json['hips'],
    waist: !exists(json, 'waist') ? undefined : json['waist'],
    sex: !exists(json, 'sex') ? undefined : json['sex'],
    dob: !exists(json, 'dob') ? undefined : json['dob'],
    note: !exists(json, 'note') ? undefined : json['note'],
  };
}

export function CreateUserDetailsDtoToJSON(
  value?: CreateUserDetailsDto | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    phone: value.phone,
    trainingType: value.trainingType,
    goals: value.goals,
    healthStatus: value.healthStatus,
    height: value.height,
    weight: value.weight,
    chest: value.chest,
    hips: value.hips,
    waist: value.waist,
    sex: value.sex,
    dob: value.dob,
    note: value.note,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CreateUserDetailsDtoTrainingTypeEnum {
  Personal = 'personal',
  Combined = 'combined',
  Remote = 'remote',
}
