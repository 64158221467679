/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { Typography } from '@material-ui/core';
import { COLORS, MPX } from '../../../../styles/themes';

interface TitleAndDescriptionProps {
  title: string;
  description: string;
  hide?: boolean;
}

const TitleAndDescription: React.FC<TitleAndDescriptionProps> = ({
  description,
  title,
  hide,
}) => (
  <div>
    {!hide && (
      <div>
        <Typography variant="body1" css={titleCss}>
          {title}
        </Typography>
        <Typography variant="body1" css={descriptionCss}>
          {description}
        </Typography>
      </div>
    )}
  </div>
);

const titleCss = css`
  color: ${COLORS.gray7};
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: uppercase;
`;

const descriptionCss = css`
  color: ${COLORS.darkBlue};
  margin-top: ${MPX}px !important;
`;

export default TitleAndDescription;
