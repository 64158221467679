/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';
import { Grid, Box } from '@material-ui/core';
import { FC } from 'react';
import { EventDTO } from '../../../interfaces/event';
import { COLORS, MPX } from '../../../styles/themes';
import { getFromToToDateText } from '../../../code/helpers/calendarHelper';

interface EventItemProps {
  event: EventDTO;
  handleClick: (event: EventDTO) => void;
}

export const EventItem: FC<EventItemProps> = ({ event, handleClick }) => (
  <Grid
    container
    css={wrapperCss}
    direction="column"
    spacing={1}
    onClick={(): void => handleClick(event)}
  >
    <Grid item container alignItems="center">
      <div
        css={dotCss(
          // eslint-disable-next-line no-nested-ternary
          event.borderColor
            ? event.borderColor
            : event.textColor
            ? event.textColor
            : COLORS.lightDarkBlue,
        )}
      />
      <Box fontWeight={400} fontSize={18} css={titleCss}>
        {event.title}
      </Box>
    </Grid>
    <Grid item css={dateCss}>
      {getFromToToDateText(event.start, event.end, event.location)}
    </Grid>
  </Grid>
);

const wrapperCss = css`
  border-bottom: 1px solid ${COLORS.gray5};
  padding: ${2 * MPX}px ${MPX}px;
`;

const titleCss = css`
  color: ${COLORS.blueDark};
`;
const dotCss = (color: string): SerializedStyles => css`
  background-color: ${color};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0px ${MPX}px;
`;
const dateCss = css`
  color: ${COLORS.gray7};
  font-size: 12pt;
  font-weight: 400;
`;
