/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderTrainingDescriptionDetail from './singleExerciseDescriptionComponents/HeaderTrainingDescriptionDetail';
import { TrainingExerciseDTO } from '../../../../strapi/TrainingDTO';
import CommentTrainingDescriptionDetail from './singleExerciseDescriptionComponents/CommentTrainingDescriptionDetail';
import {
  followsPause,
  getCurrentSerieOfTimeExercise,
  getDataAboutTrainingProgress,
  getImagesFromExerciseForCarousel,
  isNextFinish,
} from '../../helper/functions';
import ProgressBarWrapper from './singleExerciseDescriptionComponents/ProgressBarWrapper';
import { MPX } from '../../../../styles/themes';
import { CenteredSpinner } from '../../../shared/Loader';
import {
  getExercisesOfInterest,
  getFinishURL,
  getNextURL,
  getPauseURL,
  isInPauseAfterExercise,
  singleExerciseShouldTakeSeconds,
  trainingIsRunning,
} from '../../../../store/trainingParts/selectors';
import { TrainingPause } from '../../helper/enums';
import { getSelectedTrainingWithSortedParts } from '../../../../store/training/selectors';
import { SectionType, UpdateType } from '../../../../interfaces/enums';
import { performUpdateExerciseIs } from '../../../../store/training/actions';
import { performIsInPauseAfterExercise } from '../../../../store/trainingParts/actions';

export interface SingleExerciseTimeDescriptionDetailProps {
  exerciseTitle: string;
  trainingExercise: TrainingExerciseDTO;
  detailLink: string;
  orderOfExercise?: number;
  sectionPartId: string;
  sectionPartIsDone: boolean;
}

const SingleExerciseTimeDescriptionDetail: React.FC<SingleExerciseTimeDescriptionDetailProps> =
  ({
    exerciseTitle,
    trainingExercise,
    detailLink,
    orderOfExercise,
    sectionPartIsDone,
  }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const nextURL = useSelector(getNextURL);
    const pauseURL = useSelector(getPauseURL);
    const finishURL = useSelector(getFinishURL);
    const { currentExercise, followingExercise } = useSelector(
      getExercisesOfInterest,
    );
    const isInPauseExercise = useSelector(isInPauseAfterExercise);
    const training = useSelector(getSelectedTrainingWithSortedParts);
    const shouldTakeTime = useSelector(singleExerciseShouldTakeSeconds);
    const [elapsedTime, setTime] = useState(() => 0);
    const isRunning = useSelector(trainingIsRunning);

    const { numberOfDoneSkippedExercises, doneExercises } =
      getDataAboutTrainingProgress(training);

    useEffect(() => {
      const remainingTime = shouldTakeTime && shouldTakeTime - elapsedTime;

      if (isRunning && (remainingTime === undefined || remainingTime > 0)) {
        const elapsedTrigger = setTimeout(
          () => setTime(prevTime => prevTime + 1),
          1000,
        );

        return () => {
          clearTimeout(elapsedTrigger);
        };
      }
      (async () => {
        if (
          trainingExercise &&
          currentExercise &&
          training &&
          shouldTakeTime &&
          isRunning &&
          shouldTakeTime - elapsedTime === 0
        ) {
          await dispatch(
            performUpdateExerciseIs(
              currentExercise.id,
              SectionType.SECTION_PART,
              UpdateType.DONE,
            ),
          );
          if (
            followsPause(
              UpdateType.DONE,
              currentExercise,
              followingExercise,
              isInPauseExercise,
            )
          ) {
            await dispatch(performIsInPauseAfterExercise(!isInPauseExercise));
            history.push(pauseURL);
            return;
          }

          if (
            isNextFinish(
              UpdateType.DONE,
              doneExercises,
              numberOfDoneSkippedExercises,
              followingExercise,
            )
          ) {
            history.push(finishURL);
            return;
          }

          history.push(nextURL);
        }
      })();
      return undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elapsedTime, isRunning]);

    if (!trainingExercise || !training) {
      return <CenteredSpinner message="Připravuji tréning" />;
    }

    const currentSerie = getCurrentSerieOfTimeExercise(
      trainingExercise.series,
      trainingExercise.repeats,
      trainingExercise.pause,
      elapsedTime,
    );

    return (
      <div css={wrapperCss}>
        <HeaderTrainingDescriptionDetail
          images={getImagesFromExerciseForCarousel(
            [trainingExercise],
            [detailLink],
          )}
          title={exerciseTitle}
          trainingExercise={trainingExercise}
          detailLink={detailLink}
          currentSerie={currentSerie.serie}
          isNotRepeats
          isNotWeights={!trainingExercise.weights}
          orderOfExercise={orderOfExercise}
        />
        <div css={progressCss}>
          <ProgressBarWrapper
            durationElapsed={
              !sectionPartIsDone ? currentSerie.elapsedTimeInSerie : 0
            }
            durationTotal={!sectionPartIsDone ? currentSerie.timeInSerie : 0}
            isPause={
              !sectionPartIsDone
                ? currentSerie.type === TrainingPause.pause
                : false
            }
            otherBarNumber={!sectionPartIsDone ? currentSerie.other : 0}
            currentSeries={!sectionPartIsDone ? currentSerie.serie : 0}
            seriesTotal={trainingExercise.series}
          />
        </div>
        {trainingExercise.comment && (
          <div css={commentWrapperCss}>
            <CommentTrainingDescriptionDetail
              comment={trainingExercise.comment}
            />
          </div>
        )}
      </div>
    );
  };

export default SingleExerciseTimeDescriptionDetail;

const wrapperCss = css`
  padding-bottom: ${MPX * 20}px;
`;

const progressCss = css`
  padding-left: 2vh;
  padding-right: 2vh;
`;

const commentWrapperCss = css`
  padding-left: 1vh;
  padding-right: 1vh;
  box-sizing: border-box;
  margin-top: ${MPX * 4}px;
`;
