/** @jsx jsx */
import { FC, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { jsx, css } from '@emotion/react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { COLORS, MPX } from '../../styles/themes';

import {
  performFetchProgress,
  performAddWeightProgress,
  performPatchWeightProgress,
} from '../../store/progress/actions';
import { WeightWrapper } from './comps/WeightWrapper';
import { PhotosWrapper } from './comps/PhotosWrapper';
import { MeasuresWrapper } from './comps/MeasuresWrapper';
import { WeightProgressDTO } from '../../strapi/WeightProgressDTO';
import { PhotoProgressDTO } from '../../strapi/PhotoProgressDTO';
import { MeasureProgressDTO } from '../../strapi/MeasuresProgressDTO';
import { mobileBodyMargin } from '../../styles/general';
import { getLoggedUser } from '../../store/auth/selectors';

export interface StateProps {
  weightProgress: WeightProgressDTO[];
  photoProgress: PhotoProgressDTO[];
  measureProgress: MeasureProgressDTO[];
  isLoadingAddPhoto: boolean;
}

export interface DispatchProps {
  performFetchProgress: typeof performFetchProgress;
  performAddWeightProgress: typeof performAddWeightProgress;
  performPatchWeightProgress: typeof performPatchWeightProgress;
  clearRequestState: () => void;
}

export interface RouteParams {
  id: string;
}

type ClientProgressProps = StateProps &
  DispatchProps &
  RouteComponentProps<RouteParams>;

export const ClientProgress: FC<ClientProgressProps> = ({
  performFetchProgress,
  performAddWeightProgress,
  weightProgress,
  photoProgress,
  measureProgress,
  performPatchWeightProgress,
  isLoadingAddPhoto,
  clearRequestState,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const user = useSelector(getLoggedUser);

  const { id: userId } = useParams<{ id: string }>();

  /* 
  in customer application, there is no userId in URL
  therefore I am getting and determining whether there is any logged user
  if there is and no userId in URL, clients progress can be shown
  */
  const isClient = !userId;
  const id = userId || user?.id;
  useEffect(() => {
    performFetchProgress(id);
  }, [performFetchProgress, id]);

  return (
    <div css={[backgroundCss(isMobile), mobileBodyMargin(isMobile)]}>
      {id && (
        <Fragment>
          <PhotosWrapper
            userId={id}
            photoProgress={photoProgress}
            isLoadingAddPhoto={isLoadingAddPhoto}
            clearRequestState={clearRequestState}
            isClient={isClient}
          />
          <WeightWrapper
            userId={id}
            performAddWeightProgress={performAddWeightProgress}
            performFetchProgress={performFetchProgress}
            performPatchWeightProgress={performPatchWeightProgress}
            weightProgress={weightProgress}
            isClient={isClient}
          />
          <MeasuresWrapper
            userId={id}
            measureProgress={measureProgress}
            isClient={isClient}
          />
        </Fragment>
      )}
    </div>
  );
};

const backgroundCss = (isMobile: boolean) => css`
  background-color: ${isMobile ? COLORS.white : COLORS.bg};
  flex-grow: 1;
  padding: ${!isMobile && `${7.5 * MPX}px 2%`};
`;
