/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import {
  TrainingDTO,
  TrainingSectionDTO,
} from '../../../../strapi/TrainingDTO';
import { COLORS, MPX } from '../../../../styles/themes';
import { getOrderOfTrainingSection } from '../../helper/functions';

export interface WarmUpStretchDetail {
  training: TrainingDTO | undefined;
  sectionId: string;
}

const getTrainingSection = (
  training: TrainingDTO | undefined,
  sectionId: string,
): TrainingSectionDTO | undefined => {
  if (!training) {
    return undefined;
  }
  return training.training_sections.find(ts => ts.id === sectionId);
};

const WarmUpStretchDetail: React.FC<WarmUpStretchDetail> = ({
  training,
  sectionId,
}) => {
  const trainingSection = getTrainingSection(training, sectionId);
  if (!training || !trainingSection) {
    return null;
  }

  const numberOfSectionOrder = getOrderOfTrainingSection(
    trainingSection,
    training,
  );

  return (
    <div>
      {training && (
        <div css={textCss}>
          <div css={headerCss}>
            {numberOfSectionOrder}. {trainingSection.title}
          </div>
          <div>{trainingSection.description}</div>
        </div>
      )}
    </div>
  );
};

export default WarmUpStretchDetail;

const textCss = css`
  color: ${COLORS.white};
  padding-left: 2vh;
  padding-right: 2vh;
  word-wrap: break-word;
`;

const headerCss = css`
  font-size: ${MPX * 6}px;
  font-weight: 500;
  margin-bottom: ${MPX * 5}px;
  margin-top: ${MPX * 3}px;
`;
