/** @jsx jsx */
import { FC, Fragment, useCallback, useState } from 'react';
import { TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { jsx, css, SerializedStyles } from '@emotion/react';
import { Queue, Create, Delete } from '@material-ui/icons';
import { format } from 'date-fns';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { AlertDialog } from '../../../../shared/AlertDialog';
import { PopupMenu } from '../../../../shared/PopupMenu';
import { COLORS } from '../../../../../styles/themes';
import { LocalTrainingPlanDTO } from '../../../../../strapi/TrainingPlanDTO';
import { TrainingPlanState } from '../../../../../enums/TrainingPlanStateEnum';
import {
  routePathBuilder,
  ApplicationPath,
  TrainerPath,
} from '../../../../../routes';
import {
  stableSort,
  getComparator,
  Order,
} from '../../../../../code/helpers/tables';
import { performRemoveTrainingPlan } from '../../../../../store/trainingPlans/actions';

export interface TrainingPlanTableBodyProps {
  trainingPlans: LocalTrainingPlanDTO[];
  order: Order;
  orderBy: keyof LocalTrainingPlanDTO;
  removeTrainingPlan: typeof performRemoveTrainingPlan;
}

export const TrainingPlanTableBody: FC<TrainingPlanTableBodyProps> = ({
  trainingPlans,
  order,
  orderBy,
  removeTrainingPlan,
}) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [currentlySelected, setCurrentlySelected] = useState<
    string | undefined
  >();

  const duplicateTraining = (trainingId: string): void => {
    history.push(`/t/clients/${id}/training-plans/${trainingId}/duplicate`);
  };

  const redirectEdit = (trainingId: string): void => {
    history.push(`/t/clients/${id}/training-plans/${trainingId}/informations`);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = useCallback(
    (id: string): void => {
      removeTrainingPlan(id, () => {
        enqueueSnackbar(<Trans>Plán byl odstraněn.</Trans>, {
          variant: 'success',
        });
      });
      setCurrentlySelected(undefined);
    },
    [removeTrainingPlan, enqueueSnackbar],
  );

  return (
    <Fragment>
      <AlertDialog
        fullWidth
        closeIcon
        isOpen={!!currentlySelected}
        agreeButtonText={<Trans>Smazat</Trans>}
        headerText={<Trans>Smazat tréninkový plán</Trans>}
        cancelButtonText={<Trans>Zrušit</Trans>}
        handleClose={(): void => {
          setCurrentlySelected(undefined);
        }}
        handleAgree={(): void => {
          if (currentlySelected) {
            handleDelete(currentlySelected);
          }
        }}
        content={<Typography>Jste si jisti? Tato akce je nevratná.</Typography>}
      />
      <TableBody>
        {stableSort(trainingPlans, getComparator(order, orderBy)).map(
          (trainingPlan: LocalTrainingPlanDTO, index: number) => (
            <TableRow
              key={trainingPlan.id}
              css={tableRow(index)}
              hover
              onClick={(): void => redirectEdit(trainingPlan.id)}
            >
              <TableCell align="left">
                <Link
                  css={linkCss}
                  to={`${routePathBuilder([
                    ApplicationPath.Trainer,
                    TrainerPath.Clients,
                  ])}/${id}/training-plans/${trainingPlan.id}`}
                >
                  {trainingPlan.title}
                </Link>
              </TableCell>

              <TableCell align="center">
                {trainingPlan.trainingsCount}
              </TableCell>
              <TableCell
                align="left"
                css={colorTextHandler(trainingPlan.state)}
              >
                {trainingPlan.state}
              </TableCell>

              <TableCell align="left">
                {trainingPlan.firstTraining &&
                  format(new Date(trainingPlan.firstTraining), 'P')}
              </TableCell>

              <TableCell align="left">
                {trainingPlan.lastTraining &&
                  format(new Date(trainingPlan.lastTraining), 'P')}
              </TableCell>

              <TableCell align="left">
                <PopupMenu
                  menuItems={[
                    {
                      label: 'Editovat',
                      icon: Create,
                      onClick: (): void => redirectEdit(trainingPlan.id),
                      disabled:
                        trainingPlan.state === TrainingPlanState.Finished,
                    },
                    {
                      label: 'Duplikovat',
                      icon: Queue,
                      onClick: (): void => duplicateTraining(trainingPlan.id),
                      disabled: false,
                    },
                    {
                      label: 'Smazat',
                      icon: Delete,
                      onClick: (): void => {
                        setCurrentlySelected(trainingPlan.id);
                      },
                      disabled: trainingPlan.state !== TrainingPlanState.Saved,
                    },
                  ]}
                />
              </TableCell>
            </TableRow>
          ),
        )}
      </TableBody>
    </Fragment>
  );
};

const tableRow = (index: number): SerializedStyles => css`
  background-color: ${index % 2 ? COLORS.evenTableRow : 'white'};
  border-style: hidden;
  &:hover {
    cursor: pointer;
  }
`;

const colorTextHandler = (state: string): SerializedStyles => css`
  color: ${state === TrainingPlanState.Running
    ? `${COLORS.secondary} !important`
    : `${COLORS.gray6} !important`};
`;

const linkCss = css`
  text-decoration: none;
  color: ${COLORS.primaryDark};
`;
