/** @jsx jsx */
import React from 'react';
import { jsx, css, SerializedStyles } from '@emotion/react';
import { Trans } from '@lingui/macro';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { ToggleButtonGroupClassKey } from '@material-ui/lab/ToggleButtonGroup';
import { GenderEnum } from '../../enums/GeneralEnums';
import { TrainingTypeEnum } from '../../enums/TrainingTypesEnum';

export interface ToggleButtonItemProps {
  value: string;
  label: string;
}

interface ToggleGroupButtonsProps {
  value: GenderEnum | TrainingTypeEnum;
  setValue: any;
  items: ToggleButtonItemProps[];
  classes?: Partial<Record<ToggleButtonGroupClassKey, string>>;
  disabled?: boolean;
}

export const ToggleGroupButtons: React.FC<ToggleGroupButtonsProps> = ({
  value,
  setValue,
  items,
  classes,
  disabled,
}) => (
  <ToggleButtonGroup
    classes={classes}
    size="small"
    value={value}
    exclusive
    css={group(disabled)}
    onChange={(event, newValue): void => {
      if (newValue != null) {
        setValue(newValue);
      }
    }}
  >
    {items.map((item: ToggleButtonItemProps) => (
      <ToggleButton value={item.value} key={item.label} css={button}>
        <Trans id={item.label} />
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
);

const button = css`
  width: 100%;
`;

const group = (disabled: boolean | undefined): SerializedStyles => css`
  width: 100%;
  pointer-events: ${disabled ? 'none' : 'auto'};
`;
