/** @jsx jsx */
import { FC, useCallback, useEffect, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { ReactSortable, Sortable, Store } from 'react-sortablejs';
import { COLORS, MPX } from '../../../styles/themes';
import { SetWrapper } from '../trainingExercise/SetWrapper';
import {
  dropzoneTextCss,
  dropzoneCss,
  dropZoneOptionTextCss,
} from '../../../styles/dropzone';
import { TrainingSectionTypeEnum } from '../../../enums/TrainingTypesEnum';
import {
  TrainingSectionWithOperation,
  TrainingSectionPartWithOperation,
  TrainingExerciseWithOperation,
} from '../../../interfaces/traning';
import {
  createTrainingSectionPart,
  updateSectionPartAttribute,
} from '../../../code/helpers/trainingExercise';
import { copyTrainingExercise } from '../../../code/helpers/trainingSetupHelpers';
import { OperationEnum } from '../../../enums/OperationEnum';
import { previousPauseNoSet } from '../../../code/helpers/trainingExerciseSeries';

interface SetupSectionProps {
  section: TrainingSectionWithOperation;
  updateSection: (section: TrainingSectionWithOperation) => void;
  handleAdd: (from: TrainingSectionPartWithOperation) => void;
}

export const SetupSection: FC<SetupSectionProps> = ({
  section,
  updateSection,
  handleAdd,
}) => {
  const [sectionToSave, setSectionToSave] =
    useState<TrainingSectionWithOperation>(section);

  useEffect(() => {
    setSectionToSave({
      ...section,
      training_section_parts: section.training_section_parts,
    });
  }, [section, section.training_section_parts]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const createSectionPart = (type?: TrainingSectionTypeEnum) => {
    const order = sectionToSave.training_section_parts.length + 1;

    const newSectionPart = createTrainingSectionPart(
      order,
      sectionToSave.id,
      type,
    );
    const updated: TrainingSectionWithOperation = {
      ...sectionToSave,
      training_section_parts: [
        ...sectionToSave.training_section_parts,
        newSectionPart,
      ],
    };

    setSectionToSave(updated);
    updateSection(updated);
  };

  // UPDATES TSP ORDER ON ITEMS SWAP
  const updateSectionPartOrder = useCallback(
    (
      newlyOrderedParts: TrainingSectionPartWithOperation[],
      sortable?: Sortable | null,
      store?: Store,
    ) => {
      if (store && !store.dragging) {
        return;
      }
      // separate elements to arrays
      // DELETED which indexes will be ignored
      const deletedElements = newlyOrderedParts.filter(
        fd => fd.operation === OperationEnum.DELETED,
      );
      // ACTIVE that will be reordered
      const activeElements = newlyOrderedParts.filter(
        fd => fd.operation !== OperationEnum.DELETED,
      );
      const updatedActiveElements = activeElements.map((nop, index) => {
        // if is just training exercise dropped by sortable then create TSP
        // create wrapper around it
        if (!nop.training_exercises) {
          const prevTSP = index > 0 ? activeElements[index - 1] : undefined;
          // Sortable return TrainingExercise type, I had to fit it within single state update function
          const copyExercise: TrainingExerciseWithOperation =
            copyTrainingExercise(
              // @ts-ignore
              nop,
              prevTSP,
            );

          const newTSP = createTrainingSectionPart(
            index + 1,
            sectionToSave.id,
            TrainingSectionTypeEnum.SingleExercise,
            copyExercise,
          );

          newTSP.pause = 0;
          newTSP.training_exercises[0].pauseAfterExercise = 0;
          return updateSectionPartAttribute(newTSP, index + 1, 'order');
        }

        // Wrapper no needed. Reorder sections parts
        return updateSectionPartAttribute(nop, index + 1, 'order');
      });

      const updated: TrainingSectionWithOperation = {
        ...sectionToSave,
        training_section_parts: [...updatedActiveElements, ...deletedElements],
      };

      setSectionToSave(updated);
      updateSection(updated);
    },
    [sectionToSave, updateSection],
  );

  const updateSuperSet = (
    superSet: TrainingSectionPartWithOperation,
    id: number | string,
  ) => {
    const updated: TrainingSectionWithOperation = {
      ...sectionToSave,
      training_section_parts: sectionToSave.training_section_parts.map(tsp =>
        tsp.id === id ? superSet : tsp,
      ),
    };

    updateSectionPartOrder(updated.training_section_parts);
  };

  const renderSectionHeader = () => (
    <Grid item xs={12}>
      <Grid container alignItems="center" css={headerContainerCss}>
        <Grid item xs={4} css={[headerExerciseDescCss, setHeaderCss]}>
          <Trans>Cvik</Trans>
        </Grid>
        <Grid item xs={8}>
          <Grid container alignItems="center">
            <Grid item xs={11}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={3} css={headerDescCss}>
                  <Trans>Série</Trans>
                </Grid>
                <Grid item xs={3} css={headerDescCss}>
                  <Trans>Opakování</Trans>
                </Grid>
                <Grid item xs={3} css={headerDescCss}>
                  <Trans>Váha</Trans>
                </Grid>
                <Grid item xs={3} css={headerDescCss}>
                  <Trans>Pauza</Trans>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderSetWrapperSortable = () => (
    <ReactSortable
      list={sectionToSave.training_section_parts}
      setList={updateSectionPartOrder}
      animation={150}
      css={fullWidthCss}
      group={{ name: 'training-setup', pull: false, put: true }}
      fallbackOnBody
      swapThreshold={0.65}
      fallbackTolerance={3}
      delay={500}
      delayOnTouchOnly
    >
      {sectionToSave.training_section_parts
        .map((tsp, index) => {
          const isLast =
            sectionToSave.training_section_parts.length - 1 === index;

          if (tsp.operation !== OperationEnum.DELETED) {
            return (
              <SetWrapper
                key={tsp.id}
                isLast={isLast}
                previousPause={previousPauseNoSet(sectionToSave, index)}
                sectionPart={tsp}
                updateSuperSet={superSet => updateSuperSet(superSet, tsp.id)}
                handleAdd={handleAdd}
              />
            );
          }

          return null;
        })
        .filter(Boolean)}
    </ReactSortable>
  );
  // pokud mam smazane vsechny cviky chci ukazat text s pridejte cvik
  // fyzicky porad existuji v training_section_parts ale jsou deleted

  const checkEmptySection = () =>
    // to znamena ze vsechny cviky mam smazany fyzickz
    sectionToSave.training_section_parts.filter(
      sec => sec.operation === OperationEnum.DELETED,
    ).length === sectionToSave.training_section_parts.length;

  return (
    <Grid container css={whiteBckgCss(isMobile)} justify="center">
      {!isMobile && renderSectionHeader()}
      {renderSetWrapperSortable()}

      {((isMobile &&
        (sectionToSave.training_section_parts.length === 0 ||
          checkEmptySection())) ||
        !isMobile) && (
        <div css={[dropzoneCss(false), isMobile && marginMobileCss]}>
          <div css={dropzoneTextCss}>
            {isMobile ? (
              <Trans>Přidejte cvik tlačítkem přidat,</Trans>
            ) : (
              <Trans>Přidejte cvik přetáhnutím z pravého seznamu,</Trans>
            )}
            <span
              css={dropZoneOptionTextCss}
              role="link"
              tabIndex={0}
              onKeyDown={(): void =>
                createSectionPart(TrainingSectionTypeEnum.SuperSet)
              }
              onClick={(): void =>
                createSectionPart(TrainingSectionTypeEnum.SuperSet)
              }
            >
              &nbsp;
              <Trans>vytvořte supersérii</Trans>
            </span>
            &nbsp;
            <Trans>nebo</Trans>
            <span
              css={dropZoneOptionTextCss}
              role="link"
              tabIndex={0}
              onKeyDown={(): void =>
                createSectionPart(TrainingSectionTypeEnum.CircuitTraining)
              }
              onClick={(): void =>
                createSectionPart(TrainingSectionTypeEnum.CircuitTraining)
              }
            >
              &nbsp;
              <Trans>kruhový trénink.</Trans>
            </span>
          </div>
        </div>
      )}
    </Grid>
  );
};

const headerContainerCss = css`
  min-height: 40px;
  background-color: white;
`;

const whiteBckgCss = (isMobile: boolean) => css`
  background-color: white;

  border-radius: 0 0 8px 8px;
  border: ${isMobile ? `1px solid ${COLORS.gray8}` : 'none'};
`;

const headerExerciseDescCss = css`
  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.gray6};
  text-align: center;
`;

const headerDescCss = css`
  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.gray6};
  text-align: center;
`;

const fullWidthCss = css`
  width: 100%;
`;

const setHeaderCss = css`
  margin-bottom: ${MPX}px !important;
  margin-top: ${MPX}px;
`;

export const marginMobileCss = css`
  margin: ${3 * MPX}px;
`;
