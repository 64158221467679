import { AxiosResponse } from 'axios';
import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import { ActivateAccountDto, RegistrationDto } from '../../api/src';
import {
  receiveUserFromCookie,
  removeUserFromCookie,
  saveUserToCookie,
} from '../../code/helpers/api';
import { baseAsyncRequest } from '../actionHelpers';
import { State } from './reducers';

// NEW
import { ForgotPasswordDTO } from '../../strapi/ForgotPasswordDTO';
import { LoginDTO } from '../../strapi/LoginDTO';
import { RegistrationDTO } from '../../strapi/RegistrationDTO';
import { ResetPasswordDTO } from '../../strapi/ResetPasswordDTO';
import {
  UpdatePasswordDTO,
  UpdateUserDTO,
  UserDTO,
} from '../../strapi/UserDTO';

import { makeServerCallAsync } from '../../code/helpers/api/api';
import { removeAuthHeader, setAuthToken } from '../../code/helpers/api/http';
import { RS as RequestStatus } from '../../interfaces/enums';
import { AT as ActionTypes } from '../actionTypes';
import { setRS as setRequestStatus } from '../request/actions';

// END NEW IMPORTS

// NEW API

export const setLoggedUser = action(
  ActionTypes.SET_LOGGED_USER,
  payload<{ user: UserDTO }>(),
);

/**
 * Refresh user that is saved in cookie, if token is valid perfom onRefreshCallback,
 * logout user otherwise.
 *
 * @param onRefreshCallback
 */
export const refreshUserFromCookie: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (onRefreshCallback?: (data: UserDTO) => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const type = ActionTypes.REFRESH_USER_FROM_COOKIE;

    dispatch(
      setRequestStatus({
        rs: RequestStatus.Loading, // request Status
        n: type,
      }),
    );

    const user = receiveUserFromCookie();

    if (!user) {
      setTimeout(() => {
        dispatch(
          setRequestStatus({
            rs: RequestStatus.Failure,
            n: type,
            message: 'Nepodařilo se obnovit relaci.',
          }),
        );
      });
      return undefined;
    }

    setAuthToken(user.token);

    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/users/${user.id}`);

    const results = await baseAsyncRequest(type, request, dispatch, {
      sentryCapture: false,
      defaultMessage: 'Vaše aktivita expirovala. Prosím, znovu se přihlaste.',
    });

    if (results) {
      dispatch(setLoggedUser({ user: results.data }));

      if (onRefreshCallback) {
        onRefreshCallback(results.data);
      }
    } else {
      // Invalid token
      // Remove user info from cookie
      removeUserFromCookie();
      removeAuthHeader();
    }
    return undefined;
  };

/**
 * Perfom login action
 *
 * @param loginData
 * @param onLoginCallback
 */
export const performUserLogin: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (loginData: LoginDTO, onLoginCallback: (data: UserDTO) => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', '/auth/local', {
        identifier: loginData.email,
        password: loginData.password,
      });

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_USER_LOGIN,
      request,
      dispatch,
      {
        errorMap: {
          400: 'Byly zadány nesprávné přihlašovací údaje.',
          422: 'Zadaný uživatel neexistuje.',
        },
        defaultMessage: 'Přihlášení se nezdařilo. Zkuste znovu.',
      },
    );

    if (results) {
      const { data } = results;

      const {
        jwt,
        user: { id },
      } = data;

      saveUserToCookie(jwt, id);
      setAuthToken(jwt);

      // @ts-ignore
      dispatch(refreshUserFromCookie(onLoginCallback));
    }
  };

/**
 * User logout
 */
export const logOut = action(ActionTypes.LOG_OUT);

export const performUpdateUser: ActionCreator<
  ThunkAction<Promise<void>, State, UserDTO, any>
> =
  (id: number, updatedUserDTO: UpdateUserDTO, onSuccessCallback?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('put', `/users/${id}`, updatedUserDTO);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_UPDATE_CLIENT,
      request,
      dispatch,
      {
        defaultMessage: 'Klienta se nepodařilo upravit.',
      },
    );

    if (results) {
      const { data } = results;
      dispatch(setLoggedUser({ user: data }));
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    }
  };
export const performUpdatePassword: ActionCreator<
  ThunkAction<Promise<void>, State, UserDTO, any>
> =
  (newDTO: UpdatePasswordDTO, onLoginCallback: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/password/change`, newDTO);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_UPDATE_PASSWORD,
      request,
      dispatch,
      {
        errorMap: {
          400: 'Zkontrolujte si své staré heslo',
        },
        defaultMessage: 'Heslo nebylo změněno',
      },
    );

    if (results) {
      const { data } = results;

      const {
        jwt,
        user: { id, role },
      } = data;

      saveUserToCookie(jwt, id);
      setAuthToken(jwt);
      // @ts-ignore
      dispatch(refreshUserFromCookie(onLoginCallback));
    }
  };
/**
 *
 *  Perform forgot password
 *
 * @param ForgotPassworDTO
 */
export const performForgotPassword: ActionCreator<
  ThunkAction<Promise<void>, State, ForgotPasswordDTO, any>
> =
  (forgotPasswordDTO: ForgotPasswordDTO) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', '/auth/forgot-password', forgotPasswordDTO);

    await baseAsyncRequest(
      ActionTypes.PERFORM_FORGOT_PASSWORD,
      request,
      dispatch,
      {
        errorMap: {
          422: 'Heslo nelze resetovat. Zadaný email není zaregistrován.',
        },
        defaultMessage: 'Nastala chyba serveru. Zkuste znovu.',
      },
    );
  };

/**
 * Perform registration of new user with trainer role
 *
 * @param registrationDTO
 */
export const performTrainerRegister: ActionCreator<
  ThunkAction<Promise<void>, State, RegistrationDto, any>
> =
  (registrationDTO: RegistrationDTO) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', '/auth/local/register', registrationDTO);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_TRAINER_REGISTER,
      request,
      dispatch,
      {
        errorIdMap: {
          'Auth.form.error.email.taken': 'Tento email už je zaregistrován.',
        },
      },
    );

    if (results) {
      // login new user
      const { email, password } = registrationDTO;
      const loginDTO: LoginDTO = {
        email,
        password,
      };
      // @ts-ignore
      dispatch(performUserLogin(loginDTO));
    }
  };

export const performResetPassword: ActionCreator<
  ThunkAction<Promise<void>, State, ResetPasswordDTO, any>
> =
  (resetPasswordDTO: ResetPasswordDTO) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', '/auth/reset-password', resetPasswordDTO);

    await baseAsyncRequest(
      ActionTypes.PERFORM_RESET_PASSWORD,
      request,
      dispatch,
      {
        defaultMessage:
          'Heslo se nepodařilo obnovit. Zkuste opakovat celý proces znovu.',
        errorMap: {
          404: 'Token pro obnovu hesla je neplatný.',
          400: 'Z bezpečnostních důvodů vypršela platnost tohoto odkazu. Pokud stále nevíte svoje heslo do účtu, musíte si znovu požádat o jeho obnovení.',
        },
      },
    );
  };

/**
 * Activate trainee account, curently uses performResetPassword
 *
 * @param resetPasswordDTO
 */
export const performTraineeAccountActivation: ActionCreator<
  ThunkAction<Promise<void>, State, ActivateAccountDto, any>
> =
  (resetPasswordDTO: ResetPasswordDTO) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    // @ts-ignore
    dispatch(performResetPassword(resetPasswordDTO));
  };

/**
 * Will remove account of logged user from system.
 *
 * @param id
 * @returns
 */

export const performDeleteMyAccount: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (onDeleteCb?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('delete', `/users-permissions/users/me`);

    const result = await baseAsyncRequest(
      ActionTypes.DELETE_MY_ACCOUNT,
      request,
      dispatch,
      {
        defaultMessage:
          'Váš účet se nepodařilo smazat. Nastala chyba. Zkuste to zopakovat prosím.',
      },
    );

    if (result?.status === 200) {
      dispatch(logOut());
      onDeleteCb?.();
    }
  };
