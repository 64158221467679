/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import { Grid, IconButton, Portal } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { css, jsx } from '@emotion/react';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { COLORS, TOP_MENU_HEIGHT, MPX } from '../../../styles/themes';
import { getExerciseById } from '../../../store/exercisesLibrary/selector';
import { performFetchExerciseById } from '../../../store/exercisesLibrary/actions';
import { HeadingWithBackButton } from '../shared/HeadingWithBackButton';
import { ApplicationPath, ClientPath, routePathBuilder } from '../../../routes';
import OverviewBodyParts from './components/OverviewBodyParts';
import { getVideoFromMedia } from '../../../code/helpers/strapi';
import { FormatEnum, MediaFileDTO } from '../../../strapi/MediaFileDTO';

const TrainingOverview: React.FC = () => {
  const {
    trainingId,
    exerciseId,
    sectionId,
    typeExercise,
    partId,
    trainingExerciseId,
  } = useParams<{
    trainingId: string;
    sectionId: string;
    exerciseId: string;
    typeExercise: string;
    trainingExerciseId: string;
    partId: string;
    sectionType: string;
  }>();
  const [openLightBox, setOpenLightBox] = useState<boolean>(false);
  const [openVideo, setOpenVideo] = useState<boolean>(false);
  const dispatch = useDispatch();
  const exercise = useSelector(getExerciseById(exerciseId));
  const refVideo = useRef<any>(null);
  const classes = useStyles();

  useEffect(() => {
    if (!exercise || exercise.id !== exerciseId) {
      dispatch(performFetchExerciseById(exerciseId));
    }
    return () => {
      setOpenVideo(false);
      setOpenLightBox(false);
    };
  }, [exerciseId, exercise, dispatch]);

  if (!exercise) {
    return null;
  }

  const toggleFullScreen = () => {
    if (refVideo.current.requestFullscreen) {
      refVideo.current.requestFullscreen();
    } else if (refVideo.current.webkitRequestFullscreen) {
      refVideo.current.webkitRequestFullscreen();
    } else if (refVideo.current.msRequestFullscreen) {
      refVideo.current.msRequestFullscreen();
    }
  };

  const video = getVideoFromMedia(exercise.Illustration, FormatEnum.SMALL);
  const renderLightBox = () => (
    <Lightbox
      mainSrc={exercise.Illustration[0].url}
      onCloseRequest={() => setOpenLightBox(false)}
    />
  );

  const renderVideo = (video: MediaFileDTO | undefined) => (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      loop
      controls
      id="myvideo"
      ref={refVideo}
      css={dialogMediaCss}
      onClick={() => refVideo.current.pause}
    >
      <source src={video ? video.url : ''} type={video ? video.mime : ''} />
    </video>
  );

  const handleClickOnImage = () => {
    if (!video) {
      setOpenLightBox(true);
    } else if (refVideo && refVideo.current) {
      refVideo.current.play();
      setOpenVideo(true);
      toggleFullScreen();
    }
  };

  const renderVideoOrImage = () => (
    <div
      role="button"
      tabIndex={0}
      onClick={() => {
        handleClickOnImage();
      }}
      onKeyDown={() => {
        handleClickOnImage();
      }}
    >
      <div css={imageCss}>
        {!openLightBox && (
          <div css={imageButtonWrapperCss}>
            <Grid
              container
              justify="center"
              alignItems="center"
              css={gridButtonCss}
            >
              <IconButton className={classes.playIcon}>
                <PlayCircleFilledWhiteIcon />
              </IconButton>
            </Grid>
          </div>
        )}
        <img
          src={exercise.Illustration[0].url}
          css={imageCss}
          alt={exercise.title.text}
        />
      </div>
    </div>
  );

  return (
    <div css={trainingDetailWrapperCss}>
      <Portal>
        <div css={headerCss}>
          <HeadingWithBackButton
            title="Detail cviku"
            link={`${routePathBuilder([
              ApplicationPath.Client,
              ClientPath.Training,
              trainingId,
              ClientPath.Training_Section,
              sectionId,
              ClientPath.Section_Part,
              partId || '0',
              typeExercise,
              ClientPath.Exercise,
              exerciseId || '0',
              ClientPath.Training_Exercise,
              trainingExerciseId || '0',
            ])}`}
          />
        </div>
      </Portal>
      <div>
        <div>{!openVideo && renderVideoOrImage()}</div>
        {openLightBox && renderLightBox()}
        <div css={videoCss(video && openVideo)}>{renderVideo(video)}</div>
      </div>
      <div css={informationCss}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          css={headerExerciseCss}
        >
          <h2>{exercise.title.text}</h2>
        </Grid>
        {exercise.parts && <OverviewBodyParts parts={exercise.parts} />}
        {exercise.description && (
          <div css={descriptionCss}>
            {exercise.description.map(d => d && `${d.text || ''} `)}
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingOverview;

const useStyles = makeStyles(() => ({
  playIcon: {
    '& svg': {
      fontSize: 125,
      zIndex: 99,
      color: `${COLORS.baseOrange}`,
    },
  },
}));

const trainingDetailWrapperCss = css`
  padding-top: ${TOP_MENU_HEIGHT}px;
  overflow-y: auto;
  background-color: ${COLORS.bcgTrainingClient};
  min-height: 96vh;
`;

const headerExerciseCss = css`
  color: ${COLORS.white};
`;

const headerCss = css`
  width: 100%;
  height: ${TOP_MENU_HEIGHT}px;
  position: fixed;
  z-index: 998;
`;

const imageCss = css`
  width: 100%;
  height: 30vh;
  object-fit: cover;
  position: relative;
`;

const descriptionCss = css`
  color: ${COLORS.white};
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const informationCss = css`
  padding-left: ${0.7 * MPX}vw;
  padding-right: ${0.7 * MPX}vw;
`;

const dialogMediaCss = css`
  max-height: 300px;
  width: 100%;
  background-color: #000000;
`;

const imageButtonWrapperCss = css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 998;
`;

const gridButtonCss = css`
  height: 100%;
`;

const videoCss = (open: boolean | undefined) => css`
  visibility: ${open ? 'visible' : 'hidden'};
  width: ${open ? 'initial' : '0px'};
  height: ${open ? 'initial' : '0px'};
`;
