import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { TrainerSchedule, DispatchProps, RouteParams } from './TrainerSchedule';
import { State } from '../../store/combinedReducers';
import {
  performSetTrainingDate,
  setTraining,
} from '../../store/training/actions';
import { performFetchClients } from '../../store/client/actions';
import {
  getEventsForTrainer,
  getWholeTrainingPlanById,
  getEventsForMobileTrainer,
} from '../../store/trainingPlans/selectors';
import {
  setCalendarClickDate,
  setPlanningMode,
  performFetchTrainerEvents,
  performAddNewTrainerEvent,
  performUpdateTrainerEvent,
} from '../../store/calendar/actions';

const mapStateToProps = (
  state: State,
  ownProps: RouteComponentProps<RouteParams>,
) => ({
  training: state.trainings.selectedTraining,
  events: getEventsForTrainer(state),
  activeTrainingPlan: getWholeTrainingPlanById(
    ownProps.match.params.trainingPlanId,
  )(state),
  eventsMobile: getEventsForMobileTrainer(state),
  selectedDate: state.calendar.activeDate,
  trainingPlans: state.trainingPlans.trainerTrainingPlans,
  planningState: state.calendar.planningMode,
  loggedUser: state.auth.loggedUser,
  clients: state.client.clients,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(
    {
      performSetTrainingDate,
      setTraining,
      setCalendarClickDate,
      setPlanningMode,
      performFetchTrainerEvents,
      performFetchClients,
      performAddNewTrainerEvent,
      performUpdateTrainerEvent,
    },
    dispatch,
  ),
});

const TrainerScheduleContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrainerSchedule);

export { TrainerScheduleContainer as default };
