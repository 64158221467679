import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import { TrainingPlanDTO } from '../../strapi/TrainingPlanDTO';
import {
  processTrainingPlans,
  addTrainingPlan,
  updateTrainingPlan,
  removeTrainingPlan,
  setAllTrainingPlans,
  updateTrainingInTrainingPlan,
} from './actions';

export interface State {
  trainingPlans: TrainingPlanDTO[];
  trainerTrainingPlans: TrainingPlanDTO[];
}

export const initialState: State = {
  trainingPlans: [],
  trainerTrainingPlans: [],
};

export const trainingPlansReducer = reducer<State>(
  initialState,
  on(processTrainingPlans, (state: State, { payload }) => {
    const { trainingPlans } = payload;
    state.trainingPlans = trainingPlans;
  }),
  on(setAllTrainingPlans, (state: State, { payload }) => {
    const { trainerTrainingPlans } = payload;
    state.trainerTrainingPlans = trainerTrainingPlans;
  }),
  on(addTrainingPlan, (state: State, { payload }) => {
    const { trainingPlan } = payload;
    state.trainingPlans = state.trainingPlans.filter(
      item => item.id !== trainingPlan.id,
    );
    state.trainingPlans.push(trainingPlan);
    state.trainerTrainingPlans = state.trainerTrainingPlans.filter(
      item => item.id !== trainingPlan.id,
    );
    state.trainerTrainingPlans.push(trainingPlan);
  }),
  on(updateTrainingPlan, (state: State, { payload }) => {
    const { trainingPlan } = payload;
    state.trainingPlans = state.trainingPlans.filter(
      item => item.id !== trainingPlan.id,
    );
    state.trainerTrainingPlans = state.trainerTrainingPlans.filter(
      item => item.id !== trainingPlan.id,
    );
    state.trainingPlans.push(trainingPlan);
    state.trainerTrainingPlans.push(trainingPlan);
  }),
  on(updateTrainingInTrainingPlan, (state: State, { payload }) => {
    const { trainingPlanId, training } = payload;

    state.trainerTrainingPlans = state.trainerTrainingPlans.map(tp => {
      if (tp.id === trainingPlanId) {
        const newTP: TrainingPlanDTO = {
          ...tp,
          trainings: tp.trainings.map(t => {
            if (t.id === training.id) {
              return training;
            }
            return t;
          }),
        };

        return newTP;
      }
      return tp;
    });

    state.trainingPlans = state.trainingPlans.map(tp => {
      if (tp.id === trainingPlanId) {
        const newTP: TrainingPlanDTO = {
          ...tp,
          trainings: tp.trainings.map(t => {
            if (t.id === training.id) {
              return training;
            }
            return t;
          }),
        };
        return newTP;
      }
      return tp;
    });
  }),
  on(removeTrainingPlan, (state: State, { payload }) => {
    const { planId } = payload;
    state.trainingPlans = state.trainingPlans.filter(
      item => item.id !== planId,
    );
    state.trainerTrainingPlans = state.trainerTrainingPlans.filter(
      item => item.id !== planId,
    );
  }),
);
