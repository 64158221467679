import {
  ETrainingBottomMenu,
  TrainingSectionIndex,
} from '../../components/customerApp/helper/enums';
import {
  clearStringToOnlyTextualNumber,
  getDataAboutTrainingProgress,
  isStringOnlyNumber,
  orderByOrder,
  splitUsingCommonDelimiter,
} from '../../components/customerApp/helper/functions';
import {
  ExercisesOfInterest,
  FollowingSections,
  SectionsOfInterest,
  TypeOfSectionWithSection,
} from '../../components/customerApp/helper/interfaces';
import { TrainingSectionTypeEnum } from '../../enums/TrainingTypesEnum';
import { SectionOrder, TypeOfSection } from '../../interfaces/enums';
import { ApplicationPath, ClientPath, routePathBuilder } from '../../routes';
import {
  TrainingSectionDTO,
  TrainingSectionPartsDTO,
} from '../../strapi/TrainingDTO';
import { TrainingActionTypes } from '../actionTypes';
import { State } from '../combinedReducers';
import { isSuccessful } from '../request/selectors';

export const trainingIsRunningFrom = (state: State): Date | undefined =>
  state.trainingParts.trainingRunningFrom;

export const trainingIsRunning = (state: State): boolean =>
  state.trainingParts.trainingIsRunnig;

export const trainingIsRunningFor = (state: State): number | undefined =>
  state.trainingParts.trainingRunningFor;

export const isInPauseAfterExercise = (state: State): boolean =>
  state.trainingParts.inPauseAfterExercise;

export const callingBETime = (state: State): number | undefined =>
  state.trainingParts.timeToDisplayWhenCallingBE;

export const trainingIsRunningForInTotal = (state: State): number => {
  if (!state.trainings.selectedTraining) {
    return 0;
  }

  const trg = state.trainings.selectedTraining;
  const storedOnBECallTime = state.trainingParts.timeToDisplayWhenCallingBE;

  if (storedOnBECallTime) {
    return storedOnBECallTime;
  }
  return (
    Number(state.trainingParts.trainingRunningFor) + Number(trg.elapsedTime)
  );
};

const getFirstSkippedSection = (
  state: State,
): TrainingSectionDTO | undefined => {
  if (!state.trainings.selectedTraining) {
    return undefined;
  }

  const orderedSections: TrainingSectionDTO[] = orderByOrder(
    state.trainings.selectedTraining.training_sections,
  );

  if (orderedSections[TrainingSectionIndex.WarmUp].isSkipped) {
    return orderedSections[TrainingSectionIndex.WarmUp];
  }

  if (orderedSections[TrainingSectionIndex.Stretch].isSkipped) {
    return orderedSections[TrainingSectionIndex.Stretch];
  }

  return undefined;
};

const getFirstSkippedExercise = (
  state: State,
): TrainingSectionPartsDTO | undefined => {
  if (!state.trainings.selectedTraining) {
    return undefined;
  }

  const orderedSections: TrainingSectionDTO[] = orderByOrder(
    state.trainings.selectedTraining.training_sections,
  );

  const exercises: TrainingSectionPartsDTO[] = orderByOrder(
    orderedSections[TrainingSectionIndex.Training].training_section_parts,
  );

  return exercises.find(exercise => exercise.isSkipped);
};

// from training section get current exercise and following exercise
export const getExercisesOfInterest = (state: State): ExercisesOfInterest => {
  if (!state.trainings.selectedTraining) {
    return {
      previousExercise: undefined,
      currentExercise: undefined,
      followingExercise: undefined,
    };
  }

  const orderedSections: TrainingSectionDTO[] = orderByOrder(
    state.trainings.selectedTraining.training_sections,
  );

  // training section is always second
  const orderedParts: TrainingSectionPartsDTO[] = orderByOrder(
    orderedSections[TrainingSectionIndex.Training].training_section_parts,
  );

  // current exercise is first exercise that is not done
  const currentExercise = orderedParts.find(
    part => !part.isDone && !part.isSkipped,
  );
  let followingExercise;
  let previousExercise;
  if (currentExercise) {
    const currentOrder = currentExercise.order;
    const remainingItems = orderedParts.slice(currentOrder);

    // following exercise is second to first exercise that is not done
    followingExercise = remainingItems.find(part => !part.isDone);

    // order is index from 1. to get previous item i need to index array - 2
    previousExercise = orderedParts[currentOrder - 2];
  }
  /*
    currentExercise === undefined && followingExercise === undefined => all is done, training is done
    currentExercise !== undefined && followingExercise === undefined => all is done but last exercise, following is stretch
    currentExercise !== undefined && followingExercise !== undefined => currently in training section
    currentExercise !== undefined && followingExercise !== undefined => currently in warm up section => have to check with warmup
  */
  return { previousExercise, currentExercise, followingExercise };
};

// from current training get current section and following section
export const getSectionsOfInterest = (state: State): SectionsOfInterest => {
  if (!state.trainings.selectedTraining) {
    return { currentSection: undefined, followingSection: undefined };
  }

  const sections = orderByOrder(
    state.trainings.selectedTraining.training_sections,
  );
  const warmUp = sections[TrainingSectionIndex.WarmUp];
  const trainingExercises = sections[TrainingSectionIndex.Training];
  const stretch = sections[TrainingSectionIndex.Stretch];
  const { currentExercise, followingExercise } = getExercisesOfInterest(state);

  // Special edge case => training has 0 exercises
  if (
    trainingExercises.training_section_parts.length === 0 &&
    !warmUp.isDone &&
    !warmUp.isSkipped
  ) {
    return { currentSection: warmUp, followingSection: stretch };
  }

  // Special edge case => training has 0 exercises
  if (
    trainingExercises.training_section_parts.length === 0 &&
    (warmUp.isDone || warmUp.isSkipped)
  ) {
    return { currentSection: stretch, followingSection: undefined };
  }

  // Stretch is not handled but all exercises are already skipped/done => currently at stretch
  if (!stretch.isDone && !stretch.isSkipped && !currentExercise) {
    return { currentSection: stretch, followingSection: undefined };
  }

  // All exercises are done => training is done
  if (!currentExercise && (stretch.isDone || stretch.isSkipped)) {
    return { currentSection: undefined, followingSection: undefined };
  }

  // Got currentPart, followingPart but warmup is not done
  if (!warmUp.isDone && !warmUp.isSkipped) {
    return { currentSection: warmUp, followingSection: trainingExercises };
  }

  // All exercises are done but one => next section is stretch
  if (!followingExercise) {
    return { currentSection: trainingExercises, followingSection: stretch };
  }

  return {
    currentSection: trainingExercises,
    followingSection: trainingExercises,
  };
};

export const isWarmUp = (section: TrainingSectionDTO): boolean =>
  section.order === SectionOrder.WARM_UP;

export const isTraining = (section: TrainingSectionDTO): boolean =>
  section.order === SectionOrder.TRAINING;

export const isStretch = (section: TrainingSectionDTO): boolean =>
  section.order === SectionOrder.STRETCH;

export const fillTrainingExerciseID = (
  exercise: TrainingSectionPartsDTO,
): string =>
  exercise.training_exercises.length === 1
    ? exercise.training_exercises[0].id
    : '0';

export const fillExerciseID = (exercise: TrainingSectionPartsDTO): string =>
  exercise.training_exercises.length === 1
    ? exercise.training_exercises[0].exercise.id
    : '0';

// route to training overview
const trainingOverviewURL = (id: string) =>
  routePathBuilder([ApplicationPath.Client, ClientPath.Training, id]);

const scheduleURL = () =>
  routePathBuilder([ApplicationPath.Client, ClientPath.Schedule]);

export const getNextURL = (state: State): string => {
  if (!state.trainings.selectedTraining) {
    return scheduleURL();
  }

  const training = state.trainings.selectedTraining;
  const { currentSection, followingSection } = getSectionsOfInterest(state);
  const { currentExercise, followingExercise } = getExercisesOfInterest(state);

  // only both at the same time can be undefined => training is done
  // route to training overview
  if (!currentSection || !followingSection) {
    return trainingOverviewURL(training.id);
  }

  /* 
      I am at warm up and next section is training
      Current exercise is checked only because of the lint not null warning
    */
  if (
    isWarmUp(currentSection) &&
    isTraining(followingSection) &&
    currentExercise
  ) {
    return routePathBuilder([
      ApplicationPath.Client,
      ClientPath.Training,
      training.id,
      ClientPath.Training_Section,
      followingSection.id,
      ClientPath.Section_Part,
      currentExercise.id,
      currentExercise.type,
      ClientPath.Exercise,
      fillExerciseID(currentExercise),
      ClientPath.Training_Exercise,
      fillTrainingExerciseID(currentExercise),
    ]);
  }

  /*
  I am at training and next is training.
  CurrentExercise and followingExercise are checked only because of the lint not null warning
  */
  if (
    isTraining(currentSection) &&
    isTraining(followingSection) &&
    currentExercise &&
    followingExercise
  ) {
    return routePathBuilder([
      ApplicationPath.Client,
      ClientPath.Training,
      training.id,
      ClientPath.Training_Section,
      followingSection.id,
      ClientPath.Section_Part,
      followingExercise.id,
      followingExercise.type,
      ClientPath.Exercise,
      fillExerciseID(followingExercise),
      ClientPath.Training_Exercise,
      fillTrainingExerciseID(followingExercise),
    ]);
  }

  // All exercises are done
  if (isStretch(followingSection) || isStretch(currentSection)) {
    return routePathBuilder([
      ApplicationPath.Client,
      ClientPath.Training,
      training.id,
      ClientPath.Training_Section,
      followingSection.id,
      ClientPath.Section_Part,
      '0',
      ClientPath.Stretch,
      ClientPath.Exercise,
      '0',
      ClientPath.Training_Exercise,
      '0',
    ]);
  }

  /*
      training is all done and there is no next exercise or section
      route to training overview
    */
  return trainingOverviewURL(training.id);
};

const determineIfWarmUpStretchPause = (
  section: TrainingSectionDTO,
  isFinish: boolean,
): string => {
  if (isWarmUp(section)) {
    return ClientPath.WarmUp;
  }

  return isFinish ? ClientPath.Finish : ClientPath.Stretch;
};

export const getCurrentURL = (state: State): string => {
  if (!state.trainings.selectedTraining) {
    return scheduleURL();
  }

  const training = state.trainings.selectedTraining;
  const { currentSection } = getSectionsOfInterest(state);
  const { currentExercise } = getExercisesOfInterest(state);
  if (!currentSection) {
    return trainingOverviewURL(training.id);
  }

  /* 
      I am currently either at stretch or warm up. they got bot similar URL address
      so they are handled at the same time
    */
  if (isStretch(currentSection) || isWarmUp(currentSection)) {
    const { doneExercises, numberOfDoneSkippedExercises } =
      getDataAboutTrainingProgress(training);
    const isFinish = doneExercises !== numberOfDoneSkippedExercises;
    return routePathBuilder([
      ApplicationPath.Client,
      ClientPath.Training,
      training.id,
      ClientPath.Training_Section,
      currentSection.id,
      ClientPath.Section_Part,
      '0',
      determineIfWarmUpStretchPause(currentSection, isFinish),
      ClientPath.Exercise,
      '0',
      ClientPath.Training_Exercise,
      '0',
    ]);
  }

  // I am currently in training section in some exercise
  if (isTraining(currentSection) && currentExercise) {
    return routePathBuilder([
      ApplicationPath.Client,
      ClientPath.Training,
      training.id,
      ClientPath.Training_Section,
      currentSection.id,
      ClientPath.Section_Part,
      currentExercise.id,
      currentExercise.type,
      ClientPath.Exercise,
      fillExerciseID(currentExercise),
      ClientPath.Training_Exercise,
      fillTrainingExerciseID(currentExercise),
    ]);
  }
  return trainingOverviewURL(training.id);
};

export const getFirstSkippedURL = (state: State): string => {
  if (!state.trainings.selectedTraining) {
    return scheduleURL();
  }

  const training = state.trainings.selectedTraining;
  const trainingSection = orderByOrder(
    state.trainings.selectedTraining.training_sections,
  )[TrainingSectionIndex.Training];
  const firstSkippedSection = getFirstSkippedSection(state);
  const firstSkippedExercise = getFirstSkippedExercise(state);

  if (!firstSkippedSection && !firstSkippedExercise) {
    return trainingOverviewURL(training.id);
  }

  if (
    (firstSkippedSection &&
      !isWarmUp(firstSkippedSection) &&
      firstSkippedExercise) ||
    (!firstSkippedSection && firstSkippedExercise)
  ) {
    return routePathBuilder([
      ApplicationPath.Client,
      ClientPath.Training,
      training.id,
      ClientPath.Training_Section,
      trainingSection.id,
      ClientPath.Section_Part,
      firstSkippedExercise.id,
      firstSkippedExercise.type,
      ClientPath.Exercise,
      fillExerciseID(firstSkippedExercise),
      ClientPath.Training_Exercise,
      fillTrainingExerciseID(firstSkippedExercise),
    ]);
  }

  if (
    (firstSkippedSection && isStretch(firstSkippedSection)) ||
    (firstSkippedSection && isWarmUp(firstSkippedSection))
  ) {
    return routePathBuilder([
      ApplicationPath.Client,
      ClientPath.Training,
      training.id,
      ClientPath.Training_Section,
      firstSkippedSection.id,
      ClientPath.Section_Part,
      '0',
      isWarmUp(firstSkippedSection) ? ClientPath.WarmUp : ClientPath.Stretch,
      ClientPath.Exercise,
      '0',
      ClientPath.Training_Exercise,
      '0',
    ]);
  }

  return trainingOverviewURL(training.id);
};

export const getStretchURL = (state: State): string => {
  if (!state.trainings.selectedTraining) {
    return scheduleURL();
  }

  const training = state.trainings.selectedTraining;
  const stretch = orderByOrder(training.training_sections)[
    TrainingSectionIndex.Stretch
  ];

  if (!stretch) {
    return trainingOverviewURL(training.id);
  }

  return routePathBuilder([
    ApplicationPath.Client,
    ClientPath.Training,
    training.id,
    ClientPath.Training_Section,
    stretch.id,
    ClientPath.Section_Part,
    '0',
    ClientPath.Stretch,
    ClientPath.Exercise,
    '0',
    ClientPath.Training_Exercise,
    '0',
  ]);
};

export const getPauseURL = (state: State): string => {
  if (!state.trainings.selectedTraining) {
    return scheduleURL();
  }

  const training = state.trainings.selectedTraining;
  const { currentSection } = getSectionsOfInterest(state);
  const { currentExercise } = getExercisesOfInterest(state);

  if (!currentSection) {
    return trainingOverviewURL(training.id);
  }

  // I am currently in training section in some exercise
  if (isTraining(currentSection) && currentExercise) {
    return routePathBuilder([
      ApplicationPath.Client,
      ClientPath.Training,
      training.id,
      ClientPath.Training_Section,
      currentSection.id,
      ClientPath.Section_Part,
      currentExercise.id,
      ClientPath.Pause,
      ClientPath.Exercise,
      fillExerciseID(currentExercise),
      ClientPath.Training_Exercise,
      fillTrainingExerciseID(currentExercise),
    ]);
  }
  return trainingOverviewURL(training.id);
};

export const getFinishURL = (state: State): string => {
  if (!state.trainings.selectedTraining) {
    // TODO return to schedule
    return '';
  }

  const training = state.trainings.selectedTraining;
  const { currentSection } = getSectionsOfInterest(state);

  if (!currentSection) {
    return trainingOverviewURL(training.id);
  }

  // I am currently in training section in some exercise
  return routePathBuilder([
    ApplicationPath.Client,
    ClientPath.Training,
    training.id,
    ClientPath.Training_Section,
    currentSection.id,
    ClientPath.Section_Part,
    '0',
    ClientPath.Finish,
    ClientPath.Exercise,
    '0',
    ClientPath.Training_Exercise,
    '0',
  ]);
};

/*
   returns current position where I am in training
   eg. nothing is done/skipped => I am at warmup => returns 1
   eg. all is done/skipped but stretch => I am at stretch => returns order of stretch section 
        => count of exercises + warmup section (1) + stretch section (1)
*/
export const getCurrentOrder = (state: State): number => {
  if (!state.trainings.selectedTraining) {
    return 0;
  }

  const training = state.trainings.selectedTraining;
  const { currentSection } = getSectionsOfInterest(state);
  const { currentExercise } = getExercisesOfInterest(state);
  const trainingExercises = orderByOrder(training.training_sections)[
    TrainingSectionIndex.Training
  ];
  const { doneExercises } = getDataAboutTrainingProgress(training);

  if (training.isFinished) {
    return 0;
  }

  if (currentSection && isWarmUp(currentSection)) {
    return 1;
  }

  if (currentSection && isStretch(currentSection)) {
    return trainingExercises.training_section_parts.length + 2;
  }

  if (currentExercise && currentSection && isTraining(currentSection)) {
    return currentExercise.order + 1;
  }

  return doneExercises;
};
/*
  Return the very first training section part that is not done or skipped. Section parts are looped 
  backwards.
*/
export const getFollowingSectionParts = (
  state: State,
): FollowingSections | undefined => {
  if (!state.trainings.selectedTraining) {
    return undefined;
  }

  const trg = state.trainings.selectedTraining;
  const trainingSection = trg.training_sections.find(
    ts => ts.order === SectionOrder.TRAINING,
  );

  if (!trainingSection) {
    return undefined;
  }

  const isEveryDone = trainingSection.training_section_parts.every(
    tse => tse.isDone || tse.isSkipped,
  );

  if (isEveryDone) {
    return {
      currentTrainingSection: undefined,
      nextTrainingSection: undefined,
      skippedTrainingSection: undefined,
    };
  }

  let index = trainingSection.training_section_parts.length - 1;
  const sectionParts = trainingSection.training_section_parts;
  const orderedSectionParts =
    sectionParts.length > 0 && orderByOrder(sectionParts);

  for (; index >= 0; index -= 1) {
    const skippedOrder: number = index + 2;
    const nextOrder: number = index + 1;
    const currentOrder: number = index;

    const currentTrainingSection = orderedSectionParts[currentOrder];
    const nextTrainingSection = orderedSectionParts[nextOrder];
    const skippedTrainingSection = orderedSectionParts[skippedOrder];
    if (
      currentTrainingSection &&
      (currentTrainingSection.isDone || currentTrainingSection.isSkipped) &&
      nextTrainingSection
    ) {
      return {
        currentTrainingSection,
        nextTrainingSection,
        skippedTrainingSection,
      };
    }
  }

  /*
    None training section part is done/skipped; thus no next section after the first done
    section found is available. Hovewer we have to return the first training section part after warm up if possible.
  */
  if (sectionParts.length > 0) {
    return {
      currentTrainingSection: undefined,
      nextTrainingSection: orderedSectionParts[0],
      skippedTrainingSection: orderedSectionParts[1],
    };
  }
  return undefined;
};

export const getCurrentSection = (
  state: State,
): TypeOfSectionWithSection | undefined => {
  if (!state.trainings.selectedTraining) {
    return undefined;
  }

  const trg = state.trainings.selectedTraining;
  const trainingSection = trg.training_sections.find(
    ts => ts.order === SectionOrder.TRAINING,
  );

  const warmUpSection = trg.training_sections.find(
    ts => ts.order === SectionOrder.WARM_UP,
  );

  const stretchSection = trg.training_sections.find(
    ts => ts.order === SectionOrder.STRETCH,
  );

  if (!trainingSection || !warmUpSection || !stretchSection) {
    return undefined;
  }
  const areDone = trainingSection.training_section_parts.filter(
    tsp => tsp.isDone,
  ).length;

  // 0 exercises are done and warm up is not done
  // returns warmup
  if (areDone === 0 && !warmUpSection.isDone) {
    return { type: TypeOfSection.WARM_UP, section: warmUpSection };
  }

  // all exercises are done and stretch is not done
  // returns stretch section
  if (
    areDone === trainingSection.training_section_parts.length &&
    !stretchSection.isDone
  ) {
    return { type: TypeOfSection.STRETCH, section: stretchSection };
  }

  // all exercises are done, warmup and stretch is done as well
  // return type finished because the training is finished
  if (
    areDone === trainingSection.training_section_parts.length &&
    warmUpSection.isDone &&
    stretchSection.isDone
  ) {
    return { type: TypeOfSection.FINISHED, section: stretchSection };
  }

  return { type: TypeOfSection.TRAINING, section: trainingSection };
};

export const getSectionPartById =
  (id: string) =>
  (state: State): TrainingSectionPartsDTO | undefined => {
    if (!state.trainings.selectedTraining) {
      return undefined;
    }

    const trg = state.trainings.selectedTraining;
    const trainingSection = trg.training_sections.find(
      ts => ts.training_section_parts.length > 0,
    );

    if (!trainingSection) {
      return undefined;
    }

    return trainingSection.training_section_parts.find(tsp => tsp.id === id);
  };

/*
  Get pure time of whole exercise based on number of series and time of cycles
*/

export const singleExerciseShouldTakeSeconds = (
  state: State,
): number | undefined => {
  const { currentExercise } = getExercisesOfInterest(state);

  // check if exercise is single exercise => expect just one exercise
  if (
    !currentExercise ||
    currentExercise.type !== TrainingSectionTypeEnum.SingleExercise
  ) {
    return undefined;
  }

  const {
    series: cycles,
    repeats: timeExercise,
    pause: timePause,
  } = currentExercise.training_exercises[0];

  /* 
    ... + 2 * cycles => repeats or pause starts from 0 which is not displayed in progress bars
    progress bar starts from 1. Have to therefore count plus 2 multiplied by number of series 
  */

  // all repeats got the same time
  if (isStringOnlyNumber(timeExercise)) {
    return (
      (Number(timePause) + Number(timeExercise)) * Number(cycles) +
      2 * Number(cycles)
    );
  }

  // each repeat got different time and pause is the same
  return (
    splitUsingCommonDelimiter(timeExercise)
      .map(repeat => Number(clearStringToOnlyTextualNumber(repeat)))
      .reduce((acc, cur) => acc + cur) +
    2 * Number(cycles) +
    Number(timePause) * Number(cycles)
  );
};

export const shouldTakePauseTimeInSeconds = (
  state: State,
): number | undefined => {
  const { previousExercise } = getExercisesOfInterest(state);

  if (!previousExercise) {
    return undefined;
  }

  const success = isSuccessful([TrainingActionTypes.PERFORM_FETCH_TRAINING])(
    state,
  );

  if (!success) {
    return undefined;
  }

  const isSimple =
    previousExercise.type === TrainingSectionTypeEnum.SingleExercise;
  return isSimple
    ? Number(previousExercise.training_exercises[0].pauseAfterExercise)
    : Number(
        orderByOrder(previousExercise.training_exercises).pop()
          .pauseAfterExercise,
      );
};

export const getCurrentExerciseIsRunningInTotal = (state: State): number => {
  const followingSectionParts = getFollowingSectionParts(state);

  if (!followingSectionParts?.nextTrainingSection) {
    return 0;
  }

  const currentlyRunningFor: number = trainingIsRunningFor(state) || 0;
  const currentSectionElapsedTime: number =
    followingSectionParts.nextTrainingSection.elapsedTime || 0;
  return currentSectionElapsedTime + currentlyRunningFor;
};

export const isBottomMenuHigher =
  (
    isInPauseAfterExercise: boolean,
    isInFinish: boolean,
    isCircuitRunning: boolean,
  ) =>
  (state: State): boolean => {
    const res = getTrainingIsInForBottomMenu(
      isInPauseAfterExercise,
      isInFinish,
      isCircuitRunning,
    )(state);

    // TODO WATCH WHEN GET TIME BACK - NO_TIME
    if (
      res === ETrainingBottomMenu.StartTraining ||
      res === ETrainingBottomMenu.RunningTraining ||
      res === ETrainingBottomMenu.SkipCircleTraining ||
      res === ETrainingBottomMenu.BeforeCircleTraining
    ) {
      return true;
    }

    if (res === ETrainingBottomMenu.BasicTraining) {
      return false;
    }
    return false;
  };

export const getTrainingIsInForBottomMenu =
  (
    isInPauseAfterExercise: boolean,
    isInFinish: boolean,
    isCircuitRunning: boolean,
  ) =>
  (state: State): ETrainingBottomMenu => {
    const runningInTotal = trainingIsRunningForInTotal(state);
    const { currentSection, followingSection } = getSectionsOfInterest(state);
    const { currentExercise } = getExercisesOfInterest(state);

    if (!currentSection || isInFinish) {
      return ETrainingBottomMenu.FinishedTraining;
    }
    // TRAINING not started
    if (runningInTotal === 0 && currentSection.order === SectionOrder.WARM_UP) {
      return ETrainingBottomMenu.StartTraining;
    }

    // TRAINING is in warm up phase
    if (currentSection.order === SectionOrder.WARM_UP) {
      return ETrainingBottomMenu.RunningTraining;
    }

    // TRAINING is finished
    if (
      !followingSection &&
      isStretch(currentSection) &&
      (currentSection.isDone || currentSection.isSkipped)
    ) {
      return ETrainingBottomMenu.BasicTraining;
    }

    if (isCircuitRunning) {
      return ETrainingBottomMenu.SkipCircleTraining;
    }

    // TRAINING is at stretch
    if (currentSection.order === SectionOrder.STRETCH) {
      return ETrainingBottomMenu.RunningTraining;
    }

    if (isInPauseAfterExercise) {
      return ETrainingBottomMenu.RunningTraining;
    }

    // Do not have training section yet but trainining is running
    if (!currentExercise) {
      return ETrainingBottomMenu.BasicTraining;
    }

    // Training is at circuit exercise
    if (currentExercise.type === TrainingSectionTypeEnum.CircuitTraining) {
      return ETrainingBottomMenu.BeforeCircleTraining;
    }

    // Training is at Single Exercise or Super Serie
    if (
      currentExercise.type === TrainingSectionTypeEnum.SingleExercise ||
      currentExercise.type === TrainingSectionTypeEnum.SuperSet
    ) {
      return ETrainingBottomMenu.RunningTraining;
    }

    return ETrainingBottomMenu.BasicTraining;
  };
