// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChatResponseDto
 */
export interface ChatResponseDto {
  /**
   *
   * @type {number}
   * @memberof ChatResponseDto
   */
  chatId: number;
  /**
   *
   * @type {string}
   * @memberof ChatResponseDto
   */
  chatName: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ChatResponseDto
   */
  messages: Array<string>;
}

export function ChatResponseDtoFromJSON(json: any): ChatResponseDto {
  return ChatResponseDtoFromJSONTyped(json, false);
}

export function ChatResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChatResponseDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    chatId: json['chatId'],
    chatName: json['chatName'],
    messages: json['messages'],
  };
}

export function ChatResponseDtoToJSON(value?: ChatResponseDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    chatId: value.chatId,
    chatName: value.chatName,
    messages: value.messages,
  };
}
