import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import { AxiosResponse } from 'axios';
import { State } from '../combinedReducers';
import { baseAsyncRequest } from '../actionHelpers';
import { AT as ActionTypes } from '../actionTypes';
import { makeServerCallAsync } from '../../code/helpers/api/api';
import { NoteDTO, CreateNoteDTO, UpdateNoteDTO } from '../../strapi/NoteDTO';

export const setNotes = action(
  ActionTypes.SET_NOTES,
  payload<{ notes: NoteDTO[] }>(),
);

export const addNote = action(
  ActionTypes.ADD_NOTE,
  payload<{ note: NoteDTO }>(),
);

export const updateNote = action(
  ActionTypes.UPDATE_NOTE,
  payload<{ note: NoteDTO }>(),
);

export const deleteNote = action(
  ActionTypes.DELETE_NOTE,
  payload<{ note: NoteDTO }>(),
);

export const performFetchNotesByUserId: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (userId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/notes?user=${userId}`);

    const results = await baseAsyncRequest(
      ActionTypes.FETCH_NOTES,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;

      dispatch(setNotes({ notes: data }));
    }
  };

export const performAddNote: ActionCreator<
  ThunkAction<Promise<void>, State, NoteDTO, any>
> =
  (note: CreateNoteDTO, onCreateCallback?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', '/notes', note);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_ADD_NOTE,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;

      dispatch(addNote({ note: data }));
      if (onCreateCallback) {
        onCreateCallback();
      }
    }
  };

export const performUpdateNote: ActionCreator<
  ThunkAction<Promise<void>, State, NoteDTO, any>
> =
  (noteId: string, newData: UpdateNoteDTO, onUpdateCallback?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('put', `/notes/${noteId}`, newData);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_UPDATE_NOTE,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;

      dispatch(updateNote({ note: data }));
      if (onUpdateCallback) {
        onUpdateCallback();
      }
    }
  };

export const performDeleteNote: ActionCreator<
  ThunkAction<Promise<void>, State, NoteDTO, any>
> =
  (noteId: string, newData: UpdateNoteDTO, onUpdateCallback?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('delete', `/notes/${noteId}`);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_DELETE_NOTE,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;

      dispatch(deleteNote({ note: data }));
      if (onUpdateCallback) {
        onUpdateCallback();
      }
    }
  };
