// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CreateUserDetailsDto,
  CreateUserDetailsDtoFromJSON,
  CreateUserDetailsDtoFromJSONTyped,
  CreateUserDetailsDtoToJSON,
} from './';

/**
 *
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
  /**
   *
   * @type {string}
   * @memberof CreateUserDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserDto
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserDto
   */
  lastName: string;
  /**
   *
   * @type {number}
   * @memberof CreateUserDto
   */
  trainerId: number;
  /**
   *
   * @type {CreateUserDetailsDto}
   * @memberof CreateUserDto
   */
  clientDetails: CreateUserDetailsDto;
}

export function CreateUserDtoFromJSON(json: any): CreateUserDto {
  return CreateUserDtoFromJSONTyped(json, false);
}

export function CreateUserDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateUserDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    trainerId: json['trainerId'],
    clientDetails: CreateUserDetailsDtoFromJSON(json['clientDetails']),
  };
}

export function CreateUserDtoToJSON(value?: CreateUserDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    firstName: value.firstName,
    lastName: value.lastName,
    trainerId: value.trainerId,
    clientDetails: CreateUserDetailsDtoToJSON(value.clientDetails),
  };
}
