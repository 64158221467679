/** @jsx jsx */
import { FC, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { Trans } from '@lingui/macro';
import { COLORS, MPX } from '../../../../styles/themes';
import { NoteDTO, UpdateNoteDTO } from '../../../../strapi/NoteDTO';
import { DATE_DAY_MONTH_YEAR } from '../../../../code/constants/date';
import { ModalWindow } from '../../../shared/ModalWindow';
import { AppTextInput } from '../../../shared/Forms';
import { requiredRule } from '../../../shared/helpers/FormRules';

interface NoteProps {
  note: NoteDTO;
  editNote?: (note: NoteDTO, newData: UpdateNoteDTO) => void;
  deleteNote?: (id: string) => void;
}

export const Note: FC<NoteProps> = ({ note, deleteNote, editNote }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const onDelete = () => {
    if (deleteNote) {
      deleteNote(note.id);

      setIsModalOpen(false);
    }
  };

  const onSubmit = (e: any) => {
    if (editNote) {
      editNote(note, {
        text: e.note,
      });

      setIsModalOpen(false);
    }
  };

  return (
    <div>
      <ModalWindow
        headerText={<Trans>Poznámka</Trans>}
        isOpen={isModalOpen}
        handleClose={(isDeleteButton: boolean | undefined) => {
          setIsModalOpen(false);
          if (isDeleteButton) {
            onDelete();
          }
        }}
        agreeButtonText={<Trans>Uložit</Trans>}
        cancelButtonText={<Trans>Smazat</Trans>}
        onSubmit={onSubmit}
        closeIcon
        mobileFooter={false}
      >
        <AppTextInput
          label="Poznámka"
          variant="outlined"
          name="note"
          rules={{ ...requiredRule }}
          multiline
          rows="8"
          rowsMax="8"
          defaultValue={note.text}
        />
      </ModalWindow>
      <Grid
        container
        direction="column"
        justify="space-between"
        css={backgroundCss(isMobile)}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <Grid item css={gridItemCss}>
          <div css={textCss}>{note.text}</div>
        </Grid>
        <Grid
          container
          item
          alignItems="flex-end"
          justify="flex-end"
          css={dateCss}
        >
          {format(parseISO(note.createdAt), DATE_DAY_MONTH_YEAR)}
        </Grid>
      </Grid>
    </div>
  );
};

const backgroundCss = (isMobile: boolean) => css`
  background-color: ${isMobile ? COLORS.bg : COLORS.white};
  height: 180px;
  padding: ${4 * MPX}px;
  border-radius: ${2 * MPX}px;
`;
const dateCss = css`
  opacity: 0.5;
`;
const gridItemCss = css`
  line-height: ${MPX * 6}px;
  max-width: 100% !important;
`;
const textCss = css`
  color: ${COLORS.blueDark} !important;

  overflow: hidden;
  display: block;
  display: -webkit-box;
  word-break: break-all;
  max-lines: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;
