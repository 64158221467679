import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SectionType, UpdateType } from '../../../../../interfaces/enums';
import { changeCircularRunning } from '../../../../../store/general/actions';
import { getCircularRunning } from '../../../../../store/general/selectors';
import {
  performUpdateExerciseIs,
  performUpdateTime,
} from '../../../../../store/training/actions';
import { performIsInPauseAfterExercise } from '../../../../../store/trainingParts/actions';
import {
  getExercisesOfInterest,
  getFinishURL,
  getNextURL,
  getPauseURL,
  trainingIsRunning,
  trainingIsRunningForInTotal,
} from '../../../../../store/trainingParts/selectors';
import {
  TrainingDTO,
  TrainingExerciseDTO,
} from '../../../../../strapi/TrainingDTO';
import {
  clearStringToOnlyTextualNumber,
  followsPause,
  getDataAboutTrainingProgress,
  isNextFinish,
  isStringOnlyNumber,
  splitUsingCommonDelimiter,
} from '../../../helper/functions';
import RunningCircuitExercise from './RunningCircuitExercise';

interface RunningCircuitProps {
  orderedExercises: TrainingExerciseDTO[];
  pauseAfterCircuit: number;
  numberOfSeries: number;
  sectionPartId: string;
  training: TrainingDTO;
  detailLink: (exerciseId: string) => string;
}

let elapsedTrigger: NodeJS.Timeout | null = null;
let exerciseDoneTrigger: NodeJS.Timeout | null = null;

const RunningCircuit: React.FC<RunningCircuitProps> = ({
  orderedExercises,
  pauseAfterCircuit,
  numberOfSeries,
  sectionPartId,
  training,
  detailLink,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // first exercise is always the first exercise
  const [currentTrainingExercise, setCurrentExercise] =
    useState<TrainingExerciseDTO>(orderedExercises[0]);

  // initial serie is always the first one => 1
  const [currentSerie, setCurrentSerie] = useState(1);
  const [elapsedTime, setTime] = useState(0);
  const [isPause, setIsPause] = useState(() => true);
  const isRunning = useSelector(trainingIsRunning);
  const pauseURL = useSelector(getPauseURL);
  const finishURL = useSelector(getFinishURL);
  const nextURL = useSelector(getNextURL);
  const isCircularRunning = useSelector(getCircularRunning);
  const totalTime = useSelector(trainingIsRunningForInTotal);
  const { currentExercise, followingExercise } = useSelector(
    getExercisesOfInterest,
  );

  const { numberOfDoneSkippedExercises, doneExercises } =
    getDataAboutTrainingProgress(training);

  useEffect(
    () => () => {
      dispatch(changeCircularRunning({ running: false }));
    },

    [dispatch, isCircularRunning],
  );

  useEffect(() => {
    if (currentSerie - 1 === Number(numberOfSeries)) {
      dispatch(performUpdateTime(training.id, totalTime));
      dispatch(
        performUpdateExerciseIs(
          sectionPartId,
          SectionType.SECTION_PART,
          UpdateType.DONE,
        ),
      );
      dispatch(performIsInPauseAfterExercise(true));

      if (
        followsPause(UpdateType.DONE, currentExercise, followingExercise, false)
      ) {
        history.push(pauseURL);
        return;
      }
      if (
        isNextFinish(
          UpdateType.DONE,
          doneExercises,
          numberOfDoneSkippedExercises,
          followingExercise,
        )
      ) {
        history.push(finishURL);
        return;
      }

      dispatch(performIsInPauseAfterExercise(false));
      dispatch(changeCircularRunning({ running: false }));
      history.push(nextURL);
    }
    // only currentSerie is important and on its change I have to check whether I
    // have already accomplished whole circuit exercise
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSerie]);

  const pauseTotal = useMemo(() => {
    const currentOrder = currentTrainingExercise.order;
    const previousExercise = orderedExercises[currentOrder - 2];
    if (currentOrder !== 1 && previousExercise) {
      return Number(previousExercise.pauseAfterExercise);
    }

    if (currentSerie === 1 && currentOrder === 1) {
      // first pause at circuit exercise is always set to 3 seconds
      return 3;
    }

    if (currentSerie > 1 && currentOrder === 1) {
      return currentExercise ? Number(currentExercise.pause) : 0;
    }

    return 0;
    // has to has isPause and isRunning deps
    // pauseTotal has to be recalculated when isRunning or isPause are changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTrainingExercise,
    currentSerie,
    orderedExercises,
    isRunning,
    isPause,
  ]);

  // TODO REFACTOR NEEDED. Custom hook might be better
  const parseNumberWithDelimiters = useCallback(
    (text: string) => {
      if (isStringOnlyNumber(text)) {
        return Number(text);
      }

      const clearedTimesOfExercise = splitUsingCommonDelimiter(text);

      if (clearedTimesOfExercise.length === 1) {
        /*
        After database is migrated there will be no such a inputs as 15x or 15s
        Therefore this line should be refactored after migration
      */
        return Number(
          clearStringToOnlyTextualNumber(clearedTimesOfExercise[0]),
        );
      }

      return Number(
        clearStringToOnlyTextualNumber(
          clearedTimesOfExercise[currentSerie - 1],
        ),
      );
    },
    // has to has isPause and isRunning deps
    // timeTotal has to be recalculated when isRunning or isPause are changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTrainingExercise.repeats, isPause, isRunning],
  );

  const timeTotal = useMemo(
    () => parseNumberWithDelimiters(currentTrainingExercise.repeats),
    [currentTrainingExercise.repeats, parseNumberWithDelimiters],
  );

  const currentWeight = useMemo(
    () => parseNumberWithDelimiters(currentTrainingExercise.weights),
    [currentTrainingExercise.weights, parseNumberWithDelimiters],
  );

  // set setTimeout on mount => handles what to do after the time is elapsed
  useEffect(() => {
    const indexOfNextExercise = currentTrainingExercise.order;
    let nextExercise = orderedExercises[indexOfNextExercise];

    if (exerciseDoneTrigger) {
      clearTimeout(exerciseDoneTrigger);
      exerciseDoneTrigger = null;
    }

    if (isRunning && timeTotal) {
      if (isPause) {
        exerciseDoneTrigger = setTimeout(async () => {
          setIsPause(false);
          setTime(0);
        }, 1000 * (pauseTotal + 1 - elapsedTime));
      } else {
        exerciseDoneTrigger = setTimeout(async () => {
          const currentOrder = currentTrainingExercise.order;
          if (
            currentOrder === orderedExercises[orderedExercises.length - 1].order
          ) {
            setIsPause(Boolean(currentExercise?.pause));
          } else {
            setIsPause(Boolean(currentTrainingExercise.pauseAfterExercise));
          }
          setTime(0);

          if (!nextExercise) {
            [nextExercise] = orderedExercises;
            setCurrentSerie(prevSerie => prevSerie + 1);
          }

          setCurrentExercise(nextExercise);
        }, 1000 * (timeTotal + 1 - elapsedTime));
      }
    } else if (exerciseDoneTrigger) {
      clearTimeout(exerciseDoneTrigger);
      exerciseDoneTrigger = null;
    }

    return () => {
      if (exerciseDoneTrigger) {
        clearTimeout(exerciseDoneTrigger);
        exerciseDoneTrigger = null;
      }
    };
    // timeTotal and pauseTotal re-renders produces bugs on ProgressBars
    // ProgressBar overflows for a few milliseconds
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentExercise, isRunning, isPause, elapsedTime]);

  // set setTimeout on mount => stopwatch
  // counts seconds elapsed
  useEffect(() => {
    if (isRunning) {
      elapsedTrigger = setTimeout(
        () => setTime(prevTime => prevTime + 1),
        1000,
      );
    } else if (elapsedTrigger) {
      clearTimeout(elapsedTrigger);
      elapsedTrigger = null;
    }

    return () => {
      if (elapsedTrigger) {
        clearTimeout(elapsedTrigger);
        elapsedTrigger = null;
      }
    };
  }, [elapsedTime, isRunning]);

  return (
    <div>
      <RunningCircuitExercise
        exercise={currentTrainingExercise}
        link={detailLink(currentTrainingExercise.exercise.id)}
        pauseTotal={pauseTotal}
        timeTotal={timeTotal}
        elapsedTime={elapsedTime}
        isPause={isPause}
        currentWeight={currentWeight}
      />
    </div>
  );
};

export default RunningCircuit;
