import { combineReducers } from 'redux';
import * as general from './general/reducers';
import * as request from './request/reducers';
import * as auth from './auth/reducers';
import * as client from './client/reducers';
import * as chat from './chat/reducers';
import * as exercises from './exercisesLibrary/reducers';
import * as trainingPlans from './trainingPlans/reducers';
import * as trainings from './training/reducers';
import * as calendar from './calendar/reducers';
import * as progresses from './progress/reducers';
import * as notes from './notes/reducers';
import * as documents from './docs/reducers';
import * as trainingParts from './trainingParts/reducers';

export interface State {
  general: general.State;
  loading: request.LoadingReducerState;
  error: request.ErrorReducerState;
  success: request.SuccessReducerState;
  auth: auth.State;
  client: client.State;
  chat: chat.State;
  exercises: exercises.State;
  trainingPlans: trainingPlans.State;
  trainings: trainings.State;
  calendar: calendar.State;
  progresses: progresses.State;
  notes: notes.State;
  documents: documents.State;
  trainingParts: trainingParts.State;
}

export const initialState: State = {
  general: general.initialState,
  loading: request.initialState,
  error: request.initialState,
  success: request.initialState,
  auth: auth.initialState,
  client: client.initialState,
  chat: chat.initialState,
  exercises: exercises.initialState,
  trainingPlans: trainingPlans.initialState,
  trainings: trainings.initialState,
  calendar: calendar.initialState,
  progresses: progresses.initialState,
  notes: notes.initialState,
  documents: documents.initialState,
  trainingParts: trainingParts.initialState,
};

export const reducer = combineReducers<State>({
  general: general.generalReducer,
  loading: request.loadingReducer,
  error: request.errorReducer,
  success: request.successReducer,
  auth: auth.authReducer,
  client: client.clientReducer,
  chat: chat.chatReducer,
  exercises: exercises.exercisesReducers,
  trainingPlans: trainingPlans.trainingPlansReducer,
  trainings: trainings.trainingsReducer,
  calendar: calendar.calendarReducer,
  progresses: progresses.progressesReducer,
  notes: notes.notesReducer,
  documents: documents.documentsReducer,
  trainingParts: trainingParts.trainingPartsReducer,
});
