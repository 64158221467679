import { UserDTO } from '../strapi/UserDTO';

export enum EventType {
  training = 'TRAINING',
  event = 'EVENT',
}
export interface EventDTO {
  title: string;
  id: string;
  start: Date;
  end?: Date;
  borderColor?: string;
  backgroundColor?: string;
  location?: string;
  textColor?: string;
  moreInOneDay?: boolean;
  trainingPlanId?: string;
  client?: UserDTO;
  isFinished?: boolean;
  type: EventType;
}
