import { FormatEnum, MediaFileDTO } from '../../strapi/MediaFileDTO';
import { getImageFromMedia } from './strapi';

const createPlaceHolder = ({ width, height } = { width: 300, height: 300 }) =>
  `https://via.placeholder.com/${width}x${height}`;

export const getImageUrl = (
  illustrations: MediaFileDTO[] | undefined,
  width: number,
  height: number,
  format: FormatEnum,
): string => {
  const placeholder = createPlaceHolder({ width, height });

  if (!illustrations) {
    return placeholder;
  }

  const thumbImage = getImageFromMedia(illustrations, format);

  return thumbImage ? thumbImage.url : placeholder;
};

export const getLargestImage = (
  illustrations: MediaFileDTO[] | undefined,
): string => {
  if (!illustrations) {
    return createPlaceHolder();
  }

  const large = getImageFromMedia(illustrations, FormatEnum.LARGE);
  const medium = getImageFromMedia(illustrations, FormatEnum.MEDIUM);
  const small = getImageFromMedia(illustrations, FormatEnum.SMALL);
  const thumb = getImageFromMedia(illustrations, FormatEnum.THUMBNAIL);
  const found = [large, medium, small, thumb].find(image => image?.url);

  return found?.url || createPlaceHolder();
};
