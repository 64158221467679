/** @jsx jsx */
import React, { ReactNode, useState } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/react';
import {
  TextField,
  Button,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@material-ui/core';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Trans } from '@lingui/macro';
import { ButtonProps } from '@material-ui/core/Button';
import { v4 as uuid } from 'uuid';
import { Result } from './Results';
import { formMargin } from '../../styles/mixins';
import { COLORS } from '../../styles/themes';

export * from './AppMultiSelect';
export * from './AppNumberInput';

export type AppTextInputProps = {
  rules?: RegisterOptions;
  name: string;
  label: string;
  helperText?: string;
  upperHelperText?: JSX.Element;
} & TextFieldProps;

export const AppTextInput: React.FC<AppTextInputProps> = ({
  rules,
  label,
  name,
  fullWidth,
  helperText,
  variant,
  margin,
  upperHelperText,
  ...restProps
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  const getHelperOrErrorText = () => {
    if (error && error.message) {
      return <Trans id={error.message} />;
    }

    if (helperText) {
      return <Trans id={helperText} />;
    }
    return null;
  };
  // i want to omit value and default value
  // controlled/uncontrolled
  const { ...restOfRest } = restProps;
  const customProps: TextFieldProps = {
    label: <Trans id={label} />,
    margin: margin || 'dense',
    variant: variant || 'outlined',
    fullWidth: fullWidth != null ? fullWidth : true,
    helperText: getHelperOrErrorText(),
    error: error != null,
    InputLabelProps: {
      required: rules && rules.required != null,
    },
    ...restOfRest,
  };

  return (
    <div css={appTextInputWrapper}>
      {upperHelperText != null && (
        <span css={appTextInputUpperHint}>{upperHelperText}</span>
      )}
      <Controller
        name={name}
        rules={rules}
        defaultValue={restProps.defaultValue}
        render={({ field }) => (
          <TextField {...field} {...customProps} css={appTextInput} />
        )}
      />
    </div>
  );
};

const appTextInput = css`
  .MuiInputBase-root {
    background: white;
    border-radius: 4px;
  }
  ${formMargin}
`;

const appTextInputWrapper = css`
  position: relative;
  width: inherit;
`;

const appTextInputUpperHint = css`
  position: absolute;
  right: 0;
  top: -12px;
`;

type AppFormSubmitProps = {
  text: string;
  disabledOnError?: boolean;
  loading?: boolean;
  width?: number;
  height?: number;
  name?: string;
  value?: string;
} & ButtonProps;

export const AppFormSubmit: React.FC<AppFormSubmitProps> = ({
  text,
  variant,
  color,
  fullWidth,
  size,
  loading,
  width,
  height,
  name,
  value,
  disabled,
  ...restProps
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const allProps: ButtonProps = {
    variant: variant || 'contained',
    color: color || 'secondary',
    size: size || 'large',
    fullWidth: fullWidth != null ? fullWidth : true,
    disabled:
      loading || JSON.stringify(errors) !== JSON.stringify({}) || disabled,
  };
  React.useEffect(() => {
    if (name) {
      register(name);
    }
  }, [name, register]);

  return (
    <div css={submitBtnWrapper}>
      <Button
        onClick={(): void => {
          if (name && value) {
            setValue(name, value);
          }
        }}
        css={submitBtnCss(width, height)}
        type="submit"
        value={value || ''}
        name={name || ''}
        {...restProps}
        {...allProps}
      >
        <Trans id={text} />
      </Button>
      {loading && <CircularProgress size={24} css={submitBtnProgress} />}
    </div>
  );
};

const submitBtnWrapper = css`
  position: relative;
  margin: 20px 0 10px;
  width: inherit;
  text-align: center;
`;
const submitBtnCss = (
  width: number | undefined,
  height: number | undefined,
): SerializedStyles => css`
  height: ${height}px !important;
  width: ${width}px !important;
`;
const submitBtnProgress = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

export const AppFormVertical: React.FC = props => {
  const { children } = props;
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      {children}
    </Grid>
  );
};

interface AppFormRequestErrorProps {
  // eslint-disable-next-line react/require-default-props
  text?: string;
}

export const AppFormRequestError: React.FC<AppFormRequestErrorProps> = ({
  text,
}: AppFormRequestErrorProps) => {
  if (!text) {
    return null;
  }
  return <Result status="error" title={text} />;
};

export interface AppSelectInputProps {
  name: string;
  // eslint-disable-next-line
  value?: any;
  options: Array<{ value: string | number; text: string }>;
  label: ReactNode;
  rules?: RegisterOptions;
}

export const AppSelectInput: React.FC<AppSelectInputProps> = ({
  name,
  value = '',
  options,
  label,
  rules,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();
  const [selected, setSelected] = useState(value);

  const error = errors[name];
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setSelected(event.target.value);
    setValue(name, event.target.value);
    trigger(name);
  };

  const selectUuid = uuid();

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect((): void => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  React.useEffect(() => {
    if (rules) {
      register(name, rules);
    } else {
      register(name);
    }
  }, [register, name, rules]);

  React.useEffect(() => {
    if (selected) {
      setValue(name, selected, { shouldValidate: true });
    }
  }, [selected, name, setValue]);

  return (
    <FormControl
      variant="outlined"
      css={selectWrapperCss}
      error={!!error}
      margin="dense"
    >
      <InputLabel
        ref={inputLabel}
        id={selectUuid}
        margin="dense"
        required={rules && rules.required != null}
      >
        {label}
      </InputLabel>
      <Select
        error={error != null}
        inputProps={{ required: rules && rules.required != null }}
        labelId={selectUuid}
        css={selectCss}
        variant="outlined"
        autoWidth
        multiple={false}
        value={selected}
        onChange={handleChange}
        labelWidth={labelWidth}
        margin="dense"
        name={name}
        onClose={(): void => {
          trigger(name);
        }}
      >
        {options.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};

const selectWrapperCss = css`
  width: 100%;
  margin-bottom: 25px !important;
`;

const selectCss = css`
  width: 100%;

  .MuiOutlinedInput-input {
    background: ${COLORS.contrast};
  }
`;
