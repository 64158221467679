import React from 'react';
import { SectionType } from '../../../../interfaces/enums';
import { TrainingSectionPartsDTO } from '../../../../strapi/TrainingDTO';
import TrainingDetailItem from './TrainingDetailItem';

export interface TrainingDetailSingleWrapper {
  part: TrainingSectionPartsDTO;
  orderOfExercise: number;
  sectionId: string;
}

const TrainingDetailSingleWrapper: React.FC<TrainingDetailSingleWrapper> = ({
  part,
  orderOfExercise,
  sectionId,
}) => (
  <div>
    {part.training_exercises.map(exercise => (
      <TrainingDetailItem
        title={exercise.exercise.title.text}
        orderOfExercise={orderOfExercise}
        key={exercise.id}
        trainingSection={part}
        sectionId={sectionId}
        partId={part.id}
        exerciseId={exercise.exercise.id}
        trainingExerciseId={exercise.id}
        sectionType={part.type}
        type={SectionType.SECTION_PART}
      />
    ))}
  </div>
);

export default TrainingDetailSingleWrapper;
