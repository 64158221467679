/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { format, parseISO } from 'date-fns';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-zoom';
import { COLORS } from '../../../styles/themes';
import { WeightProgressDTO } from '../../../strapi/WeightProgressDTO';

interface Props {
  weightProgress: WeightProgressDTO[];
}

export const WeightChart: FC<Props> = ({ weightProgress }) => {
  const progressForTable = weightProgress.map((item: any) => ({
    y: item.weight,
    x: parseISO(item.createdAt),
  }));

  const data = {
    labels: progressForTable.map((i: any) => format(i.x, 'MM/yyyy')),
    datasets: [
      {
        fill: false,
        lineTension: 0.5,
        backgroundColor: COLORS.lightDarkBlue,
        borderColor: COLORS.lightDarkBlue,
        borderWidth: 2,
        data: progressForTable,
      },
    ],
  };

  const tooltips: any = {
    callbacks: {
      title: (tooltipItem: any): string => `${tooltipItem[0].value}kg`,
      label: (tooltipItem: any, data: any): string =>
        format(data.datasets[0].data[tooltipItem.index].x, 'dd.MM. yyyy'),
    },
    backgroundColor: COLORS.scheduleInputBg,
    displayColors: false,
  };

  const plugins: any = {
    zoom: {
      enabled: true,
      mode: 'x',
    },
    title: { display: false },
    legend: { display: false },
    tooltips,
    pan: {
      enabled: true,
      mode: 'x',
      speed: 10,
      threshold: 10,
      rangeMin: {
        x: null,
        y: null,
      },
      rangeMax: {
        x: null,
        y: null,
      },
    },
  };

  return (
    <Line
      type={Line}
      // @ts-ignore
      data={data}
      options={{
        responsive: true,

        plugins,
      }}
    />
  );
};
