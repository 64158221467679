/* eslint-disable no-console */
/** @jsx jsx */
import React, { lazy, Suspense } from 'react';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';

import { ThemeProvider } from '@material-ui/styles';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Global, css, jsx } from '@emotion/react';
import * as plurals from 'make-plural/plurals';
import { SnackbarProvider } from 'notistack';
import { Capacitor } from '@capacitor/core';
import { App as CapApp } from '@capacitor/app';
import { APP_THEME, COLORS } from './styles/themes';
import { changeUiLanguage } from './store/general/actions';
import { UserDTO } from './strapi/UserDTO';
import { AppLanguages } from './code/constants/languages';
import catalogCs from './locales/cs/messages';
import catalogEn from './locales/en/messages';
import { CenteredSpinner } from './components/shared/Loader';
import { routePathBuilder, ApplicationPath, PUBLIC_APP_ROUTES } from './routes';
import { refreshUserFromCookie } from './store/auth/actions';
import { fetchTrainingPlans } from './store/trainingPlans/actions';
import ScrollToTop from './code/helpers/scrollToTop';
import { registerNotifications } from './code/helpers/notifications';

const HomeContainer = lazy(() => import('./components/home/HomeContainer'));

export interface StateProps {
  loggedUser: UserDTO | undefined;
  language: AppLanguages;
  userRefreshedSuccess: boolean;
  userRefreshedFail: string | undefined;
  isCircularRunning: boolean;
}

export interface DispatchProps {
  changeUiLanguage: typeof changeUiLanguage;
  refreshUserFromCookie: typeof refreshUserFromCookie;
  fetchTrainingPlans: typeof fetchTrainingPlans;
}

type AppProps = DispatchProps & StateProps;

interface AppState {
  refreshUserDone: boolean;
}

const renderGlobalCssSettings = (): JSX.Element => (
  <Global
    styles={css`
      html {
        box-sizing: border-box !important;
        margin: 0 !important;
        padding: 0 !important;
        overflow-x: hidden;
        min-height: 100vh;
      }
      body {
        min-height: 100vh;
        overflow-x: hidden;
        background-color: ${COLORS.bg};
        display: flex;
        font-family: 'Rubik', sans-serif;
      }
    `}
  />
);

const catalogs: { [key in AppLanguages]: unknown } = {
  cs: catalogCs,
  en: catalogEn,
};

// Signed-in user context
export const UserContext = React.createContext<UserDTO | undefined>(undefined);

export class App extends React.Component<AppProps, AppState> {
  public state: AppState = {
    refreshUserDone: false,
  };

  public render(): JSX.Element {
    const { loggedUser } = this.props;

    return (
      <I18nProvider i18n={i18n}>
        <Suspense fallback={this.renderLoading('Aplikace se načítá...')}>
          <ThemeProvider theme={APP_THEME}>
            <UserContext.Provider value={loggedUser}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <div css={contentWrapper}>
                  {renderGlobalCssSettings()}
                  {this.renderRouter()}
                </div>
              </SnackbarProvider>
            </UserContext.Provider>
          </ThemeProvider>
        </Suspense>
      </I18nProvider>
    );
  }

  public componentDidMount(): void {
    const { refreshUserFromCookie, fetchTrainingPlans, language } = this.props;
    refreshUserFromCookie();
    fetchTrainingPlans();
    i18n.load(AppLanguages.EN, catalogs.en);
    i18n.load(AppLanguages.CS, catalogs.cs);
    i18n.loadLocaleData(AppLanguages.CS, {
      plurals: plurals.cs,
    });
    i18n.loadLocaleData(AppLanguages.EN, {
      plurals: plurals.en,
    });
    i18n.activate(language);

    if (Capacitor.isNativePlatform()) {
      CapApp.addListener('backButton', ({ canGoBack }) => {
        if (this.props.isCircularRunning) {
          return;
        }

        if (!canGoBack) {
          CapApp.exitApp();
        } else {
          window.history.back();
        }
      });
    }
  }

  componentWillUnmount(): void {
    if (Capacitor.isNativePlatform()) {
      CapApp.removeAllListeners();
    }
  }

  public componentDidUpdate(prevProps: typeof this.props): void {
    const { loggedUser, userRefreshedSuccess, userRefreshedFail } = this.props;

    if (
      Capacitor.isNativePlatform() &&
      loggedUser &&
      prevProps.loggedUser !== loggedUser
    ) {
      registerNotifications(loggedUser);
    }

    const { refreshUserDone } = this.state;
    if (refreshUserDone) {
      return;
    }
    if ((userRefreshedSuccess && loggedUser != null) || userRefreshedFail) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ refreshUserDone: true });
    }
  }

  private renderRouter(): JSX.Element {
    const { loggedUser } = this.props;
    const { refreshUserDone } = this.state;

    if (!refreshUserDone) {
      return this.renderLoading('Načítají se data...');
    }

    let routes = this.renderUnloggedRoutes();

    if (loggedUser != null) {
      routes = this.renderLoggedRoutes();
    }
    return (
      <Router>
        <ScrollToTop />
        {routes}
      </Router>
    );
  }

  private renderLoggedRoutes = (): JSX.Element => (
    <Switch>
      <Route
        path={routePathBuilder(ApplicationPath.Home)}
        component={HomeContainer}
      />
      <Redirect to={routePathBuilder(ApplicationPath.Home)} />
    </Switch>
  );

  private renderUnloggedRoutes = (): JSX.Element => (
    <Switch>
      {PUBLIC_APP_ROUTES.map(route => (
        <Route key={route.path} path={route.path} {...route.props} />
      ))}
      <Redirect to={routePathBuilder(ApplicationPath.Login)} />
    </Switch>
  );

  private renderLoading = (text: string) => <CenteredSpinner message={text} />;
}

const contentWrapper = css`
  height: 100% !important;
`;
