/** @jsx jsx */
import { FC, useState, MouseEvent } from 'react';
import { jsx } from '@emotion/react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableCellProps,
  TableSortLabel,
} from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { TrainingPlanTableBody } from './TrainingPlanTableBody';
import { LocalTrainingPlanDTO } from '../../../../../strapi/TrainingPlanDTO';
import { Order } from '../../../../../code/helpers/tables';
import { performRemoveTrainingPlan } from '../../../../../store/trainingPlans/actions';

export interface TrainingPlanTableProps {
  trainingPlans: LocalTrainingPlanDTO[];
  removeTrainingPlan: typeof performRemoveTrainingPlan;
}

interface HeadCell {
  id: keyof LocalTrainingPlanDTO;
  align: TableCellProps['align'];
  label: JSX.Element;
}

const headCells: HeadCell[] = [
  { id: 'title', align: 'left', label: <Trans>Název</Trans> },
  { id: 'trainingsCount', align: 'left', label: <Trans>Počet tréninků</Trans> },
  { id: 'state', align: 'left', label: <Trans>Stav</Trans> },
  { id: 'firstTraining', align: 'left', label: <Trans>První trénink</Trans> },
  { id: 'lastTraining', align: 'left', label: <Trans>Poslední trénink</Trans> },
];

export const TrainingPlanTable: FC<TrainingPlanTableProps> = ({
  trainingPlans,
  removeTrainingPlan,
}) => {
  const [orderBy, setOrderBy] = useState<keyof LocalTrainingPlanDTO>('title');
  const [order, setOrder] = useState<Order>('asc');

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof LocalTrainingPlanDTO,
  ): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div>
      <Table aria-label="training plans table" size="small">
        <TableHead>
          <TableRow>
            {headCells.map(hc => (
              <TableCell
                key={hc.id}
                align={hc.align}
                sortDirection={orderBy === hc.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === hc.id}
                  direction={orderBy === hc.id ? order : 'asc'}
                  onClick={(e): void => handleRequestSort(e, hc.id)}
                >
                  {hc.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TrainingPlanTableBody
          trainingPlans={trainingPlans}
          order={order}
          orderBy={orderBy}
          removeTrainingPlan={removeTrainingPlan}
        />
      </Table>
    </div>
  );
};
