/** @jsx jsx */
import React from 'react';
import { useSelector } from 'react-redux';
import { jsx, css, SerializedStyles } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Grid } from '@material-ui/core';
import { COLORS } from '../../../styles/themes';
import { getCircularRunning } from '../../../store/general/selectors';

interface TextWithInformationIconProps {
  title: string;
  link: string;
  orderOfExercise?: number;
}

const TextWithInformationIcon: React.FC<TextWithInformationIconProps> = ({
  title,
  link,
  orderOfExercise,
}) => {
  const history = useHistory();
  const isCircuitRunning = useSelector(getCircularRunning);

  const routeToDetail = () => {
    if (!isCircuitRunning) {
      history.push(link);
    }
  };
  return (
    <Grid container css={gridTitleRow}>
      <Grid item xs={8} css={titleCss}>
        {orderOfExercise && `${orderOfExercise.toString()}. `}
        {title}
      </Grid>
      <Grid item xs={4}>
        <Grid container justify="flex-end">
          <InfoOutlinedIcon
            css={iconCss(isCircuitRunning)}
            onClick={routeToDetail}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TextWithInformationIcon;

const gridTitleRow = css`
  padding-top: 2vh;
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const titleCss = css`
  color: ${COLORS.white};
  width: 60vw;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-size: x-large;
`;

const iconCss = (isCircuitRunning: boolean): SerializedStyles => css`
  color: ${isCircuitRunning ? COLORS.gray : COLORS.baseOrange} !important;
`;
