/** @jsx jsx */
import { useEffect, useState, useCallback, FC } from 'react';
import { jsx, css } from '@emotion/react';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useImmer } from 'use-immer';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import { ProgressItem } from './ProgressItem';
import { ModalWindow } from '../../shared/ModalWindow';
import { AppTextInput } from '../../shared/Forms';
import {
  decimalNumberRule,
  requiredRule,
} from '../../shared/helpers/FormRules';
import { OperationEnum } from '../../../enums/OperationEnum';
import { MeasuresTable } from './MeasuresTable';
import {
  performFetchProgress,
  performPatchMeasuresProgress,
  performAddMeasuresProgress,
} from '../../../store/progress/actions';
import { MeasureEditRow } from './MeasureEditRow';
import {
  MeasureProgressDTO,
  MeasureValues,
  CreateMeasureProgressDTO,
} from '../../../strapi/MeasuresProgressDTO';

interface Props {
  userId: string;
  measureProgress: MeasureProgressDTO[];
  isClient: boolean;
}

export const MeasuresWrapper: FC<Props> = ({
  userId,
  measureProgress,
  isClient,
}) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [measureData, setMeasureData] = useImmer(measureProgress);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setMeasureData(() => measureProgress);
  }, [measureProgress, setMeasureData]);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const openEditModal = useCallback(() => {
    setIsEditModalOpen(true);
  }, []);

  const onSubmit = (e: MeasureValues) => {
    const dataToSave: CreateMeasureProgressDTO = {
      user: userId,
      waist: e.waist,
      hips: e.hips,
      chest: e.chest,
    };
    dispatch(
      performAddMeasuresProgress(dataToSave, () => {
        setIsModalOpen(false);
        dispatch(performFetchProgress(userId));
      }),
    );
  };

  const onEditSubmit = () => {
    dispatch(
      performPatchMeasuresProgress(measureData, () => {
        setIsEditModalOpen(false);
        dispatch(performFetchProgress(userId));
      }),
    );
  };

  const editWeight = (id: string, { waist, hips, chest }: MeasureValues) => {
    const rowIndex = measureData.findIndex((item: any) => item.id === id);
    if (rowIndex !== -1) {
      setMeasureData(draft => {
        draft[rowIndex].waist = waist;
        draft[rowIndex].hips = hips;
        draft[rowIndex].chest = chest;
        draft[rowIndex].operation = OperationEnum.UPDATED;
      });
    }
  };

  const deleteWeight = (id: string) => {
    const rowIndex = measureData.findIndex((item: any) => item.id === id);
    if (rowIndex !== -1) {
      setMeasureData(draft => {
        draft[rowIndex].operation = OperationEnum.DELETED;
      });
    }
  };

  let chest;
  let hips;
  let waist;

  if (measureData.length > 0) {
    chest = measureData[0].chest;
    hips = measureData[0].hips;
    waist = measureData[0].waist;
  }

  return (
    <ProgressItem
      title={<Trans>Míry</Trans>}
      titleAdd={<Trans>Přidat míry</Trans>}
      numberOfInputs={measureProgress.length}
      handleAdd={openModal}
      handleEdit={openEditModal}
      canAdd={!isClient}
      canModify={!isClient}
    >
      <ModalWindow
        headerText={<Trans>Nové měření</Trans>}
        isOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        agreeButtonText={<Trans>Přidat míry</Trans>}
        onSubmit={onSubmit}
        closeIcon
        disableSubmitBtnOnError
        fullScreen={isMobile}
        mobileFooter
      >
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <AppTextInput
              label="Hrudník"
              type="number"
              variant="outlined"
              name="chest"
              inputProps={{ step: 0.1 }}
              rules={{ pattern: decimalNumberRule, ...requiredRule }}
              defaultValue={chest}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <AppTextInput
              label="Pas"
              type="number"
              variant="outlined"
              name="waist"
              inputProps={{ step: 0.1 }}
              rules={{ pattern: decimalNumberRule, ...requiredRule }}
              defaultValue={waist}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <AppTextInput
              type="number"
              label="Boky"
              variant="outlined"
              name="hips"
              inputProps={{ step: 0.1 }}
              rules={{ pattern: decimalNumberRule, ...requiredRule }}
              defaultValue={hips}
            />
          </Grid>
        </Grid>
      </ModalWindow>

      <ModalWindow
        headerText={<Trans>Upravit míry</Trans>}
        isOpen={isEditModalOpen}
        handleClose={() => {
          setIsEditModalOpen(false);
          setMeasureData(() => measureProgress);
        }}
        agreeButtonText={<Trans>Uložit</Trans>}
        onSubmit={onEditSubmit}
        cancelButtonText={<Trans>Zrušit</Trans>}
        closeIcon
        disableSubmitBtnOnError
        fullScreen={isMobile}
        mobileFooter
      >
        <div css={wrapperCss}>
          {measureData.filter(
            (item: any) => item.operation !== OperationEnum.DELETED,
          ).length > 0 ? (
            <div>
              {measureData
                .filter((item: any) => item.operation !== OperationEnum.DELETED)
                .map((item: any) => (
                  <MeasureEditRow
                    item={item}
                    key={item.id}
                    deleteWeight={deleteWeight}
                    editWeight={editWeight}
                  />
                ))}
            </div>
          ) : (
            <Typography>
              <Trans>Žádné záznamy nenalezeny.</Trans>
            </Typography>
          )}
        </div>
      </ModalWindow>

      {measureProgress.length > 0 ? (
        <div>
          <MeasuresTable measureProgress={measureProgress} />
        </div>
      ) : (
        <Typography>
          <Trans>Žádné záznamy nenalezeny.</Trans>
        </Typography>
      )}
    </ProgressItem>
  );
};

const wrapperCss = css`
  max-height: 80vh;
`;
