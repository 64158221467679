/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useRef, useEffect, FC } from 'react';
import { parseISO, format } from 'date-fns';
import { MeasureProgressDTO } from '../../../strapi/MeasuresProgressDTO';
import { DATE_DAY_MONTH_YEAR } from '../../../code/constants/date';
import { MPX } from '../../../styles/themes';

interface Props {
  measureProgress: MeasureProgressDTO[];
}

export const MeasuresTable: FC<Props> = ({ measureProgress }) => {
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (tableWrapperRef.current && tableRef.current) {
      tableWrapperRef.current.scrollLeft = tableRef.current.clientWidth;
    }
  }, [tableWrapperRef]);

  return (
    <div css={tableWrapper(isMobile)} ref={tableWrapperRef}>
      <table css={tableCss} ref={tableRef}>
        <thead>
          <tr>
            <th css={[textLeftCss, tableFixedLeftColCss]}>Partie</th>
            {measureProgress &&
              measureProgress.map(item => (
                <th key={item.id}>
                  {format(parseISO(item.createdAt), DATE_DAY_MONTH_YEAR)}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td css={[textLeftCss, tableFixedLeftColCss]}>Hrudník</td>
            {measureProgress &&
              measureProgress.map(item => (
                <td key={item.id}>{`${item.chest} cm`}</td>
              ))}
          </tr>

          <tr>
            <td css={[textLeftCss, tableFixedLeftColCss]}>Pas</td>
            {measureProgress &&
              measureProgress.map(item => (
                <td key={item.id}>{`${item.waist} cm`}</td>
              ))}
          </tr>
          <tr>
            <td css={[textLeftCss, tableFixedLeftColCss]}>Boky</td>
            {measureProgress &&
              measureProgress.map(item => (
                <td key={item.id}>{`${item.hips} cm`}</td>
              ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const tableWrapper = (isMobile: boolean): SerializedStyles => css`
  overflow-x: auto;
  max-width: ${isMobile ? '' : `calc(100vw - ${77.5 * MPX}px)`};
  position: relative;
`;

const tableCss = css`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  td,
  th {
    width: ${27.5 * MPX}px;
    text-align: center;
    color: #586a7e;
  }

  thead th {
    background: #eaeff2;
    padding: ${3.5 * MPX}px ${5 * MPX}px;
    color: #8897a4;
    font-size: 12px;
  }

  thead th:nth-of-type(1) {
    background: linear-gradient(
      to right,
      #eaeff2 70%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  tbody {
    border-radius: ${MPX}px;
    overflow: hidden;
  }

  tbody tr {
    background: #fff;
  }

  tbody td {
    background: #fff;
    padding: ${3.5 * MPX}px ${5 * MPX}px;
  }

  tbody td:nth-of-type(1) {
    background: linear-gradient(
      to right,
      white 70%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  tbody tr:nth-of-type(2n) {
    background: #f5f7f8;
    td {
      background: #f5f7f8;
      background: linear-gradient(
        to right,
        #f5f7f8 70%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
`;

const tableFixedLeftColCss = css`
  position: sticky;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
`;

const textLeftCss = css`
  text-align: left !important;
`;
