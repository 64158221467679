/** @jsx jsx */
import React from 'react';
import { Divider } from '@material-ui/core';
import { jsx, css } from '@emotion/react';
import { AppTitlePrimary } from '../../shared/Typo';
import PasswordSettingsContainer from '../../accountSettings/comps/PasswordSettingsContainer';
import { MPX } from '../../../styles/themes';
import { DangerZone } from '../../accountSettings/comps/DangerZone';

export const Password: React.FC = () => {
  return (
    <div>
      <div css={headerCss}>
        <AppTitlePrimary title="Nastavení" alignLeft />
      </div>
      <PasswordSettingsContainer />

      <Divider variant="middle" style={{ margin: '30px 0' }} />
      <DangerZone />
    </div>
  );
};

const headerCss = css`
  background: #fff;
  text-align: center;
  padding-top: ${`${2 * MPX}px`};
  padding-left: ${`${2 * MPX}px`};
`;
