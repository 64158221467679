/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { Typography } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { COLORS } from '../../styles/themes';

interface AppTitleProps {
  title: string;
  alignLeft?: boolean;
}

export const AppTitlePrimary: React.FC<AppTitleProps> = ({
  title,
  alignLeft,
}) => (
  <Typography
    variant="h5"
    css={alignLeft ? appTitleMobile : appTitle}
    color="textPrimary"
  >
    <Trans id={title} />
  </Typography>
);

const appTitle = css`
  margin-bottom: 30px !important;
`;

const appTitleMobile = css`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px !important;
  line-height: 48px !important;
`;

interface AppTextDividerProps {
  text: string;
  width?: number;
}

export const AppTextDivider: React.FC<AppTextDividerProps> = ({
  text,
  width,
}) => (
  <div css={dividerWrapper}>
    <div
      css={dividerWrapper2}
      style={{ width: `${width ? `${width}px` : '200px'}` }}
    >
      <span css={dividerContent}>
        <Trans id={text} />
      </span>
    </div>
  </div>
);

const dividerWrapper = css`
  display: block;
  text-align: center;
  margin: 15px 0;
  display: flex;
  justify-content: center;
`;

const dividerWrapper2 = css`
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
`;

const dividerContent = css`
  position: relative;
  display: inline-block;
  color: ${COLORS.gray4};

  &:before {
    right: 100%;
    margin-right: 15px;
  }

  &:after {
    left: 100%;
    margin-left: 15px;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: ${COLORS.gray4};
  }
`;

export const CaptionHint: React.FC = props => {
  const { children } = props;
  return (
    <div css={captionHint}>
      <Typography variant="caption">{children}</Typography>
    </div>
  );
};

const captionHint = css`
  margin: 20px 0px;
  color: ${COLORS.gray5};
`;
