/** @jsx jsx */
import { Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { css, jsx } from '@emotion/react';
import { RS } from '../../interfaces/enums';

interface SpinnerProps {
  message?: string;
}

export const Spinner: React.FC = () => (
  <Grid container align-items="center" justify="center">
    <Grid item>
      <CircularProgress color="primary" />
    </Grid>
  </Grid>
);

export const CenteredSpinner: React.FC<SpinnerProps> = ({ message }) => (
  <div css={loadingAppCss}>
    <Spinner />
    <Typography>
      <Trans id={message} />
    </Typography>
  </div>
);

interface LoaderProps {
  requestState: RS;
  children: JSX.Element;
}

export const Loader: React.SFC<LoaderProps> = ({
  requestState,
  children,
}: LoaderProps) => (
  <div>
    {requestState === RS.Success && children}
    {requestState === RS.Loading && <Spinner />}
    {requestState === RS.Failure && <div> error </div>}
  </div>
);

const loadingAppCss = css`
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;
