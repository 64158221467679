import axios from 'axios';

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const setAuthToken = (token: string): void => {
  http.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeAuthHeader = (): void => {
  delete http.defaults.headers.common.Authorization;
};
