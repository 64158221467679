/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Trans } from '@lingui/macro';
import React from 'react';
import { Sound, useAnySound } from '../../../../../code/helpers/sounds';
import { TrainingExerciseDTO } from '../../../../../strapi/TrainingDTO';
import { COLORS, MPX } from '../../../../../styles/themes';
import { getImagesFromExerciseForCarousel } from '../../../helper/functions';
import ImageCarousel from '../../../shared/ImageCarousel';
import TextWithInformationIcon from '../../../shared/TextWithInformationIcon';
import TrainingDetailDivider from '../headerTrainingComponents/TrainingDetailDivider';
import CommentTrainingDescriptionDetail from '../singleExerciseDescriptionComponents/CommentTrainingDescriptionDetail';
import ProgressBar from '../singleExerciseDescriptionComponents/ProgressBar';

interface RunningCircuitExercise {
  exercise: TrainingExerciseDTO;
  link: string;
  pauseTotal: number;
  timeTotal: number;
  elapsedTime: number;
  isPause: boolean;
  currentWeight: number;
}

const RunningCircuitExercise: React.FC<RunningCircuitExercise> = ({
  exercise,
  link,
  pauseTotal,
  timeTotal,
  elapsedTime,
  isPause,
  currentWeight,
}) => {
  const image = getImagesFromExerciseForCarousel([exercise], [link]);

  const { play } = useAnySound();

  if (!isPause && elapsedTime === 0) {
    play(Sound.go);
  }

  return (
    <div>
      <ImageCarousel images={image} />
      <div css={titleWrapperCss}>
        <TextWithInformationIcon
          title={exercise.exercise.title.text}
          link={link}
        />
        {Boolean(currentWeight) && (
          <div>
            <div css={weightCss}>
              <Trans>Váha</Trans>
            </div>
            <div css={weightDataCss}>{`${currentWeight}kg`}</div>
          </div>
        )}
      </div>
      <TrainingDetailDivider />
      <div css={titleWrapperCss}>
        <div css={topWrapperCss}>
          <ProgressBar
            durationTotal={isPause ? pauseTotal : 0}
            durationElapsed={isPause ? elapsedTime : 0}
            isElapsed={!isPause}
            isPause
            noGo
          />
        </div>
        <ProgressBar
          durationTotal={timeTotal}
          durationElapsed={!isPause ? elapsedTime : 0}
          isLength
        />
      </div>
      <div css={commentWrapperCss}>
        <CommentTrainingDescriptionDetail comment={exercise.comment} />
      </div>
    </div>
  );
};

export default RunningCircuitExercise;

const titleWrapperCss = css`
  padding-left: 2vh;
  padding-right: 2vh;
`;

const weightCss = css`
  color: ${COLORS.gray4};
  font-size: large;
  padding-top: ${MPX * 0.5}vh;
`;

const weightDataCss = css`
  color: ${COLORS.white};
  font-size: x-large;
  padding-top: 2vh;
`;

const commentWrapperCss = css`
  padding-left: 1vh;
  padding-right: 1vh;
  box-sizing: border-box;
  margin-top: ${MPX * 4}px;
`;

const topWrapperCss = css`
  margin-bottom: ${MPX * 4}px;
`;
