/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { t } from '@lingui/macro';
import { css, jsx } from '@emotion/react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import TitleAndDescription from './components/TitleAndDescription';
import { MPX } from '../../../styles/themes';
import { HeadingWithBackButton } from '../shared/HeadingWithBackButton';
import { getLoggedUser } from '../../../store/auth/selectors';
import { getEventById } from '../../../store/calendar/selectors';
import { performFetchTrainerEventsByClientId } from '../../../store/calendar/actions';
import { ApplicationPath, ClientPath, routePathBuilder } from '../../../routes';

const EventPreview: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [fetched, setFetched] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { eventId } = useParams<{ eventId: string }>();
  const event = useSelector(getEventById(eventId));
  const user = useSelector(getLoggedUser);

  useEffect(() => {
    if (((!event && !fetched) || (event && event.id !== eventId)) && user) {
      dispatch(performFetchTrainerEventsByClientId(user.id, [], setFetched));
    }
  }, [dispatch, event, eventId, fetched, user]);

  if (!event || !isMobile) {
    return null;
  }

  return (
    <div>
      <HeadingWithBackButton
        isLight
        shouldWrap
        title={event.title}
        link={routePathBuilder([ApplicationPath.Client, ClientPath.Schedule])}
      />

      <div css={wrapperCss}>
        <div css={titleWrapperCss}>
          <TitleAndDescription
            title={t`Datum`}
            description={
              event.start ? format(new Date(event.start), 'dd.MM.yyyy') : ''
            }
          />
        </div>
        <div css={titleWrapperCss}>
          <TitleAndDescription
            title={t`Čas`}
            description={`${
              event.start ? format(new Date(event.start), 'HH:mm') : ''
            } - ${event.end ? format(new Date(event.end), 'HH:mm') : ''}`}
          />
        </div>
        <div css={titleWrapperCss}>
          <TitleAndDescription
            title={t`Místo`}
            description={event.location || ''}
            hide={!event.location}
          />
        </div>
      </div>
    </div>
  );
};

export default EventPreview;

const wrapperCss = css`
  padding-left: 2vh;
  padding-right: 2vh;
  margin-bottom: ${4 * MPX}px;
`;

const titleWrapperCss = css`
  margin-top: ${MPX * 4}px;
`;
