import { RegisterOptions } from 'react-hook-form';

// desetinne cislo s max dvema misty oddelene . nebo ,
export const decimalNumberRule = {
  value: /^-?(?:\d+|\d{1,2}(?:,\d{2})+)(?:(\.|,)(\d{1,2}))?$/,
  message: `Desetinné číslo s max dvěma místy`,
};

export const numberSeparatedBySemicolon = {
  value: /^([1-9][0-9]+[;]?|[1-9]+[;]?)*$/,
  message: `Celé číslo oddělené ;`,
};

export const decimalNumberSeparated = {
  value:
    /^((-?(?:\d+|\d{1,2}(?:,\d{2})+)(?:(\.|,)(\d{1,2}))?))+(;(-?(?:\d+|\d{1,2}(?:,\d{2})+)(?:(\.|,)(\d{1,2}))?))*$/,
  message: `Desetinné číslo s max dvěma místy oddělené ;`,
};
export const requiredRule: RegisterOptions = {
  pattern: {
    value: /([^\s])/,
    message: `Tato položka je povinná.`,
  },
  // value dont work with undefined - this is why required is also used
  required: `Tato položka je povinná.`,
};

export const emailRule: RegisterOptions = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: 'Emailová adresa je v nesprávném tvaru.',
  },
};

export const numberRule: RegisterOptions = {
  pattern: {
    value: /^[-+]?\d*\.?\d*$/i,
    message: 'Hodnota není číslo.',
  },
};

const PASSWORD_LENGTH = 8;

export const PASSWORD_HINT = `Heslo musí obsahovat alespoň ${PASSWORD_LENGTH} znaků, kombinaci malých a velkých písmen a čísel`;

export const passwordRules: ValidationOptions = {
  minLength: {
    value: PASSWORD_LENGTH,
    message: PASSWORD_HINT,
  },
  pattern: {
    // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
    value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*/,
    message:
      'Heslo musí obsahovat minimálne 8 znaků a musí obsahovat alespoň jedno velké písmeno, jedno malé písmeno a alespoň jedno číslo.',
  },
};

export const sameAsRule = (
  fieldName: string,
  watch: any,
): ValidationOptions => ({
  validate: {
    value: (value: string): string | undefined => {
      const otherFieldValue = watch(fieldName);
      return value === otherFieldValue ? undefined : 'Hesla se musí shodovat.';
    },
  },
});

export const notEmptyRule: ValidationOptions = {
  validate: {
    value: (value: string[]): string | undefined =>
      value && value.length > 0 ? undefined : 'Vyberte alespoň jednu položku.',
  },
};
