import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import { intervalToDuration } from 'date-fns';
import { State } from '../combinedReducers';
import { TrainingPartActionTypes as ActionTypes } from '../actionTypes';
import { convertDurationToSeconds } from '../../components/customerApp/helper/functions';

export const pauseRunningTraining = action(ActionTypes.END_TRAINING_PART);

export const setTemporaryTime = action(
  ActionTypes.SET_TEMPORARY_TIME,
  payload<{ time: number }>(),
);

export const removeTemporaryTime = action(ActionTypes.REMOVE_TEMPORARY_TIME);

export const startTrainingFrom = action(
  ActionTypes.START_TRAINING_PART,
  payload<{ trainingRunningFrom: Date }>(),
);

export const pauseChange = action(
  ActionTypes.IS_IN_PAUSE_AFTER_EXERCISE,
  payload<{ isInPause: boolean }>(),
);

export const setTimeToDisplayWhenCallingBE = action(
  ActionTypes.SET_TIME_TO_DISPLAY_WHEN_CALLING_BE,
  payload<{ time: undefined | number }>(),
);

export const setTrainingRunningFor = action(
  ActionTypes.SET_TRAINING_RUNNING_FOR,
  payload<{ trainingRunningFor: number }>(),
);

export const performAddSecondsToRunningFor: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (trainingRunningFrom: number) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    let duration: Duration = {};
    if (trainingRunningFrom) {
      duration = intervalToDuration({
        start: trainingRunningFrom,
        end: new Date(),
      });

      setTimeout(() => {
        dispatch(
          setTrainingRunningFor({
            trainingRunningFor: convertDurationToSeconds(duration),
          }),
        );
      }, 1000);
    }
  };

export const performStartTrainingFrom: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (trainingRunningFrom: Date) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(startTrainingFrom({ trainingRunningFrom }));
  };

export const performIsInPauseAfterExercise: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (isInPause: boolean) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(pauseChange({ isInPause }));
  };
