export enum ExerciseInputsEnum {
  SERIES = 'SERIES',
  PAUSE = 'PAUSE',
  REPEATS = 'REPEATS',
  REPEATS_MODIFIER = 'REPEATS_MODIFIER',
  PAUSE_MODIFIER = 'PAUSE_MODIFIER',
  WEIGHTS = 'WEIGHTS',
  COMMENT = 'COMMENT',
}

interface InputNameState {
  name: ExerciseInputsEnum;
  stateAttr: string;
}

export const inputNameState: InputNameState[] = [
  {
    name: ExerciseInputsEnum.SERIES,
    stateAttr: 'series',
  },
  {
    name: ExerciseInputsEnum.PAUSE,
    stateAttr: 'pause',
  },
  {
    name: ExerciseInputsEnum.REPEATS,
    stateAttr: 'repeats',
  },
  {
    name: ExerciseInputsEnum.REPEATS_MODIFIER,
    stateAttr: 'repeats_modifier',
  },
  {
    name: ExerciseInputsEnum.PAUSE_MODIFIER,
    stateAttr: 'pause_modifier',
  },
  {
    name: ExerciseInputsEnum.WEIGHTS,
    stateAttr: 'weights',
  },
  {
    name: ExerciseInputsEnum.COMMENT,
    stateAttr: 'comment',
  },
];

export const minSecDate = (pause?: number): Date => {
  const dateMinSec = new Date();
  if (pause) {
    const pauseMinutes = Math.floor(pause / 60);
    const pauseSeconds = pause - pauseMinutes * 60;
    dateMinSec.setMinutes(pauseMinutes);
    dateMinSec.setSeconds(pauseSeconds);
  } else {
    dateMinSec.setMinutes(0);
    dateMinSec.setSeconds(0);
  }
  return dateMinSec;
};
