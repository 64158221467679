/** @jsx jsx */
import { FC, useRef, useEffect, useState, useCallback, Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { Delete } from '@material-ui/icons';
import { useImmer } from 'use-immer';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import Lightbox from 'react-image-lightbox';
import { useSnackbar } from 'notistack';

import { ProgressItem } from './ProgressItem';
import { PhotoProgressDTO } from '../../../strapi/PhotoProgressDTO';
import { performUploadMedia } from '../../../store/media/actions';
import {
  performAddImageProgress,
  performFetchProgress,
  performPatchPhotoProgress,
} from '../../../store/progress/actions';
import { ModalWindow } from '../../shared/ModalWindow';
import { OperationEnum } from '../../../enums/OperationEnum';
import { MPX } from '../../../styles/themes';
import 'react-image-lightbox/style.css';
import { Spinner } from '../../shared/Loader';
import { checkUploadedFileSize } from '../../../code/helpers/file';

interface Props {
  userId: string;
  photoProgress: PhotoProgressDTO[];
  isLoadingAddPhoto: boolean;
  clearRequestState: () => void;
  isClient: boolean;
}

export const PhotosWrapper: FC<Props> = ({
  photoProgress,
  userId,
  isLoadingAddPhoto,
  clearRequestState,
  isClient,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [photoData, setPhotoData] = useImmer(photoProgress);
  const [openLightBox, setOpenLightBox] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setPhotoData(() => photoProgress);
  }, [photoProgress, setPhotoData]);

  const dispatch = useDispatch();
  useEffect(
    () => (): void => {
      clearRequestState();
    },
    [clearRequestState],
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const uploadFile = (event: any) => {
    const file = event.target.files[0];

    checkUploadedFileSize(
      file.size,
      () => {
        const formData = new FormData();
        formData.append('files', file);

        dispatch(
          performUploadMedia(formData, (data: any) => {
            dispatch(
              performAddImageProgress(
                {
                  user: userId,
                  photo: data[0].id,
                },
                () => {
                  dispatch(performFetchProgress(userId));
                },
              ),
            );
          }),
        );
      },
      enqueueSnackbar,
    );
  };

  const handleSave = () => {
    dispatch(
      performPatchPhotoProgress(photoData, () => {
        dispatch(performFetchProgress(userId));
        setIsModalOpen(false);
      }),
    );
  };

  const deleteImage = (imageId: string) => {
    const imageIndex = photoData.findIndex(item => item.id === imageId);

    setPhotoData(draft => {
      draft[imageIndex].operation = OperationEnum.DELETED;
    });
  };

  const renderLightBox = () => (
    <Lightbox
      mainSrc={photoData[photoIndex].photo.url}
      nextSrc={photoData[(photoIndex + 1) % photoData.length].photo.url}
      prevSrc={
        photoData[(photoIndex + photoData.length - 1) % photoData.length].photo
          .url
      }
      onCloseRequest={() => setOpenLightBox(false)}
      onMovePrevRequest={() =>
        setPhotoIndex((photoIndex + photoData.length - 1) % photoData.length)
      }
      onMoveNextRequest={() =>
        setPhotoIndex((photoIndex + 1) % photoData.length)
      }
    />
  );
  return (
    <ProgressItem
      title={<Trans>Foto</Trans>}
      titleAdd={<Trans>Nahrát foto</Trans>}
      numberOfInputs={photoProgress.length}
      canAdd
      canModify={!isClient}
      // TODO add functions
      handleAdd={() => {
        if (inputFileRef && inputFileRef.current) {
          inputFileRef.current.click();
        }
      }}
      handleEdit={openModal}
    >
      {isLoadingAddPhoto ? (
        <Spinner />
      ) : (
        <Fragment>
          <input
            css={fileInputCss}
            type="file"
            ref={inputFileRef}
            onChange={uploadFile}
          />
          <ModalWindow
            headerText={<Trans>Upravit foto</Trans>}
            isOpen={isModalOpen}
            handleClose={() => {
              setIsModalOpen(false);
              setPhotoData(() => photoProgress);
            }}
            agreeButtonText={<Trans>Uložit</Trans>}
            cancelButtonText={<Trans>Zrušit</Trans>}
            handleAgree={handleSave}
            closeIcon
            fullScreen={isMobile}
            mobileFooter
          >
            <div css={[imageWrapperCss, wrapperCss]}>
              {photoData.filter(
                (item: PhotoProgressDTO) =>
                  item.operation !== OperationEnum.DELETED,
              ).length > 0 ? (
                <div>
                  {photoData
                    .filter(item => item.operation !== OperationEnum.DELETED)
                    .map(item => (
                      <div css={[imageCss, imageModalCss]} key={item.id}>
                        <div
                          role="button"
                          tabIndex={0}
                          css={imageOverlayCss}
                          onClick={() => {
                            deleteImage(item.id);
                          }}
                          onKeyDown={() => {
                            deleteImage(item.id);
                          }}
                        >
                          <div css={deleteTextCss}>
                            <Delete /> {!isMobile && <div>SMAZAT</div>}
                          </div>
                        </div>
                        <img src={item.photo.url} alt="" />
                      </div>
                    ))}
                </div>
              ) : (
                <Typography>
                  <Trans>Žádné záznamy nenalezeny.</Trans>
                </Typography>
              )}
            </div>
          </ModalWindow>
          {openLightBox && renderLightBox()}
          {photoProgress.length > 0 ? (
            <div css={imageWrapperCss}>
              {photoProgress.map((item, index) => (
                <div
                  role="button"
                  tabIndex={0}
                  css={imageCss}
                  key={item.id}
                  onClick={() => {
                    setPhotoIndex(index);
                    setOpenLightBox(true);
                  }}
                  onKeyDown={() => {
                    setPhotoIndex(index);
                    setOpenLightBox(true);
                  }}
                >
                  <img src={item.photo.url} alt="" />
                </div>
              ))}
            </div>
          ) : (
            <Typography>
              <Trans>Žádné záznamy nenalezeny.</Trans>
            </Typography>
          )}
        </Fragment>
      )}
    </ProgressItem>
  );
};

const fileInputCss = css`
  display: none;
`;

const imgHeight = 62.5 * MPX;
const imgMargin = 3.75 * MPX;

const imageWrapperCss = css`
  overflow: auto;
  overflow-y: hidden;
  max-height: ${imgHeight + imgMargin}px !important;
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
`;

const imageCss = css`
  width: ${50 * MPX}px;
  height: ${imgHeight}px;
  margin-right: ${3.75 * MPX}px;
  margin-bottom: ${imgMargin}px;
  border-radius: ${1.25 * MPX}px;
  display: inline-block;

  img {
    width: ${50 * MPX}px;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const wrapperCss = css`
  max-height: 60vh;
  flex-wrap: wrap;
`;

const imageModalCss = css`
  position: relative;
  flex: 0 0 calc((100% - ${7.5 * MPX}px) / 3);
  width: calc((100% - ${7.5 * MPX}px) / 3);
  max-width: calc((100% - ${7.5 * MPX}px) / 3);

  &:nth-of-type(3n) {
    margin-right: 0;
  }
`;

const imageOverlayCss = css`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const deleteTextCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
