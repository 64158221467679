import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import {
  setDocuments,
  deleteDocument,
  updateDocument,
  addDocument,
} from './actions';

import { DocumentDTO } from '../../strapi/DocumentDTO';

export interface State {
  documents: DocumentDTO[];
}

export const initialState: State = {
  documents: [],
};

export const documentsReducer = reducer<State>(
  initialState,
  on(setDocuments, (state: State, { payload }) => {
    state.documents = payload.documents;
  }),
  on(addDocument, (state: State, { payload }) => {
    state.documents.push(payload.document);
  }),
  on(updateDocument, (state: State, { payload: { document } }) => {
    const docIndex = state.documents.findIndex(item => item.id === document.id);
    state.documents[docIndex] = document;
  }),
  on(deleteDocument, (state: State, { payload }) => {
    state.documents = state.documents.filter(item => item.id !== payload.id);
  }),
);
