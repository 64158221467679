/** @jsx jsx */
import { FC, useCallback } from 'react';
import { css, jsx } from '@emotion/react';
import { Grid, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { Delete } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';
import { AppTextInput } from '../../shared/Forms';
import {
  decimalNumberRule,
  requiredRule,
} from '../../shared/helpers/FormRules';
import { DATE_DAY_MONTH_YEAR } from '../../../code/constants/date';
import { MPX } from '../../../styles/themes';

interface Prosp {
  item: any;
  deleteWeight: (id: string) => void;
  editWeight: (id: string, value: number) => void;
}
export const WeightEditRow: FC<Prosp> = ({
  item,
  deleteWeight,
  editWeight,
}) => {
  const { getValues } = useFormContext();

  const deleteRow = useCallback(() => {
    deleteWeight(item.id);
  }, [item.id, deleteWeight]);

  const editRow = () => {
    editWeight(item.id, Number(getValues()[`weight-${item.id}`]));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container alignItems="center">
      <Grid
        item
        container
        xs={12}
        sm={5}
        justify={isMobile ? 'flex-start' : undefined}
      >
        <div css={textCss}>
          {format(parseISO(item.createdAt), DATE_DAY_MONTH_YEAR)}
        </div>
      </Grid>
      <Grid item xs={10} sm={5}>
        <AppTextInput
          type="number"
          label="Váha"
          variant="outlined"
          name={`weight-${item.id}`}
          inputProps={{ step: 0.1 }}
          rules={{ pattern: decimalNumberRule, ...requiredRule }}
          defaultValue={item.weight}
          onBlur={editRow}
        />
      </Grid>
      <Grid item xs={2} sm={2}>
        <div css={textCss}>
          <IconButton aria-label="delete" onClick={deleteRow}>
            <Delete />
          </IconButton>
        </div>
      </Grid>
    </Grid>
  );
};

const textCss = css`
  margin-top: ${2 * MPX}px;
  margin-bottom: ${6.25 * MPX}px;
`;
