/** @jsx jsx */
import { FC, useCallback } from 'react';
import { css, jsx } from '@emotion/react';
import { Grid, IconButton, Hidden } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { Delete } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';
import { AppTextInput } from '../../shared/Forms';
import {
  decimalNumberRule,
  requiredRule,
} from '../../shared/helpers/FormRules';
import { DATE_DAY_MONTH_YEAR } from '../../../code/constants/date';
import { MPX } from '../../../styles/themes';
import { MeasureValues } from '../../../strapi/MeasuresProgressDTO';

interface Prosp {
  item: any;
  deleteWeight: (id: string) => void;
  editWeight: (id: string, value: MeasureValues) => void;
}
export const MeasureEditRow: FC<Prosp> = ({
  item,
  deleteWeight,
  editWeight,
}) => {
  const { getValues } = useFormContext();

  const deleteRow = useCallback(() => {
    deleteWeight(item.id);
  }, [item.id, deleteWeight]);

  const editRow = () => {
    editWeight(item.id, {
      chest: Number(getValues()[`chest-${item.id}`]),
      waist: Number(getValues()[`waist-${item.id}`]),
      hips: Number(getValues()[`hips-${item.id}`]),
    });
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item lg={3} sm={10} xs={10}>
        <div css={textCss}>
          {format(parseISO(item.createdAt), DATE_DAY_MONTH_YEAR)}
        </div>
      </Grid>
      <Hidden only="lg">
        <Grid item sm={2} xs={2}>
          <div css={textCss}>
            <IconButton aria-label="delete" onClick={deleteRow}>
              <Delete />
            </IconButton>
          </div>
        </Grid>
      </Hidden>
      <Grid item lg={2} sm={12} xs={12} md={12}>
        <AppTextInput
          type="number"
          label="Hrudník"
          variant="outlined"
          name={`chest-${item.id}`}
          inputProps={{ step: 0.1 }}
          rules={{ pattern: decimalNumberRule, ...requiredRule }}
          defaultValue={item.chest}
          onBlur={editRow}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={2}>
        <AppTextInput
          type="number"
          label="Pas"
          variant="outlined"
          name={`waist-${item.id}`}
          inputProps={{ step: 0.1 }}
          rules={{ pattern: decimalNumberRule, ...requiredRule }}
          defaultValue={item.waist}
          onBlur={editRow}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={2}>
        <AppTextInput
          type="number"
          label="Boky"
          variant="outlined"
          name={`hips-${item.id}`}
          inputProps={{ step: 0.1 }}
          rules={{ pattern: decimalNumberRule, ...requiredRule }}
          defaultValue={item.hips}
          onBlur={editRow}
        />
      </Grid>
      <Hidden mdDown>
        <Grid item sm={2}>
          <div css={textCss}>
            <IconButton aria-label="delete" onClick={deleteRow}>
              <Delete />
            </IconButton>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
};

const textCss = css`
  margin-top: ${2 * MPX}px;
  margin-bottom: ${6.25 * MPX}px;
  text-align: left;
`;
