/** @jsx jsx */
import { useState, FC, useEffect, ChangeEvent } from 'react';
import { jsx, css, SerializedStyles } from '@emotion/react';
import { t, Trans } from '@lingui/macro';
import { useHistory, useParams } from 'react-router-dom';
import { cs } from 'date-fns/locale';
import {
  IconButton,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  TextField,
  Grid,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useSnackbar } from 'notistack';
import { MPX, COLORS } from '../../../styles/themes';
import { TrainingDTO } from '../../../strapi/TrainingDTO';
import {
  getNameForTraining,
  decideDateTo,
  decideLabel,
} from '../../../code/helpers/training';
import { performSetTrainingDate } from '../../../store/training/actions';
import { TrainingTypeEnum } from '../../../enums/TrainingTypesEnum';
import { TypeOfInput } from '../../../interfaces/enums';
import { setPlanningMode } from '../../../store/calendar/actions';
import { ToggleGroupButtons } from '../../shared/ToggleGroupButtons';

interface ComponentProps {
  training: TrainingDTO | undefined;
  performSetTrainingDate: typeof performSetTrainingDate;
  handleClose: () => void;
  // used for disabling the inputs
  disabled: boolean;
  clientName: string;
  planningState: boolean;
  setPlanningMode: typeof setPlanningMode;
  requestError?: string | undefined;
}
const typeEventToggle = [
  {
    value: TrainingTypeEnum.Remote,
    label: 'Klient cvičí sám',
  },
  {
    value: TrainingTypeEnum.Personal,
    label: 'Klient cvičí se mnou',
  },
];
export const ScheduleModal: FC<ComponentProps> = ({
  training,
  performSetTrainingDate,
  handleClose,
  disabled,
  clientName,
  setPlanningMode,
  planningState,
  requestError,
}) => {
  const history = useHistory();
  const { id, trainingPlanId } = useParams<{
    id: string;
    trainingPlanId: string;
  }>();
  const [selectedTimeTo, handleTimeToChange] = useState(decideDateTo(training));
  const [trainingType, setTrainingType] = useState(
    training && training.withTrainer
      ? TrainingTypeEnum.Personal
      : TrainingTypeEnum.Remote,
  );
  const [selectedDate, handleDateChange] = useState(
    training && training.dateFrom ? new Date(training.dateFrom) : new Date(),
  );
  const [trainingPlace, setTrainingPlace] = useState(
    training && training.location ? training.location : '',
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (requestError) {
      enqueueSnackbar(requestError, {
        variant: 'error',
      });
    }
  }, [requestError, enqueueSnackbar]);

  const handleShowDetail = () => {
    if (training) {
      history.push(
        `/t/clients/${id}/training-plans/${trainingPlanId}/trainings/${training.id}/setup`,
      );
    }
  };
  const handleFormSave = (): void => {
    const successCb = (): void => {
      // that means that training wasnt planned before
      if (planningState) {
        history.push(
          `/t/clients/${id}/training-plans/${trainingPlanId}/trainings`,
        );
        setPlanningMode(false);
      }
      enqueueSnackbar(<Trans>Trénink byl naplánován.</Trans>, {
        variant: 'success',
      });
    };

    if (training) {
      performSetTrainingDate(
        training,
        selectedDate,
        selectedTimeTo,
        trainingType,
        trainingPlace,
        successCb,
      );
    }
  };

  return (
    <div css={isMobile ? scheduleCardCssMobile : scheduleCardCss(isMobile)}>
      <Grid container css={marginBottomCss}>
        {isMobile && (
          <Grid container item xs={1} justify="flex-end">
            <IconButton aria-label="close" onClick={(): void => handleClose()}>
              <Close />
            </IconButton>
          </Grid>
        )}
        <Grid
          container
          justifyContent="space-between"
          css={trainingNameCss(isMobile)}
        >
          <Grid item>
            <Typography variant="h6">
              {training &&
                (!disabled
                  ? getNameForTraining(training, t`Trénink`)
                  : clientName)}
            </Typography>
          </Grid>
          {!isMobile && (
            <Grid item>
              <IconButton size="small" onClick={(): void => handleClose()}>
                <Close css={IconColorCss(isMobile)} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      {isMobile && !disabled && (
        <div css={inputWithLabelCss(false, isMobile)}>
          <label htmlFor="date-picker-inline">
            <Trans>Typ tréninku</Trans>
          </label>
        </div>
      )}
      {!disabled && isMobile && (
        <div css={[marginBottomCss, fontToggleCss]}>
          <ToggleGroupButtons
            value={trainingType}
            items={typeEventToggle}
            setValue={(value: unknown): void => {
              setTrainingType(value as TrainingTypeEnum);
            }}
          />
        </div>
      )}
      {!disabled && !isMobile && (
        <div css={modalRowCss(isMobile ? 'flex-start' : 'center')}>
          <Button
            css={
              trainingType === TrainingTypeEnum.Remote
                ? switchButtonContainedCss(isMobile)
                : switchButtonOutlinedCss(isMobile)
            }
            fullWidth
            variant={
              trainingType === TrainingTypeEnum.Remote
                ? 'contained'
                : 'outlined'
            }
            onClick={(): void => setTrainingType(TrainingTypeEnum.Remote)}
          >
            <Trans>Klient cvičí sám</Trans>
          </Button>
          <Button
            css={
              trainingType === TrainingTypeEnum.Personal
                ? switchButtonContainedCss(isMobile)
                : switchButtonOutlinedCss(isMobile)
            }
            fullWidth
            variant={
              trainingType === TrainingTypeEnum.Personal
                ? 'contained'
                : 'outlined'
            }
            onClick={(): void => setTrainingType(TrainingTypeEnum.Personal)}
          >
            <Trans>Klient cvičí se mnou</Trans>
          </Button>
        </div>
      )}

      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={12} sm={6}>
          <div css={[inputWithLabelCss(false, isMobile)]}>
            <label htmlFor="date-picker-inline">
              <Trans>Datum</Trans>
            </label>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
              <DatePicker
                css={[
                  inputCss(isMobile, disabled),
                  marginInputCss(TypeOfInput.Left, isMobile),
                ]}
                disableToolbar
                disabled={disabled}
                variant="inline"
                inputVariant="outlined"
                format="dd.MM.yyyy"
                size="small"
                fullWidth
                id="date-picker-inline"
                value={selectedDate}
                onChange={(date: MaterialUiPickersDate): void => {
                  handleDateChange(date || new Date());
                  handleTimeToChange(date || new Date());
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div
            css={[
              inputWithLabelCss(
                trainingType === TrainingTypeEnum.Remote,
                isMobile,
              ),
            ]}
          >
            <label htmlFor="time-picker-inline">
              <Trans>Začátek</Trans>
            </label>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
              <TextField
                css={[
                  inputCss(isMobile, disabled),
                  marginInputCss(TypeOfInput.Center, isMobile),
                ]}
                disabled={trainingType === TrainingTypeEnum.Remote || disabled}
                variant="outlined"
                id="time-picker-inline"
                defaultValue={selectedDate.toTimeString().slice(0, 5)}
                type="time"
                fullWidth
                size="small"
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ): void => {
                  const timeFromDate = new Date(selectedDate);
                  timeFromDate.setHours(
                    parseInt(event.target.value.slice(0, 2), 10) || 0,
                  );
                  timeFromDate.setMinutes(
                    parseInt(event.target.value.slice(3, 5), 10) || 0,
                  );
                  handleDateChange(timeFromDate);
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div
            css={[
              inputWithLabelCss(
                trainingType === TrainingTypeEnum.Remote,
                isMobile,
              ),
            ]}
          >
            <label htmlFor="time-to">
              <Trans>Konec</Trans>
            </label>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
              <TextField
                css={[
                  inputCss(
                    isMobile,
                    disabled,
                    !(selectedDate <= selectedTimeTo),
                  ),
                ]}
                disabled={trainingType === TrainingTypeEnum.Remote || disabled}
                variant="outlined"
                fullWidth
                id="time-to"
                size="small"
                defaultValue={selectedTimeTo.toTimeString().slice(0, 5)}
                type="time"
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ): void => {
                  const timeToDate = new Date(selectedDate);
                  timeToDate.setHours(
                    parseInt(event.target.value.slice(0, 2), 10) || 0,
                  );
                  timeToDate.setMinutes(
                    parseInt(event.target.value.slice(3, 5), 10) || 0,
                  );
                  handleTimeToChange(timeToDate);
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
      </Grid>
      <div css={modalRowCss('center', isMobile)}>
        {/* Prepared for later use. Need to fix onChange event. */}
        <div
          css={[
            inputWithLabelCss(
              trainingType === TrainingTypeEnum.Remote,
              isMobile,
            ),
            fullWidthCss,
          ]}
        >
          <label htmlFor="outlined-gym-native-simple">
            <Trans>Místo</Trans>
          </label>

          <TextField
            css={[
              inputCss(isMobile, disabled),
              selectIconCss(trainingType === TrainingTypeEnum.Remote),
            ]}
            variant="outlined"
            autoComplete="gym"
            name="gym"
            value={trainingPlace}
            size={isMobile ? 'small' : 'medium'}
            disabled={trainingType === TrainingTypeEnum.Remote || disabled}
            onChange={e => setTrainingPlace(e.target.value)}
          />
        </div>
      </div>
      <div css={modalRowCss('flex-end', isMobile)}>
        <Button
          variant="text"
          css={[buttonCss(isMobile), detailButtonCss(!disabled || !isMobile)]}
          onClick={handleShowDetail}
        >
          <Trans>Zobrazit trénink</Trans>
        </Button>
        {!disabled && (
          <Button
            type="submit"
            variant="contained"
            disabled={
              trainingType === TrainingTypeEnum.Remote
                ? false
                : !(selectedDate < selectedTimeTo)
            }
            color="secondary"
            css={buttonCss(isMobile)}
            onClick={() => handleFormSave()}
          >
            <Trans>Uložit</Trans>
          </Button>
        )}
      </div>
    </div>
  );
};

const detailButtonCss = (hasMargin: boolean) => css`
  color: ${COLORS.white} !important;
  margin-right: ${hasMargin ? MPX * 2 : 0}px !important;
`;

const fullWidthCss = css`
  width: 100%;
`;
const buttonCss = (isMobile: boolean) => css`
  margin-top: ${isMobile ? 2 * MPX : 0}px !important;
  width: ${isMobile ? '80%' : 'inherit'} !important;
`;
const inputWithLabelCss = (isDisabled = false, isMobile: boolean) => css`
  display: flex;
  flex-direction: column;
  width: ${isMobile && '100%'};
  label {
    margin-bottom: ${isMobile ? 1 * MPX : 2 * MPX}px;
    color: ${decideLabel(isDisabled, isMobile)};
  }
`;
const scheduleCardCss = (isMobile: boolean) => css`
  ${!isMobile && `position: relative;`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${isMobile ? '300px' : '490px'};
  height: ${isMobile ? '500px' : '400px'};
  border-radius: 4px;
  padding: ${4 * MPX}px ${8 * MPX}px;
  background-color: ${isMobile ? COLORS.white : COLORS.primary};
  margin: ${isMobile ? `${2 * MPX}px` : 'auto'};

  ::after {
    content: '';
    width: 20px;
    height: 20px;
    background-color: ${COLORS.primary};
    position: absolute;
    top: 209px;
    left: -10px;
    transform: rotate(45deg);
  }
`;
const scheduleCardCssMobile = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 4px;
  padding: ${2 * MPX}px ${4 * MPX}px;
  background-color: ${COLORS.white};
  margin: ${2 * MPX}px;
`;

const IconColorCss = (isMobile: boolean) => css`
  color: ${isMobile ? COLORS.baseOrange : COLORS.contrast};
`;

const modalRowCss = (
  justify: Justify = 'center',
  isMobile?: boolean,
): SerializedStyles => css`
  display: flex;
  align-items: ${isMobile && 'center'};
  flex-direction: ${isMobile ? 'column' : 'row'};
  justify-content: ${justify};
  width: ${isMobile && '100% !important'};
`;
const decideMargin = (type: TypeOfInput): string => {
  if (type === TypeOfInput.Left) {
    return `0 ${2 * MPX}px 0 0 `;
  }
  if (type === TypeOfInput.Center) {
    return `0  ${2 * MPX}px ${2 * MPX}px 0 `;
  }
  return `0 0 ${2 * MPX}px 0 `;
};
const trainingNameCss = (isMobile: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  color: ${isMobile ? COLORS.darkDarkBlue : COLORS.contrast};
`;
const marginInputCss = (
  type: TypeOfInput,
  isMobile: boolean,
): SerializedStyles => css`
  margin: ${isMobile ? 0 : decideMargin(type)} !important;
`;
const decideBorder = (
  isMobile: boolean,
  isDisabled: boolean,
  isRed?: boolean,
): string => {
  if (isRed) {
    return COLORS.danger;
  }
  if (isMobile && !isDisabled) {
    return COLORS.lightGrey;
  }
  if (isMobile && isDisabled) {
    return COLORS.gray7;
  }
  return COLORS.contrast;
};

const inputCss = (
  isMobile: boolean,
  isDisabled: boolean,
  isRed?: boolean,
): SerializedStyles => css`
  .MuiOutlinedInput-root {
    margin-bottom: ${isMobile ? `${3 * MPX}px` : '0px'} !important;
    border: ${isRed && `1 px solid ${COLORS.danger}`};
    border: ${isMobile && `1 px solid ${COLORS.gray}`};
    :focus {
      border: ${isRed && `1 px solid ${COLORS.danger}`} !important;
    }
    :hover {
      border: ${isRed && `1 px solid ${COLORS.danger} `} !important;
    }
  }
  input {
    padding: ${4 * MPX}px ${3 * MPX}px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${decideBorder(isMobile, isDisabled, isRed)};
  }
  .MuiInputBase-input {
    color: ${isMobile ? COLORS.darkDarkBlue : COLORS.contrast};
    ${!isMobile && `background-color: ${COLORS.scheduleInputBg}`};
  }
  .MuiInputBase-input.Mui-disabled {
    color: ${COLORS.gray7};
  }
  .MuiSelect-icon {
    color: ${isMobile ? COLORS.darkDarkBlue : COLORS.contrast};
  }
  .MuiInputBase-root.Mui-disabled {
    color: ${isMobile ? COLORS.white : COLORS.scheduleInputBg};
  }
`;

const selectIconCss = (disabled: boolean): SerializedStyles => css`
  .MuiSelect-icon {
    color: ${disabled ? COLORS.scheduleInputBg : COLORS.contrast};
  }
`;

const switchButtonContainedCss = (isMobile: boolean): SerializedStyles => css`
  background-color: ${COLORS.contrast} !important;
  color: ${COLORS.primary} !important;
  padding: ${3 * MPX}px ${MPX}px !important;
  margin-bottom: ${isMobile ? `${3 * MPX}px` : '0px'} !important;
  .MuiToggleButton-root.Mui-selected {
    background-color: ${COLORS.contrast} !important;
    color: ${COLORS.primary} !important;
    padding: ${3 * MPX}px ${MPX}px !important;
    margin-bottom: ${isMobile ? `${3 * MPX}px` : '0px'} !important;
  }
`;

const switchButtonOutlinedCss = (isMobile: boolean) => css`
  border-color: ${isMobile ? COLORS.gray7 : COLORS.contrast} !important;
  color: ${isMobile ? COLORS.gray7 : COLORS.contrast} !important;
  background-color: ${isMobile
    ? COLORS.white
    : COLORS.scheduleInputBg} !important;
  .MuiToggleButton-root.Mui-selected {
    background-color: ${COLORS.contrast} !important;
    color: ${COLORS.primary} !important;
  }
`;

const marginBottomCss = css`
  margin-bottom: ${2 * MPX}px;
`;

const fontToggleCss = css`
  .MuiToggleButton-label {
    font-size: ${MPX * 3}px !important;
    text-transform: none;
  }
`;

type Justify =
  | 'center'
  | 'space-between'
  | 'space-aroung'
  | 'flex-start'
  | 'flex-end';
