/** @jsx jsx */
import { FC, Fragment, ReactNode, useState } from 'react';
import { jsx, css } from '@emotion/react';
import {
  Grid,
  InputAdornment,
  OutlinedInput,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  ExerciseSerie,
  TrainingExerciseWithOperation,
} from '../../../../interfaces/traning';
import { inputCss, inputsCss, setPaddingCss } from '../TraningExercise';
import { ExerciseInputsEnum } from '../../../../code/helpers/trainingExerciseInputs';
import { COLORS, MPX } from '../../../../styles/themes';
import { validateText } from '../../../../code/helpers/trainingSetupHelpers';
import { decimalNumberRule } from '../../../shared/helpers/FormRules';
import { ExerciseTrainingTypeEnum } from '../../../../strapi/ExerciseDTO';
import { TimeDropdownPicker } from './TimeDropdownPicker';
import {
  PauseItemsTimeCircle,
  PauseItemsTimeNotCircle,
  TrainingSectionTypeEnum,
} from '../../../../enums/TrainingTypesEnum';
import { checkNumberRepeat } from '../../../../code/helpers/trainingExerciseSeries';

interface ExerciseSeriesRowProps {
  exercise: TrainingExerciseWithOperation;
  serie: ExerciseSerie;
  serieName: ReactNode;
  isSuperSet: boolean;
  type: TrainingSectionTypeEnum;
  mergeIntoParent: (
    value: string,
    serieKey: number,
    inputName: ExerciseInputsEnum,
  ) => void;
}

const REG_VALID_REPEATS = /^[1-9][0-9]*$/i;

export const ExerciseSeriesRow: FC<ExerciseSeriesRowProps> = ({
  exercise,
  serie,
  serieName,
  isSuperSet,
  mergeIntoParent,
  type,
}) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState(serie.repeats);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const renderExerciseSeriesInputs = () => (
    <Grid
      container
      item
      xs={10}
      sm={8}
      alignItems="center"
      css={[!isMobile && inputsCss, !isSuperSet && !isMobile && setPaddingCss]}
    >
      <Grid container alignItems="center" spacing={1} item xs={12} sm={11}>
        {!isMobile && (
          <Grid item sm={2}>
            <Fragment />
          </Grid>
        )}
        <Grid item xs={5} sm={4}>
          {type === TrainingSectionTypeEnum.CircuitTraining ||
          exercise.exercise.exerciseTrainingType ===
            ExerciseTrainingTypeEnum.TIME ? (
            <TimeDropdownPicker
              hasPause={exercise.exercise.hasPause}
              value={
                typeof serie.repeats === 'number'
                  ? serie.repeats
                  : checkNumberRepeat(serie.repeats)
              }
              handlePauseChange={e =>
                mergeIntoParent(
                  e.toString(),
                  serie.key,
                  ExerciseInputsEnum.REPEATS,
                )
              }
              itemsForSelect={
                type !== TrainingSectionTypeEnum.CircuitTraining
                  ? PauseItemsTimeNotCircle
                  : PauseItemsTimeCircle
              }
            />
          ) : (
            <OutlinedInput
              css={inputCss(isMobile)}
              disabled={!exercise.exercise.hasRepeats}
              name={ExerciseInputsEnum.REPEATS}
              type="number"
              inputMode="numeric"
              inputProps={{ min: 1 }}
              defaultValue={serie.repeats}
              endAdornment={
                <InputAdornment position="end">
                  {exercise.repeats_modifier || ''}
                </InputAdornment>
              }
              onKeyDown={e => setInputValue(e.target.value)}
              onChange={e => {
                if (validateText(e.target.value, REG_VALID_REPEATS)) {
                  e.target.value = inputValue;
                }
              }}
              onBlur={value =>
                mergeIntoParent(
                  value.target.value === '' ? '1' : value.target.value,
                  serie.key,
                  ExerciseInputsEnum.REPEATS,
                )
              }
            />
          )}
        </Grid>
        <Grid item xs={4} sm={3}>
          <OutlinedInput
            css={inputCss(isMobile)}
            disabled={!exercise.exercise.hasWeights}
            name={ExerciseInputsEnum.WEIGHTS}
            type="number"
            inputMode="numeric"
            defaultValue={serie.weights}
            onBlur={e => {
              // chci mit v main inputu vzdycky 0 a ne prazdnou hodnotu
              mergeIntoParent(
                e.target.value === '' ? '0' : e.target.value,
                serie.key,
                ExerciseInputsEnum.WEIGHTS,
              );
            }}
            error={
              validateText(serie.weights, decimalNumberRule.value) ||
              !serie.weights
            }
            endAdornment="kg"
          />
        </Grid>

        <Grid item xs={3} sm={3}>
          <Fragment />
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid item sm={1}>
          <Fragment />
        </Grid>
      )}
    </Grid>
  );

  return (
    <Grid
      container
      alignItems="center"
      justify={isMobile ? 'center' : 'space-evenly'}
      css={topMargin}
      key={serie.key}
    >
      <Grid item xs={2} sm={4}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={3} sm={7}>
            <span css={serieTitleCss(isMobile)}>
              {isMobile ? `${serie.key + 1}.` : serieName}
            </span>
          </Grid>
        </Grid>
      </Grid>
      {renderExerciseSeriesInputs()}
    </Grid>
  );
};

const serieTitleCss = (isMobile: boolean) => css`
  color: ${COLORS.darkBlue};
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
  margin-left: ${(isMobile ? 0 : 10) * MPX}px;
`;

const topMargin = css`
  margin-top: ${3 * MPX}px;
`;
