/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { MPX } from '../../../../../styles/themes';
import { buildStringWithSemicolons } from '../../../helper/functions';

export interface PauseExerciseRowProps {
  title: string;
  weights: string;
}

const PauseExerciseRow: React.FC<PauseExerciseRowProps> = ({
  title,
  weights,
}) => (
  <React.Fragment>
    <Grid container direction="row" css={gridCss} justify="space-between">
      <Grid item>{title}</Grid>
      <Grid item>{buildStringWithSemicolons(weights)}</Grid>
    </Grid>
  </React.Fragment>
);

export default PauseExerciseRow;

const gridCss = css`
  padding: ${0.5 * MPX}vh;
`;
