import { createSelector, Selector } from 'reselect';
import { State } from '../combinedReducers';
import {
  TrainingDTO,
  TrainingSectionDTO,
  TrainingSectionPartsDTO,
} from '../../strapi/TrainingDTO';
import { FilteredSectionsExercises } from '../../components/customerApp/helper/interfaces';
import { orderByOrder } from '../../components/customerApp/helper/functions';
import { TrainingSectionIndex } from '../../components/customerApp/helper/enums';

export const trainingsReducer = (state: State): TrainingDTO | undefined =>
  state.trainings.selectedTraining;

export const clientTrainingsReducer = (
  state: State,
): TrainingDTO[] | undefined => state.trainings.clientTrainings;

export const startedTrainingFromReducer = (state: State): Date | undefined =>
  state.trainings.trainingRunningFrom;

export const trainingIsRunningReducer = (state: State): boolean =>
  state.trainings.trainingIsRunnig;

export const getClientTrainings = createSelector(
  clientTrainingsReducer,
  clientTrainings => clientTrainings,
);

export const getSelectedTrainingWithSortedParts = createSelector(
  trainingsReducer,
  trainingsReducer => trainingsReducer,
);

export const getSelectedTrainingSections = createSelector(
  trainingsReducer,
  training => training?.training_sections,
);

export const getSelectedTrainingId = createSelector(
  trainingsReducer,
  training => training?.id,
);

export const getIsTrainingRunning = createSelector(
  trainingIsRunningReducer,
  trainingIsRunningReducer => trainingIsRunningReducer,
);

export const getRunningFrom = createSelector(
  startedTrainingFromReducer,
  startedTrainingFromReducer => startedTrainingFromReducer,
);

export const getSelectedTraining: Selector<State, TrainingDTO | undefined> =
  createSelector(trainingsReducer, training => {
    if (training) {
      if (training.training_sections) {
        const sortedSections = [...training.training_sections].sort(
          (a, b) => a.order - b.order,
        );

        const remappedSections = sortedSections.map(sp => {
          if (sp.training_section_parts) {
            const sortedTsp = [...sp.training_section_parts].sort(
              (a, b) => a.order - b.order,
            );

            const remappedTsp = sortedTsp.map(tsp => {
              if (tsp.training_exercises) {
                const sortedTe = [...tsp.training_exercises].sort(
                  (a, b) => a.order - b.order,
                );

                return { ...tsp, training_exercises: sortedTe };
              }

              return tsp;
            });

            return { ...sp, training_section_parts: remappedTsp };
          }

          return sp;
        });
        const newTraining: TrainingDTO = {
          ...training,
          training_sections: remappedSections,
        };
        return newTraining;
      }
    }
    return undefined;
  });

// filter skipped sections and exercises
export const getSkipped = (state: State): FilteredSectionsExercises => {
  const training = getSelectedTraining(state);
  if (!training) {
    return { sections: [], exercises: [] };
  }

  const allSections: TrainingSectionDTO[] = orderByOrder(
    training.training_sections,
  );
  const allExercises: TrainingSectionPartsDTO[] = orderByOrder(
    allSections[TrainingSectionIndex.Training].training_section_parts,
  );

  const sections = allSections.filter(
    section => section.order !== 2 && section.isSkipped,
  );

  const exercises = allExercises.filter(exercise => exercise.isSkipped);

  return { sections, exercises };
};
