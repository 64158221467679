/** @jsx jsx */
import React, { useMemo } from 'react';
import { css, jsx } from '@emotion/react';
import ProgressBar from './ProgressBar';
import { MPX } from '../../../../../styles/themes';

export interface TrainingProgressBarProps {
  durationTotal: number;
  durationElapsed: number;
  currentSeries: number;
  seriesTotal: number;
  isPause: boolean;
  otherBarNumber: number;
}

const ProgressBarWrapper: React.FC<TrainingProgressBarProps> = ({
  durationElapsed,
  durationTotal,
  currentSeries,
  seriesTotal,
  isPause,
  otherBarNumber,
}) => {
  const topBarElapsedTime = !isPause ? durationElapsed : 0;
  const topBarTotalTime = !isPause ? durationTotal : 0;

  const bottomBarElapsedTime = isPause ? durationElapsed : 0;
  const bottomBarTotalTime = isPause ? durationTotal : otherBarNumber;

  const topBar = (
    <div css={topWrapperCss}>
      <ProgressBar
        currentSeries={currentSeries}
        seriesTotal={seriesTotal}
        durationElapsed={topBarElapsedTime}
        durationTotal={topBarTotalTime}
        isElapsed={isPause}
      />
    </div>
  );

  const bottomBar = useMemo(
    () => (
      <ProgressBar
        durationElapsed={bottomBarElapsedTime}
        durationTotal={bottomBarTotalTime}
        isPause
        noGo
      />
    ),
    [bottomBarElapsedTime, bottomBarTotalTime],
  );

  return (
    <div css={wrapperCss}>
      {topBar}
      {bottomBar}
    </div>
  );
};

export default ProgressBarWrapper;

const wrapperCss = css`
  position: relative;
`;

const topWrapperCss = css`
  margin-bottom: ${MPX * 4}px;
`;
