// what

/**
 * TABLE SORTING
 */

export type Order = 'asc' | 'desc';

export const descendingComparator = <T>(
  a: T,
  b: T,
  orderBy: keyof T,
): number => {
  const stringValues =
    typeof a[orderBy] === 'string' || typeof b[orderBy] === 'string';

  if (stringValues) {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key,
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number) =>
  order === 'desc'
    ? (a, b): number => descendingComparator(a, b, orderBy)
    : (a, b): number => -descendingComparator(a, b, orderBy);

export const stableSort = <T>(
  array: any[],
  comparator: (a: T, b: T) => number,
): any[] => {
  if (array) {
    const stabilizedThis = array.map(
      (el: any, index: number) => [el, index] as [T, number],
    );
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });

    return stabilizedThis.map((el: any) => el[0]);
  }
  return [];
};
