import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import { AxiosResponse } from 'axios';
import { State } from '../combinedReducers';
import { baseAsyncRequest } from '../actionHelpers';

// NEW API
import { ClientActionTypes as ActionTypes } from '../actionTypes';
import { makeServerCallAsync } from '../../code/helpers/api/api';

import { UserDTO } from '../../strapi/UserDTO';
import { UpdateClientDTO } from '../../strapi/UpdateClientDTO';
// END

export const addClient = action(
  ActionTypes.ADD_CLIENT,
  payload<{ client: UserDTO }>(),
);

export const updateClient = action(
  ActionTypes.UPDATE_CLIENT,
  payload<{ client: UserDTO }>(),
);

export const setClients = action(
  ActionTypes.SET_CLIENTS,
  payload<{ clients: UserDTO[] }>(),
);

export const setActiveClient = action(
  ActionTypes.SET_ACTIVE_CLIENT,
  payload<UserDTO | undefined>(),
);

export const setSearchQuery = action(
  ActionTypes.SET_SEARCH_QUERY,
  payload<string>(),
);

export const clearSearchQuery = action(ActionTypes.CLEAR_SEARCH_QUERY);

export const deleteClient = action(
  ActionTypes.DELETE_CLIENT,
  payload<{ clientId: string }>(),
);

export const performFetchClients: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (userId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/users?trainer_eq=${userId}`);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_FETCH_CLIENTS,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;

      dispatch(setClients({ clients: data }));
    }
  };

export const performAddClient: ActionCreator<
  ThunkAction<Promise<void>, State, UserDTO, any>
> =
  (createUserDTO: UserDTO, onCreateCallback: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', '/auth/local/register', createUserDTO);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_ADD_CLIENT,
      request,
      dispatch,
      {
        defaultMessage: 'Klienta se nepodařilo vytvořit.',
        errorMap: {
          409: 'Klient s danou emailovou adresou je již zaregistrován.',
        },
      },
    );

    if (results) {
      const {
        data: { user },
      } = results;

      dispatch(addClient({ client: user }));
      onCreateCallback();
    }
  };

export const performUpdateClient: ActionCreator<
  ThunkAction<Promise<void>, State, UserDTO, any>
> =
  (
    clientId: number,
    updateClientDTO: UpdateClientDTO,
    onCreateCallback: () => void,
  ) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('put', `/users/${clientId}`, updateClientDTO);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_UPDATE_CLIENT,
      request,
      dispatch,
      {
        defaultMessage: 'Klienta se nepodařilo upravit.',
        errorMap: {
          409: 'Klient s danou emailovou adresou je již zaregistrován.',
        },
      },
    );

    if (results) {
      const { data } = results;
      dispatch(updateClient({ client: data }));
      onCreateCallback();
    }
  };

export const performDeleteClient: ActionCreator<any> =
  (id: string, onDeleteCallback: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('delete', `/users/${id}`);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_DELETE_CLIENT,
      request,
      dispatch,
    );

    if (results && results.status === 200) {
      dispatch(deleteClient({ clientId: id }));
      onDeleteCallback();
    }
  };

export const performFetchUserById: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (id: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(deleteClient({ clientId: id }));

    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/users/${id}`);

    const results = await baseAsyncRequest(
      ActionTypes.FETCH_CLIENT,
      request,
      dispatch,
    );

    if (results && results.status === 200) {
      dispatch(addClient({ client: results.data }));
      dispatch(setActiveClient(results.data));
    }
  };
