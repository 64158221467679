import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { App, DispatchProps, StateProps } from './App';

import { State } from './store/combinedReducers';
import { changeUiLanguage } from './store/general/actions';
import { refreshUserFromCookie } from './store/auth/actions';
import { isSuccessful, getError } from './store/request/selectors';
import { fetchTrainingPlans } from './store/trainingPlans/actions';
import { AT } from './store/actionTypes';

const requestActions = [AT.REFRESH_USER_FROM_COOKIE];

const mapStateToProps = (state: State): StateProps => ({
  loggedUser: state.auth.loggedUser,
  language: state.general.language,
  userRefreshedSuccess: isSuccessful(requestActions)(state),
  userRefreshedFail: getError(requestActions)(state),
  isCircularRunning: state.general.isCircularRunning,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(
    {
      changeUiLanguage,
      refreshUserFromCookie,
      fetchTrainingPlans,
    },
    dispatch,
  ),
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
