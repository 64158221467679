// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  UserResponseDto,
  UserResponseDtoFromJSON,
  UserResponseDtoFromJSONTyped,
  UserResponseDtoToJSON,
} from './';

/**
 *
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
  /**
   *
   * @type {string}
   * @memberof LoginResponseDto
   */
  token: string;
  /**
   *
   * @type {UserResponseDto}
   * @memberof LoginResponseDto
   */
  user: UserResponseDto;
}

export function LoginResponseDtoFromJSON(json: any): LoginResponseDto {
  return LoginResponseDtoFromJSONTyped(json, false);
}

export function LoginResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LoginResponseDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    token: json['token'],
    user: UserResponseDtoFromJSON(json['user']),
  };
}

export function LoginResponseDtoToJSON(value?: LoginResponseDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token: value.token,
    user: UserResponseDtoToJSON(value.user),
  };
}
