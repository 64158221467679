/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Typography, Paper, Grid } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { COLORS } from '../../styles/themes';

interface ResultProps {
  status: 'success' | 'error';
  title: string;
  subTitle?: string;
  extra?: JSX.Element;
}

export const Result: React.FC<ResultProps> = ({ status, title, subTitle }) => {
  const data = results[status];

  return (
    <Paper css={[result, data.style]} elevation={0}>
      <Grid container justify="flex-start" alignItems="center" spacing={2}>
        <Grid item container justify="center" xs={2}>
          {data.icon}
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="subtitle2"
            css={css`
              text-align: left;
            `}
          >
            <Trans id={title} />
          </Typography>
          {subTitle && (
            <Typography component="p" variant="body2">
              <Trans id={subTitle} />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

const result = css`
  margin: 10px 0px;
  padding: 15px 6px;
  width: inherit;
  color: #fff !important;
`;

const results = {
  error: {
    style: css`
      background-color: ${COLORS.danger} !important;
    `,
    icon: <ErrorIcon />,
  },
  success: {
    style: css`
      background-color: ${COLORS.success} !important;
    `,
    icon: <CheckCircleIcon />,
  },
};
