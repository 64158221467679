/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/ArrowBack';
import { COLORS, MPX, TOP_MENU_HEIGHT } from '../../../styles/themes';
import ModalAlert from '../trainingDetailDescription/components/shared/ModalAlert';
import { ApplicationPath, ClientPath, routePathBuilder } from '../../../routes';
import { getCircularRunning } from '../../../store/general/selectors';
import { wrapTextToNewLine } from '../helper/functions';

export interface HeadingWithBackButtonProps {
  title: string;
  link?: string;
  isLight?: boolean;
  shouldWrap?: boolean;
  showConfirmationModalOnClose?: boolean;
  closeFunction?: () => void;
}

export const HeadingWithBackButton: React.FC<HeadingWithBackButtonProps> = ({
  title,
  link,
  isLight,
  shouldWrap,
  showConfirmationModalOnClose,
  closeFunction,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const isCircularRunning = useSelector(getCircularRunning);

  const [confirmationModal, setConfirmationModal] = React.useState(false);

  const pauseTraining = React.useCallback(() => {
    // TODO PAUSE TRAINING ON BACK
  }, []);

  const handleClose = React.useCallback(() => {
    if (showConfirmationModalOnClose) {
      setConfirmationModal(true);
    } else {
      pauseTraining();

      if (link) {
        history.push(link);
      }
    }
  }, [
    history,
    link,
    pauseTraining,
    setConfirmationModal,
    showConfirmationModalOnClose,
  ]);

  const toDoNextModal = () => {
    setConfirmationModal(false);
  };

  const endModal = () => {
    pauseTraining();
    setConfirmationModal(false);
    history.push(
      routePathBuilder([ApplicationPath.Client, ClientPath.Schedule]),
    );
  };

  // If the title is too long, wrap it to the new line
  const wrapText = shouldWrap && wrapTextToNewLine(isMobile, title.length);

  return (
    <div css={headerWrapper(isLight, wrapText)}>
      {confirmationModal && (
        <ModalAlert toDoNext={toDoNextModal} end={endModal} />
      )}
      <Grid container alignItems="center" spacing={1}>
        <Grid item md={3}>
          <Grid container alignItems="center" alignContent="center">
            {isMobile && !closeFunction && link && (
              <IconButton
                aria-label="close"
                css={[
                  iconCss(isMobile),
                  isMobile && iconMobileCss(isCircularRunning),
                ]}
                onClick={handleClose}
                disabled={isCircularRunning}
              >
                <KeyboardBackspaceIcon css={iconMobileCss(isCircularRunning)} />
              </IconButton>
            )}
          </Grid>
          {isMobile && closeFunction && (
            <IconButton
              aria-label="close"
              css={[
                iconCss(isMobile),
                isMobile && iconMobileCss(isCircularRunning),
              ]}
              onClick={closeFunction}
              disabled={isCircularRunning}
            >
              <KeyboardBackspaceIcon css={iconMobileCss(isCircularRunning)} />
            </IconButton>
          )}
        </Grid>
        <Grid
          item
          container
          justify={!isMobile ? 'center' : 'flex-start'}
          xs={wrapText ? 12 : 10}
          md={6}
          css={typoWrapper(isMobile)}
        >
          <Typography variant="h5" css={titleCss(isLight)}>
            <Trans id={title} />
          </Typography>
        </Grid>
        <Grid container item justify="flex-end" md={3}>
          {!isMobile && link && (
            <Link
              color="secondary"
              component={RouterLink}
              to={link}
              onClick={handleClose}
            >
              <IconButton
                aria-label="close"
                disabled={isCircularRunning}
                css={iconCss(isMobile)}
              >
                <KeyboardBackspaceIcon />
              </IconButton>
            </Link>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const titleCss = (isLight: boolean | undefined) => css`
  color: ${isLight ? COLORS.darkDarkBlue : COLORS.white};
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
`;

const headerWrapper = (
  isLight: boolean | undefined,
  shouldWrap: boolean | undefined,
) => css`
  width: 100%;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  background-color: ${isLight ? COLORS.white : COLORS.bcgTrainingClient};
  height: ${shouldWrap ? 'auto' : `${TOP_MENU_HEIGHT}px`};
`;

const typoWrapper = (isMobile: boolean) => css`
  width: 100%;
  margin: 0 auto;
  text-align: ${isMobile ? 'left' : 'center'};
  padding-left: ${isMobile ? `${3 * MPX}px` : 'initial'} !important;
`;

const iconCss = (isMobile: boolean) => css`
  width: ${isMobile && MPX * 6}px;
  height: ${isMobile && MPX * 6}px;
`;

const iconMobileCss = (isCircularRunning: boolean) => css`
  width: ${8 * MPX}px !important;
  height: ${8 * MPX}px !important;
  color: ${!isCircularRunning ? COLORS.baseOrange : COLORS.gray7} !important;
  margin-top: 0 !important;
  padding: 0 !important;
`;
