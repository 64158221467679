import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import { AxiosResponse } from 'axios';
import { makeServerCallAsync } from '../../code/helpers/api/api';
import { EventDTO } from '../../interfaces/event';
import { baseAsyncRequest } from '../actionHelpers';
import { CalendarActionTypes as ActionTypes } from '../actionTypes';
import { State } from '../general/reducers';
import {
  remapEventToCalendarEvent,
  remapTrainerEventToClientsEvent,
} from '../../code/helpers/calendarHelper';
import { TrainerEventDTO } from '../../strapi/TrainerEventDTO';
import { UserDTO } from '../../strapi/UserDTO';
import { TrainingDTO } from '../../strapi/TrainingDTO';

export const setCalendarClickDate = action(
  ActionTypes.SET_CALENDAR_CLICK_DATE,
  payload<{ date: Date }>(),
);

export const setPlanningMode = action(
  ActionTypes.SET_PLANNING_MODE,
  payload<boolean>(),
);

export const setTrainerEvents = action(
  ActionTypes.SET_TRAINER_EVENTS,
  payload<{ trainerEvents: EventDTO[] }>(),
);
export const addTrainerEvent = action(
  ActionTypes.ADD_TRAINER_EVENT,
  payload<{ trainerEvent: EventDTO }>(),
);
export const updateTrainerEvent = action(
  ActionTypes.UPDATE_TRAINER_EVENT,
  payload<{ trainerEvent: EventDTO }>(),
);

export const performFetchTrainerEvents: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (id: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/trainer-events/trainer/${id}`);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_FETCH_TRAINER_EVENTS,
      request,
      dispatch,
      {},
    );

    if (result && result.status === 200) {
      dispatch(
        setTrainerEvents({
          trainerEvents: remapEventToCalendarEvent(
            result.data as TrainerEventDTO[],
          ),
        }),
      );
    }
  };

export const performFetchTrainerEventsByClientId: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (
    id: string,
    trainings: TrainingDTO[] = [],
    handleFetched:
      | React.Dispatch<React.SetStateAction<boolean>>
      | undefined = undefined,
  ) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/trainer-events/client/${id}`);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_FETCH_TRAINER_EVENTS,
      request,
      dispatch,
      {},
    );

    if (result && result.status === 200) {
      dispatch(
        setTrainerEvents({
          trainerEvents: remapTrainerEventToClientsEvent(
            result.data as TrainerEventDTO[],
            trainings,
          ),
        }),
      );
    }
    if (handleFetched) {
      handleFetched(true);
    }
  };

export const performAddNewTrainerEvent: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (event: EventDTO, loggedUser: UserDTO, onCreateCallback: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const trainerEvent = {
      dateFrom: event.start.toString(),
      dateTo: event.end ? event.end.toString() : event.start.toString(),
      title: event.title,
      client: event.client,
      trainer: loggedUser,
      location: event.location,
    };
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/trainer-events/`, trainerEvent);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_ADD_NEW_TRAINER_EVENT,
      request,
      dispatch,
      {},
    );

    if (result && result.status === 200) {
      dispatch(
        addTrainerEvent({
          trainerEvent: remapEventToCalendarEvent([
            result.data as TrainerEventDTO,
          ])[0],
        }),
      );
      onCreateCallback();
    }
  };

export const performUpdateTrainerEvent: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (event: EventDTO, loggedUser: UserDTO, onUpdateCallback: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const trainerEvent = {
      id: event.id,
      dateFrom: event.start.toString(),
      dateTo: event.end ? event.end.toString() : event.start.toString(),
      title: event.title,
      client: event.client,
      trainer: loggedUser,
      location: event.location,
    };
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('put', `/trainer-events/${event.id}`, trainerEvent);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_UPDATE_TRAINER_EVENT,
      request,
      dispatch,
      {},
    );

    if (result && result.status === 200) {
      dispatch(
        updateTrainerEvent({
          trainerEvent: remapEventToCalendarEvent([
            result.data as TrainerEventDTO,
          ])[0],
        }),
      );
      onUpdateCallback();
    }
  };
