// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserResponseDto
 */
export interface UserResponseDto {
  /**
   *
   * @type {number}
   * @memberof UserResponseDto
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  roles: string;
}

export function UserResponseDtoFromJSON(json: any): UserResponseDto {
  return UserResponseDtoFromJSONTyped(json, false);
}

export function UserResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserResponseDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: json['userId'],
    email: json['email'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    roles: json['roles'],
  };
}

export function UserResponseDtoToJSON(value?: UserResponseDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    email: value.email,
    firstName: value.firstName,
    lastName: value.lastName,
    roles: value.roles,
  };
}
