// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MarkMessagesReadResponseDto
 */
export interface MarkMessagesReadResponseDto {
  /**
   *
   * @type {string}
   * @memberof MarkMessagesReadResponseDto
   */
  message: string;
}

export function MarkMessagesReadResponseDtoFromJSON(
  json: any,
): MarkMessagesReadResponseDto {
  return MarkMessagesReadResponseDtoFromJSONTyped(json, false);
}

export function MarkMessagesReadResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MarkMessagesReadResponseDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: json['message'],
  };
}

export function MarkMessagesReadResponseDtoToJSON(
  value?: MarkMessagesReadResponseDto | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
  };
}
