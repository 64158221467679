/** @jsx jsx */
import { FC, useState, useEffect, useCallback } from 'react';
import { jsx, css } from '@emotion/react';
import { useImmer } from 'use-immer';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { ProgressItem } from './ProgressItem';
import { ModalWindow } from '../../shared/ModalWindow';
import { AppTextInput } from '../../shared/Forms';
import {
  decimalNumberRule,
  requiredRule,
} from '../../shared/helpers/FormRules';
import { WeightChart } from './WeightChart';
import {
  performAddWeightProgress,
  performFetchProgress,
  performPatchWeightProgress,
} from '../../../store/progress/actions';
import { WeightEditRow } from './WeightEditRow';
import { OperationEnum } from '../../../enums/OperationEnum';
import { WeightProgressDTO } from '../../../strapi/WeightProgressDTO';

interface Props {
  performAddWeightProgress: typeof performAddWeightProgress;
  performFetchProgress: typeof performFetchProgress;
  performPatchWeightProgress: typeof performPatchWeightProgress;
  userId: string;
  weightProgress: WeightProgressDTO[];
  isClient: boolean;
}

export const WeightWrapper: FC<Props> = ({
  performFetchProgress,
  performAddWeightProgress,
  performPatchWeightProgress,
  userId,
  weightProgress,
  isClient,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [weightData, setWeightData] = useImmer(weightProgress);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setWeightData(() => weightProgress);
  }, [weightProgress, setWeightData]);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const openEditModal = useCallback(() => {
    setIsEditModalOpen(true);
  }, []);

  const onSubmitNewWeight = (e: unknown) => {
    const dataToSave = {
      weight: Number(e.weight),
      user: userId,
    };

    performAddWeightProgress(dataToSave, () => {
      setIsModalOpen(false);

      performFetchProgress(userId);
    });
  };

  const onEditSubmit = () => {
    performPatchWeightProgress(weightData, () => {
      setIsEditModalOpen(false);
      performFetchProgress(userId);
    });
  };

  const editWeight = (id: string, value: number) => {
    const rowIndex = weightData.findIndex((item: any) => item.id === id);

    if (rowIndex !== -1) {
      setWeightData(draft => {
        draft[rowIndex].weight = value;
        draft[rowIndex].operation = OperationEnum.UPDATED;
      });
    }
  };

  const deleteWeight = (id: string) => {
    const rowIndex = weightData.findIndex((item: any) => item.id === id);

    if (rowIndex !== -1) {
      setWeightData(draft => {
        draft[rowIndex].operation = OperationEnum.DELETED;
      });
    }
  };

  let weight;

  if (weightData.length > 0) {
    weight = weightData[0].weight;
  }

  return (
    <ProgressItem
      title={<Trans>Hmotnost</Trans>}
      titleAdd={<Trans>Přidat hmotnost</Trans>}
      // TODO add functions
      numberOfInputs={weightProgress.length}
      handleAdd={openModal}
      handleEdit={openEditModal}
      canAdd={!isClient}
      canModify={!isClient}
    >
      <ModalWindow
        headerText={<Trans>Nové vážení</Trans>}
        isOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        agreeButtonText={<Trans>Přidat váhu</Trans>}
        onSubmit={onSubmitNewWeight}
        closeIcon
        disableSubmitBtnOnError
        fullScreen={isMobile}
        mobileFooter
      >
        <AppTextInput
          type="number"
          label="Váha"
          variant="outlined"
          name="weight"
          inputProps={{ step: 0.1 }}
          rules={{ pattern: decimalNumberRule, ...requiredRule }}
          defaultValue={weight}
        />
      </ModalWindow>

      <ModalWindow
        headerText={<Trans>Upravit hmotnost</Trans>}
        isOpen={isEditModalOpen}
        handleClose={() => {
          setIsEditModalOpen(false);

          setWeightData(() => weightProgress);
        }}
        agreeButtonText={<Trans>Uložit</Trans>}
        onSubmit={onEditSubmit}
        cancelButtonText={<Trans>Zrušit</Trans>}
        closeIcon
        disableSubmitBtnOnError
        fullScreen={isMobile}
        mobileFooter
      >
        <div css={wrapperCss}>
          {weightData.filter(
            (item: any) => item.operation !== OperationEnum.DELETED,
          ).length > 0 ? (
            <div>
              {weightData
                .filter((item: any) => item.operation !== OperationEnum.DELETED)
                .map((item: any) => (
                  <WeightEditRow
                    item={item}
                    key={item.id}
                    deleteWeight={deleteWeight}
                    editWeight={editWeight}
                  />
                ))}
            </div>
          ) : (
            <Typography>
              <Trans>Žádné záznamy nenalezeny.</Trans>
            </Typography>
          )}
        </div>
      </ModalWindow>

      {weightProgress.length > 0 ? (
        <div>
          <WeightChart weightProgress={weightProgress} />
        </div>
      ) : (
        <Typography>
          <Trans>Žádné záznamy nenalezeny.</Trans>
        </Typography>
      )}
    </ProgressItem>
  );
};

const wrapperCss = css`
  max-height: 80vh;
`;
