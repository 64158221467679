/** @jsx jsx */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, jsx, SerializedStyles } from '@emotion/react';
import { Button, Grid, IconButton } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  TrainingDTO,
  TrainingExerciseDTO,
  TrainingSectionPartsDTO,
} from '../../../../strapi/TrainingDTO';
import {
  COLORS,
  EXTENDED_MENU_HEIGHT,
  MPX,
  TOP_MENU_HEIGHT,
} from '../../../../styles/themes';
import TrainingDetailDivider from './headerTrainingComponents/TrainingDetailDivider';
import SerieOpakovanieVahaPauzaRow from './headerTrainingComponents/SerieRepeatWeightPauseRow';
import SerieOpakovanieVahaPauzaDataRow from './headerTrainingComponents/SerieRepeatWeightPauseDataRow';
import { RepeatsModifierEnum } from '../../../../enums/RepeatsModifierEnum';
import ExerciseRow from './superSerieDescriptionComponents/ExerciseRow';
import ImageCarousel from '../../shared/ImageCarousel';
import {
  getImagesFromExerciseForCarousel,
  getDataForSpecificSerieOrCircuit,
  convertNumberToMinutesAndSeconds,
  handleSerieChange,
  orderByOrder,
} from '../../helper/functions';
import { TrainingExerciseKeys } from '../../../../enums/TrainingTypesEnum';
import { titleCss } from '../../shared/css';
import RunningCircuit from './circuitExerciseComponents/RunningCircuit';
import { changeCircularRunning } from '../../../../store/general/actions';
import {
  getExercisesOfInterest,
  getSectionsOfInterest,
} from '../../../../store/trainingParts/selectors';
import { SectionOrder } from '../../../../interfaces/enums';
import { getCircularRunning } from '../../../../store/general/selectors';

export interface CircuitExerciseDescriptionDetailProps {
  sectionPart?: TrainingSectionPartsDTO;
  training: TrainingDTO;
  detailLink: (exerciseId: string) => string;
}

const CircuitExerciseDescriptionDetail: React.FC<CircuitExerciseDescriptionDetailProps> =
  ({ sectionPart, training, detailLink }) => {
    const [circuitOrder, setCircuitOrder] = useState(1);
    const dispatch = useDispatch();
    const { currentExercise } = useSelector(getExercisesOfInterest);
    const { currentSection } = useSelector(getSectionsOfInterest);
    const isCircuitRunning = useSelector(getCircularRunning);

    if (!sectionPart) {
      return null;
    }

    const handleCircuit = (number: number) => {
      handleSerieChange(
        number,
        circuitOrder,
        sectionPart.series,
        setCircuitOrder,
      );
    };

    const getDataForSpecificCircuit = (
      trainingExercise: TrainingExerciseDTO,
      type: TrainingExerciseKeys,
    ) => getDataForSpecificSerieOrCircuit(trainingExercise, type, circuitOrder);

    const orderedExercises: TrainingExerciseDTO[] = orderByOrder(
      sectionPart.training_exercises,
    );

    const getLinks = () =>
      orderedExercises.map(te => detailLink(te.exercise.id));

    if (isCircuitRunning) {
      return (
        <RunningCircuit
          orderedExercises={orderedExercises}
          pauseAfterCircuit={sectionPart.pause}
          numberOfSeries={sectionPart.series}
          sectionPartId={sectionPart.id}
          training={training}
          detailLink={detailLink}
        />
      );
    }
    return (
      <div css={wrapperCss}>
        <ImageCarousel
          images={getImagesFromExerciseForCarousel(
            orderedExercises,
            getLinks(),
          )}
        />
        <div css={titleCss}>
          {sectionPart.order + 1}. <Trans>Kruhový trénink</Trans>
        </div>
        <SerieOpakovanieVahaPauzaRow isCircuit />
        <SerieOpakovanieVahaPauzaDataRow
          series={sectionPart.series}
          pause={sectionPart.pause}
          pauseModifier={RepeatsModifierEnum.Seconds}
        />
        <TrainingDetailDivider />
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            css={arrowsWrapperCss}
          >
            <IconButton
              css={arrowCss(circuitOrder === 1)}
              onClick={e => {
                e.preventDefault();
                handleCircuit(-1);
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Grid
              item
              container
              direction="column"
              xs={4}
              alignItems="center"
              css={textCss}
            >
              <Grid item css={seriesTitleCss}>
                <Trans>Kruh</Trans>
              </Grid>
              <Grid item css={seriesCounterCss}>
                {circuitOrder}/{sectionPart.series}
              </Grid>
            </Grid>
            <IconButton
              css={arrowCss(circuitOrder === Number(sectionPart.series))}
              onClick={e => {
                e.preventDefault();
                handleCircuit(1);
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Grid>
          <div css={exercisesCss}>
            {orderedExercises.map(trainingEx => {
              const lastExerciseId =
                orderedExercises[orderedExercises.length - 1].id;
              return (
                <ExerciseRow
                  key={trainingEx.id}
                  title={trainingEx.exercise.title.text}
                  repeats={getDataForSpecificCircuit(
                    trainingEx,
                    TrainingExerciseKeys.circuit,
                  )}
                  weight={getDataForSpecificCircuit(
                    trainingEx,
                    TrainingExerciseKeys.weights,
                  )}
                  repeatsModifier="s"
                  detailLink={detailLink(trainingEx.exercise.id)}
                  pause={
                    !trainingEx.pauseAfterExercise &&
                    lastExerciseId === trainingEx.id
                      ? ''
                      : convertNumberToMinutesAndSeconds(
                          trainingEx.pauseAfterExercise,
                        )
                  }
                />
              );
            })}
          </div>
          <div css={phantomCss}>&nbsp;</div>
        </Grid>
      </div>
    );
  };

export default CircuitExerciseDescriptionDetail;

const wrapperCss = css`
  color: white;
`;

const phantomCss = css`
  height: ${EXTENDED_MENU_HEIGHT}px;
`;

const arrowsWrapperCss = css`
  padding-left: 2vh;
  padding-right: 2vh;
  margin-top: ${1.75 * MPX}px !important;
`;

const arrowCss = (disabled: boolean): SerializedStyles => css`
  background-color: ${!disabled ? COLORS.baseOrange : 'transparent'} !important;
  color: ${!disabled ? COLORS.white : COLORS.gray4} !important;
  border-radius: ${MPX}px !important;
  border: ${!disabled
    ? `${0.4 * MPX}px solid transparent`
    : `${0.4 * MPX}px solid ${COLORS.gray4}`} !important;
`;

const textCss = css`
  color: ${COLORS.gray4};
  font-size: large;
`;

const exercisesCss = css`
  padding-top: ${MPX * 1}vh;
`;

const seriesTitleCss = css`
  font-size: ${MPX * 3.5}px;
`;

const seriesCounterCss = css`
  font-size: ${MPX * 6}px;
  color: ${COLORS.white};
  font-weight: 600;
`;
