export enum RoleType {
  TRAINER = 'trainer',
  CLIENT = 'client',
}

export interface RoleDTO {
  id: string;
  name: string;
  description: string;
  type: RoleType;
}
