/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import * as React from 'react';
import {
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { Link as RouterLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { Close } from '@material-ui/icons';
import { MPX } from '../../styles/themes';
import { iconMobileCss } from '../../styles/general';

export interface HeadingCloseButtonHeaderProps {
  title: string;
  link?: string;
  closeFunction?: () => void;
}

export const HeadingWithCloseButton: React.FC<HeadingCloseButtonHeaderProps> =
  ({ title, link, closeFunction }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Grid container alignItems="center" spacing={1} css={headerWrapper}>
        <Grid item md={3}>
          {isMobile && !closeFunction && link && (
            <Link color="secondary" component={RouterLink} to={link}>
              <IconButton
                aria-label="close"
                css={[iconCss(isMobile), isMobile && iconMobileCss]}
              >
                <Close css={iconMobileCss} />
              </IconButton>
            </Link>
          )}
          {isMobile && closeFunction && (
            <IconButton
              aria-label="close"
              css={[isMobile && iconMobileCss]}
              onClick={e => {
                e.preventDefault();
                closeFunction();
              }}
            >
              <Close css={iconMobileCss} />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={10} md={6} css={titleWrapper(isMobile)}>
          <Typography variant="h5" color="textPrimary">
            <Trans id={title} />
          </Typography>
        </Grid>
        {isMobile}
        <Grid container item justify="flex-end" md={3}>
          {!isMobile && link && (
            <Link color="secondary" component={RouterLink} to={link}>
              <IconButton aria-label="close" css={iconCss(isMobile)}>
                <Close />
              </IconButton>
            </Link>
          )}
        </Grid>
      </Grid>
    );
  };

const headerWrapper = css`
  width: 100%;
  padding: 10px 10px;
`;

const titleWrapper = (isMobile: boolean) => css`
  width: 100%;
  display: flex;
  margin: 0 auto;
  text-align: ${isMobile ? 'left' : 'center'};
`;

const iconCss = (isMobile: boolean) => css`
  width: ${isMobile && MPX * 6}px;
  height: ${isMobile && MPX * 3}px;
`;
