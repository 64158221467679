import { makeStyles, createStyles } from '@material-ui/core/styles';

export const usePopupMenuItemStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#034ea1',
      color: 'white',
      padding: '15px',
      '&:hover': {
        backgroundColor: 'rgba(3,78,161, 0.8) !important',
      },
    },
  }),
);
