/** @jsx jsx */
import { useState, Fragment, FC, useEffect } from 'react';
import { jsx, css, SerializedStyles } from '@emotion/react';
import {
  Grid,
  Avatar,
  OutlinedInput,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import AddBoxIcon from '@material-ui/icons/AddBox';
import Pause from '@material-ui/icons/Pause';
import RemoveBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { Delete } from '@material-ui/icons';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import { Trans } from '@lingui/macro';
import { PopupMenu } from '../../shared/PopupMenu';
import {
  inputNameState,
  ExerciseInputsEnum,
} from '../../../code/helpers/trainingExerciseInputs';
import {
  updateExerciseAttribute,
  updateExerciseWeightsAndRepeatsAccordingToNumberOfSeries,
} from '../../../code/helpers/trainingExercise';
import { getImageUrl } from '../../../code/helpers/library';
import { MPX, COLORS } from '../../../styles/themes';
import { AlertDialog } from '../../shared/AlertDialog';
import {
  TrainingExerciseWithOperation,
  ExerciseSerie,
} from '../../../interfaces/traning';
import { OperationEnum } from '../../../enums/OperationEnum';
import { selectedItemCss } from './SetWrapper';
import { generatedExerciseSeriesRows } from '../../../code/helpers/trainingExerciseSeries';
import { ExerciseSeriesRow } from './comps/ExerciseSeriesRow';
import { PauseRow } from './comps/PauseRow';
import {
  PauseItemsTimeCircle,
  PauseItemsTimeNotCircle,
  TrainingSectionTypeEnum,
} from '../../../enums/TrainingTypesEnum';
import { fullWidthCss } from '../../../styles/general';
import {
  PauseModifierEnum,
  RepeatsModifierEnum,
} from '../../../enums/RepeatsModifierEnum';
import { validateText } from '../../../code/helpers/trainingSetupHelpers';
import {
  decimalNumberSeparated,
  integerNumber,
  numberSeparatedBySemicolon,
} from '../../shared/helpers/FormRules';
import { FormatEnum } from '../../../strapi/MediaFileDTO';
import { TimeDropdownPicker } from './comps/TimeDropdownPicker';
import { ExerciseTrainingTypeEnum } from '../../../strapi/ExerciseDTO';
import { headerDescCss } from './SuperSet';

interface TrainingExerciseProps {
  exercise: TrainingExerciseWithOperation;
  isSuperSet: boolean;
  isLast: boolean;
  superSetType: TrainingSectionTypeEnum;
  previousAfterPause?: number | undefined;
  wrapperSeries?: number;
  updateTrainingExercise: (
    exercise: TrainingExerciseWithOperation,
    repeat?: number,
  ) => void;
}

export const TrainingExercise: FC<TrainingExerciseProps> = ({
  exercise,
  superSetType,
  isSuperSet,
  isLast,
  previousAfterPause,
  wrapperSeries,
  updateTrainingExercise,
}) => {
  const thumbUrl = getImageUrl(
    exercise.exercise.Illustration,
    65,
    45,
    FormatEnum.MEDIUM,
  );

  const [isCommentDialogOpen, setCommentDialogOpen] = useState(false);
  const [cursorState, updateCursorState] = useState<number | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [series, setSeries] = useState(
    generatedExerciseSeriesRows(exercise, superSetType, wrapperSeries),
  );
  const [seriesInput, setSeriesInput] = useState(exercise.series);
  const [isSeriesOpened, toggleSeries] = useState(false);
  const [hoverAfterPause, setHoverAfterPause] = useState(false);
  const [comment, setComment] = useState(
    exercise.comment ? exercise.comment : '',
  );

  useEffect(() => {
    if (exercise) {
      setSeries(
        generatedExerciseSeriesRows(exercise, superSetType, wrapperSeries),
      );
    }
  }, [exercise, wrapperSeries, superSetType]);

  const handleDelete = (): void => {
    const updatedExercise: TrainingExerciseWithOperation = {
      ...exercise,
      operation: OperationEnum.DELETED,
    };
    updateTrainingExercise(updatedExercise);
  };

  const handleChange = (value: number | string, name: string): void => {
    const stateToChange = inputNameState.find(
      i => i.name === (name as ExerciseInputsEnum),
    );

    let tmpExercise = exercise;
    if (stateToChange) {
      if (name === ExerciseInputsEnum.SERIES) {
        tmpExercise = updateExerciseWeightsAndRepeatsAccordingToNumberOfSeries(
          tmpExercise,
          value,
        );
      }
      const updatedExercise = updateExerciseAttribute(
        // kdyz je to pauza prepocitej pause input
        name === ExerciseInputsEnum.PAUSE_MODIFIER
          ? updateExerciseAttribute(
              tmpExercise,
              value === PauseModifierEnum.Seconds
                ? exercise.pause * 60
                : exercise.pause * (1 / 60),
              'pause',
            )
          : tmpExercise,
        value,
        stateToChange.stateAttr,
      );

      // prihod do updatu value opakovani pri kruhovzm treninky at se propisou do kazdeho kol
      updateTrainingExercise(
        updatedExercise,
        name === ExerciseInputsEnum.REPEATS &&
          superSetType === TrainingSectionTypeEnum.CircuitTraining
          ? value
          : undefined,
      );
    }
  };

  const handlePauseAfterExerciseChange = (pause: number): void => {
    const updatedExercise = updateExerciseAttribute(
      exercise,
      pause,
      'pauseAfterExercise',
    );
    updateTrainingExercise(updatedExercise);
  };

  const decideValueForMergedInput = (
    inputName: ExerciseInputsEnum,
    value: number,
  ) => {
    if (
      inputName === ExerciseInputsEnum.REPEATS &&
      (exercise.exercise.exerciseTrainingType ===
        ExerciseTrainingTypeEnum.TIME ||
        superSetType === TrainingSectionTypeEnum.CircuitTraining)
    ) {
      return value > 90 ? `${value / 60}m` : `${value}s`;
    }

    return value;
  };
  // Values from WIEGTHS or REPEATS inputs
  // distributed using semicolon to the generated components
  const mergeIntoParentInput = (
    value: string,
    serieKey: number,
    inputName: ExerciseInputsEnum,
  ): void => {
    const updatedSerie = series.find(s => s.key === serieKey);
    const attributeName = inputNameState.find(ins => ins.name === inputName);

    if (updatedSerie && attributeName) {
      const exerciseUpdated: ExerciseSerie = {
        ...updatedSerie,
        [attributeName.stateAttr]: value,
      };

      const updatedSeries = series.map(s =>
        s.key === serieKey ? exerciseUpdated : s,
      );

      const singleValueInInput = updatedSeries
        .filter(us => us[attributeName.stateAttr] !== '')
        .map(us =>
          decideValueForMergedInput(inputName, us[attributeName.stateAttr]),
        );

      const isSameSingleValueInInput = singleValueInInput.every(
        item => item === singleValueInInput[0],
      );

      const finalValue = isSameSingleValueInInput
        ? singleValueInInput[0]
        : singleValueInInput.join(';');

      setSeries(updatedSeries);
      handleChange(finalValue, inputName);
    }
  };

  const updateSeriesMainInput = (value: string, input: string) => {
    handleChange(value, input);
  };

  // https://stackoverflow.com/questions/46000544/react-controlled-input-cursor-jumps
  const handleCursorShouldntJump = (
    // keyboard event is not generic?
    // @ts-ignore
    e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (cursorState != null) {
      // You only need to update your select position in the onKeyUp handler:
      e.currentTarget.selectionStart = cursorState;
      e.currentTarget.selectionEnd = cursorState;
      // hack na to aby se nescrollnul input nakonec
      e.currentTarget.scrollLeft = cursorState;
    }
  };

  const changeNumericValueSeparatedWithSemi = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    updateCursorState(e.target.selectionStart);
    // check if numbers matches the regex (numbers separated by semicolon). if yes then handle update
    if (e.target.value.match(numberSeparatedBySemicolon.value)) {
      handleChange(e.target.value, e.target.name);
    }
  };
  const renderInputs = () => (
    <Grid
      container
      item
      sm={8}
      css={!isMobile && [inputsCss, !isSuperSet && !isMobile && setPaddingCss]}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} sm={11}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={2}>
              {!isSuperSet && (
                <OutlinedInput
                  css={inputCss(isMobile)}
                  type="number"
                  inputMode="numeric"
                  name={ExerciseInputsEnum.SERIES}
                  disabled={!exercise.exercise.hasSeries}
                  fullWidth
                  inputProps={{ min: 1 }}
                  endAdornment={isMobile ? 'x' : undefined}
                  value={seriesInput}
                  // in Safari, user can write strings into input, but as it is error onBlur event it will default into 4 series
                  onChange={(e: any) => {
                    setSeriesInput(e.target.value);
                  }}
                  // added because user can write into input,
                  // f.e. DEL or typing into input should not generate new series when typing. Should update onBlur only
                  onBlur={(e: any): void => {
                    // if input is cleared by user, we defaultly add 4 series
                    setSeriesInput(e.target.value || 4);
                    updateSeriesMainInput(e.target.value || 4, e.target.name);
                  }}
                  margin="dense"
                />
              )}
            </Grid>
            <Grid container item xs={4}>
              {superSetType !== TrainingSectionTypeEnum.CircuitTraining &&
              exercise.exercise.exerciseTrainingType ===
                ExerciseTrainingTypeEnum.REPEAT ? (
                <OutlinedInput
                  css={inputCss(isMobile)}
                  disabled={!exercise.exercise.hasRepeats || isSeriesOpened}
                  name={ExerciseInputsEnum.REPEATS}
                  value={exercise.repeats}
                  onChange={e => changeNumericValueSeparatedWithSemi(e)}
                  onKeyUp={handleCursorShouldntJump}
                  endAdornment={RepeatsModifierEnum.Quantity}
                  type="tel"
                />
              ) : (
                <TimeDropdownPicker
                  value={exercise.repeats}
                  hasPause={false}
                  css={inputCss(isMobile)}
                  itemsForSelect={
                    superSetType !== TrainingSectionTypeEnum.CircuitTraining
                      ? PauseItemsTimeNotCircle
                      : PauseItemsTimeCircle
                  }
                  handlePauseChange={e => {
                    handleChange(e.toString(), ExerciseInputsEnum.REPEATS);
                  }}
                />
              )}
            </Grid>

            <Grid item xs={3}>
              <OutlinedInput
                css={inputCss(isMobile)}
                disabled={!exercise.exercise.hasWeights || isSeriesOpened}
                name={ExerciseInputsEnum.WEIGHTS}
                inputProps={{
                  inputMode: 'numeric',
                  type: 'tel',
                }}
                onChange={e => changeNumericValueSeparatedWithSemi(e)}
                value={exercise.weights}
                error={validateText(
                  exercise.weights,
                  decimalNumberSeparated.value,
                )}
                endAdornment="kg"
                onKeyUp={handleCursorShouldntJump}
              />
            </Grid>

            {!isSuperSet && (
              <Grid container item xs={3}>
                <TimeDropdownPicker
                  hasPause={exercise.exercise.hasPause}
                  value={exercise.pause}
                  handlePauseChange={e =>
                    handleChange(e, ExerciseInputsEnum.PAUSE)
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item sm={1}>
            {renderPopUpMenu()}
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const renderPopUpMenu = (): JSX.Element => {
    const deleteText = <Trans>Smazat</Trans>;
    const addCommentText = <Trans>Přidat komentář</Trans>;
    const addPause = <Trans>Přidat pauzu za cvik</Trans>;

    const determineIfPause = [];

    const pause = {
      label: addPause.props.id,
      icon: Pause,
      onClick: () => {
        const value = previousAfterPause || 10;
        const updatedExercise = updateExerciseAttribute(
          exercise,
          value,
          'pauseAfterExercise',
        );
        updateTrainingExercise(updatedExercise);
      },
    };

    if (superSetType !== TrainingSectionTypeEnum.SuperSet) {
      determineIfPause.push(pause);
    }

    return (
      <PopupMenu
        menuItems={[
          ...[
            ...determineIfPause,
            {
              label: addCommentText.props.id,
              icon: ModeCommentOutlinedIcon,
              onClick: () => setCommentDialogOpen(!isCommentDialogOpen),
            },
            {
              label: deleteText.props.id,
              icon: Delete,
              onClick: () => handleDelete(),
            },
          ],
        ]}
      />
    );
  };

  const renderDialog = (): JSX.Element => (
    <AlertDialog
      isOpen={isCommentDialogOpen}
      fullWidth
      closeIcon
      handleClose={() => {
        setCommentDialogOpen(!isCommentDialogOpen);
        setComment(exercise.comment);
      }}
      handleAgree={() => {
        setCommentDialogOpen(!isCommentDialogOpen);
        handleChange(comment, ExerciseInputsEnum.COMMENT);
      }}
      agreeButtonText={<Trans>Přidat komentář</Trans>}
      headerText={<Trans>Komentář ke cviku</Trans>}
      content={
        <TextField
          data-at="add-exercise-comment-input"
          name={ExerciseInputsEnum.COMMENT}
          variant="outlined"
          label={<Trans>Komentář</Trans>}
          fullWidth
          multiline
          rows={6}
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
      }
    />
  );

  const showAfterPause = (): boolean => {
    if (isLast) {
      return false;
    }
    if (superSetType === TrainingSectionTypeEnum.SuperSet) {
      return false;
    }
    if (
      superSetType === TrainingSectionTypeEnum.SingleExercise &&
      exercise.pauseAfterExercise === 0
    ) {
      return false;
    }
    if (superSetType === TrainingSectionTypeEnum.CircuitTraining) {
      return true;
    }
    return !!exercise.pauseAfterExercise;
  };

  const showAfterPauseOnHover = (): boolean => {
    if (isLast && superSetType === TrainingSectionTypeEnum.SingleExercise) {
      return false;
    }
    if (
      superSetType === TrainingSectionTypeEnum.SingleExercise &&
      !exercise.pauseAfterExercise
    ) {
      return true;
    }
    if (
      (superSetType === TrainingSectionTypeEnum.CircuitTraining ||
        superSetType === TrainingSectionTypeEnum.SuperSet) &&
      isLast &&
      !exercise.pauseAfterExercise
    ) {
      return true;
    }

    return false;
  };

  const renderPauseRows = (): JSX.Element => (
    <div css={fullWidthCss}>
      {showAfterPause() && (
        <PauseRow
          pause={exercise.pauseAfterExercise}
          isSuperSet={false}
          handlePauseChange={handlePauseAfterExerciseChange}
        />
      )}
      {showAfterPauseOnHover() && !isMobile && (
        <div
          css={inBetweenPauseDivCss}
          onMouseEnter={() => setHoverAfterPause(!hoverAfterPause)}
          onMouseLeave={() => setHoverAfterPause(!hoverAfterPause)}
        >
          {hoverAfterPause && (
            <div
              css={absolutePauseInfoCss}
              onClick={() => {
                const value = previousAfterPause || 10;
                const updatedExercise = updateExerciseAttribute(
                  exercise,
                  value,
                  'pauseAfterExercise',
                );
                updateTrainingExercise(updatedExercise);
              }}
            >
              <Trans>Přidat pauzu</Trans>
            </div>
          )}
        </div>
      )}
    </div>
  );
  const renderHeaderForInputs = () => (
    <Grid container item alignItems="center" css={headerDescCss}>
      <Grid item xs={2}>
        {/* v supersetu nechci videt kolo ani serii  */}
        {!isSuperSet &&
          (superSetType === TrainingSectionTypeEnum.CircuitTraining ? (
            <Trans>Kolo</Trans>
          ) : (
            <Trans>Série</Trans>
          ))}
      </Grid>
      <Grid item xs={4}>
        <Trans>Opakování</Trans>
      </Grid>
      <Grid item xs={3}>
        <Trans>Váha</Trans>
      </Grid>
      <Grid item xs={3}>
        {superSetType === TrainingSectionTypeEnum.SingleExercise && (
          <Trans>Pauza</Trans>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Fragment>
      {exercise.operation !== OperationEnum.DELETED && (
        <div>
          <Grid
            container
            css={[exerciseWrapper(isMobile, isLast), selectedItemCss]}
          >
            {isMobile ? (
              <Grid
                container
                alignItems="center"
                direction="column"
                spacing={isMobile ? 1 : undefined}
              >
                <Grid container alignItems="center" item>
                  <Grid item xs={1}>
                    {!isSeriesOpened ? (
                      <AddBoxIcon
                        css={addBoxIconCss}
                        fontSize="small"
                        onClick={() => toggleSeries(!isSeriesOpened)}
                      />
                    ) : (
                      <RemoveBoxIcon
                        css={addBoxIconCss}
                        fontSize="small"
                        onClick={() => toggleSeries(!isSeriesOpened)}
                      />
                    )}
                  </Grid>

                  <Grid item xs={10}>
                    <span css={titleCss}>{exercise.exercise.title.text}</span>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    item
                    xs={1}
                  >
                    {renderPopUpMenu()}
                  </Grid>
                </Grid>
                {renderHeaderForInputs()}
                {renderInputs()}
                {renderDialog()}
              </Grid>
            ) : (
              <Grid container alignItems="center" justify="space-evenly">
                <Grid item xs={4}>
                  <Grid container alignItems="center" justify="space-evenly">
                    <Grid item xs={1}>
                      {!isSeriesOpened ? (
                        <AddBoxIcon
                          css={addBoxIconCss}
                          fontSize="small"
                          onClick={() => toggleSeries(!isSeriesOpened)}
                        />
                      ) : (
                        <RemoveBoxIcon
                          css={addBoxIconCss}
                          fontSize="small"
                          onClick={() => toggleSeries(!isSeriesOpened)}
                        />
                      )}
                    </Grid>
                    {!isMobile && (
                      <Grid item>
                        <Avatar
                          variant="square"
                          src={thumbUrl}
                          css={avatarCss}
                        />
                      </Grid>
                    )}

                    <Grid item xs={10} sm={7}>
                      <span css={titleCss}>{exercise.exercise.title.text}</span>
                    </Grid>
                  </Grid>
                </Grid>
                {renderInputs()}
                {renderDialog()}
              </Grid>
            )}
            <Grid container spacing={isMobile ? 4 : undefined}>
              <Grid item xs={2} />
              <Grid item xs={8}>
                {exercise.comment && (
                  <div css={commentTextCss}>{exercise.comment}</div>
                )}
              </Grid>
            </Grid>
            {isSeriesOpened &&
              series.map(serie => (
                <ExerciseSeriesRow
                  key={serie.key}
                  exercise={exercise}
                  serie={serie}
                  serieName={serie.name}
                  isSuperSet={isSuperSet}
                  type={superSetType}
                  mergeIntoParent={mergeIntoParentInput}
                />
              ))}
            {!isMobile && renderPauseRows()}
          </Grid>
          {isMobile && renderPauseRows()}
        </div>
      )}
    </Fragment>
  );
};

export const titleCss = css`
  color: ${COLORS.darkBlue};
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
`;

export const inputsCss = css`
  max-width: 80% !important;
`;

export const setPaddingCss = css`
  padding-left: ${5 * MPX}px;
`;

const exerciseWrapper = (isMobile: boolean, isLast: boolean) => css`
  margin-bottom: ${(isMobile ? 0 : 2) * MPX}px;
  margin-top: ${2 * MPX}px;
  padding: ${isMobile ? 2 * MPX : 0}px ${2 * MPX}px;
  padding-top: ${isMobile && 0};
  border-radius: ${isMobile && isLast && `0 0 10px 10px`};
  border-bottom: ${isMobile && !isLast && `1px solid ${COLORS.gray8}`};
`;

export const avatarCss = css`
  height: 45px !important;
  width: 65px !important;
  margin-right: ${2 * MPX}px !important;
`;

export const inputCss = (isMobile?: boolean): SerializedStyles => css`
  .MuiOutlinedInput-input {
    padding: 8.5px ${isMobile && isMobile ? 2 * MPX : 10}px;
    padding-right: ${isMobile && isMobile && 0};
  }

  padding-right: ${isMobile && isMobile && 2 * MPX}px !important;
`;

export const addBoxIconCss = css`
  color: ${COLORS.gray6};
`;

const commentTextCss = (isMobile: boolean) => css`
  color: ${COLORS.darkBlue};
  font-size: 11px;
  font-weight: 400;
  margin-top: ${isMobile && 2 * MPX}px;
  word-break: break-word;
`;

const inBetweenPauseDivCss = css`
  height: ${0.65 * MPX}px;
  position: relative;
  margin: 0px ${5 * MPX}px;
  margin-top: ${MPX}px;
  :hover {
    background-color: rgba(240, 90, 34, 0.20000000298023224);
  }
`;

const absolutePauseInfoCss = css`
  background-color: #fcded3;
  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.primaryDark};
  border-radius: 4px;
  padding: ${1.2 * MPX}px ${2.4 * MPX}px;
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translateY(-50%);
  cursor: pointer;
`;
