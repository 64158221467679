/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';
import { Trans } from '@lingui/macro';
import { Grid, Box } from '@material-ui/core';
import { FC } from 'react';
import { getFromToToDateText } from '../../../../code/helpers/calendarHelper';
import { EventDTO } from '../../../../interfaces/event';
import { COLORS, MPX } from '../../../../styles/themes';

interface TrainingItemProps {
  event: EventDTO;
}

export const TrainingItem: FC<TrainingItemProps> = ({ event }) => (
  <Grid container css={wrapperCss} direction="column" spacing={1}>
    <Grid item container alignItems="center">
      <div css={dotCss(event.textColor || COLORS.gray4)} />
      <Box fontWeight={400} fontSize={18} css={titleCss}>
        {event.title}
      </Box>
    </Grid>
    <Grid item css={stringCss(COLORS.gray7)}>
      {getFromToToDateText(
        event.start ? new Date(event.start) : undefined,
        event.end ? new Date(event.end) : undefined,
        event.location,
      )}
    </Grid>
    {event.isFinished && (
      <Grid item css={stringCss(COLORS.darkGreen)}>
        <Trans>Tento trénink byl dokončen</Trans>
      </Grid>
    )}
  </Grid>
);

const wrapperCss = css`
  border-bottom: 1px solid ${COLORS.gray5};
  padding: ${2 * MPX}px ${MPX}px;
`;

const titleCss = css`
  color: ${COLORS.blueDark};
`;
const dotCss = (color: string): SerializedStyles => css`
  background-color: ${color};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0px ${MPX}px;
`;
const stringCss = (color: string): SerializedStyles => css`
  color: ${color};
  font-size: 12pt;
  font-weight: 400;
`;
