// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DeleteMessageDto,
  DeleteMessageDtoFromJSON,
  DeleteMessageDtoFromJSONTyped,
  DeleteMessageDtoToJSON,
} from './';

/**
 *
 * @export
 * @interface DeleteMessageResponseDto
 */
export interface DeleteMessageResponseDto {
  /**
   *
   * @type {DeleteMessageDto}
   * @memberof DeleteMessageResponseDto
   */
  data: DeleteMessageDto;
  /**
   *
   * @type {string}
   * @memberof DeleteMessageResponseDto
   */
  message: string;
}

export function DeleteMessageResponseDtoFromJSON(
  json: any,
): DeleteMessageResponseDto {
  return DeleteMessageResponseDtoFromJSONTyped(json, false);
}

export function DeleteMessageResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DeleteMessageResponseDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: DeleteMessageDtoFromJSON(json['data']),
    message: json['message'],
  };
}

export function DeleteMessageResponseDtoToJSON(
  value?: DeleteMessageResponseDto | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: DeleteMessageDtoToJSON(value.data),
    message: value.message,
  };
}
