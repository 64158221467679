/** @jsx jsx */
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { css, jsx } from '@emotion/react';
import { Checkbox, Grid } from '@material-ui/core';

import TrainingDetailItemText from './TrainingDetailItemText';
import TrainingDetailIconNext from './TrainingDetailIconNext';
import {
  TrainingSectionDTO,
  TrainingSectionPartsDTO,
} from '../../../../strapi/TrainingDTO';
import { SectionType } from '../../../../interfaces/enums';
import { COLORS } from '../../../../styles/themes';
import { getExerciseRoute } from '../../helper/functions';

export interface TrainingDetailItem {
  title: string;
  orderOfExercise?: number;
  trainingSection: TrainingSectionPartsDTO | TrainingSectionDTO;
  sectionType: string;
  sectionId: string;
  partId?: string;
  exerciseId?: string;
  trainingExerciseId?: string;
  type: SectionType;
}

const TrainingDetailItem: React.FC<TrainingDetailItem> = ({
  title,
  orderOfExercise,
  trainingSection,
  sectionType,
  sectionId,
  partId,
  exerciseId,
  trainingExerciseId,
}) => {
  const checked = trainingSection ? trainingSection.isDone : false;
  const { id: trainingId } = useParams<{ id: string }>();

  return (
    <Grid
      item
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item xs={2}>
        {orderOfExercise && (
          <Checkbox css={checkboxCss} checked={checked} disabled />
        )}
      </Grid>
      <Grid item xs={8}>
        <TrainingDetailItemText number={orderOfExercise} title={title} />
      </Grid>
      <Grid item xs={2}>
        <Link
          css={linkCss}
          to={getExerciseRoute(
            trainingId,
            sectionId,
            partId,
            sectionType,
            exerciseId,
            trainingExerciseId,
          )}
        >
          {orderOfExercise ? (
            <TrainingDetailIconNext />
          ) : (
            <div css={phantomDiv} />
          )}
        </Link>
      </Grid>
    </Grid>
  );
};

export default TrainingDetailItem;

const checkboxCss = css`
  color: ${COLORS.white} !important;
  &.Mui-checked {
    color: ${COLORS.baseOrange} !important;
    background-color: none !important;
  }
`;

const linkCss = css`
  text-decoration: none !important;
`;

const phantomDiv = css`
  height: 1.5em;
`;
