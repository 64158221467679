import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import { AxiosResponse } from 'axios';
import { baseAsyncRequest } from '../actionHelpers';
import { makeServerCallAsync } from '../../code/helpers/api/api';
import { State } from '../combinedReducers';
import { AT as ActionTypes } from '../actionTypes';

import { CreateDocumentDTO, DocumentDTO } from '../../strapi/DocumentDTO';

export const setDocuments = action(
  ActionTypes.SET_DOCUMENTS,
  payload<{ documents: DocumentDTO[] }>(),
);

export const addDocument = action(
  ActionTypes.UPDATE_DOCUMENT,
  payload<{ document: DocumentDTO }>(),
);

export const updateDocument = action(
  ActionTypes.UPDATE_DOCUMENT,
  payload<{ document: DocumentDTO }>(),
);

export const deleteDocument = action(
  ActionTypes.DELETE_DOCUMENT,
  payload<{ id: string }>(),
);

export const performFetchDocs: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (userId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/documents?client=${userId}`);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_FETCH_DOCUMENTS,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;

      dispatch(setDocuments({ documents: data }));
    }
  };

export const performAddDocument: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (doc: CreateDocumentDTO, successCb?: any) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/documents`, doc);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_EDIT_DOCUMENTS,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;

      dispatch(addDocument({ document: data }));

      if (successCb) {
        successCb();
      }
    }
  };

export const performUpdateDoc: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (docId: string, name: string, successCb?: any) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('put', `/documents/${docId}`, {
        name,
      });

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_EDIT_DOCUMENTS,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;

      dispatch(updateDocument({ document: data }));

      if (successCb) {
        successCb();
      }
    }
  };

export const performDeleteDocument: ActionCreator<
  ThunkAction<Promise<void>, State, number, any>
> =
  (documentId: string, onSuccessCb: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('delete', `/documents/${documentId}`);

    const results = await baseAsyncRequest(
      ActionTypes.PERFORM_DELETE_DOCUMENT,
      request,
      dispatch,
    );

    if (results) {
      const { data } = results;

      onSuccessCb();

      dispatch(deleteDocument({ id: data.id }));
    }
  };
