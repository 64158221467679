/* eslint-disable */ module.exports = {
  languageData: {
    plurals: function (n, ord) {
      var s = String(n).split('.'),
        v0 = !s[1],
        t0 = Number(s[0]) == n,
        n10 = t0 && s[0].slice(-1),
        n100 = t0 && s[0].slice(-2);
      if (ord)
        return n10 == 1 && n100 != 11
          ? 'one'
          : n10 == 2 && n100 != 12
          ? 'two'
          : n10 == 3 && n100 != 13
          ? 'few'
          : 'other';
      return n == 1 && v0 ? 'one' : 'other';
    },
  },
  messages: {
    '(tr\xE9nink je sestaven)': '(tr\xE9nink je sestaven)',
    'Ahoj {name}': function (a) {
      return ['Ahoj ', a('name')];
    },
    'Aktivace \xFA\u010Dtu u\u017Eivatele':
      'Aktivace \xFA\u010Dtu u\u017Eivatele',
    'Chyba p\u0159i uklad\xE1n\xED zm\u011Bn':
      'Chyba p\u0159i uklad\xE1n\xED zm\u011Bn',
    'Co te\u010F? St\xE1hni si do mobilu na\u0161i appku <0>MotiVibe</0>':
      'Co te\u010F? St\xE1hni si do mobilu na\u0161i appku <0>MotiVibe</0>',
    'Co te\u010F? St\xE1hni si do mobilu na\u0161i appku<0>MotiVibe</0>':
      'Co te\u010F? St\xE1hni si do mobilu na\u0161i appku<0>MotiVibe</0>',
    Cvik: 'Cvik',
    'Cvik bude cvi\u010Den na po\u010Det opakov\xE1n\xED. Nap\u0159. 4 s\xE9rie po 10 opakov\xE1n\xEDch.':
      'Cvik bude cvi\u010Den na po\u010Det opakov\xE1n\xED. Nap\u0159. 4 s\xE9rie po 10 opakov\xE1n\xEDch.',
    'Cvik bude cvi\u010Den na \u010Das. Nap\u0159. 4 s\xE9rie po 20 sekund\xE1ch.':
      'Cvik bude cvi\u010Den na \u010Das. Nap\u0159. 4 s\xE9rie po 20 sekund\xE1ch.',
    'Cvik byl duplikov\xE1n.': 'Cvik byl duplikov\xE1n.',
    'Cvik byl upraven.': 'Cvik byl upraven.',
    'Cvik byl vytvo\u0159en.': 'Cvik byl vytvo\u0159en.',
    'Cviky byly odstran\u011Bny.': 'Cviky byly odstran\u011Bny.',
    'Cviky p\u0159idan\xE9 do tr\xE9ninku nejde z n\xE1zvu smazat':
      'Cviky p\u0159idan\xE9 do tr\xE9ninku nejde z n\xE1zvu smazat',
    Datum: 'Datum',
    'Datum narozen\xED': 'Datum narozen\xED',
    Do: 'Do',
    'Dokon\u010Den\xFD': 'Dokon\u010Den\xFD',
    'Dokument byl nahr\xE1n.': 'Dokument byl nahr\xE1n.',
    'Dokument byl odstran\u011Bn.': 'Dokument byl odstran\u011Bn.',
    'Dokument byl p\u0159ejmenov\xE1n.': 'Dokument byl p\u0159ejmenov\xE1n.',
    Dokumenty: 'Dokumenty',
    'Doplnit dal\u0161\xED \xFAdaje': 'Doplnit dal\u0161\xED \xFAdaje',
    'Druh aktivity': 'Druh aktivity',
    Duplikovat: 'Duplikovat',
    Filtr: 'Filtr',
    'Heslo bylo upraveno.': 'Heslo bylo upraveno.',
    Hledat: 'Hledat',
    'Hledat cviky': 'Hledat cviky',
    'Je n\xE1m l\xEDto, ale cviky pro dan\xE9 filtry neexistuj\xED.':
      'Je n\xE1m l\xEDto, ale cviky pro dan\xE9 filtry neexistuj\xED.',
    'Je n\xE1m l\xEDto, ale takov\xE9ho klienta se n\xE1m naj\xEDt nepoda\u0159ilo':
      'Je n\xE1m l\xEDto, ale takov\xE9ho klienta se n\xE1m naj\xEDt nepoda\u0159ilo',
    'Je\u0161t\u011B nem\xE1te tren\xE9rsk\xFD u\u010Det?':
      'Je\u0161t\u011B nem\xE1te tren\xE9rsk\xFD u\u010Det?',
    'Jm\xE9no a p\u0159ijmen\xED': 'Jm\xE9no a p\u0159ijmen\xED',
    Klient: 'Klient',
    'Klient byl odstran\u011Bn.': 'Klient byl odstran\u011Bn.',
    'Klient byl upraven.': 'Klient byl upraven.',
    'Klient byl vytvo\u0159en.': 'Klient byl vytvo\u0159en.',
    'Klient cvi\u010D\xED se mnou': 'Klient cvi\u010D\xED se mnou',
    'Klient cvi\u010D\xED s\xE1m': 'Klient cvi\u010D\xED s\xE1m',
    'Klient \u010Dek\xE1 na sv\u016Fj prvn\xED tr\xE9ninkov\xFD pl\xE1n':
      'Klient \u010Dek\xE1 na sv\u016Fj prvn\xED tr\xE9ninkov\xFD pl\xE1n',
    Klienti: 'Klienti',
    'Klienti budou cvi\u010Dit pouze pod Va\u0161\xEDm dohledem, nikdy ne sami. V\u0161echny jejich tr\xE9ninky budou ulo\u017Eeny ve Va\u0161em kalend\xE1\u0159i.':
      'Klienti budou cvi\u010Dit pouze pod Va\u0161\xEDm dohledem, nikdy ne sami. V\u0161echny jejich tr\xE9ninky budou ulo\u017Eeny ve Va\u0161em kalend\xE1\u0159i.',
    Knihovna: 'Knihovna',
    'Koment\xE1\u0159': 'Koment\xE1\u0159',
    Koncept: 'Koncept',
    Konec: 'Konec',
    'M\xEDsto': 'M\xEDsto',
    Nahradit: 'Nahradit',
    'Nahradit: Tr\xE9nink': 'Nahradit: Tr\xE9nink',
    'Nahrazen\xEDm tr\xE9ninku p\u0159ijdete o jeho p\u016Fvodn\xED n\xE1zev a cviky, datum z\u016Fstane zachov\xE1no. P\u0159ejete si tr\xE9nink nahradit?':
      'Nahrazen\xEDm tr\xE9ninku p\u0159ijdete o jeho p\u016Fvodn\xED n\xE1zev a cviky, datum z\u016Fstane zachov\xE1no. P\u0159ejete si tr\xE9nink nahradit?',
    'Napl\xE1novat': 'Napl\xE1novat',
    'Nov\xE1 zpr\xE1va': 'Nov\xE1 zpr\xE1va',
    'Nov\xE9 vybaven\xED': 'Nov\xE9 vybaven\xED',
    'N\xE1zev': 'N\xE1zev',
    'N\xE1zev tr\xE9ninku': 'N\xE1zev tr\xE9ninku',
    'Obnoven\xED hesla': 'Obnoven\xED hesla',
    'Opakovac\xED cvik': 'Opakovac\xED cvik',
    'Opakov\xE1n\xED': 'Opakov\xE1n\xED',
    'Ostatn\xED': 'Ostatn\xED',
    Pauza: 'Pauza',
    'Pl\xE1n byl odstran\u011Bn.': 'Pl\xE1n byl odstran\u011Bn.',
    'Pl\xE1n m\u016F\u017Eete sd\xEDlet jakmile vypln\xEDte v\u0161echny tr\xE9ninky':
      'Pl\xE1n m\u016F\u017Eete sd\xEDlet jakmile vypln\xEDte v\u0161echny tr\xE9ninky',
    'Pl\xE1n nasd\xEDlen': 'Pl\xE1n nasd\xEDlen',
    'Pl\xE1nujete TR\xC9NINK': 'Pl\xE1nujete TR\xC9NINK',
    'Pohlav\xED': 'Pohlav\xED',
    'Pojmenujte tr\xE9nink podle parti\xED':
      'Pojmenujte tr\xE9nink podle parti\xED',
    'Pokud k zadan\xE9mu emailu existuje MotiVibe \xFA\u010Det, obdr\u017E\xEDte b\u011Bhem chv\xEDle email s instrukcemi pro obnoven\xED hesla.':
      'Pokud k zadan\xE9mu emailu existuje MotiVibe \xFA\u010Det, obdr\u017E\xEDte b\u011Bhem chv\xEDle email s instrukcemi pro obnoven\xED hesla.',
    'Posledn\xED tr\xE9nink': 'Posledn\xED tr\xE9nink',
    'Posledn\xED tr\xE9nink:': 'Posledn\xED tr\xE9nink:',
    'Pozn\xE1mky': 'Pozn\xE1mky',
    'Po\u010Det tr\xE9nink\u016F': 'Po\u010Det tr\xE9nink\u016F',
    'Po\u010Det tr\xE9nink\u016F:': 'Po\u010Det tr\xE9nink\u016F:',
    'Profil byl upraven.': 'Profil byl upraven.',
    'Prvn\xED tr\xE9nink': 'Prvn\xED tr\xE9nink',
    'Prvn\xED tr\xE9nink:': 'Prvn\xED tr\xE9nink:',
    'Prv\xED tr\xE9nink': 'Prv\xED tr\xE9nink',
    'P\u0159ejete si i tak tr\xE9nink ulo\u017Eit?':
      'P\u0159ejete si i tak tr\xE9nink ulo\u017Eit?',
    'P\u0159idat': 'P\u0159idat',
    'P\u0159idat dokument': 'P\u0159idat dokument',
    'P\u0159idat instrukce': 'P\u0159idat instrukce',
    'P\u0159idat koment\xE1\u0159': 'P\u0159idat koment\xE1\u0159',
    'P\u0159idat pauzu': 'P\u0159idat pauzu',
    'P\u0159idat pl\xE1n': 'P\u0159idat pl\xE1n',
    'P\u0159idat pozn\xE1mku': 'P\u0159idat pozn\xE1mku',
    'P\u0159idejte cvik do tr\xE9ninku p\u0159et\xE1hnut\xEDm z prav\xE9ho seznamu':
      'P\u0159idejte cvik do tr\xE9ninku p\u0159et\xE1hnut\xEDm z prav\xE9ho seznamu',
    'P\u0159idejte cvik p\u0159et\xE1hnut\xEDm z prav\xE9ho seznamu,':
      'P\u0159idejte cvik p\u0159et\xE1hnut\xEDm z prav\xE9ho seznamu,',
    'P\u0159ihlasit se.': 'P\u0159ihlasit se.',
    'P\u0159ihla\u0161te se.': 'P\u0159ihla\u0161te se.',
    'P\u0159ihl\xE1\u0161en\xED': 'P\u0159ihl\xE1\u0161en\xED',
    'P\u0159\xEDchoz\xED zpr\xE1vy': 'P\u0159\xEDchoz\xED zpr\xE1vy',
    'Registrace p\u0159es e-mail': 'Registrace p\u0159es e-mail',
    'Registrujte se.': 'Registrujte se.',
    'Sd\xEDlet pl\xE1n': 'Sd\xEDlet pl\xE1n',
    'Sestavit tr\xE9nink': 'Sestavit tr\xE9nink',
    Smazat: 'Smazat',
    'Smazat filtry': 'Smazat filtry',
    Stav: 'Stav',
    'Stav pl\xE1nu:': 'Stav pl\xE1nu:',
    'Svalov\xE1 partie': 'Svalov\xE1 partie',
    'S\xE9rie': 'S\xE9rie',
    'Tato polo\u017Eka je povinn\xE1': 'Tato polo\u017Eka je povinn\xE1',
    Trenink: 'Trenink',
    'Tr\xE9nink': 'Tr\xE9nink',
    'Tr\xE9nink byl napl\xE1nov\xE1n.': 'Tr\xE9nink byl napl\xE1nov\xE1n.',
    'Tr\xE9nink nelze na\u010D\xEDst': 'Tr\xE9nink nelze na\u010D\xEDst',
    'Tr\xE9ninkov\xE9 pl\xE1ny': 'Tr\xE9ninkov\xE9 pl\xE1ny',
    'Tr\xE9ninkov\xFD pl\xE1n byl \xFAsp\u011B\u0161n\u011B duplikov\xE1n.':
      'Tr\xE9ninkov\xFD pl\xE1n byl \xFAsp\u011B\u0161n\u011B duplikov\xE1n.',
    'Tr\xE9ninkov\xFD pl\xE1n byl \xFAsp\u011B\u0161n\u011B nasd\xEDlen.':
      'Tr\xE9ninkov\xFD pl\xE1n byl \xFAsp\u011B\u0161n\u011B nasd\xEDlen.',
    'Tr\xE9ninkov\xFD pl\xE1n byl \xFAsp\u011B\u0161n\u011B upraven.':
      'Tr\xE9ninkov\xFD pl\xE1n byl \xFAsp\u011B\u0161n\u011B upraven.',
    'Tr\xE9ninkov\xFD pl\xE1n byl \xFAsp\u011B\u0161n\u011B vytvo\u0159en.':
      'Tr\xE9ninkov\xFD pl\xE1n byl \xFAsp\u011B\u0161n\u011B vytvo\u0159en.',
    'Tr\xE9ninkov\xFD pl\xE1n kon\u010D\xED':
      'Tr\xE9ninkov\xFD pl\xE1n kon\u010D\xED',
    'Tr\xE9ninkov\xFD pl\xE1n kon\u010D\xED za':
      'Tr\xE9ninkov\xFD pl\xE1n kon\u010D\xED za',
    'Tr\xE9ninkov\xFD pl\xE1n skon\u010Dil':
      'Tr\xE9ninkov\xFD pl\xE1n skon\u010Dil',
    'Tr\xE9ninky byly \xFAsp\u011B\u0161n\u011B nahrazeny.':
      'Tr\xE9ninky byly \xFAsp\u011B\u0161n\u011B nahrazeny.',
    'Typ cviku*': 'Typ cviku*',
    'Typ tr\xE9ninku': 'Typ tr\xE9ninku',
    'Ud\xE1lost byla aktualizov\xE1na': 'Ud\xE1lost byla aktualizov\xE1na',
    'Ud\xE1lost byla vytvo\u0159ena': 'Ud\xE1lost byla vytvo\u0159ena',
    'Ulo\u017Eit': 'Ulo\u017Eit',
    'Ulo\u017Eit n\xE1zev': 'Ulo\u017Eit n\xE1zev',
    'Ulo\u017Eit tr\xE9nink': 'Ulo\u017Eit tr\xE9nink',
    'Upozorn\u011Bn\xED': 'Upozorn\u011Bn\xED',
    Upravit: 'Upravit',
    'U\u017E m\xE1te tren\xE9rsk\xFD \xFA\u010Det?':
      'U\u017E m\xE1te tren\xE9rsk\xFD \xFA\u010Det?',
    'V tr\xE9ninku byly provedeny zm\u011Bny, o kter\xE9 p\u0159ijdete. P\u0159ejete si je zru\u0161it?':
      'V tr\xE9ninku byly provedeny zm\u011Bny, o kter\xE9 p\u0159ijdete. P\u0159ejete si je zru\u0161it?',
    'V tr\xE9ninku chyb\xED cviky na': 'V tr\xE9ninku chyb\xED cviky na',
    'Vlo\u017Eit dokument': 'Vlo\u017Eit dokument',
    'Vybaven\xED': 'Vybaven\xED',
    'Vybaven\xED bylo p\u0159id\xE1no.': 'Vybaven\xED bylo p\u0159id\xE1no.',
    'Vytvo\u0159it bezpe\u010Dn\xE9 heslo':
      'Vytvo\u0159it bezpe\u010Dn\xE9 heslo',
    'Vytvo\u0159it nov\xFD cvik': 'Vytvo\u0159it nov\xFD cvik',
    'Vytvo\u0159it pozn\xE1mku': 'Vytvo\u0159it pozn\xE1mku',
    'V\xE1ha': 'V\xE1ha',
    'ZDARMA ST\xC1HN\u011ATE NA': 'ZDARMA ST\xC1HN\u011ATE NA',
    'Zacn\u011Bte ps\xE1t...': 'Zacn\u011Bte ps\xE1t...',
    'Zadejte e-mail k Va\u0161emu MotiVibe \xFA\u010Dtu a my V\xE1m na n\u011Bj po\u0161leme odkaz na obnoven\xED hesla.':
      'Zadejte e-mail k Va\u0161emu MotiVibe \xFA\u010Dtu a my V\xE1m na n\u011Bj po\u0161leme odkaz na obnoven\xED hesla.',
    'Zadejte email k Va\u0161emu MotiVibe \xFA\u010Dtu a my V\xE1m na n\u011Bj po\u0161leme odkaz na obnoven\xED hesla.':
      'Zadejte email k Va\u0161emu MotiVibe \xFA\u010Dtu a my V\xE1m na n\u011Bj po\u0161leme odkaz na obnoven\xED hesla.',
    'Zapomenut\xE9 heslo?': 'Zapomenut\xE9 heslo?',
    'Zav\u0159\xEDt': 'Zav\u0159\xEDt',
    'Za\u010D\xE1tek': 'Za\u010D\xE1tek',
    'Zb\xFDv\xE1 napl\xE1novat {0}': function (a) {
      return ['Zb\xFDv\xE1 napl\xE1novat ', a('0')];
    },
    'Zm\u011Bny byly ulo\u017Eeny': 'Zm\u011Bny byly ulo\u017Eeny',
    'Zobrazit cviky': 'Zobrazit cviky',
    'Zobrazit tr\xE9nink': 'Zobrazit tr\xE9nink',
    'Zp\u011Bt na p\u0159ihl\xE1\u0161en\xED':
      'Zp\u011Bt na p\u0159ihl\xE1\u0161en\xED',
    'Zru\u0161it': 'Zru\u0161it',
    'dal\u0161\xEDch': 'dal\u0161\xEDch',
    den: 'den',
    dnes: 'dnes',
    dny: 'dny',
    'dn\xED': 'dn\xED',
    kolo: 'kolo',
    kopie: 'kopie',
    'kruhov\xFD tr\xE9nink.': 'kruhov\xFD tr\xE9nink.',
    nebo: 'nebo',
    search: 'search',
    's\xE9rie': 's\xE9rie',
    'trenink\u016F k nahrazen\xED': 'trenink\u016F k nahrazen\xED',
    'vytvo\u0159te supers\xE9rii': 'vytvo\u0159te supers\xE9rii',
    za: 'za',
    '{0}': function (a) {
      return [a('0')];
    },
    '{agreeButtonText}': function (a) {
      return [a('agreeButtonText')];
    },
    '{cancelButtonText}': function (a) {
      return [a('cancelButtonText')];
    },
    '{headerText}': function (a) {
      return [a('headerText')];
    },
    '{infoText}': function (a) {
      return [a('infoText')];
    },
    '{labelText}': function (a) {
      return [a('labelText')];
    },
    '{label}': function (a) {
      return [a('label')];
    },
    '{messagesCount, plural, one {M\xE1te # spr\xE1vu} other {M\xE1te # spr\xE1v}}':
      function (a) {
        return [
          a('messagesCount', 'plural', {
            one: ['M\xE1te ', '#', ' spr\xE1vu'],
            other: ['M\xE1te ', '#', ' spr\xE1v'],
          }),
        ];
      },
    '{sum} tr\xE9nink': function (a) {
      return [a('sum'), ' tr\xE9nink'];
    },
    '{sum} tr\xE9ninky': function (a) {
      return [a('sum'), ' tr\xE9ninky'];
    },
    '{sum} tr\xE9nink\u016F': function (a) {
      return [a('sum'), ' tr\xE9nink\u016F'];
    },
    '{text}': function (a) {
      return [a('text')];
    },
    '{titleAdd}': function (a) {
      return [a('titleAdd')];
    },
    '{trainingText}': function (a) {
      return [a('trainingText')];
    },
    '{typeOfSet}': function (a) {
      return [a('typeOfSet')];
    },
    '\u010Cas': '\u010Cas',
    '\u010Casov\xFD cvik': '\u010Casov\xFD cvik',
    '\u0160patn\xFD form\xE1t data.': '\u0160patn\xFD form\xE1t data.',
    '\u017D\xE1dn\xE9 z\xE1znamy nenalezeny.':
      '\u017D\xE1dn\xE9 z\xE1znamy nenalezeny.',
  },
};
