import { DELIMITER } from '../../../code/helpers/trainingExercise';
import { TrainingExerciseKeys } from '../../../enums/TrainingTypesEnum';
import { SectionOrder, UpdateType } from '../../../interfaces/enums';
import { ApplicationPath, ClientPath, routePathBuilder } from '../../../routes';
import { isTraining } from '../../../store/trainingParts/selectors';
import {
  TrainingDTO,
  TrainingExerciseDTO,
  TrainingSectionDTO,
  TrainingSectionPartsDTO,
} from '../../../strapi/TrainingDTO';
import { NUMBER_CHARS_TO_WRAP_HEADING } from '../shared/constants';
import { IImageCarouselImage } from '../shared/ImageCarousel';
import { TrainingPause } from './enums';
import {
  TimeExerciseData,
  TrainingInterval,
  Interval,
  InfoAboutTrainingExercisesProgress,
} from './interfaces';

export const isStringOnlyNumber = (text: string): boolean => /^\d+$/.test(text);

export const clearStringToOnlyTextualNumber = (text: string): string =>
  text.replace(/\D/g, '');

export const splitUsingCommonDelimiter = (text: string): string[] =>
  text.split(DELIMITER);

export const getImagesFromExerciseForCarousel = (
  exercises: TrainingExerciseDTO[] | undefined,
  links: string[],
): IImageCarouselImage[] => {
  if (!exercises || exercises.some(item => item === undefined)) {
    return [];
  }

  return exercises.map((exercise, index) => ({
    url: exercise.exercise.Illustration[0].url,
    link: links[index],
    alt: exercise.exercise.title.text,
  }));
};

export const countNumberOfExercisesInTotal = (
  training: TrainingDTO,
): number => {
  let totalNumberOfExercises = 0;
  training.training_sections.forEach(ts => {
    if (ts.training_section_parts.length === 0 && !isTraining(ts)) {
      totalNumberOfExercises += 1;
    } else {
      totalNumberOfExercises += ts.training_section_parts.length;
    }
  });

  return totalNumberOfExercises;
};

export const countNumberOfExercisesDone = (training: TrainingDTO): number => {
  let doneExercises = 0;
  training.training_sections.forEach(ts => {
    if (ts.training_section_parts.length === 0 && ts.isDone) {
      doneExercises += 1;
    } else {
      doneExercises += ts.training_section_parts.filter(
        tsp => tsp.isDone,
      ).length;
    }
  });

  return doneExercises;
};

export const countDoneSkippedTotal = (training: TrainingDTO): number => {
  let doneSkippedExercises = 0;
  training.training_sections.forEach(ts => {
    if ((ts.training_section_parts.length === 0 && ts.isDone) || ts.isSkipped) {
      doneSkippedExercises += 1;
    } else {
      doneSkippedExercises += ts.training_section_parts.filter(
        tsp => tsp.isDone || tsp.isSkipped,
      ).length;
    }
  });
  return doneSkippedExercises;
};

export const getDataAboutTrainingProgress = (
  training: TrainingDTO | undefined,
): InfoAboutTrainingExercisesProgress => {
  if (!training) {
    return {
      doneExercises: 0,
      numberOfDoneSkippedExercises: 0,
      totalNumberOfExercises: 0,
    };
  }

  return {
    doneExercises: countNumberOfExercisesDone(training),
    numberOfDoneSkippedExercises: countDoneSkippedTotal(training),
    totalNumberOfExercises: countNumberOfExercisesInTotal(training),
  };
};

/* Training Exercise has attribute weights and series. Such an attribute can be single number
    represented as string or string that has regex -> (\d+;)+ <-. In order to get correct data 
    for specific serie or circuit the string has to be splitted by common delimiter which is 
    currently semicolon.
    In order to have working app with current data (9.8.2021) text preprocessing has to be in
    in some cases. Attribute repeats can also contains strings such as "10x". These types 
    has to be polished; thus replace function (replace(/\D/g, '')) is used to abstract only number 
    from strings.
*/
export const getDataForSpecificSerieOrCircuit = (
  trainingExercise: TrainingExerciseDTO,
  type: TrainingExerciseKeys,
  order: number,
): string => {
  if (type === TrainingExerciseKeys.circuit) {
    const splitedString = splitUsingCommonDelimiter(trainingExercise.repeats);
    const finalString =
      splitedString.length > 1 ? splitedString[order - 1] : splitedString[0];

    return convertNumberToMinutesAndSeconds(
      Number(finalString ? clearStringToOnlyTextualNumber(finalString) : ''),
    );
  }

  const splitted = splitUsingCommonDelimiter(trainingExercise[type]);

  if (splitted.length === 1) {
    return splitted[0];
  }

  return splitted[order - 1];
};

/* 
  pad string from left with zeros if necessary
  eg. 1 with padding 2 will be padded to 01
  eg.10 with padding 2 will remain the same
*/
const str_pad_left = (string: number, pad: string, length: number) =>
  (new Array(length + 1).join(pad) + string).slice(-length);

/*
  converts number to time format. 
  eg. 26 will return string 26sec
  eg. 62 will return string 01:02min
  eg. 602 will return string 10:02min
*/

export const convertNumberToMinutesAndSeconds = (time: number): string => {
  const isMinutes = time > 59;

  if (isMinutes) {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${str_pad_left(minutes, '0', 2)}min ${str_pad_left(
      seconds,
      '0',
      2,
    )}sec`;
  }

  return `${time}s`;
};

export const convertNumberToMinutesAndSecondsOnlyNumbers = (
  time: number,
): string => {
  if (time > 3600) {
    return new Date(time * 1000).toISOString().substr(11, 8);
  }

  if (time > 59) {
    return new Date(time * 1000).toISOString().substr(14, 5);
  }

  if (time > 9) {
    return new Date(time * 1000).toISOString().substr(17, 2);
  }

  return new Date(time * 1000).toISOString().substr(18, 1);
};

export const handleSerieChange = (
  numberChange: number,
  currentOrder: number,
  numberOfSeries: number,
  setNumber: React.Dispatch<React.SetStateAction<number>>,
): void => {
  if (
    !(currentOrder + numberChange < 1) &&
    !(currentOrder + numberChange > numberOfSeries)
  ) {
    setNumber(currentOrder + numberChange);
  }
};

/*
  take any array of objects where each object contains a numerical attribute "order"
  and sort this array
*/
export const orderByOrder = (anyArrayOfObjectsWithOrderAttribute: any) =>
  anyArrayOfObjectsWithOrderAttribute
    .slice()
    .sort((a: { order: number }, b: { order: number }) => a.order - b.order);

/* handle all possible formats of stored weight and converts it into
   eg. -> '10kg; 12kg; 13kg'
   eg. -> '5kg'
*/
export const buildStringWithSemicolons = (text: string): string =>
  text.replaceAll('kg', '').split(';').join('kg; ').concat('kg');

/*
  based on current index which represents current cycle of **TIME EXERCISE**
  get interval when the cycle starts and ends
 */

const buildCycleIntervalOfTimeTraining = (
  traininigIntervals: TrainingInterval[],
  boundaries: number[],
  i: number,
): Interval => {
  const prevPause: number =
    i === 0 ? 0 : traininigIntervals[i - 1].pauseTime.end + 1;
  return {
    start: prevPause,
    end: prevPause + boundaries[i + 1] - boundaries[i],
  };
};

/*
  based on current index which represents current cycle of **TIME EXERCISE**
  and based on current interval of exercise
  get interval of following pause
 */

const buildPauseIntervalOfTimeTraining = (
  currentCycleExerciseInterval: Interval,
  pauses: number[],
  i: number,
): Interval => ({
  start: currentCycleExerciseInterval.end + 1,
  end: currentCycleExerciseInterval.end + 1 + pauses[i],
});

/*
  sum up all numbers in array up to number at index
*/
const cumulateNumbersUpToIndex = (arrayOfNumbers: number[], index: number) => {
  const sliceArray = arrayOfNumbers.slice(0, index);
  return sliceArray.reduce((acc, cur) => acc + cur);
};

/*
  **TIME EXERCISE** consists of cycles and pauses. Method below creates an array of objects, where each object
  represents an interval of one cycle or one pause.

  eg.

  [
    { trainingTime: {start: 0 , end: 10}},
    { pauseTime: { start: 11, 15 }},
    { trainingTime: {start: 16, end: 25}},
    { pauseTime: { start: 26, 30 }},
  ]

  such an array represents two cycles where first cycle takes 10 seconds and is following with pause
  that takes 5 seconds.
*/

const getArrayOfTimeBoundaries = (
  cycles: number[],
  pauses: number[],
): TrainingInterval[] => {
  const boundariesArray = [];
  const len = cycles.length;
  for (let i = 1; i <= len; i += 1) {
    boundariesArray[i] = cumulateNumbersUpToIndex(cycles, i);
  }

  // exercise starts at 0 second
  boundariesArray[0] = 0;

  const traininigIntervals: TrainingInterval[] = [];
  for (let i = 0; i < len; i += 1) {
    const trainingTime = buildCycleIntervalOfTimeTraining(
      traininigIntervals,
      boundariesArray,
      i,
    );

    const pauseTime = buildPauseIntervalOfTimeTraining(trainingTime, pauses, i);
    traininigIntervals.push({ trainingTime, pauseTime });
  }

  return traininigIntervals;
};

const between = (x: number, min: number, max: number) => x >= min && x <= max;

/*
  Get information about current **TIME EXERCISE**.
  Based on already elapsed time that is associated with current exercise,
  number of series, length of pauses after each cycle and length of each cycle
  compute:
    - in which cycle am I currently in
    - how long is going to take current cycle
    - how much time have I already exercised in current cycle
    - whether I am in exercise or pause part of current cycle

*/
export const getCurrentSerieOfTimeExercise = (
  cycles: number,
  times: string,
  pause: number,
  elapsedTime: number,
): TimeExerciseData => {
  let traininigIntervals: TrainingInterval[] = [];
  if (isStringOnlyNumber(times)) {
    traininigIntervals = getArrayOfTimeBoundaries(
      Array(cycles).fill(Number(times)),
      Array(cycles).fill(Number(pause)),
    );
  } else {
    traininigIntervals = getArrayOfTimeBoundaries(
      splitUsingCommonDelimiter(times).map(repeat =>
        Number(clearStringToOnlyTextualNumber(repeat)),
      ),
      Array(cycles).fill(Number(pause)),
    );
  }

  /* 
    Iterate over all cycles and pauses and determine using 
    elapsed time in of current exercise where am I currently
  */
  for (let i = 0; i < cycles; i += 1) {
    // check if I am in EXERCISE part of any cycle and return appropriated data of current EXERCISE
    if (
      between(
        elapsedTime,
        traininigIntervals[i].trainingTime.start,
        traininigIntervals[i].trainingTime.end,
      )
    ) {
      return {
        type: TrainingPause.training,
        serie: i + 1,
        timeInSerie:
          traininigIntervals[i].trainingTime.end -
          traininigIntervals[i].trainingTime.start,
        elapsedTimeInSerie:
          elapsedTime - traininigIntervals[i].trainingTime.start,
        other:
          traininigIntervals[i].pauseTime.end -
          traininigIntervals[i].pauseTime.start,
      };
    }

    // check if I am in PAUSE part of any cycle and return appropriated data of current PAUSE
    if (
      between(
        elapsedTime,
        traininigIntervals[i].pauseTime.start,
        traininigIntervals[i].pauseTime.end,
      )
    ) {
      return {
        type: TrainingPause.pause,
        serie: i + 1,
        timeInSerie:
          traininigIntervals[i].pauseTime.end -
          traininigIntervals[i].pauseTime.start,
        elapsedTimeInSerie: elapsedTime - traininigIntervals[i].pauseTime.start,
        other:
          traininigIntervals[i].trainingTime.end -
          traininigIntervals[i].trainingTime.start,
      };
    }
  }

  /* 
    If not in PAUSE or EXERCISE part of any cycle then total elapsed time of this **TIME EXERCISE** is already
    fullfilled.
  */
  return {
    type: TrainingPause.elapsedTimeOutOfTraining,
    serie: 0,
    timeInSerie: 0,
    elapsedTimeInSerie: 0,
    other: 0,
  };
};

export const getExerciseRoute = (
  trainingId: string,
  sectionId: string | undefined,
  partId: string | undefined,
  typeExercise: string,
  exerciseId: string | undefined,
  trainingExerciseId: string | undefined,
): string =>
  routePathBuilder([
    ApplicationPath.Client,
    ClientPath.Training,
    trainingId,
    ClientPath.Training_Section,
    sectionId || '0',
    ClientPath.Section_Part,
    partId || '0',
    typeExercise,
    ClientPath.Exercise,
    exerciseId || '0',
    ClientPath.Training_Exercise,
    trainingExerciseId || '0',
  ]);

export const getTrainingSection = (
  training: TrainingDTO,
): TrainingSectionDTO | undefined =>
  training.training_sections.find(ts => ts.order === SectionOrder.TRAINING);

/* 
  get order of section in training detail. warm up got automatically order of 1.
  stretch has to be counted using number of exercises in training section
  soi = section of interest
*/
export const getOrderOfTrainingSection = (
  soiSection: TrainingSectionDTO,
  training: TrainingDTO,
): number => {
  let order = soiSection.order === SectionOrder.WARM_UP ? 1 : 2;
  if (soiSection.order === SectionOrder.STRETCH) {
    order +=
      training.training_sections.find(ts => ts.order === SectionOrder.TRAINING)
        ?.training_section_parts.length || 0;
  }

  return order;
};

// I am on last exercise and it is skipped or training has at least one skipped previous exercise
export const isNextFinish = (
  updateWhat: UpdateType,
  doneExercises: number,
  numberOfDoneSkippedExercises: number,
  followingExercise: TrainingSectionPartsDTO | undefined,
): boolean =>
  !followingExercise &&
  ((updateWhat === UpdateType.SKIPPED &&
    doneExercises === numberOfDoneSkippedExercises) ||
    doneExercises !== numberOfDoneSkippedExercises);

export const followsPause = (
  updateWhat: UpdateType,
  currentExercise: TrainingSectionPartsDTO | undefined,
  followingExercise: TrainingSectionPartsDTO | undefined,
  isInPauseExercise: boolean,
): boolean | undefined => {
  let hasPauseTime = false;
  if (currentExercise && currentExercise.training_exercises.length === 1) {
    hasPauseTime = Boolean(
      currentExercise.training_exercises[0].pauseAfterExercise,
    );
  }

  if (currentExercise && currentExercise?.training_exercises.length > 1) {
    hasPauseTime = Boolean(
      orderByOrder(currentExercise.training_exercises).pop().pauseAfterExercise,
    );
  }
  return (
    updateWhat === UpdateType.DONE &&
    !isInPauseExercise &&
    followingExercise &&
    hasPauseTime
  );
};

export const convertDurationToSeconds = (duration: Duration): number => {
  const durationWithSecond =
    duration.seconds !== undefined ? duration.seconds + 1 : 0;
  const minutesToSeconds =
    duration.minutes !== undefined ? duration.minutes * 60 : 0;
  const hoursToSeconds =
    duration.hours !== undefined ? duration.hours * 3600 : 0;

  return durationWithSecond + minutesToSeconds + hoursToSeconds;
};

export const wrapTextToNewLine = (
  isMobile: boolean,
  textLenght: number,
): boolean => isMobile && textLenght > NUMBER_CHARS_TO_WRAP_HEADING;
