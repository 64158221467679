// eslint-disable-next-line
import { Options } from 'react-hook-form/dist/types';

export interface BaseFormProps<T> {
  onFormSubmit: (data: T) => void;
  loading?: boolean;
  requestError?: string;
}

export const BASE_FORM_MODE: Options['mode'] = 'onBlur';
export const ONCHANGE_FORM_MODE: Options['mode'] = 'onChange';
