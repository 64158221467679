export enum RS {
  Initial = 'INITIAL',
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export enum SocialSite {
  FB = 'Facebook',
  Google = 'Google',
}

export enum FilterTypesBasic {
  BodyPart = 'body-part',
  Equipment = 'equipment_items',
  ExerciseType = 'exercise-type',
}
export enum TypeOfInput {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export enum SectionType {
  SECTION = 'training-section',
  SECTION_PART = 'training-section-parts',
}

export enum TypeOfSection {
  WARM_UP = 'Zahřátí',
  TRAINING = 'Trénink',
  STRETCH = 'Protažení',
  FINISHED = 'Finished',
}

export enum SectionOrder {
  WARM_UP = 1,
  TRAINING = 2,
  STRETCH = 3,
}

export enum UpdateType {
  DONE = 'updateDone',
  PAUSE = 'updateIsPause',
  SKIPPED = 'updateIsSkipped',
}
