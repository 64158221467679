import { compareDesc, compareAsc, parseISO } from 'date-fns';
import { ChartDataAxis } from '../../interfaces/user';
import { NoteDTO } from '../../strapi/NoteDTO';
import { RoleDTO } from '../../strapi/RoleDTO';
import { UserDTO } from '../../strapi/UserDTO';
import { WeightProgressDTO } from '../../strapi/WeightProgressDTO';

export const isTrainee = (role: RoleDTO): boolean => role.type === 'client';

export const isTrainer = (role: RoleDTO): boolean => role.type === 'trainer';

export const getFullName = (user: UserDTO): string =>
  `${user.name} ${user.surname}`;

// sort user notes according to date
// from newest to oldest
export const sortUserNotes = (notes: NoteDTO[]): NoteDTO[] =>
  // because of immer there is [...notes]
  // can't reassign frozen array
  [...notes].sort((a: NoteDTO, b: NoteDTO) => {
    const c = new Date(a.createdAt).getTime();
    const d = new Date(b.createdAt).getTime();
    return compareDesc(c, d);
  });

// creates data for weightChart and sort them according to date
// from oldest to newest
export const createChartData = (
  weightProgress: WeightProgressDTO[],
): ChartDataAxis[] =>
  // because of immer there is [...weightProgress]
  // can't reassign frozen array
  [...weightProgress]
    .map((item: WeightProgressDTO) => ({
      y: item.weight,
      x: parseISO(item.createdAt),
    }))
    .sort((a: ChartDataAxis, b: ChartDataAxis) => compareAsc(a.x, b.x));
