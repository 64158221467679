import { PushNotifications } from '@capacitor/push-notifications';
import { UserDTO } from '../../strapi/UserDTO';
import { makeServerCallAsync } from './api/api';

export const updateFcmToken = (user: UserDTO, token: string) =>
  makeServerCallAsync('put', `/users/${user.id}`, { fcmToken: token });

export const registerNotifications = async (user: UserDTO): Promise<void> => {
  PushNotifications.removeAllListeners();

  await PushNotifications.addListener('registration', token => {
    makeServerCallAsync('put', `/users/${user.id}`, { fcmToken: token.value });
  });

  await PushNotifications.addListener(
    'pushNotificationActionPerformed',
    notification => {
      window.location.href = notification.notification.data.url;
    },
  );

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    return;
  }

  await PushNotifications.register();
};
