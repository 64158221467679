import {
  TrainingExerciseWithOperation,
  ExerciseSerie,
  TrainingSectionWithOperation,
  TrainingSectionPartWithOperation,
} from '../../interfaces/traning';
import { TrainingSectionTypeEnum } from '../../enums/TrainingTypesEnum';
import { ExerciseTrainingTypeEnum } from '../../strapi/ExerciseDTO';

// pokud mam main input ve strednikach tak potrebuju dostat zpet hodnoty pro sekundy
export const checkNumberRepeat = (repeats: string | number) => {
  if (typeof repeats === 'string') {
    if (repeats.includes('s')) {
      return Number(repeats.replace(/s/, ''));
    }
    if (repeats.includes('m')) {
      return Number(repeats.replace(/m/, '')) * 60;
    }

    return Number(repeats);
  }
  return repeats;
};

const exerciseSerie = (
  exercise: TrainingExerciseWithOperation,
  serieNo: number,
  type: TrainingSectionTypeEnum,
): ExerciseSerie => {
  const weightSplits = exercise.weights.split(';');
  let repeatsSplits: Array<string> = exercise.repeats.split(';');
  if (
    type === TrainingSectionTypeEnum.CircuitTraining ||
    // historicky jsou nejake null
    (exercise.exercise.exerciseTrainingType != null &&
      exercise.exercise.exerciseTrainingType === ExerciseTrainingTypeEnum.TIME)
  ) {
    repeatsSplits = repeatsSplits.map(split => `${checkNumberRepeat(split)}`);
  }
  const seriesObject: ExerciseSerie = {
    repeats: repeatsSplits[serieNo] || repeatsSplits[0] || '',
    weights: weightSplits[serieNo] || weightSplits[0] || '',
    name: `${serieNo + 1}. ${
      type === TrainingSectionTypeEnum.CircuitTraining ? 'kolo' : 'série'
    } `,
    key: serieNo,
  };

  return seriesObject;
};

export const generatedExerciseSeriesRows = (
  exercise: TrainingExerciseWithOperation,
  type: TrainingSectionTypeEnum,
  wrapperSeries?: number,
): ExerciseSerie[] => {
  const seriesComps: ExerciseSerie[] = [];
  const seriesCount = wrapperSeries || exercise.series;

  for (let index = 0; index < seriesCount; index += 1) {
    const newSeriesRow = exerciseSerie(exercise, index, type);
    seriesComps.push(newSeriesRow);
  }

  return seriesComps;
};

/**
 * Selecting previous between exercises outside set wrappers (circuit training or superSerie)
 */
export const previousPauseNoSet = (
  sectionToSave: TrainingSectionWithOperation,
  index: number,
): number | undefined => {
  if (index === 0) {
    return undefined;
  }

  const prevSection: TrainingSectionPartWithOperation | undefined =
    sectionToSave.training_section_parts
      .slice(0, index)
      .reverse()
      .find(section =>
        section.type === TrainingSectionTypeEnum.SingleExercise &&
        section.training_exercises[0]
          ? section.training_exercises[0].pauseAfterExercise
          : section.pause,
      );

  if (prevSection) {
    // if previous exercise is not a single exercise, but a wrapper, then select its pause
    // otherwise select after pause from that single exercise
    return prevSection.type === TrainingSectionTypeEnum.SingleExercise &&
      prevSection.training_exercises[0]
      ? prevSection.training_exercises[0].pauseAfterExercise
      : prevSection.pause;
  }
  return undefined;
};

/**
 * Selects previous after pause between exercises in circuit training
 */
export const previousPauseInSet = (
  superSetToSave: TrainingSectionPartWithOperation,
  index: number,
): number | undefined => {
  if (superSetToSave.training_exercises[index - 1]) {
    return superSetToSave.training_exercises[index - 1].pauseAfterExercise;
  }

  // default fallback for CircuitTraining is 10, for SuperSet is undefined
  return superSetToSave.type === TrainingSectionTypeEnum.CircuitTraining
    ? 10
    : undefined;
};
