import { EventDTO } from '../../interfaces/event';
import { State } from '../combinedReducers';

export const getFetchedEvents = (state: State): EventDTO[] =>
  state.calendar.trainerEvents;

export const getActiveDate = (state: State): Date | undefined =>
  state.calendar.activeDate;
export const getEventById =
  (eventId: string) =>
  (state: State): EventDTO | undefined =>
    state.calendar.trainerEvents.find(event => event.id === eventId);
