import {
  TrainingSectionPartWithOperation,
  TrainingExerciseWithOperation,
  TrainingSectionWithOperation,
  TrainingSectionLean,
  TrainingSectionPartsLean,
  TrainingExerciseLean,
} from '../../interfaces/traning';
import { TrainingSectionTypeEnum } from '../../enums/TrainingTypesEnum';
import { FilterDTO } from '../../strapi/FilterDTO';
import {
  TrainingSectionDTO,
  TrainingSectionPartsDTO,
  TrainingExerciseDTO,
} from '../../strapi/TrainingDTO';
import { OperationEnum } from '../../enums/OperationEnum';

/**
 * Copies values from prev training exercise in training section part (wrapper)
 * @param trainingExercise Training Exercise With Operation
 * @param prevTe Training Section Part (Single Sxercise)
 */
export const copyTrainingExercise = (
  trainingExercise: TrainingExerciseWithOperation,
  prevTSP: TrainingSectionPartWithOperation | undefined,
): TrainingExerciseWithOperation => {
  if (
    prevTSP &&
    prevTSP.type === TrainingSectionTypeEnum.SingleExercise &&
    prevTSP.training_exercises.length > 0
  ) {
    const copyExercise = {
      ...trainingExercise,
      repeats: prevTSP.training_exercises[0].repeats,
      weights: prevTSP.training_exercises[0].weights,
      pause: prevTSP.training_exercises[0].pause,
      series: prevTSP.training_exercises[0].series,
    };

    return copyExercise;
  }

  return trainingExercise;
};

/**
 * Copies values from prev training exercise
 * @param trainingExercise Training Exercise With Operation
 * @param prevTe Training Exercise in Training SetUp that is before the newly created one
 */
export const copyTrainingExerciseInSuperSet = (
  trainingExercise: TrainingExerciseWithOperation,
  prevTe: TrainingExerciseWithOperation | undefined,
): TrainingExerciseWithOperation => {
  if (prevTe) {
    const copiedExercise = {
      ...trainingExercise,
      repeats: prevTe.repeats,
      weights: prevTe.weights,
      pause: prevTe.pause,
      series: prevTe.series,
    };

    return copiedExercise;
  }

  return trainingExercise;
};

export const mapSectionPartsToLean = (
  item: TrainingSectionWithOperation | TrainingSectionDTO,
): TrainingSectionPartsLean[] => {
  const sectionPartLean: TrainingSectionPartsLean[] = (
    item.training_section_parts as Array<
      TrainingSectionPartWithOperation | TrainingSectionPartsDTO
    >
  ).map((part: TrainingSectionPartWithOperation | TrainingSectionPartsDTO) => {
    const partLean: TrainingSectionPartsLean = {
      ...part,
      training_exercises: mapExerciseToLean(part),
    };
    return partLean;
  });
  return sectionPartLean;
};
export const mapExerciseToLean = (
  part: TrainingSectionPartWithOperation | TrainingSectionPartsDTO,
): TrainingExerciseLean[] => {
  const exercises: TrainingExerciseLean[] = (
    part.training_exercises as Array<
      TrainingExerciseDTO | TrainingExerciseWithOperation
    >
  ).map((exercise: TrainingExerciseDTO | TrainingExerciseWithOperation) => {
    const exerciseLean: TrainingExerciseLean = {
      ...exercise,
    };
    return exerciseLean;
  });
  return exercises;
};
// remap to lean interface
// because we have different types in  training in store and in trainingCardHeader
// and in training setup
export const getTrainingSectionLean = (
  array: Array<TrainingSectionWithOperation | TrainingSectionDTO>,
) => {
  const sectionLean: TrainingSectionLean[] = array.map(
    (item: TrainingSectionWithOperation | TrainingSectionDTO) => {
      const itemLean: TrainingSectionLean = {
        ...item,
        training_section_parts: mapSectionPartsToLean(item),
      };
      return itemLean;
    },
  );

  return sectionLean;
};

// Used for determine which body part(training) doesn't have exercise in training
export const getMissingBodyParts = (
  bodyParts: FilterDTO[],
  sections: TrainingSectionLean[],
): FilterDTO[] => {
  const bodyPartsIds = bodyParts.map(bp => bp.id);
  // set array of wanted body parts
  let missingParts: FilterDTO[] = bodyParts;
  sections.forEach(section => {
    section.training_section_parts.forEach(sectionPart => {
      if (sectionPart.training_exercises.length > 0) {
        sectionPart.training_exercises.forEach(exercise => {
          exercise.exercise.parts.forEach(part => {
            // if exercise has wanted body parts we can remove it from wanted array
            if (bodyPartsIds.includes(part.id)) {
              missingParts = missingParts.filter(p => p.id !== part.id);
            }
          });
        });
      }
    });
  });
  return missingParts;
};

export const getMissingBodyPartsText = (
  bodyParts: FilterDTO[],
  sections: TrainingSectionLean[],
): string => {
  const missingParts = getMissingBodyParts(bodyParts, sections);
  const text = missingParts.map(missPart => missPart.name);
  let textWithDot =
    text.length > 2
      ? text.join(', ').toLowerCase()
      : text.join(' a ').toLowerCase();
  textWithDot = `${textWithDot}.`;
  return textWithDot;
};

// get sections without deleted ones
// for proper text in save training
export const getSectionsWithoutDeleted = (
  sectionsToSave: TrainingSectionWithOperation[],
) => {
  const newSections = sectionsToSave.map(section => {
    const newTrainingSectionParts = [
      ...section.training_section_parts.filter(
        tsp => tsp.operation !== OperationEnum.DELETED,
      ),
    ];
    return {
      ...section,
      training_section_parts: newTrainingSectionParts,
    };
  });

  return newSections;
};

export const validateText = (text: string, regex: RegExp): boolean => {
  if (text !== '') {
    return !text.match(regex);
  }
  return false;
};
