import { createSelector } from 'reselect';
import { State } from '../combinedReducers';

export const general = (state: State): any => state.general;

export const getLanguage = createSelector(general, gen => gen.language);

export const getCircularRunning = createSelector(
  general,
  gen => gen.isCircularRunning,
);
