/** @jsx jsx */
import { FC, Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { PauseCircleFilled, Clear } from '@material-ui/icons';
import { TimeDropdownPicker } from './TimeDropdownPicker';
import { setPaddingCss, inputsCss, titleCss } from '../TraningExercise';
import { MPX, COLORS } from '../../../../styles/themes';

interface PauseRowProps {
  pause: number | undefined;
  isSuperSet: boolean;
  handlePauseChange: (pause: number) => void;
}

export const PauseRow: FC<PauseRowProps> = ({
  pause,
  isSuperSet,
  handlePauseChange,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  // sets pause to 0
  const removePause = (): void => {
    handlePauseChange(0);
  };

  const renderPicker = () => (
    <Grid
      container
      item
      xs={8}
      sm={8}
      alignItems="center"
      justify="flex-end"
      direction="row"
      css={[!isMobile && inputsCss, !isSuperSet && !isMobile && setPaddingCss]}
    >
      <Grid container item xs={10} sm={11} justify="flex-end">
        <Grid item xs={6} sm={3}>
          <TimeDropdownPicker
            hasPause
            value={pause}
            handlePauseChange={handlePauseChange}
          />
        </Grid>
      </Grid>
      {!isSuperSet && (
        <Grid item xs={2} sm={1}>
          <IconButton css={iconColorCss} onClick={() => removePause()}>
            <Clear />
          </IconButton>
        </Grid>
      )}
      {!isMobile && isSuperSet && <Grid item xs={1} sm={1} />}
    </Grid>
  );

  return (
    <Grid
      container
      alignItems="center"
      justify={isMobile ? 'space-between' : 'space-evenly'}
      css={topMarginCss(isMobile)}
    >
      <Grid item xs={4}>
        <Grid container alignItems="center" spacing={1} justify="space-evenly">
          {!isMobile && (
            <Grid item xs={1}>
              <Fragment />
            </Grid>
          )}
          <Grid item>
            <PauseCircleFilled css={iconColorCss} />
          </Grid>
          <Grid item xs={7} css={!isMobile && leftPaddingCss}>
            <span css={titleCss}>
              <Trans>Pauza</Trans>
            </span>
          </Grid>
        </Grid>
      </Grid>
      {renderPicker()}
    </Grid>
  );
};

const topMarginCss = (isMobile: boolean) => css`
  margin-top: ${3 * MPX}px;
  padding-bottom: ${isMobile && 3 * MPX}px;
  padding-right: ${isMobile && 2 * MPX}px;
  border-bottom: ${isMobile && `1px solid ${COLORS.gray8}`};
`;

const iconColorCss = css`
  color: ${COLORS.gray6};
`;

const leftPaddingCss = css`
  padding-left: ${5 * MPX}px;
`;
