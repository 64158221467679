/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { COLORS } from '../../../../../styles/themes';
import { DELIMITER, KG } from '../../../shared/constants';
import { isStringOnlyNumber } from '../../../helper/functions';

export interface SerieRepeatWeightPauseDataRowProps {
  series: number;
  repeats?: string;
  repeatsModifier?: string;
  weights?: string;
  pause: number;
  pauseModifier: string;
  notRepeats?: boolean;
}

export const determineIfComma = (
  text: string,
  other: string,
  modifier: string,
): string => {
  if (text.includes(DELIMITER) || other.includes(DELIMITER)) {
    return '';
  }

  if (isStringOnlyNumber(text)) {
    return text + modifier;
  }

  return text;
};

const SerieRepeatWeightPauseDataRow: React.FC<SerieRepeatWeightPauseDataRowProps> =
  ({
    series,
    repeats,
    repeatsModifier,
    weights,
    pause,
    pauseModifier,
    notRepeats,
  }) => (
    <div>
      <Grid container direction="row" css={gridCss}>
        <Grid item container justify="center" xs={3}>
          {series}
        </Grid>
        <Grid item container justify="center" xs={3}>
          {!notRepeats &&
            repeats &&
            weights &&
            repeatsModifier &&
            determineIfComma(repeats, weights, repeatsModifier)}
        </Grid>
        <Grid item container justify="center" xs={3}>
          {weights && repeats && determineIfComma(weights, repeats, KG)}
        </Grid>
        <Grid item container justify="center" xs={3}>
          {pause}
          {pauseModifier}
        </Grid>
      </Grid>
    </div>
  );

export default SerieRepeatWeightPauseDataRow;

const gridCss = css`
  color: ${COLORS.white};
  font-size: x-large;
  padding-top: 2vh;
`;
