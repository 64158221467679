/** @jsx jsx */
import { FC, ReactNode } from 'react';
import { jsx, css } from '@emotion/react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  CircularProgress,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { COLORS } from '../../styles/themes';
import { iconMobileCss } from '../../styles/general';

interface ComponentProps {
  isOpen: boolean;
  handleClose: () => void;
  handleAgree: () => void;
  agreeButtonText: ReactNode;
  cancelButtonText?: ReactNode;
  headerText: ReactNode;
  content: JSX.Element;
  fullWidth?: boolean;
  closeIcon?: boolean;
  isLoading?: boolean;
}

export const AlertDialog: FC<ComponentProps> = ({
  isOpen,
  handleClose,
  handleAgree,
  agreeButtonText,
  cancelButtonText,
  headerText,
  content,
  fullWidth,
  closeIcon,
  isLoading,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        css={dialogCss}
        fullWidth={fullWidth}
      >
        <DialogTitle css={headerCss}>
          {headerText}
          {closeIcon && (
            <IconButton
              onClick={handleClose}
              css={[closeIconBtn(!!fullWidth), isMobile && iconMobileCss]}
            >
              <CloseOutlined />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent css={contentCss}>{content}</DialogContent>
        <DialogActions css={actionsCss}>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            onClick={handleAgree}
            css={fullWidth && buttonCss}
          >
            {isLoading === true ? (
              <CircularProgress size={24} css={colorIconCss} />
            ) : (
              agreeButtonText
            )}
          </Button>
          {cancelButtonText && (
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              css={fullWidth && buttonCss}
            >
              {cancelButtonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const dialogCss = css`
  .MuiPaper-root {
    padding: 30px !important;
  }
`;

const headerCss = css`
  .MuiTypography-root {
    position: relative;
    font-size: 28px;
    text-align: center;
    color: ${COLORS.gray};
  }
`;

const actionsCss = css`
  justify-content: center !important;
`;

const contentCss = css`
  text-align: center;
  margin-bottom: 20px;
`;

const buttonCss = css`
  display: flex;
  flex: 1;
`;

const closeIconBtn = (fullWidth: boolean) => css`
  position: absolute !important;
  left: ${fullWidth ? -25 : -35}px;
  top: ${fullWidth ? 0 : -30}px;
  padding: 5px !important;
`;

const colorIconCss = css`
  color: ${COLORS.white} !important;
`;
