import { css, SerializedStyles } from '@emotion/react';
import { COLORS, MPX } from './themes';

export const dropzoneCss = (isMobile: boolean): SerializedStyles => css`
  text-align: center;
  border: 2px dashed #b9c3cb;
  background-color: rgba(237, 241, 244, 0.5);
  border-radius: 4px;
  color: ${COLORS.darkBlue};
  width: ${isMobile ? 'auto' : '93%'};
  padding: ${4 * MPX}px;
  margin-top: ${2 * MPX}px;
`;

export const dropZoneOptionTextCss = css`
  color: ${COLORS.baseOrange};
  font-weight: 400;
  cursor: pointer;
`;

export const dropzoneTextCss = css`
  color: ${COLORS.gray};
  font-size: 12px;
`;
