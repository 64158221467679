/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Trans } from '@lingui/macro';
import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Sound, useAnySound } from '../../../../../code/helpers/sounds';
import { COLORS, MPX } from '../../../../../styles/themes';
import { convertNumberToMinutesAndSecondsOnlyNumbers } from '../../../helper/functions';

export interface ProgressBarProps {
  durationTotal: number;
  durationElapsed: number;
  currentSeries?: number;
  seriesTotal?: number;
  isPause?: boolean;
  isElapsed?: boolean;
  isLength?: boolean;
  noGo?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  durationElapsed,
  durationTotal,
  seriesTotal,
  isPause,
  currentSeries,
  isElapsed,
  isLength,
  noGo,
}) => {
  const { play, stop } = useAnySound();

  const [isPlaying, setIsPlaying] = useState(false);
  const percentage = isElapsed ? 100 : (durationElapsed / durationTotal) * 100;
  const isOnlySeconds = durationTotal - durationElapsed < 60;

  const isStartOfExercise = useMemo(
    () =>
      durationElapsed === 0 &&
      !isElapsed &&
      !noGo &&
      !isPause &&
      (currentSeries ?? 0) > 0,
    [currentSeries, durationElapsed, isElapsed, isPause, noGo],
  );

  useEffect(() => {
    if (durationTotal - durationElapsed === 2 && !isPlaying) {
      setIsPlaying(true);
      play(Sound.countdown);
    } else if (durationTotal - durationElapsed > 2 && isPlaying) {
      setIsPlaying(false);
      stop(Sound.countdown);
    }
    // Play go if user is at the start of the exercise and countdown is not for pause
    // for pause there is only pipipi
    // GO is used only at start of the exercise
    // durationElapsed === 1 means that exercise is running
    if (isStartOfExercise) {
      play(Sound.go);
    }
  }, [
    isPlaying,
    durationTotal,
    durationElapsed,
    isStartOfExercise,
    play,
    stop,
  ]);

  const innerText = () => {
    if (isLength) {
      return <Trans>Délka</Trans>;
    }
    if (isPause) {
      return <Trans>Pauza</Trans>;
    }

    return <Trans>Opakování</Trans>;
  };
  return (
    <div css={wrapperCss}>
      <div css={backgroundCss(percentage)} />

      <Grid container wrap="nowrap" css={foregroundCss}>
        <div css={leftSideCss}>
          <Typography variant="caption" css={leftTypographyCss}>
            {innerText()}
          </Typography>
          <Typography variant="h6">
            {convertNumberToMinutesAndSecondsOnlyNumbers(
              durationTotal - durationElapsed,
            )}
            {isOnlySeconds ? 's' : 'm'}
          </Typography>
        </div>

        <div css={rightSideCss}>
          {!isPause && !isLength && (
            <Typography variant="h6">
              {currentSeries} / {seriesTotal}
            </Typography>
          )}
        </div>
      </Grid>
    </div>
  );
};

export default ProgressBar;

const wrapperCss = css`
  position: relative;
  padding: ${MPX * 2}px ${MPX * 3}px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.white};
`;

const backgroundCss = (percentage: number) => css`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: ${100 - percentage}%;
  background: ${COLORS.gray6};
`;

const foregroundCss = css`
  z-index: 2;
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  color: ${COLORS.white};
`;

const leftSideCss = css`
  flex: 2;
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
`;
const rightSideCss = css`
  flex: 1;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

const leftTypographyCss = css`
  margin-right: ${MPX * 3}px;
  min-width: ${MPX * 16}px;
`;
