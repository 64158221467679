/** @jsx jsx */
import { TextFieldProps, TextField } from '@material-ui/core';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { Trans } from '@lingui/react';
import React, { useState, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { formMargin } from '../../styles/mixins';

export type AppNumberInputProps = {
  rules?: RegisterOptions;
  name: string;
  label: string;
  helperText?: string;
  upperHelperText?: JSX.Element;
  min?: number;
  max?: number;
  defaultValue?: number;
  disabled?: boolean;
} & TextFieldProps;

export const AppNumberInput: React.FC<AppNumberInputProps> = ({
  rules,
  label,
  name,
  fullWidth,
  helperText,
  variant,
  margin,
  upperHelperText,
  defaultValue,
  min,
  max,
  disabled = false,
  ...restProps
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const error = errors[name];

  const [inputValue, setInputValue] = useState<number>(min || 0);

  const getHelperOrErrorText = () => {
    if (error && error.message) {
      return <Trans id={error.message} />;
    }

    if (helperText) {
      return <Trans id={helperText} />;
    }
    return null;
  };

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
      setValue(name, defaultValue);
    }
  }, [defaultValue, setInputValue, name, setValue]);

  const increaseNumber = (): void => {
    if (max !== undefined) {
      if (inputValue + 1 >= max) {
        setInputValue(inputValue);
        setValue(name, inputValue);
      }
    } else {
      setInputValue(inputValue + 1);
      setValue(name, inputValue + 1);
    }
  };

  const decreaseNumber = (): void => {
    if (min !== undefined) {
      if (inputValue - 1 >= min) {
        setInputValue(inputValue - 1);
        setValue(name, inputValue - 1);
      }
    } else {
      setInputValue(inputValue);
      setValue(name, inputValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(Number(e.target.value));
    setValue(name, Number(e.target.value));
  };

  const customProps: TextFieldProps = {
    label: <Trans id={label} />,
    margin: margin || 'dense',
    variant: variant || 'outlined',
    fullWidth: fullWidth != null ? fullWidth : true,
    helperText: getHelperOrErrorText(),
    error: error != null,
    InputLabelProps: {
      required: rules && rules.required != null,
    },
  };
  return (
    <div css={numberWrapperCss}>
      <button
        type="button"
        onClick={decreaseNumber}
        css={[buttonCss, buttonLeftCss]}
        disabled={disabled}
      >
        -
      </button>
      <div css={appNumberInputWrapper(customProps.fullWidth)}>
        {upperHelperText != null && (
          <span css={appNumberInputUpperHint}>{upperHelperText}</span>
        )}
        <TextField
          {...register(name, rules)}
          {...restProps}
          {...customProps}
          css={appNumberInput}
          value={inputValue || defaultValue}
          defaultValue={defaultValue}
          type="number"
          onChange={handleChange}
          inputProps={{
            min: min || undefined,
            max: max || undefined,
          }}
          disabled={disabled}
        />
      </div>
      <button
        type="button"
        onClick={increaseNumber}
        css={[buttonCss, buttonRightCss]}
        disabled={disabled}
      >
        +
      </button>
    </div>
  );
};

const buttonCss = css`
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.23);
  width: 40px;
  cursor: pointer;

  &:hover {
    border-color: rgb(0, 56, 120);
  }
`;

const buttonRightCss = css`
  border-left: 0;
  border-radius: 0 4px 4px 0;
`;

const buttonLeftCss = css`
  border-right: 0;
  border-radius: 4px 0 0 4px;
`;

const numberWrapperCss = css`
  display: flex;
  margin-top: 8px !important;
  ${formMargin}
`;

const appNumberInput = css`
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .MuiInputBase-root {
    background: white !important;
    border-radius: 0 !important;
  }
`;

const appNumberInputWrapper = (fullWidth: boolean) => css`
  position: relative;
  width: ${fullWidth ? '100%' : 'inherit'};
`;

const appNumberInputUpperHint = css`
  position: absolute;
  right: 0;
  top: -12px;
`;
