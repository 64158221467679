/** @jsx jsx */
import * as React from 'react';
import { jsx } from '@emotion/react';

import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { headerBodyMargin } from '../../styles/general';

export interface LeftHeadingWithButtonsProps {
  title: string;
}

export const LeftHeadingWithButtons: React.FC<LeftHeadingWithButtonsProps> =
  props => {
    const { children, title } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
      <Grid
        container
        justify="space-between"
        alignItems="center"
        css={headerBodyMargin(isMobile)}
      >
        <Grid item xs={6}>
          <Typography variant="h5" color="textPrimary">
            <Trans id={title} />
          </Typography>
        </Grid>
        {children}
      </Grid>
    );
  };
