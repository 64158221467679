/** @jsx jsx */
import React, { ReactNode } from 'react';
import { v4 as uuid } from 'uuid';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  ListItemText,
  Checkbox,
  FormHelperText,
  MenuProps,
} from '@material-ui/core';
import { css, jsx } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { RegisterOptions } from 'react-hook-form/dist/types';
import { COLORS, MPX } from '../../styles/themes';

export interface AppMultiSelectProps {
  name: string;
  value?: any;
  options: Array<{ value: number | number; text: string }>;
  label: ReactNode;
  rules?: RegisterOptions;
  MenuProps?: Partial<MenuProps>;
}

export const AppMultiSelect: React.FC<AppMultiSelectProps> = ({
  label,
  name,
  options,
  value = null,
  rules,
  MenuProps,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();
  const [selected, setSelected] = React.useState<number[]>(value || []);
  const error = errors[name];

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setSelected(event.target.value as number[]);
    setValue(name, event.target.value);
    trigger(name);
  };

  const handleDelete = (value: number): void => {
    setSelected(selected.filter((item: number) => item !== value));
    setValue(
      name,
      selected.filter((item: number) => item !== value),
      { shouldValidate: true },
    );
  };

  const selectUuid = uuid();

  const findTextByValue = (value: number): string => {
    const val = options.find(item => item.value === value);
    return val ? val.text : '';
  };

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    if (rules) {
      register(name, rules);
    } else {
      register(name);
    }
  }, [register, name, rules]);

  React.useEffect((): void => {
    setLabelWidth(inputLabel.current!.offsetWidth);

    if (value) {
      setValue(name, value, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      variant="outlined"
      css={selectWrapperCss}
      error={!!error}
      margin="dense"
    >
      <InputLabel
        ref={inputLabel}
        id={selectUuid}
        margin="dense"
        required={rules && (rules.required != null || rules.validate != null)}
      >
        {label}
      </InputLabel>
      <Select
        name={name}
        labelId={selectUuid}
        css={selectCss}
        variant="outlined"
        multiple
        onChange={handleChange}
        labelWidth={labelWidth}
        margin="dense"
        value={selected}
        MenuProps={MenuProps}
        onClose={(): void => {
          trigger(name);
        }}
        renderValue={(selected): JSX.Element => (
          <div>
            {(selected as number[]).map((value: number) => (
              <Chip
                key={value}
                label={findTextByValue(value)}
                onDelete={(): void => {
                  handleDelete(value);
                }}
                onMouseDown={(event): void => {
                  event.stopPropagation();
                }}
              />
            ))}
          </div>
        )}
      >
        {options.map(item => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox checked={selected.includes(item.value)} />
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};

const selectWrapperCss = css`
  width: 100%;
  margin-bottom: 25px !important;
`;

export const selectCss = css`
  width: 100%;

  .MuiOutlinedInput-input {
    background: ${COLORS.contrast};
  }

  .MuiSelect-selectMenu {
    white-space: normal;
  }

  .MuiChip-root {
    margin: ${MPX}px;
  }
`;
