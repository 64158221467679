import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store from './store';
import { AppContainer } from './AppContainer';
import 'normalize.css';
import { unregister } from './serviceWorker';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import { version } from '../package.json';
import { nativeSoundsCtrl } from './code/helpers/sounds';

if (process.env.REACT_APP_ENV) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    release: version,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
  });
}

nativeSoundsCtrl.loadAll().then(() => {
  const renderProvider = (): JSX.Element => (
    <ErrorBoundary>
      <Provider store={store}>
        <AppContainer />
      </Provider>
    </ErrorBoundary>
  );

  ReactDOM.render(
    renderProvider(),
    document.getElementById('root') as HTMLElement,
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
