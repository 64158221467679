import { differenceInCalendarDays, isBefore } from 'date-fns';
import { TrainingDTO } from '../../strapi/TrainingDTO';
import { TrainingPlanState } from '../../enums/TrainingPlanStateEnum';
import { FilterDTO } from '../../strapi/FilterDTO';
import { Filter } from '../../interfaces/filter';
import { FilterTypes } from '../../components/trainer/exercisesLibrary/comps/LeftMenu';

export const setTrainingState = (
  startDate: string | undefined,
  lastTrainingDate: string | undefined,
  isFinished: boolean,
): TrainingPlanState => {
  const now = new Date();
  // tyden a mene do posledneho treningu tak je porbihajici a nejsou treninky dokoncene
  if (
    lastTrainingDate &&
    differenceInCalendarDays(new Date(lastTrainingDate), now) <= 7 &&
    differenceInCalendarDays(new Date(lastTrainingDate), now) >= 0 &&
    !isFinished
  ) {
    return TrainingPlanState.Running;
  }
  // dokoncenz je pokud jsou vsechnz treninky dokoncene nebo pokud je
  // datum posledniho treninku vcera nebo driv
  if (isFinished || (lastTrainingDate && new Date(lastTrainingDate) < now)) {
    return TrainingPlanState.Finished;
  }
  if (startDate) {
    const sd = new Date(startDate).getDay();
    if (sd <= now.getDay()) {
      return TrainingPlanState.Running;
    }
  }

  return TrainingPlanState.Saved;
};

export interface TrainingPlanEndAndStart {
  state: TrainingPlanState;
  firstTraining: string | undefined;
  lastTraining: string | undefined;
}

export const setTrainingPlanState = (
  trainings: TrainingDTO[],
): TrainingPlanEndAndStart => {
  if (trainings === undefined || trainings.length === 0) {
    return {
      state: setTrainingState(undefined, undefined, false),
      firstTraining: undefined,
      lastTraining: undefined,
    };
  }
  const sortedTrainings: TrainingDTO[] = [...trainings];
  sortedTrainings.sort(
    (prev: TrainingDTO, curr: TrainingDTO) => prev.order - curr.order,
  );

  const tpDateFrom =
    sortedTrainings.length > 0 ? sortedTrainings[0].dateFrom : undefined;
  // oznacuje jestli jsou vsechny treninky dokoncene v ramci planu
  let isFinished = false;
  sortedTrainings.forEach(training => {
    if (training.isFinished !== undefined && training.isFinished === true) {
      isFinished = true;
    } else {
      isFinished = false;
    }
  });

  return {
    state: setTrainingState(
      tpDateFrom,
      sortedTrainings[sortedTrainings.length - 1].dateTo,
      isFinished,
    ),
    firstTraining: sortedTrainings[0].dateFrom,
    lastTraining: sortedTrainings[sortedTrainings.length - 1].dateTo,
  };
};

export const getNameFromBodyParts = (
  bodyParts: FilterDTO[],
  selected: string[],
): string => {
  if (selected.length === 0) {
    return '';
  }

  return ''.concat(
    bodyParts
      .filter(item => selected.includes(item.id))
      .map(item => item.name)
      .join(', '),
  );
};

export const countFilters = (activeFilters: Filter): number | FilterTypes => {
  let result = 0;

  Object.values(FilterTypes).map(type => {
    if (activeFilters[type].length > 0) {
      result += activeFilters[type].length;
    }
    return type;
  });

  return result;
};

// It compares two arrays and returns differences between array. There will be only one item from ModalFilter.tsx
export const getDifferenceBetweenArray = (
  array1: string[],
  array2: string[],
): string => {
  const filteredArray = array1
    .filter(item => !array2.includes(item))
    .concat(array2.filter(x => !array1.includes(x)));
  return filteredArray[0];
};

// modal should open only when some trianing to replace has date or training
export const checkIfOpenReplaceModal = (
  replaceBy: string | undefined,
  selectedToReplace: string[],
  trainings: TrainingDTO[],
): boolean => {
  let showModal = false;
  if (trainings.length > 0 && replaceBy && selectedToReplace.length > 0) {
    const foundReplaceBy = trainings.find(
      training => training.id === replaceBy,
    );
    if (foundReplaceBy) {
      trainings.forEach(training => {
        if (selectedToReplace.includes(training.id)) {
          // if training is planned or has exercises open modal
          if (training.dateFrom) {
            showModal = true;
          } else {
            training.training_sections.forEach(section => {
              if (section.training_section_parts.length > 0) {
                showModal = true;
              }
            });
          }
        }
      });
    }
  }

  return showModal;
};

// used for trainingPlanWarning
export const decideEndedPlans = (
  dateOfLastTrainingPlan: string | undefined,
  withoutPlan: boolean,
  allIsFinished: boolean,
): boolean => {
  // pokud jsou vsechnz treninky odcvicene tak se povazuje za skonceny a je jedno jake to je datum

  if (allIsFinished) {
    return true;
  }
  // je poslední trénink naplánován na [datum] a zároveň dnes je [datum]
  // davame jen warning
  // proto false
  if (
    dateOfLastTrainingPlan &&
    differenceInCalendarDays(new Date(dateOfLastTrainingPlan), new Date()) ===
      0 &&
    !withoutPlan
  ) {
    return false;
  }
  // je poslední trénink naplánován na [datum] a zároveň datum bylo včera nebo dříve [datum]
  if (
    dateOfLastTrainingPlan &&
    isBefore(new Date(dateOfLastTrainingPlan), new Date()) &&
    !withoutPlan
  ) {
    return true;
  }
  return false;
};
