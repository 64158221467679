/** @jsx jsx */
import { FC } from 'react';
import { jsx, css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import {
  TrainingSectionTypeEnum,
  TrainingSetWrapperText,
} from '../../../enums/TrainingTypesEnum';
import { SuperSet } from './SuperSet';
import { TrainingSectionPartWithOperation } from '../../../interfaces/traning';

interface SetWrapperProps {
  isLast: boolean;
  previousPause: number | undefined;
  sectionPart: TrainingSectionPartWithOperation;
  updateSuperSet: (superSet: TrainingSectionPartWithOperation) => void;
  handleAdd: (from: TrainingSectionPartWithOperation) => void;
}

export const SetWrapper: FC<SetWrapperProps> = ({
  sectionPart,
  isLast,
  previousPause,
  updateSuperSet,
  handleAdd,
}) => {
  const isSingleOne =
    sectionPart.type === TrainingSectionTypeEnum.SingleExercise;
  const name = TrainingSetWrapperText[sectionPart.type];

  return (
    <Grid container css={selectedItemCss}>
      <SuperSet
        typeOfSet={name}
        superSet={sectionPart}
        isSingleOne={isSingleOne}
        isLast={isLast}
        previousPause={previousPause}
        updateSuperSet={updateSuperSet}
        handleAdd={handleAdd}
      />
    </Grid>
  );
};

export const selectedItemCss = css`
  &.sortable-ghost {
    background-color: rgba(0, 148, 217, 0.15);
    cursor: move;
  }
  &.sortable-drag {
    background-color: rgba(0, 148, 217, 0.15);
    cursor: move;
  }
`;
