/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Trans } from '@lingui/macro';
import { Grid } from '@material-ui/core';
import { COLORS, MPX } from '../../../../../styles/themes';

export interface CommentTrainingDescriptionDetailProps {
  comment: string;
}

const CommentTrainingDescriptionDetail: React.FC<CommentTrainingDescriptionDetailProps> =
  ({ comment }) => (
    <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        css={gridCss}
      >
        <Grid item xs={12} css={commentCss}>
          <Trans>Komentář</Trans>
        </Grid>
        <Grid item xs={12}>
          <p css={textCss}>{comment}</p>
        </Grid>
      </Grid>
    </div>
  );

export default CommentTrainingDescriptionDetail;

const gridCss = css`
  padding: 2vw;
`;

const textCss = css`
  padding-top: 0px;
  margin-top: ${MPX}px;
  color: ${COLORS.white};
  overflow: hidden;
  word-wrap: break-word;
`;

const commentCss = css`
  color: ${COLORS.gray4};
  font-size: large;
  font-size: ${4 * MPX}px;
`;
