export enum RepeatsModifierEnum {
  Quantity = 'x',
  Seconds = 's',
  Minutes = 'm',
}

export enum PauseModifierEnum {
  Seconds = 's',
  Minutes = 'm',
}
