/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/react';
import { Grid, IconButton } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  TrainingExerciseDTO,
  TrainingSectionPartsDTO,
} from '../../../../strapi/TrainingDTO';
import { COLORS, MPX, TOP_MENU_HEIGHT } from '../../../../styles/themes';
import TrainingDetailDivider from './headerTrainingComponents/TrainingDetailDivider';
import SerieOpakovanieVahaPauzaRow from './headerTrainingComponents/SerieRepeatWeightPauseRow';
import SerieOpakovanieVahaPauzaDataRow from './headerTrainingComponents/SerieRepeatWeightPauseDataRow';
import { RepeatsModifierEnum } from '../../../../enums/RepeatsModifierEnum';
import ExerciseRow from './superSerieDescriptionComponents/ExerciseRow';
import ImageCarousel from '../../shared/ImageCarousel';
import {
  getImagesFromExerciseForCarousel,
  handleSerieChange,
  orderByOrder,
  splitUsingCommonDelimiter,
} from '../../helper/functions';
import { TrainingExerciseKeys } from '../../../../enums/TrainingTypesEnum';
import { titleCss } from '../../shared/css';

export interface SuperSerieExerciseDescriptionDetailProps {
  sectionPart?: TrainingSectionPartsDTO;
  detailLink: (exerciseId: string) => string;
}

const SuperSerieExerciseDescriptionDetail: React.FC<SuperSerieExerciseDescriptionDetailProps> =
  ({ sectionPart, detailLink }) => {
    const [serieOrder, setSerieOrder] = useState(1);

    if (!sectionPart) {
      return null;
    }

    const handleSerie = (number: number) => {
      handleSerieChange(number, serieOrder, sectionPart.series, setSerieOrder);
    };

    const getDataForSpecificSerie = (
      trainingExercise: TrainingExerciseDTO,
      type: TrainingExerciseKeys,
    ) => {
      const splitted = splitUsingCommonDelimiter(trainingExercise[type]);
      if (splitted.length === 1) {
        return splitted[0];
      }

      return splitted[serieOrder - 1];
    };

    const getLinks = () =>
      sectionPart.training_exercises.map(te => detailLink(te.exercise.id));

    const orderedExercises: TrainingExerciseDTO[] = orderByOrder(
      sectionPart.training_exercises,
    );

    return (
      <div css={wrapperCss}>
        <ImageCarousel
          images={getImagesFromExerciseForCarousel(
            orderedExercises,
            getLinks(),
          )}
        />
        <div css={titleCss}>
          {sectionPart.order + 1}. <Trans>Supersérie</Trans>
        </div>
        <SerieOpakovanieVahaPauzaRow />
        <SerieOpakovanieVahaPauzaDataRow
          series={sectionPart.series}
          pause={sectionPart.pause}
          pauseModifier={RepeatsModifierEnum.Seconds}
        />
        <TrainingDetailDivider />
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            css={arrowsWrapperCss}
          >
            <IconButton
              css={arrowCss(serieOrder === 1)}
              onClick={e => {
                e.preventDefault();
                handleSerie(-1);
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Grid
              item
              container
              direction="column"
              xs={4}
              alignItems="center"
              css={textCss}
            >
              <Grid item css={seriesTitleCss}>
                <Trans> Série </Trans>
              </Grid>
              <Grid item css={seriesCounterCss}>
                {`${serieOrder} / ${sectionPart.series}`}
              </Grid>
            </Grid>
            <IconButton
              css={arrowCss(serieOrder === Number(sectionPart.series))}
              onClick={e => {
                e.preventDefault();
                handleSerie(1);
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Grid>
          <div css={exercisesCss}>
            {orderedExercises.map(trainingEx => (
              <ExerciseRow
                key={trainingEx.id}
                title={trainingEx.exercise.title.text}
                repeats={getDataForSpecificSerie(
                  trainingEx,
                  TrainingExerciseKeys.repeats,
                )}
                weight={getDataForSpecificSerie(
                  trainingEx,
                  TrainingExerciseKeys.weights,
                )}
                repeatsModifier={trainingEx.repeats_modifier}
                detailLink={detailLink(trainingEx.exercise.id)}
              />
            ))}
          </div>
        </Grid>
      </div>
    );
  };

export default SuperSerieExerciseDescriptionDetail;

const wrapperCss = css`
  color: white;
`;

const arrowsWrapperCss = css`
  padding-left: 2vh;
  padding-right: 2vh;
  margin-top: ${1.75 * MPX}px !important;
`;

const arrowCss = (disabled: boolean) => css`
  background-color: ${!disabled ? COLORS.baseOrange : 'transparent'} !important;
  color: ${!disabled ? COLORS.white : COLORS.gray4} !important;
  border-radius: ${MPX}px !important;
  border: ${!disabled
    ? `${0.4 * MPX}px solid transparent`
    : `${0.4 * MPX}px solid ${COLORS.gray4}`} !important;
`;

const textCss = css`
  color: ${COLORS.gray4};
  font-size: large;
`;

const exercisesCss = css`
  padding-top: ${MPX * 1}vh;
`;

const seriesTitleCss = css`
  font-size: ${MPX * 3.5}px;
`;

const seriesCounterCss = css`
  font-size: ${MPX * 6}px;
  color: ${COLORS.white};
  font-weight: 600;
`;
