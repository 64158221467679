/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useCallback, FC, useRef, memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHours } from 'date-fns';
import FullCalendar, { EventClickArg, ViewApi } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import csLocale from '@fullcalendar/core/locales/cs';
import isEqual from 'react-fast-compare';
import { COLORS } from '../../../styles/themes';

import {
  getHeaderTextCalendar,
  remapTrainingToEvent,
} from '../../../code/helpers/calendarHelper';
import { wrapperCss } from '../../../styles/calendar';
import { TrainingDTO } from '../../../strapi/TrainingDTO';
import { setTraining } from '../../../store/training/actions';

import { getEventsForMobileTrainer } from '../../../store/trainingPlans/selectors';
import { getLoggedUser } from '../../../store/auth/selectors';
import { performFetchTrainerEvents } from '../../../store/calendar/actions';
import { performFetchClients } from '../../../store/client/actions';
import { EventDTO } from '../../../interfaces/event';
import { getClientTrainings } from '../../../store/training/selectors';
import { getFetchedEvents } from '../../../store/calendar/selectors';

interface CalendarProps {
  eventsMobile?: EventDTO[] | undefined;
  setSelectedDate: (date: Date) => void;
  planningState: boolean;
  setTraining: typeof setTraining;
  training: TrainingDTO | undefined;
  selectedDate: Date | undefined;
  isClient?: boolean;
}
interface DateClick {
  date: Date;
  dateStr: string;
  allDay: boolean;
  dayEl: HTMLElement;
  jsEvent: MouseEvent;
  view: ViewApi;
}

export const RawCalendar: FC<CalendarProps> = ({
  setSelectedDate,
  training,
  setTraining,
  planningState,
  selectedDate,
  eventsMobile,
  isClient,
}) => {
  const calendarRef = useRef(null);
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const fetchedEvents = useSelector(getFetchedEvents);
  useEffect(() => {
    if (loggedUser) {
      if (!eventsMobile) {
        dispatch(performFetchTrainerEvents(loggedUser.id));
      }
      dispatch(performFetchClients(loggedUser.id));
    }
  }, [dispatch, loggedUser, eventsMobile]);
  const eventsMobileTrainer = useSelector(getEventsForMobileTrainer);
  // Prepare Client calendar events
  const eventsMobileClient = useSelector(getClientTrainings);
  const mappedEventsMobileClient: EventDTO[] = useMemo(
    () =>
      eventsMobileClient
        ? eventsMobileClient
            .map(training => remapTrainingToEvent(training))
            .concat(fetchedEvents || [])
        : [],
    [fetchedEvents, eventsMobileClient],
  );

  const handleDateClick = useCallback(
    (arg: DateClick) => {
      if (arg.date && arg.dateStr) {
        setSelectedDate(setHours(arg.date, 0));
        if (training && !training.dateFrom && planningState) {
          const trainingWithDate: TrainingDTO = {
            ...training,
            dateFrom: setHours(arg.date, 0).toString(),
          };

          setTraining({ training: trainingWithDate });
        } else if (calendarRef && calendarRef.current) {
          // @ts-ignore
          calendarRef.current.getApi().select(arg.date);
        }
      }
    },
    [setSelectedDate, training, planningState, setTraining],
  );
  const handleEventClick = useCallback(
    (arg: EventClickArg) => {
      if (arg.event.start) {
        setSelectedDate(setHours(arg.event.start, 0));
        if (training && !training.dateFrom && planningState) {
          const trainingWithDate: TrainingDTO = {
            ...training,
            dateFrom: new Date(arg.event.startStr).toISOString(),
          };

          setTraining({ training: trainingWithDate });
        } else if (calendarRef && calendarRef.current) {
          // @ts-ignore
          calendarRef.current.getApi().select(arg.event.start);
        }
      }
    },
    [setSelectedDate, training, planningState, setTraining],
  );

  return (
    <div css={wrapperCss(selectedDate)}>
      <FullCalendar
        ref={calendarRef}
        initialView="dayGridMonth"
        firstDay={1}
        selectable
        locale={csLocale}
        unselectAuto={false}
        eventContent={arg => {
          const div = document.createElement('div');
          const dot = document.createElement('div');

          dot.className = 'dot';
          dot.style.backgroundColor = arg.event.textColor;
          dot.style.marginRight = '5px';

          // for two dots
          const oneDiv = document.createElement('div');
          if (arg.event.extendedProps.moreInOneDay) {
            oneDiv.className = 'dot';
            oneDiv.style.backgroundColor =
              arg.event.textColor === COLORS.lightDarkBlue
                ? COLORS.gray8
                : COLORS.lightDarkBlue;
            oneDiv.style.left = '55%';
            dot.style.left = '35%';
            div.append(oneDiv);
          }
          div.append(dot);

          const arrayOfDomNodes = [div];
          return { domNodes: arrayOfDomNodes };
        }}
        headerToolbar={getHeaderTextCalendar(true, false)}
        eventClick={handleEventClick}
        dayHeaderFormat={{ weekday: 'short' }}
        weekends
        events={
          isClient
            ? mappedEventsMobileClient
            : eventsMobile || eventsMobileTrainer
        }
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
        height="auto"
        dateClick={handleDateClick}
      />
    </div>
  );
};

const areEqual = (prevProps: CalendarProps, nextProps: CalendarProps) =>
  isEqual(prevProps.training, nextProps.training);

export const Calendar = memo(RawCalendar, areEqual);
