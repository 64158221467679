/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Divider } from '@material-ui/core';
import { COLORS, MPX } from '../../../../../styles/themes';

const TrainingDetailDivider: React.FC = () => (
  <div css={wrapperDivider}>
    <Divider variant="fullWidth" light css={dividerCss} />
  </div>
);

export default TrainingDetailDivider;

const wrapperDivider = css`
  padding: ${0.5 * MPX}vh;
`;

const dividerCss = css`
  background-color: ${COLORS.white} !important;
`;
