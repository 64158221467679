/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useCallback } from 'react';
import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { useDeCapitilizeButtonStyles } from '../../../../../styles/buttons';
import { COLORS, MPX } from '../../../../../styles/themes';

export interface ModalAlertProps {
  toDoNext: () => void;
  end: () => void;
}

const ModalAlert: React.FC<ModalAlertProps> = ({ toDoNext, end }) => {
  const classes = useDeCapitilizeButtonStyles();

  const handleClose = useCallback(() => end(), [end]);
  const open = true;

  const handleContinue = useCallback(() => toDoNext(), [toDoNext]);
  const handleEndTraining = useCallback(() => end(), [end]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent css={wrapperCss}>
        <Grid container direction="column">
          <div css={textCss}>
            <Typography variant="body1">
              <Trans>Ukončit trénink</Trans>
            </Typography>
          </div>
          <div css={textCss}>
            <Typography variant="body2">
              <Trans>Opravdu si přejete ukončit probíhající trénink?</Trans>
            </Typography>
          </div>
          <div css={buttonCss}>
            <Button
              fullWidth
              color="secondary"
              onClick={handleContinue}
              variant="contained"
              classes={{ root: classes.root }}
            >
              <Trans>Pokračovat v tréninku</Trans>
            </Button>
          </div>
          <div css={buttonCss}>
            <Button
              fullWidth
              color="secondary"
              onClick={handleEndTraining}
              variant="outlined"
              classes={{ root: classes.root, outlined: classes.outlined }}
            >
              <Trans>Ukončit trénink</Trans>
            </Button>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAlert;

const textCss = css`
  color: ${COLORS.white};
  text-align: center;
  margin-bottom: ${MPX * 3}px;
`;

const wrapperCss = css`
  padding: 0px ${MPX * 5}px ${MPX * 2}px ${MPX * 5}px;
  background: ${COLORS.gray7};
`;

const buttonCss = css`
  margin-top: ${MPX * 2}px;
  margin-bottom: ${MPX * 2}px;
`;
