import * as Sentry from '@sentry/browser';
import React, { ErrorInfo } from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<
  unknown,
  ErrorBoundaryState
> {
  public static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props: ErrorBoundaryState) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.withScope((scope: Sentry.Scope) => {
      scope.setExtra('componentStack', errorInfo.componentStack);
      Sentry.captureException(error);
    });
  }

  public render(): JSX.Element | React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return children;
  }
}
