/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Trans } from '@lingui/macro';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isTrainer } from '../../../code/helpers/user';
import { AT } from '../../../store/actionTypes';
import { performDeleteMyAccount } from '../../../store/auth/actions';
import { getLoggedUser } from '../../../store/auth/selectors';
import { getError, isLoading } from '../../../store/request/selectors';

const requestActions = [AT.DELETE_MY_ACCOUNT];

export const DangerZone = () => {
  const [deleteAccoutnDialogOpened, setDeleteAccountDialogOpened] =
    useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const loading = useSelector(isLoading(requestActions));
  const error = useSelector(getError(requestActions));
  const loggedUser = useSelector(getLoggedUser);

  const handleSubmit = () => {
    dispatch(
      performDeleteMyAccount(() => {
        enqueueSnackbar(<Trans>Váš účet byl kompletně smazán.</Trans>, {
          variant: 'success',
        });
      }),
    );
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
      setDeleteAccountDialogOpened(false);
    }
  }, [error, enqueueSnackbar]);

  const warning =
    loggedUser?.role != null && isTrainer(loggedUser.role) ? (
      <Trans>
        Tato operace vymaže vaše údaje a také účty všech klientů, které jste
        založili.
      </Trans>
    ) : (
      <Trans>
        Tato operace kompletně smaže vaše údaje a dosažený progres. Rovněž
        ztratíte spojení na trenéra.
      </Trans>
    );

  return (
    <div style={{ padding: '0 15px' }}>
      <Typography variant="h5" style={{ color: 'red' }} gutterBottom>
        <Trans>Vymazání účtu</Trans>
      </Typography>

      <Typography variant="body2" gutterBottom>
        {warning}
      </Typography>

      <Button
        variant="outlined"
        color="secondary"
        style={{ borderColor: 'red', color: 'red', margin: '10px 0' }}
        fullWidth
        onClick={() => setDeleteAccountDialogOpened(true)}
      >
        <Trans>Rozumím a chci tento účet vymazat</Trans>
      </Button>

      <Dialog
        open={deleteAccoutnDialogOpened}
        onClose={() => setDeleteAccountDialogOpened(false)}
      >
        <DialogTitle>
          <Trans>Skutečně chcete vymazat svůj Motivibe účet?</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>Po potvrzení budete odhlasen a váš účet smazán!</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            variant="text"
            color="secondary"
            style={{ borderColor: 'red', color: 'red' }}
            disabled={loading}
            startIcon={
              loading ? (
                <CircularProgress color="secondary" size={15} />
              ) : undefined
            }
          >
            <Trans>Ano, smazat</Trans>
          </Button>
          <Button
            onClick={() => setDeleteAccountDialogOpened(false)}
            color="primary"
            autoFocus
            variant="contained"
          >
            <Trans>Ne, nechci</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
