import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import { setNotes, addNote, updateNote, deleteNote } from './actions';
import { NoteDTO } from '../../strapi/NoteDTO';

export interface State {
  notes: NoteDTO[];
}

export const initialState: State = {
  notes: [],
};

export const notesReducer = reducer<State>(
  initialState,
  on(setNotes, (state: State, { payload }) => {
    state.notes = payload.notes;
  }),
  on(addNote, (state: State, { payload }) => {
    state.notes.push(payload.note);
  }),
  on(updateNote, (state: State, { payload }) => {
    const nodeIndex = state.notes.findIndex(
      item => item.id === payload.note.id,
    );

    state.notes[nodeIndex] = payload.note;
  }),
  on(deleteNote, (state: State, { payload }) => {
    state.notes = state.notes.filter(item => item.id !== payload.note.id);
  }),
);
