/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import SerieRepeatWeightPauseRow from '../headerTrainingComponents/SerieRepeatWeightPauseRow';
import SerieRepeatWeightPauseDataRow from '../headerTrainingComponents/SerieRepeatWeightPauseDataRow';
import { TrainingExerciseDTO } from '../../../../../strapi/TrainingDTO';
import TrainingDetailDivider from '../headerTrainingComponents/TrainingDetailDivider';
import TextWithInformationIcon from '../../../shared/TextWithInformationIcon';
import ImageCarousel, {
  IImageCarouselImage,
} from '../../../shared/ImageCarousel';
import { TrainingExerciseKeys } from '../../../../../enums/TrainingTypesEnum';
import { getDataForSpecificSerieOrCircuit } from '../../../helper/functions';

interface HeaderTrainingDescriptionDetailProps {
  images: IImageCarouselImage[];
  title: string;
  trainingExercise: TrainingExerciseDTO | undefined;
  isNotSeries?: boolean;
  isNotRepeats?: boolean;
  isNotWeights?: boolean;
  isNotPause?: boolean;
  detailLink: string;
  currentSerie?: number;
  orderOfExercise?: number;
}

const HeaderTrainingDescriptionDetail: React.FC<HeaderTrainingDescriptionDetailProps> =
  ({
    isNotSeries = false,
    isNotRepeats = false,
    isNotWeights = false,
    isNotPause = false,
    currentSerie = 0,
    images,
    title,
    trainingExercise,
    detailLink,
    orderOfExercise,
  }) => (
    <div>
      <ImageCarousel images={images} />
      <div css={titleWrapperCss}>
        <TextWithInformationIcon
          orderOfExercise={orderOfExercise}
          title={title}
          link={detailLink}
        />
      </div>
      <SerieRepeatWeightPauseRow
        notSeries={isNotSeries}
        notPause={isNotPause}
        notRepeats={isNotRepeats}
        notWeights={isNotWeights}
      />
      {trainingExercise && (
        <SerieRepeatWeightPauseDataRow
          series={trainingExercise.series}
          weights={
            isNotWeights
              ? ''
              : getDataForSpecificSerieOrCircuit(
                  trainingExercise,
                  TrainingExerciseKeys.weights,
                  currentSerie,
                )
          }
          notRepeats={isNotRepeats}
          repeats={trainingExercise.repeats}
          repeatsModifier={trainingExercise.repeats_modifier}
          pause={trainingExercise.pause}
          pauseModifier={trainingExercise.pause_modifier}
        />
      )}
      <TrainingDetailDivider />
    </div>
  );

const titleWrapperCss = css`
  padding-left: 2vh;
  padding-right: 2vh;
`;

export default HeaderTrainingDescriptionDetail;
