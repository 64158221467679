import { Capacitor } from '@capacitor/core';
import { NativeAudio } from '@capgo/native-audio';
import React, { useMemo } from 'react';
import useSound from 'use-sound';
import countdownSound from '../../assets/sounds/countdown.mp3';
import goSound from '../../assets/sounds/goSound.mp3';

export enum Sound {
  countdown = 'countdown',
  go = 'go',
}

class NativeSoundsController {
  constructor() {
    this.loadAll();
  }

  public play = async (sound: Sound) => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    const { isPlaying } = await NativeAudio.isPlaying({ assetId: sound });
    if (!isPlaying) {
      await NativeAudio.play({ assetId: sound, time: 0 });
    }
  };

  public stop = async (sound: Sound) => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    await NativeAudio.stop({ assetId: sound });
  };

  public loadAll = async () => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    const sounds = [
      {
        assetId: Sound.countdown,
        assetPath: `public${countdownSound}`,
        audioChannelNum: 1,
        isUrl: false,
      },
      {
        assetId: Sound.go,
        assetPath: `public${goSound}`,
        audioChannelNum: 1,
        isUrl: false,
      },
    ] as const;

    /* eslint-disable no-restricted-syntax */
    for (const sound of sounds) {
      try {
        /* eslint-disable no-await-in-loop */
        await NativeAudio.preload(sound);
      } catch (error) {
        // This try-catch is prevence againts preloading same sound multiple time. Not sure why it is happening, but in iOS 13 it throws error occassionally.
      }
      // This is hack. Calling preload() in sequence throws memory error in NativeAudio library.
      /* eslint-disable no-await-in-loop */
      await new Promise(resolve => setTimeout(resolve, 500));
    }
  };
}

export const nativeSoundsCtrl = new NativeSoundsController();

function useHowlerSound() {
  const [playGo, { stop: stopGo }] = useSound(goSound);
  const [playCount, { stop: stopCount }] = useSound(countdownSound);

  return useMemo(
    () => ({
      [Sound.go]: { play: playGo, stop: stopGo },
      [Sound.countdown]: { play: playCount, stop: stopCount },
    }),
    /* eslint-disable react-hooks/exhaustive-deps */
    [],
  );
}

export function useAnySound() {
  const howlerSounds = useHowlerSound();

  function play(sound: Sound) {
    if (Capacitor.isNativePlatform()) {
      nativeSoundsCtrl.play(sound);
    } else {
      howlerSounds[sound].play();
    }
  }

  function stop(sound: Sound) {
    if (Capacitor.isNativePlatform()) {
      nativeSoundsCtrl.stop(sound);
    } else {
      howlerSounds[sound].stop();
    }
  }

  return React.useMemo(
    () => ({
      play,
      stop,
      /* eslint-disable react-hooks/exhaustive-deps */
    }),
    [],
  );
}
