// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ActivateAccountDto,
  ActivateAccountDtoFromJSON,
  ActivateAccountDtoToJSON,
  ActivateAccountResponseDto,
  ActivateAccountResponseDtoFromJSON,
  ActivateAccountResponseDtoToJSON,
  ClientResponseDto,
  ClientResponseDtoFromJSON,
  ClientResponseDtoToJSON,
  ConversationsResponseDto,
  ConversationsResponseDtoFromJSON,
  ConversationsResponseDtoToJSON,
  CreateConversationDto,
  CreateConversationDtoFromJSON,
  CreateConversationDtoToJSON,
  CreateConversationResponseDto,
  CreateConversationResponseDtoFromJSON,
  CreateConversationResponseDtoToJSON,
  CreateMessageDto,
  CreateMessageDtoFromJSON,
  CreateMessageDtoToJSON,
  CreateMessageResponseDto,
  CreateMessageResponseDtoFromJSON,
  CreateMessageResponseDtoToJSON,
  CreateUserDto,
  CreateUserDtoFromJSON,
  CreateUserDtoToJSON,
  DeleteMessageDto,
  DeleteMessageDtoFromJSON,
  DeleteMessageDtoToJSON,
  DeleteMessageResponseDto,
  DeleteMessageResponseDtoFromJSON,
  DeleteMessageResponseDtoToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ForgotPasswordDto,
  ForgotPasswordDtoFromJSON,
  ForgotPasswordDtoToJSON,
  ForgotPasswordResponseDto,
  ForgotPasswordResponseDtoFromJSON,
  ForgotPasswordResponseDtoToJSON,
  LoginDto,
  LoginDtoFromJSON,
  LoginDtoToJSON,
  LoginResponseDto,
  LoginResponseDtoFromJSON,
  LoginResponseDtoToJSON,
  MarkMessagesReadDto,
  MarkMessagesReadDtoFromJSON,
  MarkMessagesReadDtoToJSON,
  MarkMessagesReadResponseDto,
  MarkMessagesReadResponseDtoFromJSON,
  MarkMessagesReadResponseDtoToJSON,
  RegistrationDto,
  RegistrationDtoFromJSON,
  RegistrationDtoToJSON,
  RegistrationResponseDto,
  RegistrationResponseDtoFromJSON,
  RegistrationResponseDtoToJSON,
  ResetPasswordDto,
  ResetPasswordDtoFromJSON,
  ResetPasswordDtoToJSON,
  ResetPasswordResponseObjectDto,
  ResetPasswordResponseObjectDtoFromJSON,
  ResetPasswordResponseObjectDtoToJSON,
  UpdateConversationDto,
  UpdateConversationDtoFromJSON,
  UpdateConversationDtoToJSON,
  UpdateConversationResponseDto,
  UpdateConversationResponseDtoFromJSON,
  UpdateConversationResponseDtoToJSON,
  UpdateMessageDto,
  UpdateMessageDtoFromJSON,
  UpdateMessageDtoToJSON,
  UpdateMessageResponseDto,
  UpdateMessageResponseDtoFromJSON,
  UpdateMessageResponseDtoToJSON,
  UpdateUserDetailsDto,
  UpdateUserDetailsDtoFromJSON,
  UpdateUserDetailsDtoToJSON,
  UpdateUserDto,
  UpdateUserDtoFromJSON,
  UpdateUserDtoToJSON,
  UserResponseDto,
  UserResponseDtoFromJSON,
  UserResponseDtoToJSON,
} from '../models';

export interface ApiChatConversationsMessagesDeleteRequest {
  deleteMessageDto: DeleteMessageDto;
}

export interface ApiChatConversationsMessagesPostRequest {
  createMessageDto: CreateMessageDto;
}

export interface ApiChatConversationsMessagesPutRequest {
  updateMessageDto: UpdateMessageDto;
}

export interface ApiChatConversationsMessagesReadPostRequest {
  markMessagesReadDto: MarkMessagesReadDto;
}

export interface ApiChatConversationsPostRequest {
  createConversationDto: CreateConversationDto;
}

export interface ApiChatConversationsPutRequest {
  updateConversationDto: UpdateConversationDto;
}

export interface ApiLoginPostRequest {
  loginDto: LoginDto;
}

export interface ApiRegistrationActivateAccountPostRequest {
  activateAccountDto: ActivateAccountDto;
}

export interface ApiRegistrationForgotPasswordPostRequest {
  forgotPasswordDto: ForgotPasswordDto;
}

export interface ApiRegistrationPostRequest {
  registrationDto: RegistrationDto;
}

export interface ApiRegistrationResetPasswordPostRequest {
  resetPasswordDto: ResetPasswordDto;
}

export interface ApiUsersDetailsIdPutRequest {
  id: number;
  updateUserDetailsDto: UpdateUserDetailsDto;
}

export interface ApiUsersIdGetRequest {
  id: number;
}

export interface ApiUsersIdPutRequest {
  id: number;
  updateUserDto: UpdateUserDto;
}

export interface ApiUsersPostRequest {
  createUserDto: CreateUserDto;
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   */
  async apiChatConversationsGetRaw(): Promise<
    runtime.ApiResponse<ConversationsResponseDto>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/chat/conversations`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ConversationsResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiChatConversationsGet(): Promise<ConversationsResponseDto> {
    const response = await this.apiChatConversationsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiChatConversationsMessagesDeleteRaw(
    requestParameters: ApiChatConversationsMessagesDeleteRequest,
  ): Promise<runtime.ApiResponse<DeleteMessageResponseDto>> {
    if (
      requestParameters.deleteMessageDto === null ||
      requestParameters.deleteMessageDto === undefined
    ) {
      throw new runtime.RequiredError(
        'deleteMessageDto',
        'Required parameter requestParameters.deleteMessageDto was null or undefined when calling apiChatConversationsMessagesDelete.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/chat/conversations/messages`,
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      body: DeleteMessageDtoToJSON(requestParameters.deleteMessageDto),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      DeleteMessageResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiChatConversationsMessagesDelete(
    requestParameters: ApiChatConversationsMessagesDeleteRequest,
  ): Promise<DeleteMessageResponseDto> {
    const response = await this.apiChatConversationsMessagesDeleteRaw(
      requestParameters,
    );
    return await response.value();
  }

  /**
   */
  async apiChatConversationsMessagesPostRaw(
    requestParameters: ApiChatConversationsMessagesPostRequest,
  ): Promise<runtime.ApiResponse<CreateMessageResponseDto>> {
    if (
      requestParameters.createMessageDto === null ||
      requestParameters.createMessageDto === undefined
    ) {
      throw new runtime.RequiredError(
        'createMessageDto',
        'Required parameter requestParameters.createMessageDto was null or undefined when calling apiChatConversationsMessagesPost.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/chat/conversations/messages`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateMessageDtoToJSON(requestParameters.createMessageDto),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CreateMessageResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiChatConversationsMessagesPost(
    requestParameters: ApiChatConversationsMessagesPostRequest,
  ): Promise<CreateMessageResponseDto> {
    const response = await this.apiChatConversationsMessagesPostRaw(
      requestParameters,
    );
    return await response.value();
  }

  /**
   */
  async apiChatConversationsMessagesPutRaw(
    requestParameters: ApiChatConversationsMessagesPutRequest,
  ): Promise<runtime.ApiResponse<UpdateMessageResponseDto>> {
    if (
      requestParameters.updateMessageDto === null ||
      requestParameters.updateMessageDto === undefined
    ) {
      throw new runtime.RequiredError(
        'updateMessageDto',
        'Required parameter requestParameters.updateMessageDto was null or undefined when calling apiChatConversationsMessagesPut.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/chat/conversations/messages`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateMessageDtoToJSON(requestParameters.updateMessageDto),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateMessageResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiChatConversationsMessagesPut(
    requestParameters: ApiChatConversationsMessagesPutRequest,
  ): Promise<UpdateMessageResponseDto> {
    const response = await this.apiChatConversationsMessagesPutRaw(
      requestParameters,
    );
    return await response.value();
  }

  /**
   */
  async apiChatConversationsMessagesReadPostRaw(
    requestParameters: ApiChatConversationsMessagesReadPostRequest,
  ): Promise<runtime.ApiResponse<MarkMessagesReadResponseDto>> {
    if (
      requestParameters.markMessagesReadDto === null ||
      requestParameters.markMessagesReadDto === undefined
    ) {
      throw new runtime.RequiredError(
        'markMessagesReadDto',
        'Required parameter requestParameters.markMessagesReadDto was null or undefined when calling apiChatConversationsMessagesReadPost.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/chat/conversations/messages/read`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: MarkMessagesReadDtoToJSON(requestParameters.markMessagesReadDto),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      MarkMessagesReadResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiChatConversationsMessagesReadPost(
    requestParameters: ApiChatConversationsMessagesReadPostRequest,
  ): Promise<MarkMessagesReadResponseDto> {
    const response = await this.apiChatConversationsMessagesReadPostRaw(
      requestParameters,
    );
    return await response.value();
  }

  /**
   */
  async apiChatConversationsPostRaw(
    requestParameters: ApiChatConversationsPostRequest,
  ): Promise<runtime.ApiResponse<CreateConversationResponseDto>> {
    if (
      requestParameters.createConversationDto === null ||
      requestParameters.createConversationDto === undefined
    ) {
      throw new runtime.RequiredError(
        'createConversationDto',
        'Required parameter requestParameters.createConversationDto was null or undefined when calling apiChatConversationsPost.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/chat/conversations`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateConversationDtoToJSON(
        requestParameters.createConversationDto,
      ),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CreateConversationResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiChatConversationsPost(
    requestParameters: ApiChatConversationsPostRequest,
  ): Promise<CreateConversationResponseDto> {
    const response = await this.apiChatConversationsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiChatConversationsPutRaw(
    requestParameters: ApiChatConversationsPutRequest,
  ): Promise<runtime.ApiResponse<UpdateConversationResponseDto>> {
    if (
      requestParameters.updateConversationDto === null ||
      requestParameters.updateConversationDto === undefined
    ) {
      throw new runtime.RequiredError(
        'updateConversationDto',
        'Required parameter requestParameters.updateConversationDto was null or undefined when calling apiChatConversationsPut.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/chat/conversations`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateConversationDtoToJSON(
        requestParameters.updateConversationDto,
      ),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateConversationResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiChatConversationsPut(
    requestParameters: ApiChatConversationsPutRequest,
  ): Promise<UpdateConversationResponseDto> {
    const response = await this.apiChatConversationsPutRaw(requestParameters);
    return await response.value();
  }

  /**
   * Log in (for everyone)
   */
  async apiLoginPostRaw(
    requestParameters: ApiLoginPostRequest,
  ): Promise<runtime.ApiResponse<LoginResponseDto>> {
    if (
      requestParameters.loginDto === null ||
      requestParameters.loginDto === undefined
    ) {
      throw new runtime.RequiredError(
        'loginDto',
        'Required parameter requestParameters.loginDto was null or undefined when calling apiLoginPost.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/login`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: LoginDtoToJSON(requestParameters.loginDto),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      LoginResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   * Log in (for everyone)
   */
  async apiLoginPost(
    requestParameters: ApiLoginPostRequest,
  ): Promise<LoginResponseDto> {
    const response = await this.apiLoginPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Activate client (trainee) account
   */
  async apiRegistrationActivateAccountPostRaw(
    requestParameters: ApiRegistrationActivateAccountPostRequest,
  ): Promise<runtime.ApiResponse<ActivateAccountResponseDto>> {
    if (
      requestParameters.activateAccountDto === null ||
      requestParameters.activateAccountDto === undefined
    ) {
      throw new runtime.RequiredError(
        'activateAccountDto',
        'Required parameter requestParameters.activateAccountDto was null or undefined when calling apiRegistrationActivateAccountPost.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/registration/activate-account`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ActivateAccountDtoToJSON(requestParameters.activateAccountDto),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ActivateAccountResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   * Activate client (trainee) account
   */
  async apiRegistrationActivateAccountPost(
    requestParameters: ApiRegistrationActivateAccountPostRequest,
  ): Promise<ActivateAccountResponseDto> {
    const response = await this.apiRegistrationActivateAccountPostRaw(
      requestParameters,
    );
    return await response.value();
  }

  /**
   * Request new password
   */
  async apiRegistrationForgotPasswordPostRaw(
    requestParameters: ApiRegistrationForgotPasswordPostRequest,
  ): Promise<runtime.ApiResponse<ForgotPasswordResponseDto>> {
    if (
      requestParameters.forgotPasswordDto === null ||
      requestParameters.forgotPasswordDto === undefined
    ) {
      throw new runtime.RequiredError(
        'forgotPasswordDto',
        'Required parameter requestParameters.forgotPasswordDto was null or undefined when calling apiRegistrationForgotPasswordPost.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/registration/forgot-password`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ForgotPasswordDtoToJSON(requestParameters.forgotPasswordDto),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ForgotPasswordResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   * Request new password
   */
  async apiRegistrationForgotPasswordPost(
    requestParameters: ApiRegistrationForgotPasswordPostRequest,
  ): Promise<ForgotPasswordResponseDto> {
    const response = await this.apiRegistrationForgotPasswordPostRaw(
      requestParameters,
    );
    return await response.value();
  }

  /**
   * Trainer registration
   */
  async apiRegistrationPostRaw(
    requestParameters: ApiRegistrationPostRequest,
  ): Promise<runtime.ApiResponse<RegistrationResponseDto>> {
    if (
      requestParameters.registrationDto === null ||
      requestParameters.registrationDto === undefined
    ) {
      throw new runtime.RequiredError(
        'registrationDto',
        'Required parameter requestParameters.registrationDto was null or undefined when calling apiRegistrationPost.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/registration`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: RegistrationDtoToJSON(requestParameters.registrationDto),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RegistrationResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   * Trainer registration
   */
  async apiRegistrationPost(
    requestParameters: ApiRegistrationPostRequest,
  ): Promise<RegistrationResponseDto> {
    const response = await this.apiRegistrationPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Reset forgotten password
   */
  async apiRegistrationResetPasswordPostRaw(
    requestParameters: ApiRegistrationResetPasswordPostRequest,
  ): Promise<runtime.ApiResponse<ResetPasswordResponseObjectDto>> {
    if (
      requestParameters.resetPasswordDto === null ||
      requestParameters.resetPasswordDto === undefined
    ) {
      throw new runtime.RequiredError(
        'resetPasswordDto',
        'Required parameter requestParameters.resetPasswordDto was null or undefined when calling apiRegistrationResetPasswordPost.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/registration/reset-password`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ResetPasswordDtoToJSON(requestParameters.resetPasswordDto),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ResetPasswordResponseObjectDtoFromJSON(jsonValue),
    );
  }

  /**
   * Reset forgotten password
   */
  async apiRegistrationResetPasswordPost(
    requestParameters: ApiRegistrationResetPasswordPostRequest,
  ): Promise<ResetPasswordResponseObjectDto> {
    const response = await this.apiRegistrationResetPasswordPostRaw(
      requestParameters,
    );
    return await response.value();
  }

  /**
   * Get all clients (for trainers)
   */
  async apiUsersClientsGetRaw(): Promise<
    runtime.ApiResponse<Array<ClientResponseDto>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/users/clients`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ClientResponseDtoFromJSON),
    );
  }

  /**
   * Get all clients (for trainers)
   */
  async apiUsersClientsGet(): Promise<Array<ClientResponseDto>> {
    const response = await this.apiUsersClientsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiUsersDetailsIdPutRaw(
    requestParameters: ApiUsersDetailsIdPutRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling apiUsersDetailsIdPut.',
      );
    }

    if (
      requestParameters.updateUserDetailsDto === null ||
      requestParameters.updateUserDetailsDto === undefined
    ) {
      throw new runtime.RequiredError(
        'updateUserDetailsDto',
        'Required parameter requestParameters.updateUserDetailsDto was null or undefined when calling apiUsersDetailsIdPut.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/users/details/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateUserDetailsDtoToJSON(requestParameters.updateUserDetailsDto),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiUsersDetailsIdPut(
    requestParameters: ApiUsersDetailsIdPutRequest,
  ): Promise<void> {
    await this.apiUsersDetailsIdPutRaw(requestParameters);
  }

  /**
   * Get user basic information by id
   */
  async apiUsersIdGetRaw(
    requestParameters: ApiUsersIdGetRequest,
  ): Promise<runtime.ApiResponse<UserResponseDto>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling apiUsersIdGet.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   * Get user basic information by id
   */
  async apiUsersIdGet(
    requestParameters: ApiUsersIdGetRequest,
  ): Promise<UserResponseDto> {
    const response = await this.apiUsersIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiUsersIdPutRaw(
    requestParameters: ApiUsersIdPutRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling apiUsersIdPut.',
      );
    }

    if (
      requestParameters.updateUserDto === null ||
      requestParameters.updateUserDto === undefined
    ) {
      throw new runtime.RequiredError(
        'updateUserDto',
        'Required parameter requestParameters.updateUserDto was null or undefined when calling apiUsersIdPut.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateUserDtoToJSON(requestParameters.updateUserDto),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiUsersIdPut(requestParameters: ApiUsersIdPutRequest): Promise<void> {
    await this.apiUsersIdPutRaw(requestParameters);
  }

  /**
   * Create new client (trainee) account
   */
  async apiUsersPostRaw(
    requestParameters: ApiUsersPostRequest,
  ): Promise<runtime.ApiResponse<ClientResponseDto>> {
    if (
      requestParameters.createUserDto === null ||
      requestParameters.createUserDto === undefined
    ) {
      throw new runtime.RequiredError(
        'createUserDto',
        'Required parameter requestParameters.createUserDto was null or undefined when calling apiUsersPost.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // bearer authentication
    }

    const response = await this.request({
      path: `/api/users`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateUserDtoToJSON(requestParameters.createUserDto),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ClientResponseDtoFromJSON(jsonValue),
    );
  }

  /**
   * Create new client (trainee) account
   */
  async apiUsersPost(
    requestParameters: ApiUsersPostRequest,
  ): Promise<ClientResponseDto> {
    const response = await this.apiUsersPostRaw(requestParameters);
    return await response.value();
  }
}
