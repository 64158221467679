import { createMuiTheme } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { csCZ } from '@material-ui/core/locale';
// Multiplies of this constants are used for margins or paddings within this app
export const MPX = 4;
export const WIDTH_OF_SIDEBAR = `${MPX * 62.5}px`;
export const MENU_HEIGHT = 75;
export const EXTENDED_MENU_HEIGHT = MENU_HEIGHT * 1.65;
export const TOP_MENU_HEIGHT = 55;

export const COLORS = {
  bg: '#eaeff2',
  primary: '#034EA1',
  primaryDark: '#003878',
  secondary: '#f05a22', // orange
  tertiary: '#0071BB',
  contrast: '#fff',
  lightGrey: '#e0e0e0',
  gray4: '#b4b8ba',
  gray5: '#a5a5a5',
  gray6: '#8897A4',
  gray7: '#586A7E',
  gray8: '#B9C3CB',
  danger: '#ff4343',
  success: '#50b953',
  baseOrange: '#F05A22',
  lightBackgroundGrey: '#E5EDF5',
  gray: '#283F58',
  lightBlue: '#f4f7f8',
  evenTableRow: '#f3f6f7',
  scheduleInputBg: '#023a78',
  darkBlue: '#2D4863',
  greyBlue: '#E0E4E8',
  whiteOpacity: 'rgba(255, 255, 255, 0.75);',
  white: '#fff',
  blueLight: '#346193',
  darkerBlue: '#22405b',
  darkestBlue: ' #b9c3cb',
  blueDark: '#283f58',
  lightDarkBlue: '#0094d9',
  almostBlackBlue: '#212529',
  blue: '#34516e',
  grayBlue: '#637589',
  darkDarkBlue: '#293F58',
  lightGreen: '#bee1c5',
  darkGreen: '#01b601',
  grayBlueBackground: '#f6f7f9',
  sunnySkyBlue: '#3366cc',
  bcgTrainingClient: '#142332',
};

const palette: PaletteOptions = {
  primary: { main: COLORS.primary, contrastText: COLORS.contrast },
  secondary: { main: COLORS.secondary, contrastText: COLORS.contrast },
  text: {
    primary: COLORS.primaryDark,
  },
};

const typography: TypographyOptions = {
  fontFamily: ['Rubik', 'sans-serif'].join(','),
  h5: {
    fontWeight: 500,
  },
};
export const APP_THEME = createMuiTheme({ palette, typography }, csCZ);
