import { css, SerializedStyles } from '@emotion/react';
import { MPX } from './themes';

// default form margin bottom (in px)
export const DEFAULT_FORM_MB = 25;

export const fullWidthCss = css`
  width: 100% !important;
`;

export const fullHeightCss = css`
  height: 100vh !important;
`;

// pouyzito v sestavovani treninku na footer na mobilu
export const FOOTER_HEIGHT = 68;

export const mobileBodyMargin = (isMobile: boolean): SerializedStyles => css`
  padding: ${isMobile &&
  `${6 * MPX}px  ${4 * MPX}px ${6 * MPX}px ${4 * MPX}px !important`};
  width: ${isMobile && 'inherit !important'};
`;

export const headerBodyMargin = (isMobile: boolean): SerializedStyles => css`
  padding: ${isMobile &&
  `${2 * MPX}px  ${2 * MPX}px 0px ${2 * MPX}px !important`};
  width: ${isMobile && 'inherit !important'};
`;

export const iconMobileCss = css`
  width: ${8 * MPX}px !important;
  height: ${8 * MPX}px !important;
  padding: 0px !important;

  span svg {
    padding: 0px !important;
  }
`;
