import { css } from '@emotion/react';
import { DEFAULT_FORM_MB } from './general';

export const messageBorder = css`
  border-radius: 15px;
`;

export const formMargin = css`
  margin-bottom: ${DEFAULT_FORM_MB}px !important;
`;

export const ellipsisCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
