import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { State } from '../../../store/combinedReducers';
import { StateProps, ClientNotes, DispatchProps } from './ClientNotes';
import {
  performFetchNotesByUserId,
  performAddNote,
  performUpdateNote,
  performDeleteNote,
} from '../../../store/notes/actions';
import { NotesActionTypes } from '../../../store/actionTypes';
import { isLoading, getError } from '../../../store/request/selectors';

const requestActions = [
  NotesActionTypes.PERFORM_ADD_NOTE,
  NotesActionTypes.PERFORM_DELETE_NOTE,
  NotesActionTypes.PERFORM_UPDATE_NOTE,
];

const mapStateToProps = (state: State): StateProps => ({
  notes: state.notes.notes,
  isLoading: isLoading(requestActions)(state),
  requestError: getError(requestActions)(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(
    {
      performFetchNotesByUserId,
      performAddNote,
      performUpdateNote,
      performDeleteNote,
    },
    dispatch,
  ),
});

const ClientNotesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientNotes);

export { ClientNotesContainer as default };
