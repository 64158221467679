import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import { AxiosResponse } from 'axios';
import { State } from '../combinedReducers';
import { baseAsyncRequest } from '../actionHelpers';
import { TrainingActionTypes as ActionTypes } from '../actionTypes';
import { makeServerCallAsync } from '../../code/helpers/api/api';
import { TrainingDTO, TrainingSectionDTO } from '../../strapi/TrainingDTO';
import { TrainingTypeEnum } from '../../enums/TrainingTypesEnum';
import { updateTrainingInTrainingPlan } from '../trainingPlans/actions';
import { getUpdatedHoursForDateTo } from '../../code/helpers/training';
import { TrainingSectionWithOperation } from '../../interfaces/traning';
import { ExerciseDTO } from '../../strapi/ExerciseDTO';
import { SectionType, UpdateType } from '../../interfaces/enums';
import {
  pauseRunningTraining,
  setTemporaryTime,
  removeTemporaryTime,
} from '../trainingParts/actions';
import { updateClient } from '../client/actions';

export const setTraining = action(
  ActionTypes.SET_TRAINING,
  payload<{ training: TrainingDTO | undefined }>(),
);

export const setDoneAtTraining = action(
  ActionTypes.SET_DONES_AT_TRAINING,
  payload<{ training: TrainingDTO | undefined }>(),
);

export const updateTrainingSections = action(
  ActionTypes.UPDATE_TRAINING_SECTION,
  payload<{ sections: TrainingSectionDTO[] }>(),
);

export const setClientTrainings = action(
  ActionTypes.PERFORM_FETCH_CLIENT_TRAININGS,
  payload<{ clientTrainings: TrainingDTO[] | undefined }>(),
);

export const updateIsDoneTraining = action(
  ActionTypes.UPDATE_IS_DONE_EXERCISE,
  payload<{ updatedTraining: ExerciseDTO[] | undefined }>(),
);

export const performSetTrainingsFromToByLoggedClient: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (from: string, to: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/training/client/${from}/${to}`);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_FETCH_CLIENT_TRAININGS,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      dispatch(setClientTrainings({ clientTrainings: result.data }));
    }
  };

export const performUpdateExerciseIs: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (idSection: string, type: SectionType, updateWhat: UpdateType) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const func = async () => {
      const request = async (): Promise<AxiosResponse<any>> =>
        makeServerCallAsync('PUT', `/${type}/${updateWhat}/${idSection}`);

      const result = await request();

      if (result && result.status === 200) {
        dispatch(setDoneAtTraining({ training: result.data }));
      }
    };

    await baseAsyncRequest(ActionTypes.PERFORM_FETCH_TRAINING, func, dispatch);
  };

export const performFetchTrainingById: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (id: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('get', `/trainings/${id}`);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_FETCH_TRAINING,
      request,
      dispatch,
      {
        errorIdMap: {
          'Training.form.error.dateFrom.beforeDateTo':
            'Čas od nemůže být později než čas do.',
        },
      },
    );

    if (result && result.status === 200) {
      dispatch(setTraining({ training: result.data }));
    }
  };

export const performClearExercises: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (id: number, onSuccessCallback) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('post', `/trainings/${id}/clear`);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_CLEAR_EXERCISES,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
      dispatch(setTraining({ training: result.data }));
    }
  };

export const performSectionsUpdate: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (
    sections: TrainingSectionWithOperation[],
    onSuccessCallback: () => void,
    onErrorCallback: () => void,
  ) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async () =>
      makeServerCallAsync(
        'patch',
        `/training-sections`,
        sections.map((item: TrainingSectionWithOperation) => ({
          id: item.id,
          isText: item.isText,
          order: item.order,
          owner: item.owner,
          title: item.title,
          description: item.description,
          training: item.training,
          training_section_parts: item.training_section_parts.map(tsp => {
            if (tsp.operation === 'CREATED') {
              // eslint-disable-next-line no-param-reassign
              // id by muselo byt optional aby typescript nerval no
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              delete tsp.id;
            }

            tsp.training_exercises.map(te => {
              if (te.operation === 'CREATED') {
                // id by muselo byt optional aby typescript nerval no

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                delete te.id;
              }

              return te;
            });

            return tsp;
          }),
        })),
      );

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_PATCH_SECTION,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
      dispatch(
        updateTrainingSections({ sections: result.data.trainingSections }),
      );
    } else if (onErrorCallback) {
      onErrorCallback();
    }
  };

export const performUpdateTime: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = (trainingId: number, time: number) => async (): Promise<void> => {
  const request = async (): Promise<AxiosResponse<any>> =>
    makeServerCallAsync(
      'put',
      `/training/updateElapsedTime/${trainingId}/time/${time}`,
    );
  await request();

  // TODO WHAT IF ERR
  // if (result && result.status === 200) {
  //   dispatch(setTraining({ training: result.data }));
  // }
};

export const performPauseTraining: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (trainingId: number, time: number) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync(
        'put',
        `/training/updateElapsedTime/${trainingId}/time/${time}`,
      );
    await dispatch(setTemporaryTime({ time }));
    await dispatch(pauseRunningTraining());

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_FETCH_TRAINING,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      dispatch(setTraining({ training: result.data }));
      dispatch(removeTemporaryTime());
    }
  };

export const performSetTrainingDate: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (
    training: TrainingDTO,
    date: Date,
    timeTo: Date,
    trainingType: TrainingTypeEnum,
    location: string,
    callbackFn: () => void,
  ) =>
  async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('put', `/trainings/${training.id}`, {
        ...training,
        dateFrom: date,
        dateTo: getUpdatedHoursForDateTo(date, timeTo),
        withTrainer: trainingType === TrainingTypeEnum.Personal,
        location,
      });

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_SET_TRAINING_DATE,
      request,
      dispatch,
      {
        errorIdMap: {
          'Training.form.error.dateFrom.beforeDateTo':
            'Čas od nemůže být později než čas do',
        },
      },
    );

    if (result && result.status === 200) {
      const updatedTraining: TrainingDTO = result.data;
      dispatch(setTraining({ training: undefined }));
      dispatch(
        updateTrainingInTrainingPlan({
          trainingPlanId: updatedTraining.training_plan_id,
          training: updatedTraining,
        }),
      );

      // Update client training plans
      const client = getState().client.clients.find(
        c => c.id === getState().client.activeClient?.id,
      );

      if (client) {
        dispatch(
          updateClient({
            client: {
              ...client,
              training_plans: [
                ...client?.training_plans.map(tp =>
                  tp.id !== updatedTraining.training_plan_id
                    ? tp
                    : {
                        ...tp,
                        trainings: tp.trainings.map(t =>
                          t.id === updatedTraining.id ? updatedTraining : t,
                        ),
                      },
                ),
              ],
            },
          }),
        );
      }
      callbackFn();
    }
  };

export const performFinishTraining: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (id: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const request = async (): Promise<AxiosResponse<any>> =>
      makeServerCallAsync('put', `/training/isFinished/${id}/`);

    const result = await baseAsyncRequest(
      ActionTypes.PERFORM_FETCH_TRAINING,
      request,
      dispatch,
    );

    if (result && result.status === 200) {
      dispatch(setTraining({ training: result.data }));
    }
  };
