/** @jsx jsx */
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Portal, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { css, jsx } from '@emotion/react';

import {
  performFetchTrainingById,
  performPauseTraining,
} from '../../../store/training/actions';
import { getSelectedTrainingWithSortedParts } from '../../../store/training/selectors';
import {
  COLORS,
  EXTENDED_MENU_HEIGHT,
  MENU_HEIGHT,
  TOP_MENU_HEIGHT,
} from '../../../styles/themes';
import { HeadingWithBackButton } from '../shared/HeadingWithBackButton';
import { ApplicationPath, ClientPath, routePathBuilder } from '../../../routes';
import { performFetchExerciseById } from '../../../store/exercisesLibrary/actions';
import { getExerciseById } from '../../../store/exercisesLibrary/selector';
import WarmUpStretchDetail from './components/WarmUpStretchDetal';
import { TrainingSectionTypeEnum } from '../../../enums/TrainingTypesEnum';
import SingleExerciseDescriptionDetail from './components/SingleExerciseDescriptionDetail';
import SuperSerieExerciseDescriptionDetail from './components/SuperSerieExerciseDescriptionDetail';
import { ExerciseTrainingTypeEnum } from '../../../strapi/ExerciseDTO';
import PauseAfterExercise from './components/PauseAfterExercise';
import { CenteredSpinner } from '../../shared/Loader';
import CircuitExerciseDescriptionDetail from './components/CircuitExerciseDescriptionDetail';
import SingleExerciseTimeDescriptionDetail from './components/SingleExerciseTimeDescriptionDetail';
import {
  getSectionsOfInterest,
  isBottomMenuHigher,
  isInPauseAfterExercise,
  trainingIsRunning,
  trainingIsRunningForInTotal,
} from '../../../store/trainingParts/selectors';
import FinalExercise from './components/FinalExerciseDetail';
import { performIsInPauseAfterExercise } from '../../../store/trainingParts/actions';
import { getCircularRunning } from '../../../store/general/selectors';

const exerciseDetail = (
  trainingId: string,
  sectionId: string,
  partId: string,
  sectionType: string,
  exerciseId: string,
  trainingExerciseId: string,
) =>
  routePathBuilder([
    ApplicationPath.Client,
    ClientPath.Training,
    trainingId,
    ClientPath.Training_Section,
    sectionId,
    ClientPath.Section_Part,
    partId || '0',
    sectionType,
    ClientPath.Exercise,
    ClientPath.Detail,
    exerciseId || '0',
    ClientPath.Training_Exercise,
    trainingExerciseId || '0',
  ]);

const TrainingDetailDescription: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    trainingId,
    exerciseId,
    sectionId,
    typeExercise,
    partId,
    trainingExerciseId,
  } = useParams<{
    trainingId: string;
    sectionId: string;
    exerciseId: string;
    typeExercise: string;
    trainingExerciseId: string;
    partId: string;
  }>();

  const dispatch = useDispatch();
  const history = useHistory();
  const training = useSelector(getSelectedTrainingWithSortedParts);
  const { currentSection } = useSelector(getSectionsOfInterest);
  const isRunning = useSelector(trainingIsRunning);
  const exercise = useSelector(getExerciseById(exerciseId));
  const isInPauseExercise = useSelector(isInPauseAfterExercise);
  const totalTime = useSelector(trainingIsRunningForInTotal);
  const isInPause = useSelector(isInPauseAfterExercise);
  const isCircuitRunning = useSelector(getCircularRunning);
  const isInFinish = typeExercise === ClientPath.Finish;
  const isMenuHigher = useSelector(
    isBottomMenuHigher(isInPauseExercise, isInFinish, isCircuitRunning),
  );

  useEffect(() => {
    if (!training || training.id !== trainingId) {
      dispatch(performFetchTrainingById(trainingId));
    }
  }, [trainingId, training, dispatch]);

  useEffect(() => {
    if (Number(exerciseId) !== 0 && (!exercise || exercise.id !== exerciseId)) {
      dispatch(performFetchExerciseById(exerciseId));
    }
  }, [exerciseId, exercise, dispatch]);

  if (!training || !isMobile) {
    return <CenteredSpinner message="Připravuji tréning" />;
  }

  const trainingExercise = training.training_sections
    .find(ts => ts.id === sectionId)
    ?.training_section_parts.find(tsp => tsp.id === partId)
    ?.training_exercises.find(te => te.id === trainingExerciseId);

  const section = training?.training_sections.find(ts => ts.id === sectionId);
  const sectionPart = section?.training_section_parts.find(
    tsp => tsp.id === partId,
  );

  if (
    (!trainingExercise &&
      typeExercise === TrainingSectionTypeEnum.SingleExercise) ||
    (!exercise && typeExercise === TrainingSectionTypeEnum.SingleExercise) ||
    !section ||
    (partId !== '0' && !sectionPart)
  ) {
    return <CenteredSpinner message="Připravuji tréning" />;
  }
  const orderOfExercise =
    trainingExercise &&
    sectionPart &&
    trainingExercise.order + sectionPart.order;

  const toOverview = () => {
    if (currentSection && training && isRunning) {
      dispatch(performPauseTraining(training.id, totalTime));
    }

    if (isInPause && training && isRunning) {
      dispatch(performIsInPauseAfterExercise(false));
    }

    history.push(
      routePathBuilder([
        ApplicationPath.Client,
        ClientPath.Training,
        training.id,
      ]),
    );
  };
  return (
    <div css={trainingDetailWrapperCss(isMenuHigher)}>
      <Portal>
        <div css={headerCss}>
          <HeadingWithBackButton title="Trénink" closeFunction={toOverview} />
        </div>
      </Portal>

      {typeExercise === ClientPath.Finish && <FinalExercise />}

      {(typeExercise === ClientPath.WarmUp ||
        typeExercise === ClientPath.Stretch) && (
        <WarmUpStretchDetail sectionId={sectionId} training={training} />
      )}
      {typeExercise === TrainingSectionTypeEnum.SingleExercise &&
        exercise?.exerciseTrainingType === ExerciseTrainingTypeEnum.REPEAT && (
          <SingleExerciseDescriptionDetail
            exercise={exercise}
            // if trainingExercise is undefined and typeExercise === SingleExercise
            // which is this case, we wont go this further. App will be "Preparing Training"
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            trainingExercise={trainingExercise!}
            orderOfExercise={orderOfExercise}
            detailLink={exerciseDetail(
              trainingId,
              sectionId,
              partId,
              TrainingSectionTypeEnum.SingleExercise,
              exerciseId,
              trainingExerciseId,
            )}
          />
        )}
      {typeExercise === TrainingSectionTypeEnum.SingleExercise &&
        exercise?.exerciseTrainingType === ExerciseTrainingTypeEnum.TIME &&
        sectionPart && (
          <div>
            <SingleExerciseTimeDescriptionDetail
              exerciseTitle={exercise.title.text}
              sectionPartId={sectionPart.id}
              sectionPartIsDone={sectionPart.isDone || sectionPart.isSkipped}
              // if trainingExercise is undefined and typeExercise === SingleExercise
              // which is this case, we wont go this further. App will be "Preparing Training"
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              trainingExercise={trainingExercise!}
              orderOfExercise={orderOfExercise}
              detailLink={exerciseDetail(
                trainingId,
                sectionId,
                partId,
                TrainingSectionTypeEnum.SingleExercise,
                exerciseId,
                trainingExerciseId,
              )}
            />
          </div>
        )}
      {typeExercise === TrainingSectionTypeEnum.SuperSet && (
        <SuperSerieExerciseDescriptionDetail
          sectionPart={sectionPart}
          detailLink={exerciseId =>
            exerciseDetail(
              trainingId,
              sectionId,
              partId,
              TrainingSectionTypeEnum.SuperSet,
              exerciseId,
              trainingExerciseId,
            )
          }
        />
      )}
      {typeExercise === TrainingSectionTypeEnum.CircuitTraining && (
        <CircuitExerciseDescriptionDetail
          sectionPart={sectionPart}
          training={training}
          detailLink={exerciseId =>
            exerciseDetail(
              trainingId,
              sectionId,
              partId,
              TrainingSectionTypeEnum.CircuitTraining,
              exerciseId,
              trainingExerciseId,
            )
          }
        />
      )}
      {typeExercise === ClientPath.Pause && (
        <PauseAfterExercise
          section={section}
          sectionPart={sectionPart}
          trainingId={trainingId}
        />
      )}
    </div>
  );
};

export default TrainingDetailDescription;

const trainingDetailWrapperCss = (higher: boolean) => css`
  padding-top: ${TOP_MENU_HEIGHT}px;
  padding-bottom: ${higher ? EXTENDED_MENU_HEIGHT : MENU_HEIGHT}px;
  background-color: ${COLORS.bcgTrainingClient};
  min-height: 96vh;
`;

const headerCss = css`
  width: 100%;
  height: ${MENU_HEIGHT}px;
  position: fixed;
  z-index: 999;
`;
