import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import { ExerciseDTO } from '../../strapi/ExerciseDTO';
import {
  setExercises,
  setSearchQuery,
  setFilter,
  setFilters,
  setVisibleCount,
  addExercise,
  updateExercise,
  deleteExercise,
  resetFilters,
} from './actions';
import { FiltersDTO } from '../../strapi/FiltersDTO';
import { Filter } from '../../interfaces/filter';

export const DEFAULT_EXERCISES_TO_SHOW = 9;

export interface State {
  exercises: ExerciseDTO[];
  searchQuery: string;
  filters: FiltersDTO | null;
  activeFilters: Filter;
  visibleCount: number;
}

export const initialState: State = {
  exercises: [],
  searchQuery: '',
  filters: null,
  activeFilters: {
    'body-part': [],
    // tslint:disable-next-line: object-literal-key-quotes
    equipment_items: [],
    'exercise-type': [],
    // tslint:disable-next-line: object-literal-key-quotes
    library: [],
  },
  visibleCount: DEFAULT_EXERCISES_TO_SHOW,
};

export const exercisesReducers = reducer<State>(
  initialState,
  on(setExercises, (state: State, { payload }) => {
    const { exercises } = payload;
    state.exercises = exercises;
  }),

  on(setSearchQuery, (state: State, { payload }) => {
    const { searchQuery } = payload;
    state.searchQuery = searchQuery;
  }),

  on(setFilter, (state: State, { payload }) => {
    const { type, filter, isActive } = payload;

    if (isActive) {
      state.activeFilters[type].push(filter);
    } else {
      state.activeFilters[type] = state.activeFilters[type].filter(
        (f: string) => f !== filter,
      );
    }
  }),
  on(resetFilters, (state: State) => {
    const emptyFilters = {
      'body-part': [],
      // tslint:disable-next-line: object-literal-key-quotes
      equipment_items: [],
      'exercise-type': [],
      // tslint:disable-next-line: object-literal-key-quotes
      library: [],
    };

    state.searchQuery = '';
    state.activeFilters = emptyFilters;
  }),

  on(setVisibleCount, (state: State, { payload }) => {
    const { visibleCount } = payload;
    state.visibleCount = visibleCount;
  }),

  on(setFilters, (state: State, { payload }) => {
    state.filters = payload.filters;
  }),

  on(addExercise, (state: State, { payload }) => {
    const { exercise } = payload;
    state.exercises = state.exercises.filter(item => item.id !== exercise.id);
    state.exercises.push(exercise);
  }),

  on(updateExercise, (state: State, { payload }) => {
    const { exercise } = payload;
    const exerciseIndex = state.exercises.findIndex(
      item => item.id === exercise.id,
    );
    state.exercises[exerciseIndex] = exercise;
  }),
  on(deleteExercise, (state: State, { payload }) => {
    const { exerciseId } = payload;
    state.exercises = state.exercises.filter(
      exercise => exercise.id !== exerciseId,
    );
  }),
);
