// tslint:disable
// eslint-disable
/* eslint-disable */
/**
 * MotiVibe API
 * MotiVibe API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateConversationResponseDto
 */
export interface CreateConversationResponseDto {
  /**
   *
   * @type {number}
   * @memberof CreateConversationResponseDto
   */
  chatId: number;
  /**
   *
   * @type {string}
   * @memberof CreateConversationResponseDto
   */
  chatName: string;
}

export function CreateConversationResponseDtoFromJSON(
  json: any,
): CreateConversationResponseDto {
  return CreateConversationResponseDtoFromJSONTyped(json, false);
}

export function CreateConversationResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateConversationResponseDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    chatId: json['chatId'],
    chatName: json['chatName'],
  };
}

export function CreateConversationResponseDtoToJSON(
  value?: CreateConversationResponseDto | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    chatId: value.chatId,
    chatName: value.chatName,
  };
}
