import { format } from 'date-fns';
import { EventDTO, EventType } from '../../interfaces/event';
import { TrainerEventDTO } from '../../strapi/TrainerEventDTO';
import { TrainingDTO } from '../../strapi/TrainingDTO';
import { COLORS } from '../../styles/themes';
import {
  determineIfEventOcursInTheSameDayAsAnyTraining,
  getNameForTraining,
} from './training';

interface IHeaderText {
  left: string;
  center: string;
  right: string;
}
// helper for getting header of calendar according to size of screen
export const getHeaderTextCalendar = (
  isMobile: boolean,
  showToday: boolean,
): IHeaderText => {
  const left = showToday ? 'today prev next' : 'prev next';
  return {
    left: isMobile ? 'title' : left,
    center: isMobile ? '' : 'title',
    right: isMobile ? 'prev, next' : 'dayGridMonth,timeGridWeek,timeGridDay',
  };
};

export const getFromToToDateText = (
  dateFrom: Date | undefined,
  dateTo: Date | undefined,
  location: string | undefined,
): string =>
  `${dateFrom ? format(dateFrom, 'dd.MM. yyyy HH:mm') : ''} ${
    dateTo ? `- ${format(dateTo, 'HH:mm')}` : ''
  } ${location ? `| ${location}` : ''} `;

export const remapEventToCalendarEvent = (
  events: TrainerEventDTO[],
): EventDTO[] =>
  events.map(event => {
    const newEvent: EventDTO = {
      id: event.id,
      start: new Date(event.dateFrom),
      end: new Date(event.dateTo),
      location: event.location,
      title: event.title,
      client: event.client,
      type: EventType.event,
    };
    return newEvent;
  });

export const remapTrainingToEvent = (training: TrainingDTO): EventDTO => ({
  id: training.id,
  title: getNameForTraining(training, 'Tréning'),
  start: training.dateFrom ? new Date(training.dateFrom) : new Date(),
  end: undefined,
  moreInOneDay: false,
  location: training.location,
  textColor: COLORS.gray8,
  borderColor: COLORS.gray8,
  type: EventType.training,
  isFinished: training.isFinished,
});

export const remapTrainerEventToClientsEvent = (
  events: TrainerEventDTO[],
  trainings: TrainingDTO[],
): EventDTO[] =>
  events.map(event => {
    const isMore = determineIfEventOcursInTheSameDayAsAnyTraining(
      trainings,
      event,
    );

    const newEvent: EventDTO = {
      id: event.id,
      title: event.title,
      start: event.dateFrom ? new Date(event.dateFrom) : new Date(),
      end: event.dateTo ? new Date(event.dateTo) : new Date(),
      moreInOneDay: isMore,
      location: event.location,
      textColor: COLORS.lightDarkBlue,
      borderColor: COLORS.lightDarkBlue,
      type: EventType.event,
    };
    return newEvent;
  });
