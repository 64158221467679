import React from 'react';
import {
  TrainingSectionTypeEnum,
  TrainingSetWrapperText,
} from '../../../../enums/TrainingTypesEnum';
import { SectionType, TypeOfSection } from '../../../../interfaces/enums';
import {
  TrainingSectionDTO,
  TrainingSectionPartsDTO,
} from '../../../../strapi/TrainingDTO';
import TrainingDetailCircuitSuperWrapper from './TrainingDetailCircuitSuperWrapper';
import TrainingDetailItem from './TrainingDetailItem';
import TrainingDetailSingleWrapper from './TrainingDetailSingleWrapper';

export interface TrainingDetailSectionWrapper {
  section: TrainingSectionDTO;
  orderOfSection: number;
}

const SIMPLE = 'simple';
const COMPLEX = 'complex';

const getSectionTypeByType = (section: TrainingSectionDTO) =>
  section.title === TypeOfSection.TRAINING ? COMPLEX : SIMPLE;

const orderTrainingSectionParts = (
  trainingSectionParts: TrainingSectionPartsDTO[],
): TrainingSectionPartsDTO[] =>
  trainingSectionParts.slice().sort((a, b) => a.order - b.order);

const TrainingDetailSectionWrapper: React.FC<TrainingDetailSectionWrapper> = ({
  section,
  orderOfSection,
}) => (
  <div>
    {getSectionTypeByType(section) === SIMPLE && (
      <TrainingDetailItem
        title={section.title}
        orderOfExercise={orderOfSection}
        sectionId={section.id}
        key={section.id}
        trainingSection={section}
        sectionType={section.title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()}
        type={SectionType.SECTION}
      />
    )}

    {getSectionTypeByType(section) === COMPLEX &&
      orderTrainingSectionParts(section.training_section_parts).map(
        (part, index) => {
          if (part.type === TrainingSectionTypeEnum.SingleExercise) {
            return (
              <TrainingDetailSingleWrapper
                part={part}
                orderOfExercise={orderOfSection + index}
                sectionId={section.id}
                key={part.id}
              />
            );
          }

          return (
            <TrainingDetailCircuitSuperWrapper
              part={part}
              orderOfExercise={orderOfSection + index}
              sectionId={section.id}
              key={part.id}
              type={TrainingSetWrapperText[part.type]}
            />
          );
        },
      )}
  </div>
);

export default TrainingDetailSectionWrapper;
