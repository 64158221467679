/** @jsx jsx */
import { FC } from 'react';
import { jsx, css } from '@emotion/react';
import { Grid, Button, useMediaQuery, useTheme } from '@material-ui/core';
import { t, Trans } from '@lingui/macro';
import { format } from 'date-fns';
import { MPX, COLORS } from '../../../styles/themes';
import { TrainingDTO } from '../../../strapi/TrainingDTO';
import { getNameForTraining } from '../../../code/helpers/training';

interface StateProps {
  training: TrainingDTO;
  hasChanges: boolean;
  disabledSave: boolean;
  showDialog: () => void;
  saveTraining: () => void;
  handleDialogCancelAgree: () => void;
}

type TrainingHeaderProps = StateProps;
export const TrainingSetupHeader: FC<TrainingHeaderProps> = ({
  training,
  hasChanges,
  showDialog,
  saveTraining,
  handleDialogCancelAgree,
  disabledSave,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid
      container
      item
      xs={12}
      direction={isMobile ? 'column' : 'row'}
      justify="space-between"
      alignItems={isMobile ? 'flex-start' : 'center'}
      css={paddingCss}
    >
      <Grid item>
        <div css={headerTitleCss}>
          {getNameForTraining(training, t`Trénink`)}
        </div>

        <div css={timeCss}>
          {training.dateFrom &&
            format(new Date(training.dateFrom), 'd.M. H:mm')}
        </div>
      </Grid>
      <Grid container item justify="flex-end">
        <Grid item>
          <Button
            size={isMobile ? 'medium' : 'small'}
            variant="outlined"
            color="primary"
            css={cancelBtnCss}
            onClick={hasChanges ? showDialog : handleDialogCancelAgree}
          >
            <Trans>Zavřít</Trans>
          </Button>
        </Grid>
        <Grid item>
          <Button
            size={isMobile ? 'medium' : 'small'}
            variant="contained"
            color="secondary"
            onClick={saveTraining}
            disabled={disabledSave}
          >
            <Trans>Uložit trénink</Trans>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const cancelBtnCss = css`
  margin-right: ${2 * MPX}px !important;
  color: ${COLORS.darkBlue} !important;
  .MuiButtonBase-root .MuiButton-root .MuiButton-outlined {
    color: ${COLORS.darkBlue} !important;
  }
`;

const headerTitleCss = css`
  color: ${COLORS.darkBlue};
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;

const timeCss = css`
  color: ${COLORS.darkBlue};
  font-size: 12px;
`;
const paddingCss = (isMobile: boolean) => css`
  padding: ${4 * MPX}px ${(isMobile ? 5 : 10) * MPX}px !important;
  width: 100%;
  background-color: ${COLORS.bg};
`;
