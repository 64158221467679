/* eslint-disable */
export const emptyStringToUndefined = <T>(obj: T): T => {
  const updated = { ...obj };
  // tslint:disable-next-line: forin
  for (const key in updated) {
    const value = updated[key];
    if (typeof value === 'string') {
      // neviem to natypovat (tomas)
      // @ts-ignore
      updated[key] = value || undefined;
    }
  }
  return updated;
};

export const stringToNumber = <T>(obj: T): T => {
  const updated = { ...obj };
  // tslint:disable-next-line: forin
  for (const key in updated) {
    const value = updated[key];
    if (typeof value === 'string' && !isNaN(Number(value))) {
      // neviem to natypovat (tomas)
      // @ts-ignore
      updated[key] = Number(value);
    }
  }
  return updated;
};

export const stringNoDiacritics = (input: string): string => {
  // normalizing to NFD splits combined characters
  // ie á => a´
  // replacing the U+0300 to U+036F range with ""
  // removes the diacritical marks
  return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
