/** @jsx jsx */
import { FC, useEffect, Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  IconButton,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { Trans } from '@lingui/macro';

import { TrainingPlanTable } from './comps/TrainingPlanTable';
import { TrainingPlanCards } from './comps/TrainingPlanCards';
import { COLORS, MPX } from '../../../../styles/themes';
import {
  perfomTrainingPlansByClientId,
  performRemoveTrainingPlan,
} from '../../../../store/trainingPlans/actions';
import { LocalTrainingPlanDTO } from '../../../../strapi/TrainingPlanDTO';

import {
  routePathBuilder,
  ApplicationPath,
  TrainerPath,
} from '../../../../routes';
import { addBtnCss, iconBtnCss } from '../../../../styles/buttons';
import { iconMobileCss, mobileBodyMargin } from '../../../../styles/general';

export interface StateProps {
  trainingPlans: LocalTrainingPlanDTO[];
  isLoading: boolean;
}

export interface DispatchProps {
  perfomTrainingPlansByClientId: typeof perfomTrainingPlansByClientId;
  clearRequestState: () => void;
  removeTrainingPlan: typeof performRemoveTrainingPlan;
}

export interface RouteParams {
  id: string;
}

type TrainingPlan = StateProps &
  DispatchProps &
  RouteComponentProps<RouteParams>;

export const TrainingPlan: FC<TrainingPlan> = ({
  trainingPlans,
  isLoading,
  match,
  clearRequestState,
  perfomTrainingPlansByClientId,
  removeTrainingPlan,
}) => {
  useEffect(() => {
    perfomTrainingPlansByClientId(match.params.id);

    return (): void => {
      clearRequestState();
    };
  }, [match.params.id, clearRequestState, perfomTrainingPlansByClientId]);
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderContent = (isMobile: boolean): JSX.Element =>
    isMobile ? (
      <TrainingPlanCards
        trainingPlans={trainingPlans.reverse()}
        removeTrainingPlan={removeTrainingPlan}
      />
    ) : (
      <TrainingPlanTable
        trainingPlans={trainingPlans}
        removeTrainingPlan={removeTrainingPlan}
      />
    );
  const renderHeader = (): JSX.Element => (
    <Grid css={headerCss}>
      <Typography variant="h5">
        <Trans>Tréninkové plány</Trans>
      </Typography>

      {!isMobile ? (
        <Button
          size="medium"
          variant="contained"
          color="secondary"
          onClick={() =>
            history.push(
              `${routePathBuilder([
                ApplicationPath.Trainer,
                TrainerPath.Clients,
              ])}/${match.params.id}/training-plans/new`,
            )
          }
        >
          <Trans>Přidat plán</Trans>
        </Button>
      ) : (
        <IconButton
          css={iconBtnCss}
          onClick={() =>
            history.push(
              `${routePathBuilder([
                ApplicationPath.Trainer,
                TrainerPath.Clients,
              ])}/${match.params.id}/training-plans/new`,
            )
          }
        >
          <AddCircle css={[addBtnCss, iconMobileCss]} />
        </IconButton>
      )}
    </Grid>
  );
  return (
    <div
      css={[!isMobile && trainingPlanTableWrapper, mobileBodyMargin(isMobile)]}
    >
      {isLoading ? (
        <CircularProgress size={48} css={loadingSpinnerCss} />
      ) : (
        <Fragment>
          {renderHeader()}
          {renderContent(isMobile)}
        </Fragment>
      )}
    </div>
  );
};

const trainingPlanTableWrapper = css`
  margin-top: ${5 * MPX}px;
  padding-left: ${10 * MPX}px;
  padding-right: ${10 * MPX}px;
`;

const loadingSpinnerCss = css`
  position: relative;
  margin-top: ${15 * MPX}px;
  left: 50%;
  margin-left: ${-6 * MPX}px;
`;

export const headerCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.blueDark};
  margin-bottom: ${4 * MPX}px;
`;
