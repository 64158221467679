import { LocalizedItemDTO } from '../../strapi/LocalizedItemDTO';
import { FormatEnum, MediaFileDTO } from '../../strapi/MediaFileDTO';

export const getMediaUrl = (relativeURL: string): string =>
  process.env.REACT_APP_STORAGE_URL + relativeURL;

export const getLocalized = (
  object: LocalizedItemDTO[],
  language: string,
): LocalizedItemDTO | undefined =>
  object.find(item => item.language === language);

export const getImageFromMedia = (
  media: MediaFileDTO[],
  format: FormatEnum,
): MediaFileDTO | undefined => {
  const found = media.find(item => item.mime.includes('image/'));
  if (found && found.formats) {
    return found.formats[format];
  }
  return found;
};

export const getVideoFromMedia = (
  media: MediaFileDTO[],
  format: FormatEnum,
): MediaFileDTO | undefined => {
  const found = media.find(item => item.mime.includes('video/'));
  if (found && found.formats) {
    return found.formats[format];
  }
  return found;
};
