/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useCallback, FC, useRef, useState, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import csLocale from '@fullcalendar/core/locales/cs';
import { Trans } from '@lingui/macro';
import { Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { EventDTO } from '../../../interfaces/event';
import { TrainingDTO } from '../../../strapi/TrainingDTO';
import { ScheduleModal } from './ScheduleModal';
import {
  performSetTrainingDate,
  setTraining,
} from '../../../store/training/actions';
import { TrainingPlanDTO } from '../../../strapi/TrainingPlanDTO';
import { getHeaderTextCalendar } from '../../../code/helpers/calendarHelper';
import { wrapperCss } from '../../../styles/calendar';
import {
  performUpdateTrainerEvent,
  setPlanningMode,
} from '../../../store/calendar/actions';
import { TrainerEventModal } from '../../schedule/TrainerEventModal';
import { UserDTO } from '../../../strapi/UserDTO';

interface CalendarProps {
  events: EventDTO[];
  training: TrainingDTO | undefined;
  performSetTrainingDate: typeof performSetTrainingDate;
  performUpdateTrainerEvent: typeof performUpdateTrainerEvent;
  setTraining: typeof setTraining;
  activeTrainingPlan: TrainingPlanDTO | undefined;
  trainingPlans: TrainingPlanDTO[];
  planningState: boolean;
  setPlanningMode: typeof setPlanningMode;
  requestError?: string | undefined;
  showToday: boolean;
  clients: UserDTO[];
  loggedUser: UserDTO | undefined;
}
interface DateClick {
  date: Date;
  dateStr: string;
  allDay: boolean;
  resource?: any;
  dayEl: HTMLElement;
  jsEvent: MouseEvent;
  view: any;
}

export const DesktopCalendar: FC<CalendarProps> = ({
  events,
  training,
  performSetTrainingDate,
  setTraining,
  activeTrainingPlan,
  trainingPlans,
  planningState,
  setPlanningMode,
  requestError = undefined,
  showToday,
  clients,
  loggedUser,
  performUpdateTrainerEvent,
}) => {
  const calendarRef = useRef(null);

  const [clientName, setClientName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [activeTrainerEvent, setActiveTrainerEvent] = useState<
    EventDTO | undefined
  >(undefined);
  const handleDateClick = useCallback(
    (arg: DateClick) => {
      // calendar is in planning state (Can't click to event)
      if (training && !training.dateFrom && planningState) {
        const trainingWithDate: TrainingDTO = {
          ...training,
          dateFrom: arg.dateStr,
        };

        setTraining({ training: trainingWithDate });
      }
    },
    [training, setTraining, planningState],
  );
  const handleEventClick = useCallback(
    (id: string) => {
      const event = events.find(e => e.id === id);
      if (event) {
        const found = trainingPlans.find(tp => tp.id === event.trainingPlanId);

        if (found) {
          setClientName(`${found.client.name} ${found.client.surname}`);
          const trainingFound = found.trainings.find(t => t.id === event.id);

          if (trainingFound) {
            // calendar is in planning state (Can't click to event)
            if (training && planningState) {
              const trainingWithDate: TrainingDTO = {
                ...training,
                dateFrom: trainingFound.dateFrom,
              };

              setTraining({ training: trainingWithDate });
            } else {
              setTraining({ training: trainingFound });
            }
          }
          // its trainer event
        } else {
          setActiveTrainerEvent(event);
        }
      }
    },
    [setTraining, events, trainingPlans, planningState, training],
  );

  const handleClose = useCallback(() => {
    setTraining({ training: undefined });
    setPlanningMode(false);
  }, [setTraining, setPlanningMode]);

  const nextTranslation = <Trans>dalších</Trans>;
  const handleSaveTrainerEvent = (event: EventDTO) => {
    if (loggedUser) {
      performUpdateTrainerEvent(event, loggedUser, () => {
        enqueueSnackbar(<Trans>Událost byla aktualizována</Trans>, {
          variant: 'success',
        });
      });
    }
  };
  const decideDisabled = (): boolean => {
    if (training) {
      if (planningState) {
        return false;
      }
      if (activeTrainingPlan) {
        return activeTrainingPlan.id !== training.training_plan_id;
      }
      return true;
    }
    return false;
  };

  const ev = events.map(e => ({
    ...e,
    className: e.trainingPlanId !== undefined ? 'training-box' : 'event-box',
  }));

  return (
    <div css={wrapperCss}>
      <Dialog
        open={!!(training && training.dateFrom)}
        onClose={(): void => handleClose()}
        css={dialogCss}
      >
        <ScheduleModal
          training={training}
          performSetTrainingDate={performSetTrainingDate}
          handleClose={handleClose}
          disabled={decideDisabled()}
          clientName={clientName}
          setPlanningMode={setPlanningMode}
          planningState={planningState}
          requestError={requestError}
        />
      </Dialog>
      <Dialog
        open={!!activeTrainerEvent}
        onClose={(): void => handleClose()}
        css={dialogCss}
      >
        <TrainerEventModal
          closeIcon={Boolean(true)}
          event={activeTrainerEvent}
          handleClose={() => setActiveTrainerEvent(undefined)}
          handleSave={event => {
            handleSaveTrainerEvent(event);
          }}
          clients={clients}
        />
      </Dialog>
      <FullCalendar
        ref={calendarRef}
        initialView="dayGridMonth"
        firstDay={1}
        locale={csLocale}
        unselectAuto={false}
        dayMaxEventRows
        moreLinkContent={(countOfevents: number): string =>
          `+ ${countOfevents} ${nextTranslation.props.children}`
        }
        eventClick={info => {
          handleEventClick(info.event.id);
        }}
        headerToolbar={getHeaderTextCalendar(false, showToday)}
        dayHeaderFormat={{ weekday: 'long' }}
        weekends
        events={ev}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
        height="auto"
        dateClick={handleDateClick}
      />
    </div>
  );
};

const dialogCss = css`
  left: 20% !important;
`;
