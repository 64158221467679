import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import { TrainingDTO } from '../../strapi/TrainingDTO';
import {
  setClientTrainings,
  setDoneAtTraining,
  setTraining,
  updateTrainingSections,
} from './actions';

export interface State {
  selectedTraining: TrainingDTO | undefined;
  clientTrainings: TrainingDTO[] | undefined;
  trainingRunningFrom: Date | undefined;
  trainingIsRunnig: boolean;
}

export const initialState: State = {
  selectedTraining: undefined,
  trainingRunningFrom: undefined,
  trainingIsRunnig: false,
  clientTrainings: [],
};

export const trainingsReducer = reducer<State>(
  initialState,
  on(setTraining, (state: State, { payload }) => {
    const { training } = payload;
    state.selectedTraining = training;
  }),
  on(setDoneAtTraining, (state: State, { payload }) => {
    const { training } = payload;
    if (state.selectedTraining && training) {
      state.selectedTraining.training_sections = training?.training_sections;
    }
  }),
  on(setClientTrainings, (state: State, { payload }) => {
    state.clientTrainings = payload ? payload.clientTrainings : [];
  }),
  on(updateTrainingSections, (state: State, { payload }) => {
    const { sections } = payload;
    if (state.selectedTraining) {
      const updatedSections = state.selectedTraining.training_sections.map(
        ts => {
          const foundSection = sections.find(sec => sec.id === ts.id);
          return foundSection && ts.id === foundSection.id ? foundSection : ts;
        },
      );
      state.selectedTraining = {
        ...state.selectedTraining,
        training_sections: updatedSections,
      };
    }
  }),
);
