/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { COLORS, MPX } from '../../../../styles/themes';

export interface TrainingDetailItemText {
  title: string;
  number?: number;
}

const TrainingDetailItemText: React.FC<TrainingDetailItemText> = ({
  title,
  number,
}) => (
  <div css={textCss}>
    <div css={wrapperCss}>
      <div css={numberSpacerCss}>{number ? `${number}.` : <div />}</div>
      <div css={numberWrapperCss(!!number)}> {title} </div>
    </div>
  </div>
);

export default TrainingDetailItemText;

const textCss = css`
  color: ${COLORS.white};
  font-weight: 700;
  width: 100%;
`;

const wrapperCss = css`
  display: flex;
  flex-wrap: nowrap;
`;

const numberWrapperCss = (hasNumber: boolean) => css`
  padding-left: ${hasNumber ? 1.75 * MPX : 0}px;
  margin-top: ${!hasNumber ? MPX : 0}px;
  margin-bottom: ${!hasNumber ? 2 * MPX : 0}px;
  text-align: left;
  overflow: hidden;
  width: 100%;
`;

const numberSpacerCss = css`
  width: ${5 * MPX}px;
`;
