import { action, payload } from 'ts-action';
import { RS } from '../../interfaces/enums';
import { AT } from '../actionTypes';

export const setRS = action(
  AT.SET_RS,
  payload<{ rs: RS; n: string; message?: string }>(),
);

export const clearRS = action(AT.CLEAR_RS, payload<AT[]>());
