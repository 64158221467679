/** @jsx jsx */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { jsx, css } from '@emotion/react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { addMonths, isSameDay } from 'date-fns';
import { divCss } from '../../schedule/TrainerSchedule';
import { getClientTrainings } from '../../../store/training/selectors';
import { performSetTrainingsFromToByLoggedClient } from '../../../store/training/actions';
import { TrainingItem } from './components/TrainingItem';
import { ApplicationPath, ClientPath, routePathBuilder } from '../../../routes';
import { Calendar } from '../../trainingSchedule/comps/Calendar';
import { TrainingDTO } from '../../../strapi/TrainingDTO';
import { LeftHeadingWithButtons } from '../../shared/LeftHeadingWithButtons';
import { CLIENT_CALENDAR_HEIGHT } from '../shared/constants';
import { getLoggedUser } from '../../../store/auth/selectors';
import {
  performFetchTrainerEventsByClientId,
  setCalendarClickDate,
} from '../../../store/calendar/actions';
import {
  getActiveDate,
  getFetchedEvents,
} from '../../../store/calendar/selectors';
import { EventDTO, EventType } from '../../../interfaces/event';
import { remapTrainingToEvent } from '../../../code/helpers/calendarHelper';
import { MPX } from '../../../styles/themes';

const TrainingPlan: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const trainings = useSelector(getClientTrainings);
  const events = useSelector(getFetchedEvents);
  const user = useSelector(getLoggedUser);
  const selectedDate = useSelector(getActiveDate);

  const [visibleTrainings, setVisibleTrainings] = useState<TrainingDTO[]>([]);
  const [visibleEvents, setVisibleEvents] = useState<EventDTO[]>([]);

  const handleClickToCalendar = useCallback(
    (date: Date) => {
      dispatch(setCalendarClickDate({ date }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(
      performSetTrainingsFromToByLoggedClient(
        addMonths(new Date(), -2),
        addMonths(new Date(), 2),
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    if (trainings) {
      let trainingsInThatDay: TrainingDTO[] = [];
      trainingsInThatDay = trainings.filter(training =>
        isSameDay(
          training?.dateFrom ? new Date(training?.dateFrom) : 0,
          selectedDate || new Date(),
        ),
      );
      setVisibleTrainings(trainingsInThatDay);
    }
  }, [selectedDate, trainings]);

  useEffect(() => {
    if (user) {
      dispatch(performFetchTrainerEventsByClientId(user.id, trainings));
    }
  }, [dispatch, user, trainings]);

  useEffect(() => {
    if (events.length) {
      let eventsInThatDay: EventDTO[] = [];
      eventsInThatDay = events.filter(event =>
        isSameDay(
          event?.start ? new Date(event?.start) : 0,
          selectedDate || new Date(),
        ),
      );
      setVisibleEvents(eventsInThatDay);
    }
  }, [events, selectedDate]);

  const handleSetTraining = useCallback(
    // eslint-disable-next-line
    (payload: { training: TrainingDTO | undefined }) => {},
    [],
  );

  const toDisplay = visibleEvents
    .concat(visibleTrainings.map(training => remapTrainingToEvent(training)))
    .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());

  return (
    <div>
      {isMobile && trainings && (
        <div css={divCss}>
          <LeftHeadingWithButtons title="Rozvrh" />

          <div css={calendarCss}>
            <Calendar
              isClient
              planningState
              training={undefined}
              setTraining={handleSetTraining}
              selectedDate={selectedDate}
              setSelectedDate={handleClickToCalendar}
              eventsMobile={events}
            />
          </div>
          {toDisplay.map(event => (
            <Link
              css={linkCss}
              key={event.id}
              to={routePathBuilder([
                ApplicationPath.Client,
                event.type === EventType.training
                  ? ClientPath.Preview
                  : ClientPath.Event,
                event.id,
              ])}
            >
              <TrainingItem event={event} />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const linkCss = css`
  text-decoration: none !important;
`;

const calendarCss = css`
  height: ${CLIENT_CALENDAR_HEIGHT};
  padding-left: ${MPX * 2.5}px;
  padding-right: ${MPX * 2.5}px;
  overflow-y: scroll;
  position: relative;
`;

export default TrainingPlan;
