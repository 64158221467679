/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';
import {
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import { AddCircle, Create } from '@material-ui/icons';
import { Trans } from '@lingui/macro';
import { cancelBtnCss } from '../../trainingSetup/comps/TrainingSetupHeader';
import { MPX, COLORS } from '../../../styles/themes';
import { addBtnCss, iconBtnCss } from '../../../styles/buttons';
import { iconMobileCss } from '../../../styles/general';

export interface ProgressItemProps {
  title: React.ReactNode;
  titleAdd: React.ReactNode;
  numberOfInputs: number;
  canAdd?: boolean;
  canModify?: boolean;
  handleEdit?: () => void;
  handleAdd?: () => void;
}

export const ProgressItem: React.FC<ProgressItemProps> = props => {
  const {
    children,
    title,
    titleAdd,
    numberOfInputs,
    handleAdd,
    handleEdit,
    canAdd = true,
    canModify = true,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div css={progressWrapperCss}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5" css={colorTextCss}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          {!isMobile && numberOfInputs > 0 && (
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              css={cancelBtnCss}
              onClick={handleEdit}
            >
              <Trans>Upravit</Trans>
            </Button>
          )}

          {isMobile && numberOfInputs > 0 && canModify && (
            <IconButton css={iconBtnCss} onClick={handleEdit}>
              <Create css={editIcon(isMobile)} />
            </IconButton>
          )}

          {isMobile && canAdd && (
            <IconButton css={iconBtnCss} onClick={handleAdd}>
              <AddCircle css={[addBtnCss, iconMobileCss]} />
            </IconButton>
          )}

          {!isMobile && (
            <Button
              size="medium"
              variant="contained"
              color="secondary"
              onClick={handleAdd}
            >
              {titleAdd}
            </Button>
          )}
        </Grid>
      </Grid>
      <div css={childrenCss}>{children}</div>
    </div>
  );
};

const colorTextCss = css`
  color: ${COLORS.darkBlue};
`;
const childrenCss = css`
  margin-top: ${2 * MPX}px;
`;

const progressWrapperCss = css`
  margin-bottom: ${12.5 * MPX}px;
  width: 100%;
  position: relative;
`;

const editIcon = (isMobile: boolean): SerializedStyles => css`
  background: ${COLORS.baseOrange} !important;
  color: white;
  padding: ${MPX}px !important;
  border-radius: 50%;
  margin-right: ${isMobile && `${MPX * 2}`}px !important;
  transform: translate(4%, 2%) scale(0.833) !important;
`;
