import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import { setRS, clearRS } from './actions';
import { RS } from '../../interfaces/enums';
import { AT } from '../actionTypes';

export const initialState = {};

export interface LoadingReducerState {
  [key: string]: boolean;
}
const clearStates = (
  state: ErrorReducerState | LoadingReducerState | SuccessReducerState,
  actionTypes: AT[],
): void => {
  actionTypes.forEach(type => {
    if (type in state) {
      delete state[type];
    }
  });
};

export const loadingReducer = reducer<LoadingReducerState>(
  initialState,
  on(setRS, (state: LoadingReducerState, { payload: { n, rs } }) => {
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving LOADING
    //      and false when receiving SUCCESS / FAILURE
    state[n] = rs === RS.Loading;
  }),
  on(clearRS, (state: LoadingReducerState, { payload }) => {
    clearStates(state, payload);
  }),
);

export interface SuccessReducerState {
  [key: string]: boolean;
}
export const successReducer = reducer<SuccessReducerState>(
  initialState,
  on(setRS, (state: SuccessReducerState, { payload: { n, rs } }) => {
    // Store whether a request was successfully resulted or not
    // e.g. will be true when receiving SUCCESS
    //      and false when receiving LOADING / FAILURE
    state[n] = rs === RS.Success;
  }),
  on(clearRS, (state: SuccessReducerState, { payload }) => {
    clearStates(state, payload);
  }),
);

export interface ErrorReducerState {
  [key: string]: string | undefined;
}
export const errorReducer = reducer<ErrorReducerState>(
  initialState,
  on(setRS, (state: ErrorReducerState, { payload: { n, rs, message } }) => {
    // Store errorMessage
    // e.g. stores errorMessage when receiving FAILURE
    //      else clear errorMessage when receiving LOADING
    state[n] = rs === RS.Failure ? message : undefined;
  }),
  on(clearRS, (state: ErrorReducerState, { payload }) => {
    clearStates(state, payload);
  }),
);
