import { action, payload } from 'ts-action';
import { AppLanguages } from '../../code/constants/languages';
import { AT } from '../actionTypes';

export const changeUiLanguage = action(
  AT.CHANGE_UI_LANGUAGE,
  payload<{ nextLanguage: AppLanguages }>(),
);

export const changeCircularRunning = action(
  AT.CHANGE_CIRCULAR_RUNNING,
  payload<{ running: boolean }>(),
);
