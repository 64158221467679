import { css } from '@emotion/react';
import { makeStyles } from '@material-ui/styles';
import { COLORS, MPX } from './themes';

export const addBtnCss = css`
  color: ${COLORS.baseOrange} !important;
`;

export const iconBtnCss = css`
  padding: 0px !important;
`;

export const useDeCapitilizeButtonStyles = makeStyles(() => ({
  root: {
    textTransform: 'initial',
    paddingLeft: MPX * 7,
    paddingRight: MPX * 7,
    letterSpacing: 0.3,
  },
  outlined: { color: COLORS.white, borderWidth: 2 },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    float: 'left',
  },
}));
