/* eslint-disable */
import { Capacitor } from '@capacitor/core';
import {
  set as setCookie,
  getJSON as getCookieJSON,
  remove as removeCookie,
  CookieAttributes,
} from 'js-cookie';
import { DefaultApi, Configuration } from '../../api/src';
import { cookieName, cookieExpiresInDays } from '../constants/api';

const isIOS = Capacitor.getPlatform() === 'ios';

const set = (
  key: string,
  value: Record<string, any>,
  options?: CookieAttributes,
): string | void =>
  isIOS
    ? window.localStorage.setItem(key, JSON.stringify(value))
    : setCookie(key, value, options);

const getJSON = (key: string): any =>
  isIOS
    ? JSON.parse(window.localStorage.getItem(key) ?? 'null')
    : getCookieJSON(key);

const remove = (key: string): void =>
  isIOS ? window.localStorage.removeItem(key) : removeCookie(key);

export const getBasicSettings = (): string | any => {
  const data = getJSON(cookieName);
  let apiKey;

  if (data) {
    apiKey = `Bearer ${data.token}`;
  }

  return {
    apiKey,
  };
};

export let api: DefaultApi;

export const refreshApi = (): void => {
  api = new DefaultApi(new Configuration(getBasicSettings()));
};

refreshApi();

export const saveUserToCookie = (token: string, id: number): void => {
  set(
    cookieName,
    {
      token,
      id,
    },
    {
      expires: cookieExpiresInDays,
    },
  );
  refreshApi();
};

export const receiveUserFromCookie = ():
  | { token: string; id: number }
  | undefined => {
  const data = getJSON(cookieName);
  if (data) {
    const { token, id } = data;
    if (token != null && id != null) {
      return {
        token,
        id,
      };
    }
  }

  return undefined;
};

export const removeUserFromCookie = (): void => {
  remove(cookieName);
  refreshApi();
};
