import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import { setConversation, updateConversation, markAllAsRead } from './actions';
import { MessageDTO } from '../../strapi/MessageDTO';

export interface State {
  messages: MessageDTO[];
}

export const initialState: State = {
  messages: [],
};

export const chatReducer = reducer<State>(
  initialState,
  on(setConversation, (state: State, { payload }) => {
    state.messages = payload;
  }),
  on(updateConversation, (state: State, { payload }) => {
    state.messages.push(payload);
  }),
  on(markAllAsRead, (state: State, { payload }) => {
    state.messages = state.messages.map(m =>
      payload === m.from.id ? { ...m, isRead: true } : m,
    );
  }),
);
