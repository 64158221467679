/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { TrainingSectionPartsDTO } from '../../../../strapi/TrainingDTO';
import TrainingDetailItem from './TrainingDetailItem';
import { SectionType } from '../../../../interfaces/enums';

export interface TrainingDetailCircuitSuperWrapper {
  part: TrainingSectionPartsDTO;
  orderOfExercise: number;
  sectionId: string;
  type: string;
}

const TrainingDetailCircuitSuperWrapper: React.FC<TrainingDetailCircuitSuperWrapper> =
  ({ part, orderOfExercise, type, sectionId }) => (
    <div>
      <TrainingDetailItem
        title={type}
        orderOfExercise={orderOfExercise}
        sectionId={sectionId}
        key={sectionId}
        trainingSection={part}
        sectionType={part.type}
        partId={part.id}
        type={SectionType.SECTION_PART}
      />
      {part.training_exercises.map(exercise => (
        <div css={superAndCircuitCss} key={exercise.id}>
          <TrainingDetailItem
            title={exercise.exercise.title.text}
            sectionId={sectionId}
            partId={part.id}
            exerciseId={exercise.exercise.id}
            trainingExerciseId={exercise.id}
            trainingSection={part}
            sectionType={part.type}
            type={SectionType.SECTION_PART}
          />
        </div>
      ))}
    </div>
  );

export default TrainingDetailCircuitSuperWrapper;

const superAndCircuitCss = css`
  padding-left: 10px !important;
`;
