import { Method, AxiosResponse } from 'axios';
import { http } from './http';

/**
 * Calls the server and show error/success notification
 * @param method API call method
 * @param url API call url
 * @param doneCallback what to do with the response
 * @param payload API call payload
 * @param errorNotificationText text to show when something happen
 * @param doneNotificationText text to show when success
 */
export const makeServerCallAsync = async (
  method: Method,
  url: string,
  payload?: unknown,
): Promise<AxiosResponse> => {
  const result = await http.request({
    method,
    url,
    data: payload,
  });
  return result;
};
