import { lazy } from 'react';
import { RouteProps, useLocation } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import EqualizerFilledIcon from '@material-ui/icons/Equalizer';
import DescriptionFilledIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import TrainerScheduleContainer from './components/schedule/TrainerScheduleContainer';
import ClientNotesContainer from './components/clientDetail/notes/ClientNotesContainer';
import ClientProgressContainer from './components/clientProgress/ClientProgressContainer';
import PasswordSettingsContainer from './components/accountSettings/comps/PasswordSettingsContainer';
import TrainingPlanClient from './components/customerApp/trainingPlan/TrainingPlan';
import TrainingDetail from './components/customerApp/trainingDetail/TrainingDetail';
import TrainingDetailDescription from './components/customerApp/trainingDetailDescription/TrainingDetailDescription';
import TrainingOverview from './components/customerApp/trainingOverview/TrainingOverview';
import EventPreview from './components/customerApp/trainingPreview/EventPreview';
import { Password } from './components/customerApp/customerSettings/Password';

export enum TrainerPath {
  Overview = 'overview',
  Clients = 'clients',
  New = 'new',
  Edit = 'edit',
  Schedule = 'schedule',
  Chat = 'chat',
  Plans = 'plans',
  Exercises = 'exercises',
}

export enum ClientPath {
  Plans = 'plans',
  Schedule = 'schedule',
  Progres = 'progress',
  Chat = 'chat',
  Documents = 'documents',
  Others = 'others',
  Training = 'training',
  Description = 'description',
  Training_Section = 'training_section',
  Exercise = 'exercise',
  Section_Part = 'section_part',
  Training_Exercise = 'training_exercise',
  Detail = 'detail',
  Pause = 'pause',
  WarmUp = 'zahrati',
  Stretch = 'protazeni',
  Finish = 'ukonceni',
  Password = 'password',
  Preview = 'trainingPreview',
  Event = 'eventPreview',
}

export enum ApplicationPath {
  Login = 'login',
  Register = 'register',
  Activate = 'activate',
  Trainer = 't',
  Client = 'c',
  Home = '',
  Reset = 'reset',
  Forgot = 'forgot',
  Settings = 'settings',
  SetsProfile = 'profile',
  SetsPass = 'password',
  SetsPayments = 'payments',
  SetsLang = 'language',
  SetsNotifications = 'notifications',
  SetsApps = 'apps',
  Status = 'status',
  Help = 'help',
}

enum IEnum {}
type RP<T1 extends typeof IEnum, T2 extends typeof IEnum> = T1 & T2;
type RPTypeHelper<
  T1 extends typeof IEnum,
  T2 extends typeof IEnum,
  T3 extends typeof IEnum,
> = T1[keyof T1] | T2[keyof T2] | T3[keyof T3];

export type RPType = RPTypeHelper<
  typeof ApplicationPath,
  typeof TrainerPath,
  typeof ClientPath
>;

// RouterPath = Route Path
export const RouterPath: RP<typeof ApplicationPath, typeof TrainerPath> = {
  ...ApplicationPath,
  ...TrainerPath,
};

// generate route path string for passed route names
export const routePathBuilder = (
  path: RPType[] | RPType | string | string[],
): string => {
  if (!Array.isArray(path)) {
    // eslint-disable-next-line no-param-reassign
    path = [path];
  }
  return `/${path.join('/')}`;
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = (): URLSearchParams =>
  new URLSearchParams(useLocation().search);

const LoginContainer = lazy(
  () => import('./components/auth/login/LoginContainer'),
);
const RegisterContainer = lazy(
  () => import('./components/auth/register/RegisterContainer'),
);
const AccountActivationContainer = lazy(
  () =>
    import('./components/auth/accountActivation/AccountActivationContainer'),
);
const ProfileSettingsContainer = lazy(
  () => import('./components/accountSettings/comps/ProfileSettingsContainer'),
);
const ForgotPasswordContainer = lazy(
  () => import('./components/auth/forgotPassword/ForgotPasswordContainer'),
);
const ResetPasswordContainer = lazy(
  () => import('./components/auth/resetPassword/ResetPasswordContainer'),
);
const AccountSettingsContainer = lazy(
  () => import('./components/accountSettings/AccountSettingsContainer'),
);
const ClientListContainer = lazy(
  () => import('./components/trainer/clientList/ClientListContainer'),
);
const NewClientContainer = lazy(
  () => import('./components/trainer/newClient/NewClientContainer'),
);
const ClientDetailProfileContainer = lazy(
  () =>
    import('./components/clientDetail/profile/ClientDetailProfileContainer'),
);
const ClientTabProfile = lazy(
  () => import('./components/clientDetail/tabs/ClientTabProfileContainer'),
);
const ExercisesLibraryContainer = lazy(
  () =>
    import('./components/trainer/exercisesLibrary/ExercisesLibraryContainer'),
);
const ChatContainer = lazy(
  () => import('./components/trainer/chat/ChatContainer'),
);
const NewChatContainer = lazy(
  () => import('./components/trainer/newChat/NewChatContainer'),
);

const TrainingPlanContainer = lazy(
  () =>
    import('./components/clientDetail/tabs/trainingPlan/TrainingPlanContainer'),
);

const TrainingPreview = lazy(
  () => import('./components/customerApp/trainingPreview/TrainingPreview'),
);

const TrainingPlanDetailContainer = lazy(
  () => import('./components/trainingPlanDetail/TrainingPlanDetailContainer'),
);

const TrainingListContainer = lazy(
  () => import('./components/trainingsList/TrainingsListContainer'),
);

const TrainingSetupContainer = lazy(
  () => import('./components/trainingSetup/TrainingSetupContainer'),
);

const NewExerciseContainer = lazy(
  () =>
    import(
      // Disable because of wierd prettier formatting
      // tslint:disable-next-line: trailing-comma
      './components/trainer/exercisesLibrary/newExercise/NewExerciseContainer'
    ),
);

const EditExerciseContainer = lazy(
  () =>
    import(
      // Disable because of wierd prettier formatting
      // tslint:disable-next-line: trailing-comma
      './components/trainer/exercisesLibrary/editExercise/EditExerciseContainer'
    ),
);

const NewTrainingPlanContainer = lazy(
  () => import('./components/newTrainingPlan/NewTrainingPlanContainer'),
);

const EditTrainingPlanContainer = lazy(
  () => import('./components/editTrainingPlan/EditTrainingPlanContainer'),
);

const DuplicateTrainingPlan = lazy(
  () =>
    import('./components/duplicateTrainingPlan/DuplicateTrainingPlanContainer'),
);

const TrainingScheduleContainer = lazy(
  () => import('./components/trainingSchedule/TrainingScheduleContainer'),
);

const ClientDocsContainer = lazy(
  () => import('./components/clientDetail/docs/ClientDocsContainer'),
);

export interface RouteSetup {
  path: string;
  props: RouteProps;
}

export interface LinkRouteSetup extends RouteSetup {
  icon?: (props: SvgIconProps) => JSX.Element;
  title: string;
  mobileOrder?: number;
}

export const MOBILE_TRAINER_INNER_ROUTES: LinkRouteSetup[] = [
  {
    path: routePathBuilder([ApplicationPath.Trainer, TrainerPath.Exercises]),
    props: {
      component: ExercisesLibraryContainer,
      exact: true,
    },
    icon: AccessibilityNewIcon,
    title: 'Knihovna cviků',
  },
];

export const MOBILE_CLIENT_INNER_ROUTES: LinkRouteSetup[] = [
  {
    path: routePathBuilder([ApplicationPath.Client, ClientPath.Documents]),
    props: {
      component: ClientDocsContainer,
      exact: true,
    },
    icon: DescriptionFilledIcon,
    title: 'Dokumenty',
  },
];

export const TRAINER_ROUTES: LinkRouteSetup[] = [
  {
    path: routePathBuilder([ApplicationPath.Trainer, TrainerPath.Clients]),
    props: {
      component: ClientListContainer,
      exact: true,
    },
    icon: PeopleIcon,
    title: 'Klienti',
    mobileOrder: 2,
  },
  {
    path: routePathBuilder([ApplicationPath.Trainer, TrainerPath.Schedule]),
    props: {
      component: TrainerScheduleContainer,
      exact: true,
    },
    icon: DateRangeIcon,
    title: 'Rozvrh',
    mobileOrder: 3,
  },
  {
    path: routePathBuilder([ApplicationPath.Trainer, TrainerPath.Chat]),
    props: {
      component: ChatContainer,
      exact: true,
    },
    icon: ChatBubbleOutlineIcon,
    title: 'Zprávy',
    mobileOrder: 4,
  },
  ...MOBILE_TRAINER_INNER_ROUTES,
];

export const CLIENT_MOBILE_ROUTES: LinkRouteSetup[] = [
  {
    path: routePathBuilder([ApplicationPath.Client, ClientPath.Progres]),
    props: {
      component: ClientProgressContainer,
      exact: true,
    },
    icon: EqualizerFilledIcon,
    title: 'Progres',
    mobileOrder: 2,
  },
  {
    path: routePathBuilder([ApplicationPath.Client, ClientPath.Schedule]),
    props: {
      component: TrainingPlanClient,
      exact: true,
    },
    icon: DateRangeIcon,
    title: 'Rozvrh',
    mobileOrder: 3,
  },
  {
    path: routePathBuilder([ApplicationPath.Client, ClientPath.Chat]),
    props: {
      component: ChatContainer,
      exact: true,
    },
    icon: ChatBubbleOutlineIcon,
    title: 'Zprávy',
    mobileOrder: 4,
  },
  ...MOBILE_CLIENT_INNER_ROUTES,
];

export const LOGGED_APP_ROUTES: LinkRouteSetup[] = [
  {
    path: routePathBuilder(ApplicationPath.Settings),
    props: {
      component: AccountSettingsContainer,
      exact: false,
    },
    icon: SettingsIcon,
    title: 'Nastavení',
  },
];

export const PUBLIC_APP_ROUTES: RouteSetup[] = [
  {
    path: routePathBuilder(ApplicationPath.Login),
    props: { exact: true, component: LoginContainer },
  },
  {
    path: routePathBuilder(ApplicationPath.Register),
    props: { exact: true, component: RegisterContainer },
  },
  {
    path: routePathBuilder(ApplicationPath.Forgot),
    props: { exact: true, component: ForgotPasswordContainer },
  },
  {
    path: routePathBuilder(ApplicationPath.Reset),
    props: { exact: true, component: ResetPasswordContainer },
  },
  {
    path: routePathBuilder([ApplicationPath.Reset, ApplicationPath.Activate]),
    props: { exact: true, component: AccountActivationContainer },
  },
];

export const ACCOUNT_SETTINGS_ROUTES: LinkRouteSetup[] = [
  {
    path: routePathBuilder([
      ApplicationPath.Settings,
      ApplicationPath.SetsProfile,
    ]),
    props: {
      exact: true,
      component: ProfileSettingsContainer,
    },
    title: 'Profil',
  },
  {
    path: routePathBuilder([
      ApplicationPath.Settings,
      ApplicationPath.SetsPass,
    ]),
    props: {
      exact: true,
      component: PasswordSettingsContainer,
    },
    title: 'Heslo',
  },
];

export const TRAINER_MANAGEMENT_CLIENT_ROUTES: RouteSetup[] = [
  {
    path: routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
      TrainerPath.New,
    ]),
    props: {
      component: NewClientContainer,
      exact: true,
    },
  },
];

export const TRAINER_MANAGEMENT_EXERCISE_ROUTES: RouteSetup[] = [
  {
    path: routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Exercises,
      TrainerPath.New,
    ]),
    props: {
      component: NewExerciseContainer,
      exact: true,
    },
  },
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Exercises,
    ])}/:id`,
    props: {
      component: EditExerciseContainer,
      exact: true,
    },
  },
];

export const TRAINING_PLAN_ROUTES: LinkRouteSetup[] = [
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/training-plans/:trainingPlanId/trainings`,
    props: {
      component: TrainingListContainer,
      exact: true,
    },
    title: 'Tréninky',
  },
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/training-plans/:trainingPlanId/schedule`,
    props: {
      component: TrainingScheduleContainer,
      exact: true,
    },
    title: 'Harmonogram',
  },
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/training-plans/:trainingPlanId/informations`,
    props: {
      component: EditTrainingPlanContainer,
      exact: true,
    },
    title: 'Informace',
  },
];

export const TRAINING_ROUTES: RouteSetup[] = [
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/training-plans/:trainingPlanId/trainings/:trainingId/setup`,
    props: {
      component: TrainingSetupContainer,
      exact: true,
    },
  },
];

export const HIDDEN_ROUTES: RouteSetup[] = [
  ...TRAINER_MANAGEMENT_CLIENT_ROUTES,
  ...TRAINER_MANAGEMENT_EXERCISE_ROUTES,
  ...TRAINING_ROUTES,
  {
    path: routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Chat,
      TrainerPath.New,
    ]),
    props: {
      component: NewChatContainer,
      exact: true,
    },
  },
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Chat,
    ])}/:id`,
    props: {
      component: ChatContainer,
      exact: true,
    },
  },
  {
    path: `${routePathBuilder([ApplicationPath.Client, TrainerPath.Chat])}/:id`,
    props: {
      component: ChatContainer,
      exact: true,
    },
  },
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/training-plans/new`,
    props: {
      component: NewTrainingPlanContainer,
      exact: true,
    },
  },
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/training-plans/:trainingPlanId/duplicate`,
    props: {
      component: DuplicateTrainingPlan,
      exact: true,
    },
  },
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/training-plans/:trainingPlanId`,
    props: {
      component: TrainingPlanDetailContainer,
      exact: false,
    },
  },

  // This route has to be last because of `exact:false`.
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id`,
    props: {
      component: ClientDetailProfileContainer,
      exact: false,
    },
  },
];

export const CLIENT_PROFILE_ROUTES: LinkRouteSetup[] = [
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/progress`,
    props: {
      component: ClientProgressContainer,
      exact: true,
    },
    title: 'Progres',
  },
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/training-plans`,
    props: {
      component: TrainingPlanContainer,
      exact: true,
    },
    title: 'Tréninkové plány',
  },
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/notes`,
    props: {
      component: ClientNotesContainer,
      exact: true,
    },
    title: 'Poznámky',
  },
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/documents`,
    props: {
      component: ClientDocsContainer,
      exact: true,
    },
    title: 'Dokumenty',
  },
  {
    path: `${routePathBuilder([
      ApplicationPath.Trainer,
      TrainerPath.Clients,
    ])}/:id/profile`,
    props: {
      component: ClientTabProfile,
      exact: true,
    },
    title: 'Profil',
  },
];

export const CLIENT_ROUTES: RouteSetup[] = [
  {
    path: '/c/plans',
    props: {
      component: TrainingPlanClient,
      exact: true,
    },
  },
  {
    path: '/c/password',
    props: {
      component: Password,
      exact: true,
    },
  },
  {
    path: '/c/trainingPreview/:id/',
    props: {
      component: TrainingPreview,
      exact: true,
    },
  },
  {
    path: '/c/eventPreview/:eventId/',
    props: {
      component: EventPreview,
      exact: true,
    },
  },
  {
    path: '/c/training/:trainingId/training_section/:sectionId/section_part/:partId/:typeExercise/exercise/:exerciseId/training_exercise/:trainingExerciseId/',
    props: {
      component: TrainingDetailDescription,
      exact: true,
    },
  },
  {
    path: '/c/training/:trainingId/training_section/:sectionId/section_part/:partId/:typeExercise/exercise/detail/:exerciseId/training_exercise/:trainingExerciseId/',
    props: {
      component: TrainingOverview,
      exact: true,
    },
  },
];

export const TRAINING_CLIENT_ROUTE: RouteSetup = {
  path: '/c/training/:id/',
  props: {
    component: TrainingDetail,
    exact: true,
  },
};
